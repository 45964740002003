import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription, timer } from "rxjs";
import { take, map } from "rxjs/operators";

// Services
import { SignUpService } from '../services/sign-up.service';
import { SurveyService } from '../services/survey.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-go-live',
  templateUrl: './go-live.component.html',
  styleUrls: ['./go-live.component.scss']
})
export class GoLiveComponent implements OnInit, OnDestroy {
  botHtmlCode = '';
  clientId = '';
  
  widgetSub: Subscription;
  complete: boolean = false;
  
  secondsLeft = 0;

  botTag;

  constructor(
    private signUpService: SignUpService,
    private surveyService: SurveyService,
    private ds: DomSanitizer
    ) { }

  ngOnInit(): void {
    this.clientId = this.signUpService.clientId;
    console.log(this.clientId);
    this.botTag = this.ds.bypassSecurityTrustHtml(`<chat-widget id="gc" key="${this.clientId}"></chat-widget>`)
   
    this.widgetSub = this.surveyService.widget$
    .subscribe(status => {
      this.complete = status;
      console.log(status);
    })
      //  this.complete = true;

    this.botHtmlCode = `
        <chat-widget id="gc" key="${this.clientId}"></chat-widget>
        <script type='text/javascript' src='https://widget-guestchat.web.app/guestchat.js'></script>
        `;

    
        const countdown$ = timer(0, 1000).pipe(
          take(90),
          map(secondsElapsed => 90 - secondsElapsed)
        );
        
        countdown$.subscribe(secondsLeft => {
          this.secondsLeft = secondsLeft;
        });
  }


  ngOnDestroy(): void {
    if (this.widgetSub) {
      this.widgetSub.unsubscribe();
    }
  }
}