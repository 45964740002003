import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { 
  NbRadioModule,
  NbCardModule,
  NbButtonModule,
  NbInputModule,
  NbSpinnerModule,
  NbActionsModule,
  NbIconModule,
  NbListModule,
  NbRouteTabsetModule,
  NbTabsetModule,
  NbCheckboxModule,
  NbSelectModule,
  NbTreeGridModule,
  NbWindowModule,
  NbTagModule, 
  NbLayoutModule,
  NbPopoverModule,
  NbAutocompleteModule} from '@nebular/theme';
  // import { AutocompleteLibModule } from 'angular-ng-autocomplete';

// import { SurveyComponent } from './survey.component';
import { SurveyPageOneComponent } from './survey-page-one/survey-page-one.component';
import { SurveyPageTwoComponent } from './survey-page-two/survey-page-two.component';
import { SurveyPageThreeComponent } from './survey-page-three/survey-page-three.component';
import { SurveyPageFourComponent } from './survey-page-four/survey-page-four.component';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


import { CustomComponentsModule } from '../custom-components/custom-components.module';

// const routes: Routes = [
//   {path: '', component: SurveyComponent },
// ];

@NgModule({
  declarations: [
    // SurveyComponent,
    SurveyPageOneComponent,
    SurveyPageTwoComponent,
    SurveyPageThreeComponent,
    SurveyPageFourComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbIconModule,
    NbListModule,
    NbSpinnerModule,
    NbRouteTabsetModule,
    NbTabsetModule,
    NbRadioModule,
    NbLayoutModule,
    NbCheckboxModule,
    NbSelectModule,
    NbTreeGridModule,
    NbWindowModule.forChild(),
    NbActionsModule,
    ReactiveFormsModule,
    FormsModule,
    // RouterModule.forChild(routes),
    NbTagModule,
    NbPopoverModule,
    NbAutocompleteModule,
    // AutocompleteLibModule,

    CustomComponentsModule
   ],
   exports: [
    SurveyPageOneComponent,
    SurveyPageTwoComponent,
    SurveyPageThreeComponent,
    SurveyPageFourComponent
   ],
})
export class SurveyModule { }
