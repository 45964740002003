<form [formGroup]="itemForm">
        <!-- Property Caters To -->
        <div class="row gc-survey">
            <div class="gc-survey-header col-12">
                <div class="gc-survey-header-left">
                    <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>                                    
                    <div>Property Caters To</div>
                </div>
                <div class="gc-survey-header-right">
                    <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[0]}" (click)="onCollapseToggle(0, $event)">
                        <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                    </div>
                    <!-- <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info" class="disabled" ></nb-icon> -->
                </div>
            </div>
            <div class="gc-survey-content col-12" *ngIf="!collapsedArray[0]">
                <div class="row gc-spacing">
                    <div class="col-12 col-md-7">
                        <div class="form-group">
                            <label class="label">Choose all that apply</label>
                            <ngx-checkbox-group 
                                [options]="[
                                    'Children', 
                                    'Property is wheelchair accessible',
                                    'Events',
                                    'Group Bookings',
                                    'Birthdays',
                                    'Long Term Renters',
                                    'Anniversaries',
                                    'Pets',
                                    'Weddings',
                                    'Service Animals',
                                    'Conferences'
                                ]" 
                                formControlName="propertyCatersTo"></ngx-checkbox-group> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Child Policy -->
        <div class="row gc-survey" *ngIf="isCateringTo('Children')">
            <div class="gc-survey-header col-12">
                <div class="gc-survey-header-left">
                    <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>                                    
                    <div>Child Policy</div>
                </div>
                <div class="gc-survey-header-right">
                    <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[1]}" (click)="onCollapseToggle(1, $event)">
                        <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                    </div>
                    <!-- <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info" class="disabled" ></nb-icon> -->
                </div>
            </div>
            <div class="gc-survey-content col-12" *ngIf="!collapsedArray[1]">
                <div class="row gc-spacing">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label class="label">
                                At what age (and older) are children allowed at the property?
                            </label>
                            <select
                                formControlName="ageOfChildrenAllowed">
                                <option
                                    *ngFor="let age of ageFromAllowed"
                                    [value]="age.value">
                                    {{ age.value }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row gc-spacing" *ngIf="childPriceRangesFA.length > 0">
                    <div class="col-12 col-md-6">
                        <label class="label">Child rates (per child, per night)</label>
                        <div class="outer-form-array-div" formArrayName="childPriceRanges">
                            <div class="form-array-item" *ngFor="let priceRange of childPriceRangesFA.controls;">
                                <ng-container [formGroup]="priceRange">
                                    <div class="row">
                                        <div class="col-12">
                                            <table>
                                                <tr>
                                                    <td><label class="label">Age From</label></td>
                                                    <td><label class="label">Age To</label></td>
                                                    <td><label class="label">Price (USD)</label></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><input
                                                        class="form-control"
                                                        formControlName="childPriceRangesAgeFrom"
                                                        type="number"
                                                        min="0"
                                                        nbInput></td>
                                                    <td><input
                                                        formControlName="childPriceRangesAgeTo"
                                                        type="number"
                                                        min="0"
                                                        nbInput></td>                                                        
                                                    <td><input
                                                        formControlName="childPriceRangesPrice"
                                                        class="form-control"
                                                        type="number"
                                                        min="0"
                                                        nbInput></td>
                                                    <td><a class="delete-schedule" (click)="onDeletePriceRange(priceRange)"><i class="fa fa-trash"></i></a></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gc-spacing">
                    <div class="col-12">
                        <a class="link-btn" (click)="addPriceRange()">Add Price Range</a>
                    </div>
                </div>
                <div class="row gc-spacing">
                    <div class="col-12 col-md-8">
                        <div class="form-group">
                            <nb-checkbox
                                #hasCribsAvailable
                                formControlName="hasCribsAvailable">
                                Cribs/Cots available
                            </nb-checkbox>
                            <div *ngIf="hasCribsAvailable.checked" class="container">
                                <nb-radio-group
                                    name="cribsCost"
                                    #cribsCost
                                    formControlName="cribsCost">
                                    <nb-radio value="Free">Free</nb-radio>
                                    <nb-radio value="Surcharge">Surcharge</nb-radio>
                                </nb-radio-group>                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Accessibility -->
        <div class="row gc-survey" *ngIf="isCateringTo('Property is wheelchair accessible')">
            <div class="gc-survey-header col-12">
                <div class="gc-survey-header-left">
                    <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>                                    
                    <div>Accessibility</div>
                </div>
                <div class="gc-survey-header-right">
                    <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[2]}" (click)="onCollapseToggle(2, $event)">
                        <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                    </div>
                    <!-- <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info" class="disabled" ></nb-icon> -->
                </div>
            </div>
            <div class="gc-survey-content col-12" *ngIf="!collapsedArray[2]">
                <div class="row gc-spacing">
                    <div class="col-12">
                        <label class="label">Wheelchair Accessible<i style="margin-left: 10px;" class="fab fa-accessible-icon"></i></label>
                        <nb-checkbox (checkedChange)="onCommonAreasCheckedChanged($event)" #accessibilitiesCommonAreas formControlName="accessibilitiesCommonAreas">
                            Common Areas
                        </nb-checkbox>
                        <nb-checkbox 
                            *ngIf="accessibilitiesCommonAreas.checked" 
                            formControlName="accessibilitiesRooms">
                            Rooms
                        </nb-checkbox>
                    </div>                        
                </div>
            </div>
        </div>
        <!-- Bathroom Amenities -->
        <div class="row gc-survey">
            <div class="gc-survey-header col-12">
                <div class="gc-survey-header-left">
                    <!-- <nb-checkbox (checkedChange)="onCheckboxChange($event, 3)" class="check-start checkbox1" #f12></nb-checkbox>  -->
                    <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>                                    
                    <div>Bathroom Amenities</div>
                </div>
                <div class="gc-survey-header-right">
                    <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[3]}" (click)="onCollapseToggle(3, $event)">
                        <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                    </div>
                    <!-- <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info" class="disabled" ></nb-icon> -->
                </div>
            </div>
            <div class="gc-survey-content col-12" *ngIf="!collapsedArray[3]">
                <div class="row gc-spacing">
                    <div class="col-12 col-md-6">
                        <h6>Bathtub</h6>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="some-none-style">
                            <div class="icon-container-2" [ngClass]="{'collapsed': visibleArray[10]}">
                                <nb-icon (click)="onToggleVisibleArray(10)"
                                    [ngClass]="{'disabled' : bathtubAvailability.value != 'some'}"
                                    icon="arrow-ios-downward-outline" status="info">
                                </nb-icon>
                            </div>
                            <div style="display: flex; width: 100%; flex-direction: column;">
                                <gc-all-some-none-selector #bathtubAvailability [isOnRequestDisabled]="true" [isSomeRoomsDisabled]="!hasRoomCategories"
                                    (makeOpen)="onToggleVisibleArray(10, $event)"
                                    formControlName="bathtubAvailability">
                                </gc-all-some-none-selector>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="visibleArray[10]">
                        <div *ngIf="bathtubAvailability.value === 'some'" class="container">
                            <div class="row">
                                <div class="col-12 top-white-space">
                                    <ngx-room-category-selector [roomCategories]="roomCategories"
                                        formControlName="bathtubAvailableInRooms">
                                    </ngx-room-category-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gc-spacing">
                    <div class="col-12 col-md-6">
                        <h6>Shower</h6>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="some-none-style">
                            <div class="icon-container-2" [ngClass]="{'collapsed': visibleArray[11]}">
                                <nb-icon (click)="onToggleVisibleArray(11)"
                                    [ngClass]="{'disabled' : showerAvailability.value != 'some'}"
                                    icon="arrow-ios-downward-outline" status="info">
                                </nb-icon>
                            </div>
                            <div style="display: flex; width: 100%; flex-direction: column;">
                                <gc-all-some-none-selector #showerAvailability [isOnRequestDisabled]="true" [isSomeRoomsDisabled]="!hasRoomCategories"
                                    (makeOpen)="onToggleVisibleArray(11, $event)"
                                    formControlName="showerAvailability">
                                </gc-all-some-none-selector>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="visibleArray[11]">
                        <div *ngIf="showerAvailability.value === 'some'" class="container">
                            <div class="row">
                                <div class="col-12 top-white-space">
                                    <ngx-room-category-selector [roomCategories]="roomCategories"
                                        formControlName="showerAvailableInRooms"></ngx-room-category-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gc-spacing" *ngIf="hasRoomAccessibility()">
                    <div class="col-12 col-md-6">
                        <h6>Handicap Accessible Bathtub<i style="margin-left: 10px;"
                                class="fab fa-accessible-icon"></i></h6>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="some-none-style">
                            <div class="icon-container-2" [ngClass]="{'collapsed': visibleArray[32]}">
                                <nb-icon (click)="onToggleVisibleArray(32)"
                                    [ngClass]="{'disabled' : bathtubAvailabilityHandicap.value != 'some'}"
                                    icon="arrow-ios-downward-outline" status="info">
                                </nb-icon>
                            </div>
                            <div style="display: flex; width: 100%; flex-direction: column;">
                                <gc-all-some-none-selector #bathtubAvailabilityHandicap [isOnRequestDisabled]="true" [isSomeRoomsDisabled]="!hasRoomCategories"
                                    (makeOpen)="onToggleVisibleArray(32, $event)"
                                    formControlName="bathtubAvailabilityHandicap">
                                </gc-all-some-none-selector>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="visibleArray[32]">
                        <div *ngIf="bathtubAvailabilityHandicap.value === 'some'" class="container">
                            <div class="row">
                                <div class="col-12 top-white-space">
                                    <ngx-room-category-selector [roomCategories]="roomCategories"
                                        formControlName="bathtubAvailableInRoomsHandicap">
                                    </ngx-room-category-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gc-spacing" *ngIf="hasRoomAccessibility()">
                    <div class="col-12 col-md-6">
                        <h6>Handicap Accessible Roll-in Shower<i style="margin-left: 10px;"
                                class="fab fa-accessible-icon"></i></h6>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="some-none-style">
                            <div class="icon-container-2" [ngClass]="{'collapsed': visibleArray[32]}">
                                <nb-icon (click)="onToggleVisibleArray(32)"
                                    [ngClass]="{'disabled' : showerAvailabilityHandicap.value != 'some'}"
                                    icon="arrow-ios-downward-outline" status="info">
                                </nb-icon>
                            </div>
                            <div style="display: flex; width: 100%; flex-direction: column;">
                                <gc-all-some-none-selector #showerAvailabilityHandicap [isOnRequestDisabled]="true" [isSomeRoomsDisabled]="!hasRoomCategories"
                                    (makeOpen)="onToggleVisibleArray(32, $event)"
                                    formControlName="showerAvailabilityHandicap">
                                </gc-all-some-none-selector>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="visibleArray[32]">
                        <div *ngIf="showerAvailabilityHandicap.value === 'some'" class="container">
                            <div class="row">
                                <div class="col-12 top-white-space">
                                    <ngx-room-category-selector [roomCategories]="roomCategories"
                                        formControlName="showerAvailableInRoomsHandicap">
                                    </ngx-room-category-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gc-spacing" *ngIf="hasRoomAccessibility()">
                    <div class="col-12 col-md-6">
                        <h6>Handicap Accessible Grab Rails<i style="margin-left: 10px;"
                                class="fab fa-accessible-icon"></i></h6>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="some-none-style">
                            <div class="icon-container-2" [ngClass]="{'collapsed': visibleArray[33]}">
                                <nb-icon (click)="onToggleVisibleArray(33)"
                                    [ngClass]="{'disabled' : grabRailsAvailabilityHandicap.value != 'some'}"
                                    icon="arrow-ios-downward-outline" status="info">
                                </nb-icon>
                            </div>
                            <div style="display: flex; width: 100%; flex-direction: column;">
                                <gc-all-some-none-selector #grabRailsAvailabilityHandicap
                                    [isOnRequestDisabled]="true" [isSomeRoomsDisabled]="!hasRoomCategories" style="float: right;"
                                    (makeOpen)="onToggleVisibleArray(33, $event)"
                                    formControlName="grabRailsAvailabilityHandicap">
                                </gc-all-some-none-selector>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="visibleArray[33]">
                        <div *ngIf="grabRailsAvailabilityHandicap.value === 'some'" class="container">
                            <div class="row">
                                <div class="col-12 top-white-space">
                                    <ngx-room-category-selector [roomCategories]="roomCategories"
                                        formControlName="grabRailsAvailableInRoomsHandicap">
                                    </ngx-room-category-selector>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gc-spacing">
                    <div class="col-12 col-md-6">
                        <h6>Hair Dryer</h6>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="some-none-style">
                            <div class="icon-container-2" [ngClass]="{'collapsed': visibleArray[14]}">
                                <nb-icon (click)="onToggleVisibleArray(14)"
                                    [ngClass]="{'disabled' : hairDryerAvailability.value != 'some'}"
                                    icon="arrow-ios-downward-outline" status="info">
                                </nb-icon>
                            </div>
                            <div style="display: flex; width: 100%; flex-direction: column;">
                                <gc-all-some-none-selector #hairDryerAvailability [isOnRequestDisabled]="false" [isSomeRoomsDisabled]="!hasRoomCategories"
                                    (makeOpen)="onToggleVisibleArray(14, $event)"
                                    formControlName="hairDryerAvailability">
                                </gc-all-some-none-selector>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="visibleArray[14]">
                        <div *ngIf="hairDryerAvailability.value === 'some'" class="container">
                            <div class="row">
                                <div class="col-12 col-md-4 top-white-space">
                                    <ngx-room-category-selector [roomCategories]="roomCategories"
                                        formControlName="hairDryerAvailableInRooms"></ngx-room-category-selector>
                                </div>
                                <!-- <div class="col-12 col-md-4 top-white-space">
                                    <nb-checkbox formControlName="hairDryerOnRequest">
                                         <p style="margin-top: 14px;"><strong>Available on request</strong></p>
                                    </nb-checkbox>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Front Desk Opening Hours -->
        <div class="row gc-survey">
            <div class="gc-survey-header col-12">
                <div class="gc-survey-header-left">
                    <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 4)" class="check-start checkbox1" #f1 formControlName="hasFrontDesk" id="hasFrontDesk"></nb-checkbox>                                    
                    <!-- <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>                                     -->
                    <div>Front Desk Opening Hours</div>
                </div>
                <div class="gc-survey-header-right">
                    <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[4]}" (click)="onCollapseToggle(4, $event)">
                        <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                    </div>
                </div>
            </div>
            <div class="gc-survey-content col-12" *ngIf="!collapsedArray[4]">
                <div class="row gc-spacing">
                    <div class="col-12">
                        <div *ngIf="!f1.checked" class="container">
                            Please activate this facility.
                        </div>
                        <div *ngIf="f1.checked">
                            <div class="row">
                                <div class="col-12">
                                    <label class="label">Enter opening times</label>
                                    <ngx-schedule formControlName="frontDeskHours"></ngx-schedule>
                                </div>
                            </div>                                    
                        </div>            
                    </div>
                </div>
            </div>
        </div>

        <div class="row gc-survey">
            <div class="gc-survey-header col-12">
                <div class="gc-survey-header-left">
                    <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 5)" class="check-start checkbox1" #f2 formControlName="hasCheckInCheckOut" id="hasCheckInCheckOut"></nb-checkbox>  
                    <!-- <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>   -->
                    <div>Check-in / Check-out</div>
                </div>
                <div class="gc-survey-header-right">
                    <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[5]}" (click)="onCollapseToggle(5, $event)">
                        <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                    </div>
                </div>
            </div>
            <div class="gc-survey-content col-12" *ngIf="!collapsedArray[5]">
                <div class="row gc-spacing">
                    <div class="col-12">
                        <div *ngIf="!f2.checked" class="container">
                            Please activate this facility.
                        </div>
                        <div *ngIf="f2.checked" class="container">                                    
                            <div class="row white-top-space">
                                <!-- check-in -->
                                <div class="check-responsive-1">
                                    <div style="font-size: 20px; font-weight: bold; margin-bottom: 15px">Check-in</div>
                                    <div class="row">                        
                                        <!-- from and to -->
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <table>
                                                        <tr>
                                                            <td><label class="label">From</label></td>                                                        
                                                            <td><label  *ngIf="!noCheckInEndTime.checked" class="label">To</label></td>                                                        
                                                        </tr>
                                                        <tr>
                                                            <td><select placeholder="--select--" formControlName="checkInFromTime">
                                                                    <option *ngFor="let time of scheduleTime" [value]="time.value">
                                                                        {{ time.label }}
                                                                    </option>
                                                                </select></td>
                                                            <td><select *ngIf="!noCheckInEndTime.checked" placeholder="--select--" 
                                                                    formControlName="checkInToTime">
                                                                    <option *ngFor="let time of scheduleTime" [value]="time.value">
                                                                        {{ time.label }}
                                                                    </option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group white-top-space">
                                                        <nb-checkbox #noCheckInEndTime formControlName="checkInNoEndTime">
                                                            No check-in end time 	
                                                        </nb-checkbox>
                                                    </div>
                                                </div>
                                                <!-- check-in early -->
                                                <div class="col-12 white-top-space">
                                                    <div class="form-group">
                                                        <label class="label" for="checkInEarlyAvailable">Is early check-in available?</label>
                                                        <nb-radio-group name="checkInEarlyAvailable" #checkInEarlyAvailable formControlName="checkInEarlyAvailable">
                                                            <nb-radio value="Yes">Yes</nb-radio>
                                                            <nb-radio value="No">No</nb-radio>
                                                        </nb-radio-group>
                                                    </div>
                                                    <div class="container">
                                                        <div *ngIf="checkInEarlyAvailable.value == 'Yes'" class="outer-form-array-div">
                                                            <div class="form-array-item">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <label class="label" for="checkInEarlyCost">Early check-in cost</label>
                                                                        <nb-radio-group #checkInEarlyCost name="checkInEarlyCost" formControlName="checkInEarlyCost">
                                                                            <nb-radio value="Free">Free</nb-radio>
                                                                            <nb-radio value="Surcharge">Surcharge</nb-radio>
                                                                        </nb-radio-group>
                                                                        <div *ngIf="checkInEarlyCost.value == 'Surcharge'" class="container">
                                                                            <nb-radio-group #checkInEarlySurcharge name="checkInEarlySurcharge" formControlName="checkInEarlySurcharge">
                                                                                <nb-radio value="USD">Amount (USD)</nb-radio>
                                                                                <nb-radio value="%">Percent</nb-radio>
                                                                                <nb-radio value="Fee varies">Fee varies</nb-radio>
                                                                            </nb-radio-group>
                                                                            <div *ngIf="checkInEarlySurcharge.value == '%' || checkInEarlySurcharge.value == 'USD'">
                                                                                <div class="form-group">
                                                                                    <label for="checkInEarlySurchargeValue" class="label">{{ checkInEarlySurcharge.value }}</label>
                                                                                    <input 
                                                                                        type="number" 
                                                                                        nbInput 
                                                                                        formControlName="checkInEarlySurchargeValue">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                                        
                                            </div>                                                            
                                        </div>                                                        
                                    </div>
                                </div>
                                <!-- check-out -->
                                <div class="check-responsive-2">
                                    <div style="font-size: 20px; font-weight: bold; margin-bottom: 15px">Check-out</div>
                                    <div class="row">                        
                                        <!-- check-out time -->
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="checkOutTime" class="label">Check-out time</label>
                                                <select 
                                                    placeholder="--select--"
                                                    formControlName="checkOutTime">
                                                    <option *ngFor="let time of scheduleTime" [value]="time.value">
                                                        {{ time.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- late check-out availability -->
                                        <div class="col-12 white-top-space">
                                            <div class="form-group">
                                                <label class="label" for="isLateCheckOutAvailable">Is late check-out available?</label>
                                                <nb-radio-group 
                                                    name="isLateCheckOutAvailable" 
                                                    #isLateCheckOutAvailable 
                                                    formControlName="isLateCheckOutAvailable">
                                                    <nb-radio value="Yes">Yes</nb-radio>
                                                    <nb-radio value="No">No</nb-radio>
                                                </nb-radio-group>
                                            </div>
                                            <!-- check-out cost -->
                                            <div class="container">
                                                <div *ngIf="isLateCheckOutAvailable.value == 'Yes'" class="outer-form-array-div">
                                                    <div class="form-array-item">
                                                        <label class="label" for="lateCheckOutCost">Late check-out cost</label>
                                                        <nb-radio-group 
                                                            #lateCheckOutCost 
                                                            name="lateCheckOutCost" 
                                                            formControlName="lateCheckOutCost">
                                                            <nb-radio value="Free">Free</nb-radio>
                                                            <nb-radio value="Surcharge">Surcharge</nb-radio>
                                                        </nb-radio-group>
                                                        <div *ngIf="lateCheckOutCost.value == 'Surcharge'" class="container">
                                                            <nb-radio-group 
                                                                #lateCheckOutCostUnit 
                                                                name="lateCheckOutCostUnit" 
                                                                formControlName="lateCheckOutCostUnit">
                                                                <nb-radio value="USD">Amount (USD)</nb-radio>
                                                                <nb-radio value="%">Percent</nb-radio>
                                                                <nb-radio value="Fee varies">Fee varies</nb-radio>
                                                            </nb-radio-group>
                                                            <div *ngIf="lateCheckOutCostUnit.value == '%' || lateCheckOutCostUnit.value == 'USD'">
                                                                <div class="form-group">
                                                                    <label for="lateCheckOutCostValue" class="label">{{ lateCheckOutCostUnit.value }}</label>
                                                                    <input 
                                                                        type="number" 
                                                                        nbInput 
                                                                        formControlName="lateCheckOutCostValue">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                        
                                        </div>                                                    
                                    </div>
                                </div>                                           
                            </div>                                                              
                        </div>
                    </div>
                </div>
            </div>
        </div>
</form>