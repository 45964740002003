import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

// Services
import { SignUpService } from '../services/sign-up.service';

// Interfaces
import { BotSettings } from '../services/interfaces';

@Component({
  selector: 'app-custom-widget',
  templateUrl: './custom-widget.component.html',
  styleUrls: ['./custom-widget.component.scss']
})

export class CustomWidgetComponent implements OnInit, OnDestroy {
  
  itemForm: UntypedFormGroup;

  city: string = '';

  blankImage = '/assets/images/person_placeholder.png';
  botImages = [
    'https://assets-guestchat.web.app/BlackGirlTransparent.png',
    'https://assets-guestchat.web.app/BlackGuyTransparent.png',
    'https://assets-guestchat.web.app/BlondeGirlTransparent.png',
    'https://assets-guestchat.web.app/BlondeGuyTransparent.png',
    'https://assets-guestchat.web.app/BrunetteGirlTransparent.png',
    'https://assets-guestchat.web.app/RedGuyTransparent.png',
  ];
  colors = [
    ['#04133C', '#04133C'],
    ['#2BC6D8', '#2BC6D8'],
    ['#FF9800', '#FF9800'],
    ['#800000', '#800000'],
  ];
  selectedColor = ['#04133C', '#04133C'];
  ownColor = '#04133C';

  botSettings: BotSettings = {
    title: '',
    openingLine: '',
    botImgUrl: null,
    backgroundColors: ['#04133C', '#04133C'],
    screenPosition: 'right',
    introMessage: '',
    bottomSpacing: 50,
    fromSideSpacing: 50,
  };

  formValueSub: Subscription;

  toastMessage = '';
  showToast = false;

  clientId: string = '';
  name: string = '';

  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  botHtmlCode = '';

  constructor(
    private signUpService: SignUpService
  ) { }

  ngOnInit(): void {
    
    this.clientId = this.signUpService.clientId;
    this.name = this.signUpService.hotelName;

    this.initForm();

  }

  initForm() {

    this.botHtmlCode = `
    <chat-widget id="gc" key="${this.clientId}"></chat-widget>
    <script type='text/javascript' src='https://widget-guestchat.web.app/guestchat.js'></script>
    `;

    this.itemForm = new UntypedFormGroup({        
      title: new UntypedFormControl(this.name ? this.name : 'Sahara', Validators.required),
      botImgUrl: new UntypedFormControl(this.botImages[0]),
      screenPosition: new UntypedFormControl('right'),
      backgroundColors: new UntypedFormControl(['#04133C', '#04133C']),
      openingLine: new UntypedFormControl('Hi! I\'m your virtual assistant and can help with rates, availability, and answer any questions. -- How can I help?' , Validators.required),
      buttonShape: new UntypedFormControl('round'),
      bottomSpacing: new UntypedFormControl(50, Validators.required),
      fromSideSpacing: new UntypedFormControl(50, Validators.required)
    });
    this.processFormChanges(this.itemForm.value);
      this.formValueSub = this.itemForm.valueChanges.subscribe(values => {
        this.processFormChanges(values);
    }); 
  }

  processFormChanges(form: any) {
    this.botSettings = {
      title: form.title ? form.title : '',
      openingLine: form.openingLine ? form.openingLine : '',
      botImgUrl: form.botImgUrl ? form.botImgUrl : '',
      backgroundColors: form.backgroundColors ? form.backgroundColors : ['#04133C', '#04133C'],
      screenPosition: form.screenPosition ? form.screenPosition : 'right',
      bottomSpacing: form.bottomSpacing ? form.bottomSpacing : 50,
      fromSideSpacing: form.fromSideSpacing ? form.fromSideSpacing : 50,
      buttonShape: form.buttonShape ? form.buttonShape : 'round',
    };

    this.signUpService.setBotSettings(this.botSettings);
  }

  onSelectAvatar(avatar: any) {
    this.itemForm.get('botImgUrl').setValue(avatar);
  }
  
  get profileUrl() {
    if (!this.itemForm) {
      return this.blankImage;
    }
    if (this.itemForm.get('botImgUrl').value === null) {
      return this.blankImage;
    }
    return this.itemForm.get('botImgUrl').value;
  }
  
  onSelectColor(color: any, own = false) {
    console.log(color)
    // this.clientForm.markAsDirty();
    
    this.selectedColor = color;
    if (own) {
      this.ownColor = color[0];
    }
    
    this.itemForm.get('backgroundColors').setValue(color);
  }

  onAddColor() {
    document.getElementById('color-selector').click();
  }

  ngOnDestroy(): void {
    if (this.formValueSub) {
      this.formValueSub.unsubscribe();
    }
  }
}
