import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'ngx-add-string-item',
  templateUrl: './add-string-item.component.html',
  styleUrls: ['./add-string-item.component.scss']
})
export class AddStringItemComponent implements OnInit {
  item = '';

  constructor(
    protected dialogRef: NbDialogRef<ConfirmDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  confirm() {
    if (this.item) {
      this.dialogRef.close(this.item);
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
