import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';

import { Subscription, timer } from "rxjs";
import { take, map } from "rxjs/operators";

// Services
import { SignUpService } from '../services/sign-up.service';
import { SurveyService } from '../services/survey.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {
 
  routeSub: Subscription;

  botHtmlCode = '';
  
  clientId = '';

  botTag;

  constructor(
    private signUpService: SignUpService,
    private surveyService: SurveyService,
    private ds: DomSanitizer,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {

    this.routeSub = this.route.params
    .subscribe(params => {
      console.log(params);
      if (params.id) {
        this.clientId = params.id
        console.log(this.clientId);
        this.botTag = this.ds.bypassSecurityTrustHtml(`<chat-widget id="gc" key="${this.clientId}"></chat-widget>`)
    
        this.botHtmlCode = `
            <chat-widget id="gc" key="${this.clientId}"></chat-widget>
            <script type='text/javascript' src='https://widget-guestchat.web.app/guestchat.js'></script>
            `;
      }
    });

  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}