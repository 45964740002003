import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ngx-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => ScheduleComponent),
       multi: true
    }
 ]
})
export class ScheduleComponent implements OnInit {
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled = false;

  value: string[] = [];

  weekDays = [];
  
  scheduleModel: {day: string, enabled: boolean, from: string, to: string, allDay: boolean}[] = [
      {day: 'every day', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Weekdays', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Weekends', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Monday', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Tuesday', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Wednesday', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Thursday', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Friday', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Saturday', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false},
      {day: 'Sunday', enabled: false, from: '08:00 AM', to: '05:00 PM', allDay: false}    
  ];

  scheduleTime = [
    { value: '00:30 AM', label: '00:30 AM' }, { value: '01:00 AM', label: '01:00 AM' }, { value: '01:30 AM', label: '01:30 AM' },
    { value: '02:00 AM', label: '02:00 AM' }, { value: '02:30 AM', label: '02:30 AM' }, { value: '03:00 AM', label: '03:00 AM' },
    { value: '03:30 AM', label: '03:30 AM' }, { value: '04:00 AM', label: '04:00 AM' }, { value: '04:30 AM', label: '04:30 AM' },
    { value: '05:00 AM', label: '05:00 AM' }, { value: '05:30 AM', label: '05:30 AM' }, { value: '06:00 AM', label: '06:00 AM' },
    { value: '06:30 AM', label: '06:30 AM' }, { value: '07:00 AM', label: '07:00 AM' }, { value: '07:30 AM', label: '07:30 AM' },
    { value: '08:00 AM', label: '08:00 AM' }, { value: '08:30 AM', label: '08:30 AM' }, { value: '09:00 AM', label: '09:00 AM' },
    { value: '09:30 AM', label: '09:30 AM' }, { value: '10:00 AM', label: '10:00 AM' }, { value: '10:30 AM', label: '10:30 AM' },
    { value: '11:00 AM', label: '11:00 AM' }, { value: '11:30 AM', label: '11:30 AM' }, { value: 'Noon', label: 'Noon' }, { value: '12:30 PM', label: '12:30 PM' },
    { value: '01:00 PM', label: '01:00 PM' }, { value: '01:30 PM', label: '01:30 PM' }, { value: '02:00 PM', label: '02:00 PM' },
    { value: '02:30 PM', label: '02:30 PM' }, { value: '03:00 PM', label: '03:00 PM' }, { value: '03:30 PM', label: '03:30 PM' },
    { value: '04:00 PM', label: '04:00 PM' }, { value: '04:30 PM', label: '04:30 PM' }, { value: '05:00 PM', label: '05:00 PM' },
    { value: '05:30 PM', label: '05:30 PM' }, { value: '06:00 PM', label: '06:00 PM' }, { value: '06:30 PM', label: '06:30 PM' },
    { value: '07:00 PM', label: '07:00 PM' }, { value: '07:30 PM', label: '07:30 PM' }, { value: '08:00 PM', label: '08:00 PM' },
    { value: '08:30 PM', label: '08:30 PM' }, { value: '09:00 PM', label: '09:00 PM' }, { value: '09:30 PM', label: '09:30 PM' },
    { value: '10:00 PM', label: '10:00 PM' }, { value: '10:30 PM', label: '10:30 PM' }, { value: '11:00 PM', label: '11:00 PM' },
    { value: '11:30 PM', label: '11:30 PM' }, { value: 'Midnight', label: 'Midnight' }
  ];

  allDaysOfWeek = ['every day', 'Weekdays', 'Weekends', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  constructor() {
    this.weekDays = this.allDaysOfWeek;
    this.scheduleTime = this.scheduleTime;
  }

  ngOnInit(): void {
    
  }

  writeValue(value: string[]): void {
    if (!Array.isArray(value)) { //refactor old data
      value = [];
    }
    this.value = value;
    this.value.forEach(item => {
      const parts = item.split(",");
      Object.keys(this.scheduleModel).forEach(key => {
        if (this.scheduleModel[key].day === parts[0]) {
          let from = '08:00 AM';
          let to = '05:00 PM';
          if (parts[1].length > 0) {
            if (parts[1].trim() === 'open 24 hours') {
              this.scheduleModel[key].allDay = true;
            } else {
              this.scheduleModel[key].allDay = false;
              
              const fromTo = parts[1].split("-");
              from = fromTo[0]?.trim();
              to = fromTo[1]?.trim();
            }
            
            this.scheduleModel[key].from = from;
            this.scheduleModel[key].to = to;
            this.scheduleModel[key].enabled = true;
          }
        }
      })
    });
    
    // this.setValue();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onDayCheckedChanged(checked: any, day: string, index: number) {
    
    this.scheduleModel[index].enabled = checked.target.checked; //using this a bit differently
    
    if (checked && day === 'every day') {
      //uncheck all days when Every day is checked
      Object.keys(this.scheduleModel).forEach(key => {
        if (this.scheduleModel[key].day !== 'every day') {
          this.scheduleModel[key].enabled = false;
        }
      });
    }
    this.setValue();
  }

  onChangeAllDay(event: any, index: number) {
    this.scheduleModel[index].allDay = event;
    this.setValue();
  }

  setValue() {
    this.value = [];
    
    if (this.scheduleModel[0].enabled) { //treat some days differently
      let duration = '';
      if (this.scheduleModel[0].allDay) {
        duration = 'open 24 hours';          
      } else {
        let from = '08:00 AM';
        let to = '05:00 PM';
        if (this.scheduleModel[0].from) {
          from = this.scheduleModel[0].from;
        }
        if (this.scheduleModel[0].to) {
          to = this.scheduleModel[0].to;
        }
        duration = from + " - " + to;
      }
      this.value.push(this.scheduleModel[0].day + ", " +  duration);
    } else {
      Object.keys(this.scheduleModel).forEach(key => {
        if (this.scheduleModel[key].enabled) {
          let duration = '';
          if (this.scheduleModel[key].allDay) {
            duration = 'open 24 hours';          
          } else {
            let from = '08:00 AM';
            let to = '05:00 PM';
            if (this.scheduleModel[key].from) {
              from = this.scheduleModel[key].from;
            }
            if (this.scheduleModel[key].to) {
              to = this.scheduleModel[key].to;
            }
            duration = from + " - " + to;
          }
          this.value.push(this.scheduleModel[key].day + ", " +  duration);
        }
      });
    }
    
    this.onChange(this.value);    
  }
}
