import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  header = 'Confirm Dialog';
  title: string;
  contentText: string;

  constructor(
    protected dialogRef: NbDialogRef<ConfirmDialogComponent>
    ) { }

  ngOnInit(): void {
    
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
