
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

// Services
import { SurveyService } from '../services/survey.service';
import { RoomCategoriesService } from '../services/room-categories.service';

// Interface
import { RoomCategory } from '../services/interfaces';

// Components
import { RoomCategoryEditComponent } from './room-category-edit/room-category-edit.component';

// Nebular
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'ngx-room-categories',
  templateUrl: './room-categories.component.html',
  styleUrls: ['./room-categories.component.scss']
})
export class RoomCategoriesComponent implements OnInit, OnDestroy {

  loading = true;

  roomCategories: RoomCategory[] = []
  selectedRoom: boolean[] = [];

  constructor(
    private roomCategoriesService: RoomCategoriesService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit(): void {

    this.getRoomCategories();

  }

  getRoomCategories() {
    this.roomCategoriesService.getRoomCategories()
    .then((roomCategories: RoomCategory[]) => {
      this.roomCategories = [];
      console.log(roomCategories);
      if (roomCategories && roomCategories.length > 0) {
        this.roomCategories = roomCategories.sort((a, b) => +a.order < +b.order ? -1 : 1);
        this.roomCategories.forEach(item => {
          this.selectedRoom.push(false);
        });
      }
      this.loading = false;
    })
    .catch(err => {
        console.log(err);
    })
  }

  onAddRoomCategory() {
    this.dialogService.open(RoomCategoryEditComponent, {
      context: {
        editMode: false
      },
    }).onClose.subscribe(() => {
      this.loading = true;
      this.getRoomCategories();
    });
  }

  onEditItem(room: RoomCategory, index: number) {
    this.selectedRoom.forEach((elem, i) => {
      if (index === i) {
        this.selectedRoom[i] = true;
      } else {
        this.selectedRoom[i] = false;
      }
    });
    this.dialogService.open(RoomCategoryEditComponent, {
      context: {
        roomCategory: room
      },
    }).onClose.subscribe(() => {
      this.loading = true;
      this.getRoomCategories();
    });
  }

  ngOnDestroy() {}

}
