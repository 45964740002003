import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
// import { isError } from 'util';

@Component({
  selector: 'ngx-grace-period-definer',
  templateUrl: './grace-period-definer.component.html',
  styleUrls: ['./grace-period-definer.component.scss'],
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => GracePeriodDefinerComponent),
       multi: true
    }
 ]
})
export class GracePeriodDefinerComponent implements OnInit {
  onChange: any = () => { };
  onTouched: any = () => { };

  value: {
    pre: string,
    number: number, 
    unit1: string,
    unit2: string
  } = {
    pre: 'Within',
    number: 1, 
    unit1: 'day(s)',
    unit2: 'of booking.'
  };

  periodModel = {
    pre: 'Within',
    number: 1, 
    unit1: 'day(s)',
    unit2: 'of booking.'
  }

  constructor() { }

  ngOnInit(): void {
  }

  writeValue(value: {pre: string, number: number,  unit1: string, unit2: string }): void {
    if (value && typeof value === 'object') {
      this.periodModel.pre = value.pre;
      this.periodModel.number = value.number;
      this.periodModel.unit1 = value.unit1;
      this.periodModel.unit2 = value.unit2;
    }
  } 

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setValue() {
    this.value = {...this.periodModel};
    
    this.onChange(this.value);
  }
}
