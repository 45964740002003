<form [formGroup]="itemForm">
    <!-- Restaurant -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 0)" class="check-start checkbox1" #f3 formControlName="hasRestaurant" id="hasRestaurant"></nb-checkbox>  
                <div>Restaurant</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[0]}" (click)="onCollapseToggle(0, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[0]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f3.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f3.checked" class="container">
                        <div class="row">
                            <div class="col-12 col-md-6 white-top-space">        
                                <div class="form-group">
                                    <label class="label"># Restaurants</label>
                                    <input 
                                        type="number" 
                                        nbInput 
                                        formControlName="numberOfRestaurants">
                                </div>                                    
                                <!-- <div class="form-group white-top-space">
                                    <nb-checkbox 
                                        class="check-start"
                                        formControlName="hasNonGuestsRestaurant">Open to Non-guests (outside visitors)
                                    </nb-checkbox>
                                </div>                                     -->
                            </div>
                            <div class="col-12 col-md-6 white-top-space">        
                                <div class="form-group">
                                    <label class="label">Dietary Options</label>
                                    <ngx-checkbox-group
                                        [singleColumn]="true"
                                        [options]="['Dairy-free', 'Gluten-free', 'Vegan', 'Vegetarian']"                    
                                        formControlName="restaurantDietaryOptions">
                                    </ngx-checkbox-group>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 white-top-space">        
                                <div class="form-group">
                                    <label class="label">Cuisine / Description</label>
                                    <textarea 
                                        nbInput 
                                        placeholder="Describe cuisine here.."
                                        formControlName="restaurantCuisine"
                                        rows="5"
                                        fullWidth>
                                    </textarea>
                                </div>
                            </div>       
                            <div class="col-12 col-md-6 white-top-space">        
                                <div class="form-group">
                                    <label class="label">Dress Code</label>
                                    <textarea 
                                        nbInput 
                                        placeholder="Describe dress code here.."
                                        formControlName="restaurantDressCode"
                                        rows="5"
                                        fullWidth>
                                    </textarea>
                                </div>
                            </div>                                                         
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>
    <!-- Restaurant -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 1)" class="check-start checkbox1" #f4 formControlName="hasBar" id="hasBar"></nb-checkbox>                                    
                <div>Bar</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[1]}" (click)="onCollapseToggle(1, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[1]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f4.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f4.checked" class="container">
                        <div class="row">
                            <div class="col-12 white-top-space">
                                <div class="form-group">
                                    <label class="label"># of Bars</label>
                                    <input 
                                        type="number" 
                                        nbInput 
                                        formControlName="numberOfBars">
                                </div>
                            </div>
                            <div class="col-12 white-top-space">
                                <label class="label">Bar types</label>
                                <ngx-bar-types
                                    [singleColumn]="true"
                                    formControlName="barTypes">
                                </ngx-bar-types>
                                <!-- <ngx-checkbox-group
                                    [singleColumn]="false"
                                    [options]="barTypes"                    
                                    formControlName="barTypes">
                                </ngx-checkbox-group>
                            </div> 

                                </ngx-checkbox-group> -->
                            </div>    
                            <div class="col-12 white-top-space">
                                <label class="label">Serving</label>
                                <ngx-checkbox-group
                                    [singleColumn]="false"
                                    [options]="barServing"                    
                                    formControlName="barServing">
                                </ngx-checkbox-group>
                            </div>    
                            <!-- <div class="col-12 white-top-space">
                                <div class="flex-row">
                                    <nb-checkbox
                                        [style]="{'margin-right' : '24px'}" 
                                        formControlName="barNoLicense">
                                        No License | Non Alcoholic Drinks Only
                                    </nb-checkbox>
                                    <nb-checkbox
                                        [style]="{'margin-right' : '24px'}" 
                                        formControlName="barServeWine">
                                        Wine
                                    </nb-checkbox>
                                    <nb-checkbox
                                        [style]="{'margin-right' : '24px'}" 
                                        formControlName="barServeBeer">
                                        Beer
                                    </nb-checkbox>
                                    <nb-checkbox
                                        [style]="{'margin-right' : '24px'}" 
                                        formControlName="barServeCocktails">
                                        Cocktails
                                    </nb-checkbox>
                                    <nb-checkbox
                                        [style]="{'margin-right' : '24px'}" 
                                        formControlName="barServeHardLiquor">
                                        Hard Liquor
                                    </nb-checkbox>
                                </div>
                            </div> -->
                            <div class="col-12 col-md-6 white-top-space">
                                <div class="form-group">
                                    <label class="label">Description</label>
                                    <textarea 
                                        nbInput 
                                        formControlName="barDescription"
                                        rows="5"
                                        cols="45">
                                    </textarea>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 white-top-space">
                                <div class="form-group">
                                    <nb-checkbox 
                                        class="check-start"
                                        formControlName="hasNonGuestsBar">Open to Non-guests (outside visitors)
                                    </nb-checkbox>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <!-- Breakfast -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 2)" class="check-start checkbox1" #f5 formControlName="hasBreakfast" id="hasBreakfast"></nb-checkbox>                                    
                <div>Breakfast</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[2]}" (click)="onCollapseToggle(2, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[2]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f5.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f5.checked" class="container">
                        <div class="row">
                            <div class="col-12 white-top-space">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="label">Breakfast Cost</label>                                            
                                        <nb-radio-group 
                                            #breakfastCharge
                                            name="breakfastCharge" 
                                            formControlName="breakfastCharge">
                                            <nb-radio value="Free">Free</nb-radio>
                                            <nb-radio value="Surcharge">Surcharge (Per Person)</nb-radio>
                                        </nb-radio-group>
                                        <div class="container-inner">
                                            <div class="row">
                                                <div *ngIf="breakfastCharge.value == 'Surcharge'" class="col-12">
                                                    <nb-radio-group 
                                                        #breakfastSurcharge
                                                        name="breakfastSurcharge" 
                                                        formControlName="breakfastSurcharge">
                                                        <nb-radio value="Specific amount">Specific amount (USD)</nb-radio>
                                                        <nb-radio value="Price range">Price range (USD)</nb-radio>
                                                    </nb-radio-group>
                                                    <!-- specific amount -->
                                                    <div *ngIf="breakfastSurcharge.value == 'Specific amount'" class="col-12 white-top-space">
                                                        <div class="container">
                                                            <input 
                                                                type="number" 
                                                                nbInput
                                                                formControlName="breakfastSurchargeAmount">
                                                        </div>
                                                    </div>
                                                    <div *ngIf="breakfastSurcharge.value == 'Price range'" class="col-12 narrow-top-space">
                                                        <div class="container">
                                                            <table>
                                                                <tr>
                                                                    <td><label class="label">Minimum price</label></td>                                                        
                                                                    <td><label class="label">Maximum price</label></td>                                                        
                                                                </tr>
                                                                <tr>
                                                                    <td><input 
                                                                        type="number" 
                                                                        nbInput 
                                                                        formControlName="breakfastSurchargeAmountMin"></td>
                                                                    <td><input 
                                                                        type="number" 
                                                                        nbInput 
                                                                        formControlName="breakfastSurchargeAmountMax"></td>
                                                                </tr>
                                                            </table>
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>             
                                    <div class="col-12 white-top-space">
                                        <label class="label">Enter opening times</label>
                                        <ngx-schedule formControlName="breakfastOpeningSchedule"></ngx-schedule>
                                    </div>
                                    <div class="col-12 white-top-space">
                                        <label class="label label-margin-bottom">Breakfast types (Choose all that apply.)</label>
                                        <ngx-checkbox-group
                                            [singleColumn]="false"
                                            style="width: 300px;"
                                            [options]="breakfastTypes"
                                            formControlName="breakfastType"></ngx-checkbox-group>                                            
                                    </div>                                        
                                </div>    
                            </div>                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Dinner -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 3)" class="check-start checkbox1" #f7 formControlName="hasDinner" id="hasDinner"></nb-checkbox>                                    
                <div>Dinner</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[3]}" (click)="onCollapseToggle(3, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[3]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f7.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f7.checked" class="container">
                        <div class="row">
                            <div class="col-12 white-top-space">
                                <div class="row">
                                    <div class="col-12 col-md-8 white-top-space">
                                        <label class="label">Enter opening times</label>
                                        <ngx-schedule formControlName="dinnerOpeningSchedule"></ngx-schedule>
                                    </div>
                                    <div class="col-12 col-md-4 white-top-space">
                                        <label class="label label-margin-bottom">Dinner types (Choose all that apply.)</label>
                                        <ngx-checkbox-group
                                            [singleColumn]="true"
                                            style="width: 300px;"
                                            [options]="['Buffet', 'A la Carte']"
                                            formControlName="dinnerMenuTypes"></ngx-checkbox-group>    
                                        <nb-checkbox 
                                            class="white-top-space"
                                            formControlName="dinnerReservationRequired">
                                            <label style="margin-top: 6px;" class="label">Reservation required</label> 
                                        </nb-checkbox>
                                    </div>                                        
                                </div>    
                            </div>   
                            <div class="col-12 col-md-8 white-top-space">        
                                <div class="form-group">
                                    <label class="label">Cuisine / Description</label>
                                    <textarea 
                                        nbInput 
                                        placeholder="Describe cuisine here.."
                                        formControlName="dinnerCuisine"
                                        rows="5"
                                        fullWidth>
                                    </textarea>
                                </div>
                            </div>                             
                            <!-- <div class="col-12 col-md-4 white-top-space">        
                                <div class="form-group">
                                    <nb-checkbox 
                                        class="check-start"
                                        formControlName="hasNonGuestsDinner">Open to Non-guests (outside visitors)
                                    </nb-checkbox>
                                </div>
                            </div>                              -->
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <!-- Meal Plans -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 4)" class="check-start checkbox1" #f8 formControlName="hasMealPlans" id="hasMealPlans"></nb-checkbox>                                    
                <div>Meal Plans</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[4]}" (click)="onCollapseToggle(4, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[4]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f8.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f8.checked" class="container">
                        <div class="row">
                            <div class="col-12 col-md-7 white-top-space">
                                <div class="form-group">
                                    <label  class="label label-margin-bottom">Which meal plans do you offer? (Choose all that apply.)</label>
                                    <ngx-checkbox-group
                                        [options]="mealPlansDD"
                                        formControlName="mealPlan"></ngx-checkbox-group>                                        
                                </div>
                            </div>
                            <div class="col-12 col-md-5 white-top-space">
                                <div class="form-group">
                                    <label class="label">Description</label>
                                    <textarea 
                                        nbInput 
                                        formControlName="mealPlanDescription"
                                        rows="5"
                                        fullWidth>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</form>