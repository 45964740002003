<div class="row">
    <div 
        [ngClass]="{'col-md-6' : !singleColumn}" 
        class="col-12" 
        *ngFor="let item of options; let i= index" 
        style="min-width: 100px;">
        <nb-checkbox [ngModel]="getActiveState(item)" (checkedChange)="onChangeCheckbox($event, item)">
            <img *ngIf="images.length > 0 && images[i]"
                alt="icon for {{ item }}" 
                [src]="images[i]" 
                style="height: 30px; width: 30px; margin-right: 10px;">
                {{item}}
        </nb-checkbox>
    </div>
</div>
