import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

// Services
// import { LocationService } from '../../services/location.service';
import { MasterDataService } from '../../services/master-data.service';
import { SurveyService } from '../../services/survey.service';
import { airports } from '../../services/airports';
// import { RoomCategoriesService } from '../../services/room-categories.service';

// Interface
import { RoomCategory, Survey } from '../../services/interfaces';
import { SignUpService } from 'src/app/services/sign-up.service';
// import { NbTagComponent } from '@nebular/theme';

@Component({
  selector: 'ngx-survey-page-three',
  templateUrl: './survey-page-three.component.html',
  styleUrls: ['./survey-page-three.component.scss']
})
export class SurveyPageThreeComponent implements OnInit, OnDestroy {
   
    loading = true;

    // Form
    editMode = false;
    editItem: Survey;
    itemForm: UntypedFormGroup;
  
    weekDays = [];
    scheduleTime = [];
    barTypes = [];
    barServing = [];
    breakfastTypes = [];
    mealPlansDD = [];
    
    collapsedArray = [true, true, true, true, true];
  
    formValueSub: Subscription;
    surveySub: Subscription;
  
    //communicate farm validity to parent
    @Output() isValidForm = new EventEmitter<boolean>();
    formIsValid = null;
  
    airportsDD;
    ageFromAllowed;
    
    //notes
    // frontDeskNote = null;
    // checkInNote = null;
    // restaurantNote = null;
    // barNote = null;
    // breakfastNote = null;
    // mealNote = null;
    // poolNote = null;
    // parkingNote = null;
    // fitnessNote = null;
    // spaNote = null;
    // centerNote = null;
    // conferenceNote = null;
    // roomNote = null;
    // housekeepingNote = null;
    // babysittingNote = null;
    // petsNote = null;
    // dayPassesNote = null;
    // beachNote = null;
    // luggageNote = null;
    // jacuzziNote = null;
    // airportNote = null;
    
    roomCategories: string[] = [];
    roomCategorySub: Subscription;
  
    filteredOptions: {}[];
    @ViewChild('autoInput') input;
    airportsDB: {}[] = [];
  
    saving = false;
    hasPets = false;
    hasConferences = false;
  
    showAirportNameOptions = false;
    @ViewChild('selectAirport') selectAirport: ElementRef;
  
    keyword = 'name';
    popupShow: string;

  constructor(
    private signupService: SignUpService,
    private surveyService: SurveyService,
    private masterDataService: MasterDataService
    ) { }

  ngOnInit(): void {

    this.weekDays = this.masterDataService.daysOfWeek;
    this.barTypes = this.masterDataService.barTypeItems;
    this.barServing = this.masterDataService.barServingItems;
    this.breakfastTypes = this.masterDataService.breakfasts;
    this.mealPlansDD = this.masterDataService.mealPlansDD.map(el => el.value);

    this.scheduleTime = this.masterDataService.scheduleTime;
    this.airportsDB = airports.map((el) => {
      return {
        id: el.name + ' (' + el.iata  + ')',
        name: el.name + ' (' + el.iata  + ')'
      };
    });
    this.airportsDD = this.airportsDB.slice(0, 20);
    
    this.filteredOptions = this.airportsDD;

    if (this.formValueSub) {
        this.formValueSub.unsubscribe();
      }

    this.initForm();
    console.log(this.itemForm.value);


    this.surveySub = this.signupService.survey$.subscribe(
      survey => {
        this.editItem = survey;
        const tempObj = {};
        this.initForm();
        if (this.itemForm) {
          this.itemForm.reset();
        }

        if (this.editItem) {
          Object.keys(this.editItem).forEach(key => {
            tempObj[key] = survey[key].value
          });
          
          this.itemForm.patchValue(tempObj);

          if (this.itemForm.get('hasPool').value) {
            this.collapsedArray[0] = false
          }
          if (this.itemForm.get('hasParking').value) {
            this.collapsedArray[1] = false
          }
          if (this.itemForm.get('hasSpa').value) {
            this.collapsedArray[2] = false
          }
          if (this.itemForm.get('hasRoomService').value) {
            this.collapsedArray[3] = false
          }
          if (this.itemForm.get('hasPetsAndServiceAnimals').value) {
            this.collapsedArray[4] = false
          }
        }


          //check petsNote
          if (this.editItem && this.editItem.propertyCatersTo) {
            const tempArr = this.editItem.propertyCatersTo.value;
            if (tempArr.length) {              
              this.hasPets = tempArr.indexOf('Pets') > -1;
            //   this.hasConferences = tempArr.indexOf('Conferences') > -1;
            //   if (!this.hasConferences && this.itemForm.get('hasConferenceFacilities').value) {
            //     this.itemForm.get('hasConferenceFacilities').setValue(false);
            //   }
            }
          }

        // this.surveyService.loadingData$.next(false);
        // this.loading = false;
        console.log(this.itemForm.value);
      }
    )

    this.ageFromAllowed = this.masterDataService.ageFromAllowed;


    this.loading = false;

  }

  initForm() { 
    this.itemForm = new UntypedFormGroup({      
      //pool
      hasPool: new UntypedFormControl(this.editItem ? (this.editItem.hasPool ? this.editItem.hasPool.value : false) : null),
      numberOfOutdoorPools: new UntypedFormControl(this.editItem ? (this.editItem.numberOfOutdoorPools ? this.editItem.numberOfOutdoorPools.value : 0) : null),
      numberOfIndoorPools: new UntypedFormControl(this.editItem ? (this.editItem.numberOfIndoorPools ? this.editItem.numberOfIndoorPools.value : 0) : null),
      poolAvailability: new UntypedFormControl(this.editItem ? (this.editItem.poolAvailability ? this.editItem.poolAvailability.value : null) : null),
      hasPoolSideBar: new UntypedFormControl(this.editItem ? (this.editItem.hasPoolSideBar ? this.editItem.hasPoolSideBar.value : null) : null),
      childFriendlyPool: new UntypedFormControl(this.editItem ? (this.editItem.childFriendlyPool ? this.editItem.childFriendlyPool.value : null) : null),
      heatedPool: new UntypedFormControl(this.editItem ? (this.editItem.heatedPool ? this.editItem.heatedPool.value : null) : null),
      lifeGuardAtPool: new UntypedFormControl(this.editItem ? (this.editItem.lifeGuardAtPool ? this.editItem.lifeGuardAtPool.value : null) : null),
      chlorinatedOrSaltedPool: new UntypedFormControl(this.editItem ? (this.editItem.chlorinatedOrSaltedPool ? this.editItem.chlorinatedOrSaltedPool.value : null) : null),
      
      //parking
      hasParking: new UntypedFormControl(this.editItem ? (this.editItem.hasParking ? this.editItem.hasParking.value : false) : null),
      hasSelfParking: new UntypedFormControl(this.editItem ? (this.editItem.hasSelfParking ? this.editItem.hasSelfParking.value : null) : null),
      hasValetParking: new UntypedFormControl(this.editItem ? (this.editItem.hasValetParking ? this.editItem.hasValetParking.value : null) : null),
      hasOffsiteParking: new UntypedFormControl(this.editItem ? (this.editItem.hasOffsiteParking ? this.editItem.hasOffsiteParking.value : null) : null),
      selfParking: new UntypedFormControl(this.editItem ? (this.editItem.selfParking ? this.editItem.selfParking.value : null) : null),
      selfParkingCost: new UntypedFormControl(this.editItem ? (this.editItem.selfParkingCost ? this.editItem.selfParkingCost.value : null) : null),
      selfParkingCostUnit: new UntypedFormControl(this.editItem ? (this.editItem.selfParkingCostUnit ? this.editItem.selfParkingCostUnit.value : 'Per day') : null),
      valetParking: new UntypedFormControl(this.editItem ? (this.editItem.valetParking ? this.editItem.valetParking.value : null) : null),
      valetParkingCost: new UntypedFormControl(this.editItem ? (this.editItem.valetParkingCost ? this.editItem.valetParkingCost.value : null) : null),
      valetParkingCostUnit: new UntypedFormControl(this.editItem ? (this.editItem.valetParkingCostUnit ? this.editItem.valetParkingCostUnit.value : 'Per day') : null),
      offsiteParking: new UntypedFormControl(this.editItem ? (this.editItem.offsiteParking ? this.editItem.offsiteParking.value : null) : null),
      offsiteParkingCost: new UntypedFormControl(this.editItem ? (this.editItem.offsiteParkingCost ? this.editItem.offsiteParkingCost.value : null) : null),
      offsiteParkingCostUnit: new UntypedFormControl(this.editItem ? (this.editItem.offsiteParkingCostUnit ? this.editItem.offsiteParkingCostUnit.value : 'Per day') : null),
      offsiteStreetParking: new UntypedFormControl(this.editItem ? (this.editItem.offsiteStreetParking ? this.editItem.offsiteStreetParking.value : null) : null),

      //spa
      hasSpa: new UntypedFormControl(this.editItem ? (this.editItem.hasSpa ? this.editItem.hasSpa.value : false) : null),
      spaTime: new UntypedFormControl(this.editItem ? (this.editItem.spaTime ? this.editItem.spaTime.value : []) : null),
      spaServicesOffered: new UntypedFormControl(this.editItem ? (this.editItem.spaServicesOffered ? this.editItem.spaServicesOffered.value : []) : null),
      spaRequireReservation: new UntypedFormControl(this.editItem ? (this.editItem.spaRequireReservation ? this.editItem.spaRequireReservation.value : null) : null),
      hasNonGuestsSpa:new UntypedFormControl(this.editItem ? (this.editItem.hasNonGuestsSpa ? this.editItem.hasNonGuestsSpa.value : null) : null),      
      //room service
      hasRoomService: new UntypedFormControl(this.editItem ? (this.editItem.hasRoomService ? this.editItem.hasRoomService.value : false) : null),
      roomServiceOpeningScheduleTimes: new UntypedFormControl(this.editItem ? (this.editItem.roomServiceOpeningScheduleTimes ? this.editItem.roomServiceOpeningScheduleTimes.value : []) : null),
      
      //pets and service animals
      hasPetsAndServiceAnimals: new UntypedFormControl(this.editItem ? (this.editItem.hasPetsAndServiceAnimals ? this.editItem.hasPetsAndServiceAnimals.value : false) : null),
      allowPetsSurcharge: new UntypedFormControl(this.editItem ? (this.editItem.allowPetsSurcharge ? this.editItem.allowPetsSurcharge.value : null) : null),
      allowPetsSurchargeValue: new UntypedFormControl(this.editItem ? (this.editItem.allowPetsSurchargeValue ? this.editItem.allowPetsSurchargeValue.value : null) : null),
      allowPetsSurchargeValueUnit2: new UntypedFormControl(this.editItem ? (this.editItem.allowPetsSurchargeValueUnit2 ? this.editItem.allowPetsSurchargeValueUnit2.value : null) : null),
      petRestrictionsType: new UntypedFormControl(this.editItem ? (this.editItem.petRestrictionsType ? this.editItem.petRestrictionsType.value : null) : null),
      hasPetRestrictions: new UntypedFormControl(this.editItem ? (this.editItem.hasPetRestrictions ? this.editItem.hasPetRestrictions.value : null) : null),
      hasPetMaxWeightLimit: new UntypedFormControl(this.editItem ? (this.editItem.hasPetMaxWeightLimit ? this.editItem.hasPetMaxWeightLimit.value : null) : null),
      petMaxWeightLimitValue: new UntypedFormControl(this.editItem ? (this.editItem.petMaxWeightLimitValue ? this.editItem.petMaxWeightLimitValue.value : null) : null),
      petMaxWeightLimitValueUnit: new UntypedFormControl(this.editItem ? (this.editItem.petMaxWeightLimitValueUnit ? this.editItem.petMaxWeightLimitValueUnit.value : null) : null),
      petMaxPerRoom: new UntypedFormControl(this.editItem ? (this.editItem.petMaxPerRoom ? this.editItem.petMaxPerRoom.value : null) : null),
      petRestrictions: new UntypedFormControl(this.editItem ? (this.editItem.petRestrictions ? this.editItem.petRestrictions.value : []) : null),
      hasPetDeposit: new UntypedFormControl(this.editItem ? (this.editItem.hasPetDeposit ? this.editItem.hasPetDeposit.value : null) : null),
      petDepositValue: new UntypedFormControl(this.editItem ? (this.editItem.petDepositValue ? this.editItem.petDepositValue.value : null) : null),
      petDepositValueUnit: new UntypedFormControl(this.editItem ? (this.editItem.petDepositValueUnit ? this.editItem.petDepositValueUnit.value : null) : null),
      petFriendlyRoom: new UntypedFormControl(this.editItem ? (this.editItem.petFriendlyRoom ? this.editItem.petFriendlyRoom.value : []) : null),
      
    });
    

    this.formValueSub = this.itemForm.valueChanges.subscribe(values => {
      this.surveyService.processChanges(values);
    });
    
    this.itemForm.updateValueAndValidity();
  }

  onCheckboxChange(event, index) {
    if (event && this.collapsedArray[index]) {
     this.onCollapseToggle(index, event);
   }
 }

 onCollapseToggle(i, event: any) {
  this.collapsedArray.forEach((el, index) => {
    if (index === i) {
      this.collapsedArray[index] = !this.collapsedArray[index];
    }
  });

  this.scrollIntoView(event.target);
}

scrollIntoView(el: HTMLElement) {
  if (el) {
    el.scrollIntoView();
  }
}

hasPetRestriction(restriction: string) {
    const rest = this.itemForm.get('petRestrictions').value;
    if (rest && rest.indexOf(restriction) > -1) {
      return true;
    }
    return false;
  }


  ngOnDestroy() {
    if (this.formValueSub) {
      this.formValueSub.unsubscribe();
    }    
    if (this.surveySub) {
      this.surveySub.unsubscribe();
    }
  }
}


