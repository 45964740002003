import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

// Services
import { SignUpService } from '../services/sign-up.service';
import { country_codes } from '../services/country-codes';

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent implements OnInit, OnDestroy {
  
  fallbackCommunicationForm: UntypedFormGroup;
  itemForm: UntypedFormGroup;

  country_codes;
  selectedCountryCode = '';
  whatsappNumber = 0;
  basedOnLiveAgent = '';
  
  nextButtonSub: Subscription;
  nextButtonClickedSub: Subscription;
  skipButtonSub: Subscription;
  skipButtonClickedSub: Subscription;

  constructor(
    private signUpService: SignUpService
    ) { }

  ngOnInit(): void {

    this.initForm();

    this.country_codes = country_codes;
    
  }

  initForm() {
    this.itemForm = new UntypedFormGroup({
      botSettings: new UntypedFormGroup({
        liveChatAvailable: new UntypedFormControl(false),
        basedOn: new UntypedFormControl('Based on online status'),
        businessHours: new UntypedFormControl([]),
        timezone: new UntypedFormControl(null), 
      }),
      fallbackCommunication: new UntypedFormGroup({
        hasCallUs: new UntypedFormControl(false),
        callUsNumber: new UntypedFormControl(null),

        hasWhatsapp: new UntypedFormControl(false),
        whatsappUrl: new UntypedFormControl(null),

        hasfbMessenger: new UntypedFormControl(false),
        fbUrl: new UntypedFormControl(null),

        hasReceiveCallBack: new UntypedFormControl(false)
      })
    })
  }

  onChangeWhatsappNumber() {
    if (this.whatsappNumber) {
      this.whatsappNumber = +this.whatsappNumber.toString();
      if (this.selectedCountryCode && this.whatsappNumber.toString().length > 8) {
        console.log(this.selectedCountryCode + this.whatsappNumber);
        this.fallbackCommunicationForm.get('whatsappUrl').setValue('https://wa.me/' + this.selectedCountryCode + this.whatsappNumber);
      }
    }
  }
  
  onFBUrlInput(event: any) {
    this.fallbackCommunicationForm.get('fbUrl').setValue('https://facebook.com/' + event.target.value);
  }

  // async onSubmit() {
  //   await this.signUpService.updateLiveChatSettings(this.itemForm.value)
  //     .then((resp: any) => {
  //       // this.signUpService.addNewBotSettings(this.fallbackCommunicationForm.value)
  //       //   .then((resp: any) => {
  //       //     this.router.navigate(['/go-live']);
  //       //   })
  //       //   .catch((err) => {
  //       //     this.showSuccess(false, 'Something went wrong. Kindly, try again.');
  //       //   });
  //     })
  //     .catch((err) => {
  //       this.showSuccess(false, 'Something went wrong. Kindly, try again.');
  //     });
    
  // }

  ngOnDestroy(): void {}

}
