import { Injectable } from '@angular/core';
import { Reseller } from './interfaces';
import { Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class ResellersService {
  activeReseller: Reseller = {}

  resellers: Reseller[] = [
    {
      domains: ['signup.guestchat.com'],
      name: 'GuestChat Sales',
      normalName: 'GuestChat',
      emailFrom: 'GuestChat <noreply@mg.guestchat.com>',
      logoBig: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/logo%2Flogo-new-color.png?alt=media&token=cc85e6cc-fa14-4e5d-be2c-2349792cdfe6',
      logoSmall: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/logo%2Flogo-new-color.png?alt=media&token=cc85e6cc-fa14-4e5d-be2c-2349792cdfe6',
      signupLogo: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/GuestChat-signup-ladyOnBeach-small.png?alt=media&token=02ebdd5a-8a66-4fc6-a36d-7d7e19d206cb',
      dfProjectId: 'guestchat-sjtxut',
      agentType: 'Hotel',
      signupEmail: 'nima@guestchat.com',
    },
    {
      domains: ['portugal.guestchat.com'],
      name: 'GuestChat Portugal',
      normalName: 'GuestChat Portugal',
      emailFrom: 'GuestChat <noreply@mg.guestchat.com>',
      logoBig: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/Image20240624212454.jpg?alt=media&token=5bf3e38b-8b0a-4c69-b7ff-663cbac8c1f2',
      logoSmall: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/Image20240624212454.jpg?alt=media&token=5bf3e38b-8b0a-4c69-b7ff-663cbac8c1f2',
      signupLogo: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/GuestChat-signup-ladyOnBeach-small.png?alt=media&token=02ebdd5a-8a66-4fc6-a36d-7d7e19d206cb',
      dfProjectId: 'guestchat-sjtxut',
      agentType: 'Hotel',
      signupEmail: 'nima@guestchat.com',
    },
    {
      domains: ['cwcbusiness.guestchat.com'],
      name: 'C&W Business',
      normalName: 'C&W Business',
      emailFrom: 'C&W Business <noreply@mg.guestchat.com>',
      logoBig: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/C%26W%20Business.png?alt=media&token=a6d17823-5f69-49bf-ac99-58cfecb1c82e',
      logoSmall: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/C%26W%20Business.png?alt=media&token=a6d17823-5f69-49bf-ac99-58cfecb1c82e',
      signupLogo: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/Flow-signup-small-keyboard.png?alt=media&token=eddbb68e-1608-470a-b9d8-09cc5536e825',
      dfProjectId: 'guestchat-sjtxut',
      agentType: 'Hotel',
      signupEmail: 'andrew.fazio@cwc.com, Michelle.Adams@cwc.com, nima@guestchat.com',
    },
    {
      domains: ['chatadmin.zenturo.com'], // to be confirmed
      name: 'Zenturo',
      normalName: 'Zenturo Chat Admin',
      emailFrom: 'Zenturo Chat Admin <noreply@mg.guestchat.com>',
      logoBig: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/Zenturo%20Logo.png?alt=media&token=5985d1f0-f3c5-4a41-a7ca-d20e12dca0a9',
      logoSmall: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/Zenturo%20Logo.png?alt=media&token=5985d1f0-f3c5-4a41-a7ca-d20e12dca0a9',
      signupLogo: 'https://firebasestorage.googleapis.com/v0/b/guestchat-live.appspot.com/o/GuestChat-signup-ladyOnBeach-small.png?alt=media&token=02ebdd5a-8a66-4fc6-a36d-7d7e19d206cb',
      dfProjectId: 'lawyers-dddr',
      agentType: 'Lawyers',
      signupEmail: 'nima@guestchat.com',
    },
  ];

  constructor(
    private title: Title,) { }

  setActiveReseller() {
    const host = window.location.hostname;
    // host !== 'localhost' &&
    if (host !== 'localhost' && host !== 'signup.guestchat.com/' && host !== 'guestchat-signup.web.app/') {
      const i = this.resellers.findIndex(el => el.domains.findIndex(domain => domain === host) > -1);
      if (i > -1) {
        this.activeReseller = this.resellers[i];
      }
    } else {
      this.activeReseller = this.resellers[0];
    }
    this.title.setTitle(this.activeReseller.name);
  }

}
