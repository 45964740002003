import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RoomCategory } from '../../services/interfaces';

@Component({
  selector: 'ngx-room-category-selector',
  templateUrl: './room-category-selector.component.html',
  styleUrls: ['./room-category-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoomCategorySelectorComponent),
      multi: true
    }
  ]
})
export class RoomCategorySelectorComponent implements OnInit {
  disabled = false;

  value: string[] = [];

  onChange: any = () => { };
  onTouched: any = () => { };

  @Input('roomCategories') roomCategories: RoomCategory[];

  constructor() { }

  ngOnInit() {
    
  }

  onSelectedValue(checked: boolean, index: number) {
    if (this.value) {
      if (checked) {
        if (!this.value.includes(this.roomCategories[index].roomName)) {
          this.value.push(this.roomCategories[index].roomName);
        }
      } else {
        const fIndex = this.value.findIndex(el => el === this.roomCategories[index].roomName)
        this.value.splice(fIndex, 1);
      }
  
    } else {
      this.value = [];
      this.value.push(this.roomCategories[index].roomName);
    }
    this.onChange(this.value);
  }

  writeValue(value: string[]): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getCheckedStatus(roomId) {
    if (this.value) {
      return this.value.findIndex(el => el === roomId) > -1;
    }
    return false
  }
}
