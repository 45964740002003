import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

// Interfaces
import { BotSettings, Client, LiveChat, Survey } from './interfaces';

// Other
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment'
@Injectable({
  providedIn: 'root'
})

export class SignUpService {

  TOKEN_KEY = 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse';

  promoCode = null;

  clientId = null;
  // clientId = 'BYmNWhbO5IHwAyweAlHk';

  contactEmail = '';
  city = '';
  hotelName = '';
  country = '';

  botSettings: BotSettings = {};
  searchHotels: [] = [];

  public survey$ = new BehaviorSubject<Survey>({});

  constructor(
    private httpClient: HttpClient
  ) { }

  // --- Create Client --- //
  public async newSignUp(signUpObj: Client) {

    const body = { ...signUpObj, defMode: false };

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('content-type', 'application/json').set('authorization', this.TOKEN_KEY);

      this.httpClient.post(environment.signupBaseUrl + '/newSignUp', body, { headers })
        .subscribe({
          next: (resp: any) => {
            this.city = body.city;
            this.hotelName = body.name;
            this.country = body.country;
            this.clientId = resp.payload.id;
            resolve(resp);
          },
          error: (err) => {
            console.log(err)
            reject(err.statusText);
          }
        })

    });
  }

  // --- Search Hotel --- //
  public async searchHotel(name: string, city: string, country: string) {
    // const path = this.baseUrl + '/signup/searchHotel';
    const path = environment.signupBaseUrl + '/searchHotelV2';

    const body = {
      name,
      city,
      country
    }

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('authorization', this.TOKEN_KEY);

      this.httpClient.post(path, body, { headers })
        .subscribe((resp: any) => {
          this.searchHotels = resp.hotel;
          console.log(this.searchHotels);
          resolve(resp);
        }, (err) => {
          reject(err.statusText);
        });
    });
  }

  // --- Save Selected Hotel --- //
  public async saveSelectedHotel(destinationId: string, clientId: string, destinationString: string = '', name: string = '', city: string = '', country: string = '') {
    const path = environment.signupBaseUrl + '/saveSelectedHotel';


    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('content-type', 'application/json')
        .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');

      this.httpClient.post(path, { destinationId, clientId, destinationString, name, city, country }, { headers })
        .subscribe(resp => {
          resolve(resp);
        }, (err) => {
          reject(err.statusText);
        });
    });
  }

  // --- Save Bot Settings --- //
  public async setBotSettings(botSettings: BotSettings) {
    this.botSettings = botSettings;
  }

  // --- Add Bot Settings --- //
  public async addNewBotSettings() {

    const path = environment.signupBaseUrl + '/updateBotSettings';
    // const update = {...this.botSettings, fallbackCommunication};
    const update = { ...this.botSettings };

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('content-type', 'application/json')
        .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');

      this.httpClient.post(path, { botSettings: update, clientId: this.clientId }, { headers })
        .subscribe(resp => {
          console.log(resp);
          resolve(resp);
        }, (err) => {
          reject(err.statusText);
        });
    });
  }

  // --- Check Destination Data => status (complete) --- //
  public async getDestinationDataStatus() {
    const path = environment.signupBaseUrl + '/getDestinationDataStatus';

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('content-type', 'application/json')
        .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');

      this.httpClient.post(path, { clientId: this.clientId }, { headers })
        .subscribe(resp => {
          resolve(resp);
        }, (err) => {
          reject(err.statusText);
        });
    });
  }

  // --- Get Survey --- //
  public async getSurvey() {
    const path = environment.signupBaseUrl + '/getSurvey';

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('content-type', 'application/json')
        .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');

      this.httpClient.post(path, { clientId: this.clientId }, { headers })
        .subscribe((resp: any) => {
          if (resp.success) {
            console.log(resp.payload)
            this.survey$.next(resp.payload);
            resolve(resp);
          }
        }, (err) => {
          reject(err.statusText);
        });
    });
  }

  // --- Update Live Chat --- //
  public async updateLiveChatSettings(liveChatSettings: LiveChat) {
    const path = environment.signupBaseUrl + '/updateLiveChatSettings';

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('content-type', 'application/json')
        .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');

      this.httpClient.post(path, { liveChatSettings, clientId: this.clientId }, { headers })
        .subscribe(resp => {
          resolve(resp);
        }, (err) => {
          reject(err.statusText);
        });
    });
  }

}

