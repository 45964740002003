<nb-button-group>
    <button
        [ngClass]="{filled: value==='all', unselected: value === ''}"
        (click)="onSelectedValue('all')">{{setAllASAvailable ? 'Available' : 'All Rooms'}}</button>
    <button
        [ngClass]="{filled: value==='some', unselected: value === '', 'disable-button': isSomeRoomsDisabled}"
        (click)="onSelectedValue('some')">Some Rooms</button>
    <button
        [ngClass]="{filled: value==='request', unselected: value === '', 'disable-button': isOnRequestDisabled}"
        (click)="onSelectedValue('request')">On request</button>
    <button
        [ngClass]="{filled: value==='none', unselected: value === ''}"
        (click)="onSelectedValue('none')">None</button>
</nb-button-group>