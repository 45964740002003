<nb-card>
    <nb-card-body>
        <div class="row">
            <div class="col-md-12" style="text-align: center; margin-top: 26px; margin-bottom: 30px;">
                <span style="font-size: 26px; font-weight: bold;">Let's Personalize</span>
            </div>
        </div>
        <div class="row" style="margin-bottom: 50px">
            <div class="col-md-12">
                <div style="max-width: 750px; margin: auto;">
                    <!-- <nb-stepper orientation="horizontal" [(selectedIndex)]="activeIndex"> -->
                    <nb-stepper orientation="horizontal" [(selectedIndex)]="activeIndex" [linear]="true" [disableStepNavigation]="true">
                        <nb-step label="Widget"></nb-step>
                        <nb-step label="Chat"></nb-step>
                        <nb-step label="Rooms"></nb-step>
                        <nb-step label="Property"></nb-step>
                        <nb-step label="Dining"></nb-step>
                        <nb-step label="Facility"></nb-step>
                        <nb-step label="Services"></nb-step>
                        <nb-step label="Go Live"> </nb-step>
                    </nb-stepper>
                </div>
            </div>
            <div class="col-12" style="display: flex; justify-content: center; margin-top: 12px" *ngIf="activeIndex < 3 || (activeIndex >= 3 && !loading)">
                    <button 
                        style="width: 100px; margin-right: 6px;"
                        nbButton 
                        outline 
                        (click)="onChangeActiveIndex(false)">
                        Skip
                    </button>
                    <button 
                        style="width: 100px;"
                        nbButton 
                        status="primary" 
                        (click)="onChangeActiveIndex()">
                        Next
                    </button>
            </div>
        </div>
   
        <div class="row" style="max-width: 1200px; margin: auto;">
            <!-- Widget -->
            <div class="col-md-12" *ngIf="activeIndex === 0">
                <app-custom-widget></app-custom-widget>
            </div>
            <!-- Chat -->
            <div class="col-md-12" *ngIf="activeIndex === 1">
              <app-live-chat></app-live-chat>
            </div>
            <!-- Rooms -->
            <div class="col-md-12" *ngIf="activeIndex === 2">
                <span *ngIf="!loading; else loadingSpinner">
                    <ngx-room-categories></ngx-room-categories>
                </span>
            </div>
            <!-- Property -->
            <div class="col-md-12"  *ngIf="activeIndex === 3">
                <ngx-survey-page-one></ngx-survey-page-one>
            </div>
            <!-- Dining -->
            <div class="col-md-12"  *ngIf="activeIndex === 4">
                <ngx-survey-page-two></ngx-survey-page-two>
            </div>
            <!-- Facility -->
            <div class="col-md-12"  *ngIf="activeIndex === 5">
                <ngx-survey-page-three></ngx-survey-page-three>
            </div>
            <!-- Services -->
            <div class="col-md-12"  *ngIf="activeIndex === 6">
                <ngx-survey-page-four></ngx-survey-page-four>
            </div>
        </div>
    </nb-card-body>
</nb-card>

<ng-template #loadingSpinner>
    <div class="overlay">
        <div class="overlay-position">
            <div class="gc-animation">
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
            </div>
        </div>
    </div>
</ng-template>