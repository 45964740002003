import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BotSettings, LiveChat } from '../services/interfaces';

@Component({
  selector: 'start-button',
  templateUrl: './start-button.component.html',
  styleUrls: ['./start-button.component.scss'],
})
export class StartButtonComponent implements OnInit, OnDestroy {

  botSettings: BotSettings = {
    title: '',
    openingLine: '',
    botImgUrl: null,
    backgroundColors: ['#FFFFFF', '#FFFFFF'],
    screenPosition: 'right',
    introMessage: ''
  };


  @Input('botSettings')
  public set func(settings: any) {
    this.botSettings = settings;

    this.backgroundSuccess = { 'background-color': settings.backgroundColors[0] };
    console.log(this.backgroundSuccess);

    if (settings.screenPosition === 'left') {
      if (settings.buttonShape === 'round') {
        this.buttonStyle = { 'background-color': settings.backgroundColors[0], bottom: settings.bottomSpacing + 'px', left: settings.fromSideSpacing + 'px' };
      } else if (settings.buttonShape === 'bar') {
        this.buttonStyle = { 'background-color': settings.backgroundColors[0], left: settings.fromSideSpacing + 'px' };
      }

    } else {
      if (settings.buttonShape === 'round') {
        this.buttonStyle = { 'background-color': settings.backgroundColors[0], bottom: settings.bottomSpacing + 'px', right: settings.fromSideSpacing + 'px' };
      } else if (settings.buttonShape === 'bar') {
        this.buttonStyle = { 'background-color': settings.backgroundColors[0], right: settings.fromSideSpacing + 'px' };
      }
    }
  }
  

  // hasOpened = false;
  backgroundSuccess = {};


  buttonStyle: any = {
    'background': 'linear-gradient(to bottom right,  #FFFFFF , #FFFFFF)', bottom: '50px', right: '50px'
  }

  constructor() { }

  ngOnInit() {}

  ngOnDestroy() {}   
}
