import { Component, EventEmitter, forwardRef, Input, OnInit, Output, } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gc-all-some-none-selector',
  templateUrl: './all-some-none-selector.component.html',
  styleUrls: ['./all-some-none-selector.component.scss'],
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => AllSomeNoneSelectorComponent),
       multi: true
    }
 ]
})
export class AllSomeNoneSelectorComponent implements  ControlValueAccessor, OnInit {

  disabled = false;

  value: 'all' | 'some' | 'request' | 'none' | '' ;

  @Input() isSomeRoomsDisabled: boolean = false;
  @Input() isOnRequestDisabled: boolean = false;
  @Input() setAllASAvailable: boolean = false;

  onChange: any = () => { };
  onTouched: any = () => { };

  @Output() makeOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  onSelectedValue(value: 'all' | 'some' | 'request' | 'none' | '') {
    if (this.value === value) {
      this.value = '';
    } else {
      this.value = value;
    }

    this.onChange(this.value);
    // if (this.value == 'all' || this.value == 'some') {
    if (this.value == 'some') {
      this.makeOpen.emit(true);
    } else {
      this.makeOpen.emit(false);
    }
  }

  writeValue(value: 'all' | 'some' | 'request' | 'none' | '' ): void {
      this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
