<nb-card class="modal-view">
    <nb-card-header>
        <h4>{{ editMode ? 'Room Category Details' : 'Create Room Category'}}</h4>
        <!-- <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedLanguageIso"
            (change)="onChangeLanguage($event)"
            style="width: 160px; height: 28px; font-size: 12px; padding: 4px; margin-left: 12px;">
            <option *ngFor="let language of otherLanguages" [value]="language.iso">{{language.language}}</option>
        </select> -->
        <!-- update -->
        <button nbButton outline size="small" [disabled]="!itemForm.valid"  (click)="onSubmit()" [nbSpinner]="saving"
            nbSpinnerStatus="success" status="success">
            <nb-icon icon="save-outline"></nb-icon>{{ editMode ? 'Update' : 'Save'}}
        </button>
        <!-- delete -->
        <button *ngIf="editMode" nbButton outline status="danger" size="small" outline (click)="onDelete()"><i
                class="trash-outline"></i>Delete</button>
        <!-- cancel -->
        <button nbButton outline status="warning" size="small" outline (click)="onCancel()">
            <nb-icon icon="arrow-back-outline"></nb-icon>Cancel
        </button>
    </nb-card-header>

    <nb-card-body>
        <div class="container-dialog">
            <form [formGroup]="itemForm">
                        <div class="row  white-top-space">
                            <!-- left side -->
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="roomName" class="label">Room Category Name*</label>
                                            <input class="form-control" type="text" nbInput
                                                *ngIf="selectedLanguageIso === 'en'" #roomName fullWidth
                                                formControlName="roomName">

                                            <div [formGroup]="itemForm.get('roomNameTranslations')">
                                                <input type="text" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'af'" formControlName="af">
                                                <input type="text" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'nl'" formControlName="nl">
                                                <input type="text" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'fr'" formControlName="fr">
                                                <input type="text" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'de'" formControlName="de">
                                                <input type="text" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'es'" formControlName="es">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 white-top-space">
                                        <div class="form-group">
                                            <label for="shortSellingText" class="label">Short Selling text*</label>
                                            <textarea class="form-control" nbInput fullWidth
                                                *ngIf="selectedLanguageIso === 'en'" id="shortSellingText"
                                                formControlName="shortSellingText">
                                            </textarea>
                                            <div [formGroup]="itemForm.get('shortSellingTextTranslations')">
                                                <textarea class="form-control" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'af'" formControlName="af">
                                                </textarea>
                                                <textarea class="form-control" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'nl'" formControlName="nl">
                                                </textarea>
                                                <textarea class="form-control" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'fr'" formControlName="fr">
                                                </textarea>
                                                <textarea class="form-control" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'de'" formControlName="de">
                                                </textarea>
                                                <textarea class="form-control" nbInput fullWidth
                                                    *ngIf="selectedLanguageIso === 'es'" formControlName="es">
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="orderToShowIn" class="label">Order to show in*</label>
                                            <input class="form-control" type="number" min="1" [max]="totalRooms + 1"
                                                nbInput fullWidth formControlName="order">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-6" *ngIf="editMode">
                                <label class="label">Featured Photo</label>
                                <input type="text" formControlName="roomPhotoUrl" type="hidden">
                                <!-- <button (click)="onAddPhoto()" *ngIf="!itemForm.get('roomPhotoUrl').value" nbButton
                                    outline class="photo-add-btn bottom-left-btn" status="info" size="small">
                                    <nb-icon icon="plus-circle-outline">
                                    </nb-icon> Add Photo*
                                </button> -->
                                <div *ngIf="itemForm.get('roomPhotoUrl').value">
                                    <div class="photo-flex">
                                        <div class="photo-block" style="border: none;">
                                            <div style="cursor: pointer;" class="navi" (click)="onDeletePhoto()">
                                                <nb-icon icon="trash-outline"></nb-icon>
                                            </div>
                                            <img [src]="itemForm.get('roomPhotoUrl').value">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 white-top-space" *ngIf="editMode">
                                <div class="form-group">
                                    <label class="label">
                                        More Photos (optional)
                                        <!-- <button (click)="onAddOtherPhoto()" nbButton ghost status="primary">
                                            <nb-icon icon="plus"></nb-icon>
                                        </button> -->
                                    </label>
                                    <div class="flex-media-container">
                                        <div style="width: 100%;">
                                            <div class="example-container">
                                                <div class="file-item"
                                                    *ngFor="let item of itemForm.get('otherRoomPhotosUrls').value; let mediaIndex = index;">
                                                    <img [src]="item" alt="other photo number {{mediaIndex}}">
                                                    <div class="remove-button" style="cursor: pointer;"
                                                        (click)="onDeleteOtherPhoto(mediaIndex)">
                                                        <nb-icon icon="trash-outline"></nb-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row white-top-space">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6 col-md-3 form-group">
                                        <label for="roomSizeValue" class="label">Room Size</label>
                                        <input class="form-control" type="number" nbInput fullWidth min="1"
                                        #roomSizeValue formControlName="roomSizeValue">
                                    </div>
                                    <div class="col-6 col-md-3 form-group">
                                        <label for="roomSizeUnit" class="label">Size Unit</label>
                                        <select id="roomSizeUnit" fullWidth formControlName="roomSizeUnit" style="width: 95%;">
                                            <option value="squareFeet">sq. ft</option>
                                            <option value="squareMeter">sq. m</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-3 form-group">
                                        <label for="numberOfBathrooms" class="label">Bathrooms</label>
                                        <input class="form-control" type="number" nbInput fullWidth min="0"
                                        formControlName="numberOfBathrooms">
                                    </div>
                                
                                    
                                    <div class="col-6 col-md-3 form-group">
                                        <label for="roomView" class="label">Room View*</label>
                                        <select id="roomView" fullWidth #roomView formControlName="roomView" style="width: 95%;">
                                            <option *ngFor="let roomView of roomViews" [value]="roomView.value">
                                                {{ roomView.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row white-top-space">
                            <div class="col-12">
                                <h5>Featured Amenities and Facilities</h5>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <nb-tag-list>
                                        <nb-tag [appearance]="getFilledStatusAmenity(amenity) ? 'filled' : 'outline'"
                                            *ngFor="let amenity of amenitiesDD" [text]="amenity" status="primary"
                                            style="cursor: pointer" (click)="onToggleAmenity(amenity)"></nb-tag>
                                    </nb-tag-list>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row white-top-space">
                            <!-- beds -->
                            <div class="col-12">
                                <h5>Beds</h5>

                                <div class="container" formArrayName="beds">
                                    <div class="row" *ngFor="let bedCtrl of beds.controls; let i = index;"
                                        [formGroupName]="i">
                                        <div *ngIf="bedCtrl.get('or').value && i > 0" class="col-12"
                                            style="text-align: center; padding: 6px;">
                                            <p><strong>OR</strong></p>
                                        </div>
                                        <div *ngIf="!bedCtrl.get('or').value && i > 0" class="col-12"
                                            style="text-align: center; padding: 6px;">
                                            <p><strong>AND</strong></p>
                                        </div>
                                        <div class="col-12">
                                            <table>
                                                <tr>
                                                    <td><label *ngIf="i === 0" class="label">Bed Size*</label></td>
                                                    <td><label *ngIf="i === 0" class="label"># Of Beds*</label></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="form-group">
                                                            <select id="bedType" style="width: 160px;" fullWidth
                                                                formControlName="bedType">
                                                                <option value="Crib">Crib</option>
                                                                <option value="Twin">Twin</option>
                                                                <option value="Twin XL">Twin XL</option>
                                                                <option value="Double Bed">Double Bed</option>
                                                                <option value="Full">Full</option>
                                                                <option value="Queen">Queen</option>
                                                                <option value="King">King</option>
                                                                <option value="Cal King">Cal King</option>
                                                                <option value="Super King">Super King</option>
                                                                <option value="Sofa Bed">Sofa Bed</option>
                                                                <option value="Bunk Bed">Bunk Bed</option>
                                                                <option value="Futon">Futon</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="number" min="0" nbInput class="form-control"
                                                                #numberOfBeds formControlName="numberOfBeds">
                                                        </div>
                                                    </td>
                                                    <td><a class="delete-schedule vertical-middle"
                                                            (click)="onDeleteBed(i)"><i class="fa fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 20px">
                                    <div style="margin-bottom: 20px;" class="col-12 col-md-6">
                                        <a class="link-btn" [ngClass]="{'pointer-events-none': !checkBedsInputValid}"
                                            (click)="onAddBed(false)">
                                            <i class="far fa-plus-circle i-style"></i> {{beds.controls &&
                                            beds.controls.length === 0 ? 'And a Bed' : 'AND another Bed'}}
                                        </a>
                                    </div>
                                    <div style="margin-bottom: 20px;" class="col-12 col-md-6">
                                        <a class="link-btn" [ngClass]="{'pointer-events-none': !checkBedsInputValid}"
                                            (click)="onAddBed(true)">
                                            <i class="far fa-plus-circle i-style"></i> OR another Bed
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row white-top-space">
                            <div class="col-12">
                                <h5>Occupancy</h5>
                                <div class="row">
                                    <div class="col-6 col-md-4 form-group">
                                        <label for="adultOccupancy" class="label">Max Adults*</label>            
                                        <select style="width: 95%;" id="adultOccupancy" (change)="calcMaxOcc()" #adultOccupancy
                                            fullWidth formControlName="adultOccupancy">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    
                                    <div class="col-6 col-md-4 form-group">
                                        <label for="childrenOccupancy" class="label">Max Children*</label>            
                                        <select style="width: 95%;" id="childrenOccupancy" (change)="calcMaxOcc()"
                                            #childrenOccupancy fullWidth formControlName="childrenOccupancy">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    
                                    <div class="col-6 col-md-4 form-group">
                                        <label for="maximumOccupancy" class="label">Max*</label>            
                                        <input class="form-control" disabled type="number" nbInput fullWidth
                                        formControlName="maximumOccupancy">
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- <nb-tab tabTitle="Booking & Rates">
                        <div class="row white-top-space">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="fallbackBookingUrl" class="label">Book Now Link</label>
                                    <input class="form-control" type="url" nbInput fullWidth min="0"
                                        formControlName="fallbackBookingUrl">
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <nb-checkbox formControlName="showSecretRatesAvailable" #showSecretRatesAvailable>Enable Promotion Text
                                    </nb-checkbox>
                                </div>
                            </div>
                            <div class="col-12 col-md-6" *ngIf="showSecretRatesAvailable.checked">
                                <div class="form-group">
                                    <select id="showSecretRatesText" fullWidth formControlName="showSecretRatesText">
                                        <option value="Secret Rates Available">Secret Rates Available</option>
                                        <option value="Best Available Rate">Best Available Rate</option>
                                        <option value="Promotional Rate">Promotional Rate</option>
                                        <option value="Lowest Rate Online">Lowest Rate Online</option>
                                        <option value="Early Bird Rate">Early Bird Rate</option>
                                        <option value="Special Rate">Special Rate</option>
                                        <option value="Exclusive Rate">Exclusive Rate</option>
                                        <option value="Starting At">Starting At</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div class="row  white-top-space" formGroupName="ratesByDate">
                            <div class="col-12">
                                <h5>Manual Rates</h5>
                            </div>
                            <div *ngIf="!ratesByDate.controls.length || ratesByDate.controls.length === 0"
                                class="col-12">
                                <p>No rates available.</p>
                            </div>
                            <div class="col-12" *ngFor="let rate of ratesByDate.controls; let i = index"
                                [formGroupName]="i">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label [for]="'range'+i" class="label">Date range*</label>
                                            <input nbInput placeholder="Pick Date Range" [id]="'range'+i" fullWidth
                                                [nbDatepicker]="rangepicker" formControlName="range">
                                            <nb-rangepicker #rangepicker></nb-rangepicker>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label [for]="'rate'+i" class="label">Rate*</label>
                                            <input #rate class="form-control" [id]="'rate'+i" type="number" nbInput
                                                fullWidth min="0" formControlName="rate">
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group" style="width: calc(100% - 40px); display: inline-block">
                                            <label [for]="'currency'+i" class="label">Currency*</label>
                                            <input class="form-control" [id]="'currency'+i" type="text" nbInput
                                                fullWidth min="0" formControlName="currency">
                                        </div>
                                        <a style="width: 36px; display: inline-block; margin: 20px 0px; padding: 0px 6px"
                                            (click)="onDeleteRate(i)"><i class="fa fa-trash"></i></a>
                                    </div>

                                </div>
                            </div>
                            <div style="height: 100px;" class="col-12">

                            </div>
                            <div style="margin-bottom: 20px;" class="col-12">
                                <a class="link-btn" (click)="onAddRate()"><i class="far fa-plus-circle i-style"></i>
                                    {{ratesByDate.controls.length === 0 ? 'Add Range' : 'Add Another Range'}}</a>
                            </div>
                        </div>

                    </nb-tab> -->

                <!-- details -->



                <!-- room Size -->

                <!-- occupancy -->


                <!-- Featured Amenities and Facilities -->

                <!-- booking url -->



            </form>
        </div>
    </nb-card-body>
</nb-card>