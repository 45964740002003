<nb-card status="success">
    <nb-card-header>
        <i
          style="font-size: 18px; margin-right: 20px"
          class="fas fa-clipboard-check"></i>Create New Item
      </nb-card-header
    >
    <nb-card-body class="confirm-body">
        <input 
            type="text" 
            nbInput fullWidth 
            placeholder="Enter here" 
            [(ngModel)]="item"  />
    </nb-card-body>
    <nb-card-footer>
        <div style="text-align: center;">
            <button 
                nbButton 
                style="margin-right: 20px;" 
                hero 
                status="success" 
                (click)="confirm()">Add</button>
            <button 
                nbButton
                hero 
                (click)="cancel()">Cancel</button>
        </div>
    </nb-card-footer>
  </nb-card>
  