<div>
    <div class="row" style="overflow-x: hidden;">
        <div class="col-0 col-md-6 mobile-hide">
            <div class="widget-container">				
                <chat-area [botSettings]="botSettings"></chat-area>
                <start-button [botSettings]="botSettings"></start-button>				
            </div>
        </div>
        <div class="col-12 col-md-6">
            <form [formGroup]="itemForm" style="max-width: 500px; display: block; margin: auto; width: 100%;">
                <div class="row">
                    <div class="col-12">
                        <label class="label headers">Avatar</label>
                        <div class="avatar-picker" style="margin-top: 10px">
                            <div
                                class="avatar"
                                *ngFor="let avatar of botImages"
                                [ngClass]="{ selected: avatar === profileUrl }"
                                (click)="onSelectAvatar(avatar)">
                                <img [src]="avatar" />
                            </div>            
                        </div>
                    </div>
                
                    <div class="col-12" style="margin-top: 20px">
                        <label class="label headers">Profile Color</label>
                        <div class="color-picker" style="margin-top: 10px">
                            <div 
                                class="color" *ngFor="let color of colors"
                                [ngStyle]="{'background': 'linear-gradient(to bottom right, ' + color[0] + ',' + color[1] + ')'}"
                                [ngClass]="{selected: selectedColor[0] == color[0] && selectedColor[1] == color[1]}"
                                (click)="onSelectColor(color)">
                            </div>
                            <div class="color" (click)="onAddColor()" [ngStyle]="{'background-color': ownColor, 'padding' : '10px'}">
                                <svg  aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                            </div>
                            <input 
                                id="color-selector" type="color" style="display: none"
                                (change)="onSelectColor([$event.target.value, $event.target.value], true)">
                        </div>
                    </div>
                
                    <div class="col-12" style="margin-top: 20px">
                        <div class="form-group">
                            <label class="label" style="margin-bottom: 10px">Title</label>
                            <input 
                                nbInput
                                type="text" 
                                formControlName="title"
                                fullWidth id="title" 
                                placeholder="Title on Chat window">					
                        </div>
                    </div>
                
                    <div class="col-12" style="margin-top: 20px">
                        <div class="from-group">
                            <label class="label" style="margin-bottom: 10px">Welcome message</label>
                            <textarea 
                                rows="3"
                                type="text" fullWidth 
                                nbInput
                                formControlName="openingLine"
                                id="opening" placeholder="Greeting text"></textarea>					
                        </div>
                    </div>
                </div>
            </form>
        </div>    
    </div>
</div>