<nb-card style="height: 100%;">
    <nb-card-body style="height: 95%;">
        <div class="row chat-bubble">
            <div class="col-12 content-container">
                <img class="logo" [src]="activeReseller.logoBig" alt="After Signup">
                <p class="text">Thank you for signing up with GuestChat!</p>
                <p class="sub-text">Our team will be in contact with you soon.</p>
                <p class="sub-text">Please keep an eye on your email for further instructions.</p>
            </div>
        </div>
    </nb-card-body>
</nb-card>
