import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class MasterDataService implements OnDestroy {

  constructor() {}

  public roomViews = [
    { value: 'Atrium View', label: 'Atrium View', translations: {af: 'Atrium uitsig', nl: 'Atriumzicht', fr: `Vue de l'atrium`, de: 'Atrium-Ansicht', es: 'Vista del atrio'} },
    { value: 'Bay view', label: 'Bay view', translations: {af: 'Baai uitsig', nl: 'Baai uitzicht', fr: 'Vue sur la baie', de: 'Sicht auf die Bucht', es: 'Vista a la bahia'} },
    { value: 'Beach View', label: 'Beach View', translations: {af: 'Strand uitsig', nl: 'Uitzicht op het strand', fr: 'Vue sur la plage', de: 'Blick auf den Strand', es: 'Vista a la playa'} },
    { value: 'No Special Views', label: 'No Special Views', translations: {af: 'Geen spesiale uitsig', nl: 'Geen speciale uitzichten', fr: 'Pas de vues spéciales', de: 'Keine besonderen Ansichten', es: 'Sin vistas especiales'} },
    { value: 'Canal View', label: 'Canal View', translations: {af: 'Kanaal uitsig', nl: 'Uitzicht op de gracht', fr: 'Vue sur le canal', de: 'Blick auf den Kanal', es: 'Vista al Canal'} },
    { value: 'City View', label: 'City View', translations: {af: 'Stad uitsig', nl: 'Uitzicht op de stad', fr: 'Vue de la ville', de: 'Stadtblick', es: 'Vista de la ciudad'} },
    { value: 'Courtyard View', label: 'Courtyard View', translations: {af: 'Binnehof uitsig', nl: 'Uitzicht op binnenplaats', fr: 'Vue sur la cour', de: 'Blick auf den Innenhof', es: 'Vista al patio'} },
    { value: 'Desert View', label: 'Desert View', translations: {af: 'Woestyn uitsig', nl: 'Uitzicht op de woestijn', fr: 'Vue sur le désert', de: 'Blick auf die Wüste', es: 'Vista del desierto'} },
    { value: 'Garden View', label: 'Garden View', translations: {af: 'Tuin uitsig', nl: 'Uitzicht op de tuin', fr: 'Vue du jardin', de: 'Gartenaussicht', es: 'Vista al jardín'} },
    { value: 'Island View', label: 'Island View', translations: {af: 'Eiland uitsig', nl: 'Uitzicht op het eiland', fr: `Vue sur l'île`, de: 'Blick auf die Insel', es: 'Vista de la isla'} },
    { value: 'Golf View', label: 'Golf View', translations: {af: 'Golf uitsig', nl: 'Golfweergave', fr: 'Vue sur le golf', de: 'Golf-Ansicht', es: 'Vista al golf'} },
    { value: 'Harbor View', label: 'Harbor View', translations: {af: 'Hawe uitsig', nl: 'Uitzicht op de haven', fr: 'Vue sur le port', de: 'Blick auf den Hafen', es: 'Vista del puerto'} },
    { value: 'Hill View', label: 'Hill View', translations: {af: 'Berg uitsig', nl: 'Uitzicht op de heuvels', fr: 'Vue sur la colline', de: 'Aussicht vom Hügel', es: 'Vista de la colina'} },
    { value: 'Hotel view', label: 'Hotel view', translations: {af: 'Hotel uitsig', nl: 'Uitzicht op hotel', fr: `Vue de l'hôtel`, de: 'Hotelansicht', es: 'Vista del Hotel'} },
    { value: 'Lagoon View', label: 'Lagoon View', translations: {af: 'Strandmeer uitsig', nl: 'Uitzicht op de lagune', fr: 'Vue sur le lagon', de: 'Blick auf die Lagune', es: 'Vista a la laguna'} },
    { value: 'Lake View', label: 'Lake View', translations: {af: 'Meer uitsig', nl: 'Zicht op het meer', fr: 'Vue sur le lac', de: 'Seeblick', es: 'Vista al lago'} },
    { value: 'Partial Lake View', label: 'Partial Lake View', translations: {af: 'Gedeeltelike meer  uitsig', nl: 'Gedeeltelijk uitzicht op het meer', fr: 'Vue partielle sur le lac', de: 'Teilweise Seeblick', es: 'Vista parcial al lago'} },
    { value: 'Marina View', label: 'Marina View', translations: {af: 'Hawe uitsig', nl: 'Uitzicht op de jachthaven', fr: 'Vue sur le port de plaisance', de: 'Blick auf den Jachthafen', es: 'Vista al puerto deportivo'} },
    { value: 'Mountain View', label: 'Mountain View', translations: {af: 'Berg uitsig', nl: 'Berg uitzicht', fr: 'Vue sur la montagne', de: 'Blick auf die Berge', es: 'Vista desde la montaña'} },
    { value: 'Ocean View', label: 'Ocean View', translations: {af: 'Oseaan uitsig', nl: 'Uitzicht over de oceaan', fr: `Vue sur l'océan`, de: 'Meerblick', es: 'Vista al océano'} },
    { value: 'Ocean Front', label: 'Ocean Front', translations: {af: 'Seefront uitsig', nl: 'Uitzicht op de oceaan', fr: `Vue face à l'océan`, de: 'Meerblick', es: 'Meerblick'} },
    { value: 'Partial Ocean View', label: 'Partial Ocean View', translations: {af: 'Gedeeltelike see uitsig', nl: 'Gedeeltelijk uitzicht op de oceaan', fr: `Vue partielle sur l'océan`, de: 'Teilweise Meerblick', es: 'Vista parcial al mar'} },
    { value: 'Sea View', label: 'Sea View', translations: {af: 'See uitsig', nl: 'Zeezicht', fr: 'Vue sur la mer', de: 'Seeblick', es: 'Vista marítima'} },
    { value: 'Panoramic View', label: 'Panoramic View', translations: {af: 'Panoramiese uitsig', nl: 'Panoramisch zicht', fr: 'Vue panoramique', de: 'Panoramablick', es: 'Vista panorámica'} },
    { value: 'Partial Sea View', label: 'Partial Sea View', translations: {af: 'Gedeeltelike see uitsig', nl: 'Gedeeltelijk uitzicht op zee', fr: 'Vue partielle sur la mer', de: 'Teilweise Meerblick', es: 'Vista parcial al mar'} },
    { value: 'Parking Lot View', label: 'Parking Lot View', translations: {af: 'Parkeerterrein uitsig', nl: 'Uitzicht op parkeerplaats', fr: 'Vue sur le parking', de: 'Blick auf den Parkplatz', es: 'Vista del estacionamiento'} },
    { value: 'Pool View', label: 'Pool View', translations: {af: 'Swembad uitsig', nl: 'Zwembad uitzicht', fr: 'Vue de la piscine', de: 'Blick auf den Pool', es: 'Vista a la piscina'} },
    { value: 'Resort View', label: 'Resort View', translations: {af: 'Oord uitsig', nl: 'Uitzicht op het resort', fr: 'Vue sur le complexe', de: 'Blick auf das Resort', es: 'Vista del complejo'} },
    { value: 'River View', label: 'River View', translations: {af: 'Rivier uitsig', nl: 'rivier zicht', fr: 'Vue de la rivière', de: 'Flussblick', es: 'Vista del rio'} },
    { value: 'Valley View', label: 'Valley View', translations: {af: 'Vallei uitsig', nl: 'Uitzicht op de vallei', fr: 'Vue sur la vallée', de: 'Talblick', es: 'Vista del valle'} },
    { value: 'Vineyard View', label: 'Vineyard View', translations: {af: 'Wingerd uitsig', nl: 'Uitzicht op de wijngaard', fr: 'Vue sur le vignoble', de: 'Blick auf den Weinberg', es: 'Vista al viñedo'} },
    { value: 'Water View', label: 'Water view', translations: {af: 'Water uitsig', nl: 'Uitzicht op het water', fr: `Vue sur l'eau`, de: 'Wasserblick', es: 'Vista al agua'} },

  ];

  public scheduleTime = [
    { value: '00:30 am', label: '00:30 am' }, { value: '1 am', label: '1 am' }, { value: '1:30 am', label: '1:30 am' },
    { value: '2 am', label: '2 am' }, { value: '2:30 am', label: '2:30 am' }, { value: '3 am', label: '3 am' },
    { value: '3:30 am', label: '3:30 am' }, { value: '4 am', label: '4 am' }, { value: '4:30 am', label: '4:30 am' },
    { value: '5 am', label: '5 am' }, { value: '5:30 am', label: '5:30 am' }, { value: '6 am', label: '6 am' },
    { value: '6:30 am', label: '6:30 am' }, { value: '7 am', label: '7 am' }, { value: '7:30 am', label: '7:30 am' },
    { value: '8 am', label: '8 am' }, { value: '8:30 am', label: '8:30 am' }, { value: '9 am', label: '9 am' },
    { value: '9:30 am', label: '9:30 am' }, { value: '10 am', label: '10 am' }, { value: '10:30 am', label: '10:30 am' },
    { value: '11 am', label: '11 am' }, { value: '11:30 am', label: '11:30 am' }, { value: 'Noon', label: 'Noon' }, { value: '12:30 pm', label: '12:30 pm' },
    { value: '1 pm', label: '1 pm' }, { value: '1:30 pm', label: '1:30 pm' }, { value: '2 pm', label: '2 pm' },
    { value: '2:30 pm', label: '2:30 pm' }, { value: '3 pm', label: '3 pm' }, { value: '3:30 pm', label: '3:30 pm' },
    { value: '4 pm', label: '4 pm' }, { value: '4:30 pm', label: '4:30 pm' }, { value: '5 pm', label: '5 pm' },
    { value: '5:30 pm', label: '5:30 pm' }, { value: '6 pm', label: '6 pm' }, { value: '6:30 pm', label: '6:30 pm' },
    { value: '7 pm', label: '7 pm' }, { value: '7:30 pm', label: '7:30 pm' }, { value: '8 pm', label: '8 pm' },
    { value: '8:30 pm', label: '8:30 pm' }, { value: '9 pm', label: '9 pm' }, { value: '9:30 pm', label: '9:30 pm' },
    { value: '10 pm', label: '10 pm' }, { value: '10:30 pm', label: '10:30 pm' }, { value: '11 pm', label: '11 pm' },
    { value: '11:30 pm', label: '11:30 pm' }, { value: 'Midnight', label: 'Midnight' }
  ];

  public barTypes = [
    { value: 'Non alcoholic Bar', label: 'Non alcoholic Bar' },
    { value: 'Alcoholic Bar', label: 'Alcoholic Bar' },
    { value: 'Brewpub', label: 'Brewpub' },
    { value: 'Campagne bar', label: 'Campagne bar' },
    { value: 'Cocktail bar', label: 'Cocktail bar' },
    { value: 'Gastropub', label: 'Gastropub' },
    { value: 'Karaoke bar', label: 'Karaoke bar' },
    { value: 'Lobby Lounge', label: 'Lobby Lounge' },
    { value: 'Piano bar', label: 'Piano bar' },
    { value: 'Pub', label: 'Pub' },
    { value: 'Rooftop bar', label: 'Rooftop bar' },
    { value: 'Sports bar', label: 'Sports bar' },
    { value: 'Tapas bar', label: 'Tapas bar' },
    { value: 'Wine bar', label: 'Wine bar' },
  ];

  public barTypeItems = [
    'Brewpub',
    'Champagne bar',
    'Cocktail bar',
    'Gastropub',
    'Karaoke bar',
    'Lobby Lounge',
    'Piano bar',
    'Pub',
    'Rooftop bar',
    'Sports bar',
    'Tapas bar',
    'Wine bar',
    'Restaurant bar',
    'Beach bar',
    'Pool bar',
    'Juice bar',
  ];

  public barServingItems = [
    'No License | Non Alcoholic Drinks Only',
    'Wine',
    'Beer',
    'Cocktails',
    'Hard Liquor',
  ];

  public breakfasts = [
    'Continental', 
    'Full', 
    'English', 
    'American', 
    'Local', 
    'Buffet', 
    'A la Carte',
  ];

  public mealPlansDD = [
    { value: 'Room Only', label: 'RO (Room Only) — No food' },
    { value: 'Bed & Breakfast', label: 'BB (Bed & Breakfast) — Breakfast only' },
    { value: 'Half board', label: 'HB (Half board) —  Breakfast, dinner – no drinks included in the evening' },
    { value: 'Full board', label: 'FB (Full board) —  Breakfast, lunch, dinner – no drinks included in the evening' },
    { value: 'All-inclusive', label: 'AI  (All-inclusive) —  Breakfast, lunch, dinner, local drinks' },
    { value: 'Enhanced all-inclusive', label: 'UAI (Enhanced all-inclusive) —  Breakfast, lunch, dinner, all types of drink' },
    { value: 'Enhanced Half board', label: 'HB+ (Enhanced Half board) — Breakfast, dinner + some alcoholic/non-alcoholic drinks' },
    { value: 'Enhanced Full board', label: 'FB+ (Enhanced Full board) — Breakfast, lunch, dinner + some alcoholic/non-alcoholic drinks' },
  ];

  public daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  public languagesSelect = [
    { label: 'English', value: 'English' },
    { label: 'French', value: 'French' },
    { label: 'German', value: 'German' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Abkhazian', value: 'Abkhazian' },
    { label: 'Afar', value: 'Afar' },
    { label: 'Afrikaans', value: 'Afrikaans' },
    { label: 'Akan', value: 'Akan' },
    { label: 'Albanian', value: 'Albanian' },
    { label: 'Amharic', value: 'Amharic' },
    { label: 'Arabic', value: 'Arabic' },
    { label: 'Aragonese', value: 'Aragonese' },
    { label: 'Armenian', value: 'Armenian' },
    { label: 'Assamese', value: 'Assamese' },
    { label: 'Avaric', value: 'Avaric' },
    { label: 'Avestan', value: 'Avestan' },
    { label: 'Aymara', value: 'Aymara' },
    { label: 'Azerbaijani', value: 'Azerbaijani' },
    { label: 'Bambara', value: 'Bambara' },
    { label: 'Bashkir', value: 'Bashkir' },
    { label: 'Basque', value: 'Basque' },
    { label: 'Belarusian', value: 'Belarusian' },
    { label: 'Bengali (Bangla)', value: 'Bengali (Bangla)' },
    { label: 'Bihari', value: 'Bihari' },
    { label: 'Bislama', value: 'Bislama' },
    { label: 'Bosnian', value: 'Bosnian' },
    { label: 'Breton', value: 'Breton' },
    { label: 'Bulgarian', value: 'Bulgarian' },
    { label: 'Burmese', value: 'Burmese' },
    { label: 'Catalan', value: 'Catalan' },
    { label: 'Chamorro', value: 'Chamorro' },
    { label: 'Chechen', value: 'Chechen' },
    { label: 'Chichewa, Chewa, Nyanja' },
    { value: 'Chichewa, Chewa, Nyanja' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Chinese (Simplified)', value: 'Chinese (Simplified)' },
    { label: 'Chinese (Traditional)', value: 'Chinese (Traditional)' },
    { label: 'Chuvash', value: 'Chuvash' },
    { label: 'Cornish', value: 'Cornish' },
    { label: 'Corsican', value: 'Corsican' },
    { label: 'Cree', value: 'Cree' },
    { label: 'Croatian', value: 'Croatian' },
    { label: 'Czech', value: 'Czech' },
    { label: 'Danish', value: 'Danish' },
    { label: 'Divehi, Dhivehi, Maldivian', value: 'Divehi, Dhivehi, Maldivian' },
    { label: 'Dutch', value: 'Dutch' },
    { label: 'Dzongkha', value: 'Dzongkha' },
    { label: 'Esperanto', value: 'Esperanto' },
    { label: 'Estonian', value: 'Estonian' },
    { label: 'Ewe', value: 'Ewe' },
    { label: 'Faroese', value: 'Faroese' },
    { label: 'Fijian', value: 'Fijian' },
    { label: 'Finnish', value: 'Finnish' },
    { label: 'Fula, Fulah, Pulaar, Pular' },
    { value: 'Fula, Fulah, Pulaar, Pular' },
    { label: 'Galician', value: 'Galician' },
    { label: 'Gaelic (Scottish)', value: 'Gaelic (Scottish)' },
    { label: 'Gaelic (Manx)', value: 'Gaelic (Manx)' },
    { label: 'Georgian', value: 'Georgian' },
    { label: 'Greek', value: 'Greek' },
    { label: 'Greenlandic', value: 'Greenlandic' },
    { label: 'Guarani', value: 'Guarani' },
    { label: 'Gujarati', value: 'Gujarati' },
    { label: 'Haitian Creole', value: 'Haitian Creole' },
    { label: 'Hausa', value: 'Hausa' },
    { label: 'Hebrew', value: 'Hebrew' },
    { label: 'Herero', value: 'Herero' },
    { label: 'Hindi', value: 'Hindi' },
    { label: 'Hiri Motu', value: 'Hiri Motu' },
    { label: 'Hungarian', value: 'Hungarian' },
    { label: 'Icelandic', value: 'Icelandic' },
    { label: 'Ido', value: 'Ido' },
    { label: 'Igbo', value: 'Igbo' },
    { label: 'Indonesian', value: 'Indonesian' },
    { label: 'Interlingua', value: 'Interlingua' },
    { label: 'Interlingue', value: 'Interlingue' },
    { label: 'Inuktitut', value: 'Inuktitut' },
    { label: 'Inupiak', value: 'Inupiak' },
    { label: 'Irish', value: 'Irish' },
    { label: 'Italian', value: 'Italian' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Javanese', value: 'Javanese' },
    {
      label: 'Kalaallisut, Greenlandic',
      value: 'Kalaallisut, Greenlandic'
    },
    { label: 'Kannada', value: 'Kannada' },
    { label: 'Kanuri', value: 'Kanuri' },
    { label: 'Kashmiri', value: 'Kashmiri' },
    { label: 'Kazakh', value: 'Kazakh' },
    { label: 'Khmer', value: 'Khmer' },
    { label: 'Kikuyu', value: 'Kikuyu' },
    { label: 'Kinyarwanda (Rwanda)', value: 'Kinyarwanda (Rwanda)' },
    { label: 'Kirundi', value: 'Kirundi' },
    { label: 'Kyrgyz', value: 'Kyrgyz' },
    { label: 'Komi', value: 'Komi' },
    { label: 'Kongo', value: 'Kongo' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Kurdish', value: 'Kurdish' },
    { label: 'Kwanyama', value: 'Kwanyama' },
    { label: 'Lao', value: 'Lao' },
    { label: 'Latin', value: 'Latin' },
    { label: 'Latvian (Lettish)', value: 'Latvian (Lettish)' },
    { label: 'Limburgish ( Limburger)', value: 'Limburgish ( Limburger)' },
    { label: 'Lingala', value: 'Lingala' },
    { label: 'Lithuanian', value: 'Lithuanian' },
    { label: 'Luga-Katanga', value: 'Luga-Katanga' },
    { label: 'Luganda, Ganda', value: 'Luganda, Ganda' },
    { label: 'Luxembourgish', value: 'Luxembourgish' },
    { label: 'Manx', value: 'Manx' },
    { label: 'Macedonian', value: 'Macedonian' },
    { label: 'Malagasy', value: 'Malagasy' },
    { label: 'Malay', value: 'Malay' },
    { label: 'Malayalam', value: 'Malayalam' },
    { label: 'Maltese', value: 'Maltese' },
    { label: 'Maori', value: 'Maori' },
    { label: 'Marathi', value: 'Marathi' },
    { label: 'Marshallese', value: 'Marshallese' },
    { label: 'Moldavian', value: 'Moldavian' },
    { label: 'Mongolian', value: 'Mongolian' },
    { label: 'Nauru', value: 'Nauru' },
    { label: 'Navajo', value: 'Navajo' },
    { label: 'Ndonga', value: 'Ndonga' },
    { label: 'Northern Ndebele', value: 'Northern Ndebele' },
    { label: 'Nepali', value: 'Nepali' },
    { label: 'Norwegian', value: 'Norwegian' },
    { label: 'Norwegian bokmål', value: 'Norwegian bokmål' },
    { label: 'Norwegian nynorsk', value: 'Norwegian nynorsk' },
    { label: 'Nuosu', value: 'Nuosu' },
    { label: 'Occitan', value: 'Occitan' },
    { label: 'Ojibwe', value: 'Ojibwe' },
    { label: 'Old Church Slavonic, Old Bulgarian', value: 'Old Church Slavonic, Old Bulgarian' },
    { label: 'Oriya', value: 'Oriya' },
    { label: 'Oromo (Afaan Oromo)', value: 'Oromo (Afaan Oromo)' },
    { label: 'Ossetian', value: 'Ossetian' },
    { label: 'Pāli', value: 'Pāli' },
    { label: 'Pashto, Pushto', value: 'Pashto, Pushto' },
    { label: 'Persian (Farsi)', value: 'Persian (Farsi)' },
    { label: 'Polish', value: 'Polish' },
    { label: 'Portuguese', value: 'Portuguese' },
    { label: 'Punjabi (Eastern)', value: 'Punjabi (Eastern)' },
    { label: 'Quechua', value: 'Quechua' },
    { label: 'Romansh', value: 'Romansh' },
    { label: 'Romanian', value: 'Romanian' },
    { label: 'Russian', value: 'Russian' },
    { label: 'Sami', value: 'Sami' },
    { label: 'Samoan', value: 'Samoan' },
    { label: 'Sango', value: 'Sango' },
    { label: 'Sanskrit', value: 'Sanskrit' },
    { label: 'Serbian', value: 'Serbian' },
    { label: 'Serbo-Croatian', value: 'Serbo-Croatian' },
    { label: 'Sesotho', value: 'Sesotho' },
    { label: 'Setswana', value: 'Setswana' },
    { label: 'Shona', value: 'Shona' },
    { label: 'Sichuan Yi', value: 'Sichuan Yi' },
    { label: 'Sindhi', value: 'Sindhi' },
    { label: 'Sinhalese', value: 'Sinhalese' },
    { label: 'Siswati', value: 'Siswati' },
    { label: 'Slovak', value: 'Slovak' },
    { label: 'Slovenian', value: 'Slovenian' },
    { label: 'Somali', value: 'Somali' },
    { label: 'Southern Ndebele', value: 'Southern Ndebele' },
    { label: 'Sundanese', value: 'Sundanese' },
    { label: 'Swahili (Kiswahili)', value: 'Swahili (Kiswahili)' },
    { label: 'Swati', value: 'Swati' },
    { label: 'Swedish', value: 'Swedish' },
    { label: 'Tagalog', value: 'Tagalog' },
    { label: 'Tahitian', value: 'Tahitian' },
    { label: 'Tajik', value: 'Tajik' },
    { label: 'Tamil', value: 'Tamil' },
    { label: 'Tatar', value: 'Tatar' },
    { label: 'Telugu', value: 'Telugu' },
    { label: 'Thai', value: 'Thai' },
    { label: 'Tibetan', value: 'Tibetan' },
    { label: 'Tigrinya', value: 'Tigrinya' },
    { label: 'Tonga', value: 'Tonga' },
    { label: 'Tsonga', value: 'Tsonga' },
    { label: 'Turkish', value: 'Turkish' },
    { label: 'Turkmen', value: 'Turkmen' },
    { label: 'Twi', value: 'Twi' },
    { label: 'Uyghur', value: 'Uyghur' },
    { label: 'Ukrainian', value: 'Ukrainian' },
    { label: 'Urdu', value: 'Urdu' },
    { label: 'Uzbek', value: 'Uzbek' },
    { label: 'Venda', value: 'Venda' },
    { label: 'Vietnamese', value: 'Vietnamese' },
    { label: 'Volapük', value: 'Volapük' },
    { label: 'Wallon', value: 'Wallon' },
    { label: 'Welsh', value: 'Welsh' },
    { label: 'Wolof', value: 'Wolof' },
    { label: 'Western Frisian', value: 'Western Frisian' },
    { label: 'Xhosa', value: 'Xhosa' },
    { label: 'Yiddish', value: 'Yiddish' },
    { label: 'Yoruba', value: 'Yoruba' },
    { label: 'Zhuang, Chuang', value: 'Zhuang, Chuang' },
    { label: 'Zulu', value: 'Zulu' },
  ];

  public countrySelect = [
    {id:4,alpha2:'af',alpha3:'afg',name:"Afghanistan"},
    {id:8,alpha2:'al',alpha3:'alb',name:"Albania"},
    {id:12,alpha2:'dz',alpha3:'dza',name:"Algeria"},
    {id:20,alpha2:'ad',alpha3:'and',name:"Andorra"},
    {id:24,alpha2:'ao',alpha3:'ago',name:"Angola"},
    {id:28,alpha2:'ag',alpha3:'atg',name:"Antigua and Barbuda"},
    {id:32,alpha2:'ar',alpha3:'arg',name:"Argentina"},
    {id:51,alpha2:'am',alpha3:'arm',name:"Armenia"},
    {id:533,alpha2:'aw',alpha3:'abw',name:"Aruba"},// New data to be tested
    {id:36,alpha2:'au',alpha3:'aus',name:"Australia"},
    {id:40,alpha2:'at',alpha3:'aut',name:"Austria"},
    {id:31,alpha2:'az',alpha3:'aze',name:"Azerbaijan"},
    {id:44,alpha2:'bs',alpha3:'bhs',name:"Bahamas"},
    {id:48,alpha2:'bh',alpha3:'bhr',name:"Bahrain"},
    {id:50,alpha2:'bd',alpha3:'bgd',name:"Bangladesh"},
    {id:52,alpha2:'bb',alpha3:'brb',name:"Barbados"},
    {id:112,alpha2:'by',alpha3:'blr',name:"Belarus"},
    {id:56,alpha2:'be',alpha3:'bel',name:"Belgium"},
    {id:84,alpha2:'bz',alpha3:'blz',name:"Belize"},
    {id:204,alpha2:'bj',alpha3:'ben',name:"Benin"},
    {id:60,alpha2:'bm',alpha3:'bmu',name:"Bermuda"},// New data to be tested
    {id:64,alpha2:'bt',alpha3:'btn',name:"Bhutan"},
    {id:68,alpha2:'bo',alpha3:'bol',name:"Bolivia (Plurinational State of)"},
    {id:70,alpha2:'ba',alpha3:'bih',name:"Bosnia and Herzegovina"},
    {id:72,alpha2:'bw',alpha3:'bwa',name:"Botswana"},
    {id:535,alpha2:'bq',alpha3:'bes',name:"Bonaire, Sint Eustatius and Saba"},// New data to be tested
    {id:76,alpha2:'br',alpha3:'bra',name:"Brazil"},
    {id:96,alpha2:'bn',alpha3:'brn',name:"Brunei Darussalam"},
    {id:100,alpha2:'bg',alpha3:'bgr',name:"Bulgaria"},
    {id:854,alpha2:'bf',alpha3:'bfa',name:"Burkina Faso"},
    {id:108,alpha2:'bi',alpha3:'bdi',name:"Burundi"},
    {id:132,alpha2:'cv',alpha3:'cpv',name:"Cabo Verde"},
    {id:116,alpha2:'kh',alpha3:'khm',name:"Cambodia"},
    {id:120,alpha2:'cm',alpha3:'cmr',name:"Cameroon"},
    {id:124,alpha2:'ca',alpha3:'can',name:"Canada"},
    {id:136,alpha2:'KY',alpha3:'CYM',name:"Cayman Islands"},// New data to be tested
    {id:140,alpha2:'cf',alpha3:'caf',name:"Central African Republic"},
    {id:148,alpha2:'td',alpha3:'tcd',name:"Chad"},
    {id:152,alpha2:'cl',alpha3:'chl',name:"Chile"},
    {id:156,alpha2:'cn',alpha3:'chn',name:"China"},
    {id:170,alpha2:'co',alpha3:'col',name:"Colombia"},
    {id:174,alpha2:'km',alpha3:'com',name:"Comoros"},
    {id:178,alpha2:'cg',alpha3:'cog',name:"Congo"},
    {id:180,alpha2:'cd',alpha3:'cod',name:"Congo, Democratic Republic of the"},
    {id:188,alpha2:'cr',alpha3:'cri',name:"Costa Rica"},
    {id:384,alpha2:'ci',alpha3:'civ',name:"Côte d'Ivoire"},
    {id:191,alpha2:'hr',alpha3:'hrv',name:"Croatia"},
    {id:192,alpha2:'cu',alpha3:'cub',name:"Cuba"},
    {id:531,alpha2:'cw',alpha3:'cuw',name:"Curaçao"},// New data to be tested
    {id:196,alpha2:'cy',alpha3:'cyp',name:"Cyprus"},
    {id:203,alpha2:'cz',alpha3:'cze',name:"Czechia"},
    {id:208,alpha2:'dk',alpha3:'dnk',name:"Denmark"},
    {id:262,alpha2:'dj',alpha3:'dji',name:"Djibouti"},
    {id:212,alpha2:'dm',alpha3:'dma',name:"Dominica"},
    {id:214,alpha2:'do',alpha3:'dom',name:"Dominican Republic"},
    {id:218,alpha2:'ec',alpha3:'ecu',name:"Ecuador"},
    {id:818,alpha2:'eg',alpha3:'egy',name:"Egypt"},
    {id:222,alpha2:'sv',alpha3:'slv',name:"El Salvador"},
    {id:226,alpha2:'gq',alpha3:'gnq',name:"Equatorial Guinea"},
    {id:232,alpha2:'er',alpha3:'eri',name:"Eritrea"},
    {id:233,alpha2:'ee',alpha3:'est',name:"Estonia"},
    {id:748,alpha2:'sz',alpha3:'swz',name:"Eswatini"},
    {id:231,alpha2:'et',alpha3:'eth',name:"Ethiopia"},
    {id:242,alpha2:'fj',alpha3:'fji',name:"Fiji"},
    {id:246,alpha2:'fi',alpha3:'fin',name:"Finland"},
    {id:250,alpha2:'fr',alpha3:'fra',name:"France"},
    {id:266,alpha2:'ga',alpha3:'gab',name:"Gabon"},
    {id:270,alpha2:'gm',alpha3:'gmb',name:"Gambia"},
    {id:268,alpha2:'ge',alpha3:'geo',name:"Georgia"},
    {id:276,alpha2:'de',alpha3:'deu',name:"Germany"},
    {id:288,alpha2:'gh',alpha3:'gha',name:"Ghana"},
    {id:300,alpha2:'gr',alpha3:'grc',name:"Greece"},
    {id:308,alpha2:'gd',alpha3:'grd',name:"Grenada"},
    {id:320,alpha2:'gt',alpha3:'gtm',name:"Guatemala"},
    {id:324,alpha2:'gn',alpha3:'gin',name:"Guinea"},
    {id:624,alpha2:'gw',alpha3:'gnb',name:"Guinea-Bissau"},
    {id:328,alpha2:'gy',alpha3:'guy',name:"Guyana"},
    {id:332,alpha2:'ht',alpha3:'hti',name:"Haiti"},
    {id:340,alpha2:'hn',alpha3:'hnd',name:"Honduras"},
    {id:348,alpha2:'hu',alpha3:'hun',name:"Hungary"},
    {id:352,alpha2:'is',alpha3:'isl',name:"Iceland"},
    {id:356,alpha2:'in',alpha3:'ind',name:"India"},
    {id:360,alpha2:'id',alpha3:'idn',name:"Indonesia"},
    {id:364,alpha2:'ir',alpha3:'irn',name:"Iran (Islamic Republic of)"},
    {id:368,alpha2:'iq',alpha3:'irq',name:"Iraq"},
    {id:372,alpha2:'ie',alpha3:'irl',name:"Ireland"},
    {id:376,alpha2:'il',alpha3:'isr',name:"Israel"},
    {id:380,alpha2:'it',alpha3:'ita',name:"Italy"},
    {id:388,alpha2:'jm',alpha3:'jam',name:"Jamaica"},
    {id:392,alpha2:'jp',alpha3:'jpn',name:"Japan"},
    {id:400,alpha2:'jo',alpha3:'jor',name:"Jordan"},
    {id:398,alpha2:'kz',alpha3:'kaz',name:"Kazakhstan"},
    {id:404,alpha2:'ke',alpha3:'ken',name:"Kenya"},
    {id:296,alpha2:'ki',alpha3:'kir',name:"Kiribati"},
    {id:408,alpha2:'kp',alpha3:'prk',name:"Korea (Democratic People's Republic of)"},
    {id:410,alpha2:'kr',alpha3:'kor',name:"Korea, Republic of"},
    {id:414,alpha2:'kw',alpha3:'kwt',name:"Kuwait"},
    {id:417,alpha2:'kg',alpha3:'kgz',name:"Kyrgyzstan"},
    {id:418,alpha2:'la',alpha3:'lao',name:"Lao People's Democratic Republic"},
    {id:428,alpha2:'lv',alpha3:'lva',name:"Latvia"},
    {id:422,alpha2:'lb',alpha3:'lbn',name:"Lebanon"},
    {id:426,alpha2:'ls',alpha3:'lso',name:"Lesotho"},
    {id:430,alpha2:'lr',alpha3:'lbr',name:"Liberia"},
    {id:434,alpha2:'ly',alpha3:'lby',name:"Libya"},
    {id:438,alpha2:'li',alpha3:'lie',name:"Liechtenstein"},
    {id:440,alpha2:'lt',alpha3:'ltu',name:"Lithuania"},
    {id:442,alpha2:'lu',alpha3:'lux',name:"Luxembourg"},
    {id:450,alpha2:'mg',alpha3:'mdg',name:"Madagascar"},
    {id:454,alpha2:'mw',alpha3:'mwi',name:"Malawi"},
    {id:458,alpha2:'my',alpha3:'mys',name:"Malaysia"},
    {id:462,alpha2:'mv',alpha3:'mdv',name:"Maldives"},
    {id:466,alpha2:'ml',alpha3:'mli',name:"Mali"},
    {id:470,alpha2:'mt',alpha3:'mlt',name:"Malta"},
    {id:584,alpha2:'mh',alpha3:'mhl',name:"Marshall Islands"},
    {id:478,alpha2:'mr',alpha3:'mrt',name:"Mauritania"},
    {id:480,alpha2:'mu',alpha3:'mus',name:"Mauritius"},
    {id:484,alpha2:'mx',alpha3:'mex',name:"Mexico"},
    {id:583,alpha2:'fm',alpha3:'fsm',name:"Micronesia (Federated States of)"},
    {id:498,alpha2:'md',alpha3:'mda',name:"Moldova, Republic of"},
    {id:492,alpha2:'mc',alpha3:'mco',name:"Monaco"},
    {id:496,alpha2:'mn',alpha3:'mng',name:"Mongolia"},
    {id:499,alpha2:'me',alpha3:'mne',name:"Montenegro"},
    {id:504,alpha2:'ma',alpha3:'mar',name:"Morocco"},
    {id:508,alpha2:'mz',alpha3:'moz',name:"Mozambique"},
    {id:104,alpha2:'mm',alpha3:'mmr',name:"Myanmar"},
    {id:516,alpha2:'na',alpha3:'nam',name:"Namibia"},
    {id:520,alpha2:'nr',alpha3:'nru',name:"Nauru"},
    {id:524,alpha2:'np',alpha3:'npl',name:"Nepal"},
    {id:528,alpha2:'nl',alpha3:'nld',name:"Netherlands"},
    {id:554,alpha2:'nz',alpha3:'nzl',name:"New Zealand"},
    {id:558,alpha2:'ni',alpha3:'nic',name:"Nicaragua"},
    {id:562,alpha2:'ne',alpha3:'ner',name:"Niger"},
    {id:566,alpha2:'ng',alpha3:'nga',name:"Nigeria"},
    {id:807,alpha2:'mk',alpha3:'mkd',name:"North Macedonia"},
    {id:578,alpha2:'no',alpha3:'nor',name:"Norway"},
    {id:512,alpha2:'om',alpha3:'omn',name:"Oman"},
    {id:586,alpha2:'pk',alpha3:'pak',name:"Pakistan"},
    {id:585,alpha2:'pw',alpha3:'plw',name:"Palau"},
    {id:591,alpha2:'pa',alpha3:'pan',name:"Panama"},
    {id:598,alpha2:'pg',alpha3:'png',name:"Papua New Guinea"},
    {id:600,alpha2:'py',alpha3:'pry',name:"Paraguay"},
    {id:604,alpha2:'pe',alpha3:'per',name:"Peru"},
    {id:608,alpha2:'ph',alpha3:'phl',name:"Philippines"},
    {id:616,alpha2:'pl',alpha3:'pol',name:"Poland"},
    {id:620,alpha2:'pt',alpha3:'prt',name:"Portugal"},
    {id:634,alpha2:'qa',alpha3:'qat',name:"Qatar"},
    {id:642,alpha2:'ro',alpha3:'rou',name:"Romania"},
    {id:643,alpha2:'ru',alpha3:'rus',name:"Russian Federation"},
    {id:646,alpha2:'rw',alpha3:'rwa',name:"Rwanda"},
    {id:659,alpha2:'kn',alpha3:'kna',name:"Saint Kitts and Nevis"},
    {id:662,alpha2:'lc',alpha3:'lca',name:"Saint Lucia"},
    {id:663,alpha2:'mf',alpha3:'maf',name:"Saint Martin (French part)"}, // New data to be tested
    {id:670,alpha2:'vc',alpha3:'vct',name:"Saint Vincent and the Grenadines"},
    {id:882,alpha2:'ws',alpha3:'wsm',name:"Samoa"},
    {id:674,alpha2:'sm',alpha3:'smr',name:"San Marino"},
    {id:678,alpha2:'st',alpha3:'stp',name:"Sao Tome and Principe"},
    {id:682,alpha2:'sa',alpha3:'sau',name:"Saudi Arabia"},
    {id:686,alpha2:'sn',alpha3:'sen',name:"Senegal"},
    {id:688,alpha2:'rs',alpha3:'srb',name:"Serbia"},
    {id:690,alpha2:'sc',alpha3:'syc',name:"Seychelles"},
    {id:694,alpha2:'sl',alpha3:'sle',name:"Sierra Leone"},
    {id:702,alpha2:'sg',alpha3:'sgp',name:"Singapore"},
    {id:534,alphs2:'sx',alpha3:'sxm',name:"Sint Maarten (Dutch part)"},// New data to be tested
    {id:703,alpha2:'sk',alpha3:'svk',name:"Slovakia"},
    {id:705,alpha2:'si',alpha3:'svn',name:"Slovenia"},
    {id:90,alpha2:'sb',alpha3:'slb',name:"Solomon Islands"},
    {id:706,alpha2:'so',alpha3:'som',name:"Somalia"},
    {id:710,alpha2:'za',alpha3:'zaf',name:"South Africa"},
    {id:728,alpha2:'ss',alpha3:'ssd',name:"South Sudan"},
    {id:724,alpha2:'es',alpha3:'esp',name:"Spain"},
    {id:144,alpha2:'lk',alpha3:'lka',name:"Sri Lanka"},
    {id:729,alpha2:'sd',alpha3:'sdn',name:"Sudan"},
    {id:740,alpha2:'sr',alpha3:'sur',name:"Suriname"},
    {id:752,alpha2:'se',alpha3:'swe',name:"Sweden"},
    {id:756,alpha2:'ch',alpha3:'che',name:"Switzerland"},
    {id:760,alpha2:'sy',alpha3:'syr',name:"Syrian Arab Republic"},
    {id:762,alpha2:'tj',alpha3:'tjk',name:"Tajikistan"},
    {id:834,alpha2:'tz',alpha3:'tza',name:"Tanzania, United Republic of"},
    {id:764,alpha2:'th',alpha3:'tha',name:"Thailand"},
    {id:626,alpha2:'tl',alpha3:'tls',name:"Timor-Leste"},
    {id:768,alpha2:'tg',alpha3:'tgo',name:"Togo"},
    {id:776,alpha2:'to',alpha3:'ton',name:"Tonga"},
    {id:780,alpha2:'tt',alpha3:'tto',name:"Trinidad and Tobago"},
    {id:788,alpha2:'tn',alpha3:'tun',name:"Tunisia"},
    {id:792,alpha2:'tr',alpha3:'tur',name:"Türkiye"},
    {id:795,alpha2:'tm',alpha3:'tkm',name:"Turkmenistan"},
    {id:798,alpha2:'tv',alpha3:'tuv',name:"Tuvalu"},
    {id:800,alpha2:'ug',alpha3:'uga',name:"Uganda"},
    {id:804,alpha2:'ua',alpha3:'ukr',name:"Ukraine"},
    {id:784,alpha2:'ae',alpha3:'are',name:"United Arab Emirates"},
    {id:826,alpha2:'gb',alpha3:'gbr',name:"United Kingdom"},
    {id:840,alpha2:'us',alpha3:'usa',name:"United States of America"},
    {id:858,alpha2:'uy',alpha3:'ury',name:"Uruguay"},
    {id:860,alpha2:'uz',alpha3:'uzb',name:"Uzbekistan"},
    {id:548,alpha2:'vu',alpha3:'vut',name:"Vanuatu"},
    {id:862,alpha2:'ve',alpha3:'ven',name:"Venezuela (Bolivarian Republic of)"},
    {id:704,alpha2:'vn',alpha3:'vnm',name:"Vietnam"},
    {id:887,alpha2:'ye',alpha3:'yem',name:"Yemen"},
    {id:894,alpha2:'zm',alpha3:'zmb',name:"Zambia"},
    {id:716,alpha2:'zw',alpha3:'zwe',name:"Zimbabwe"}
  ];



  public ageFromAllowed = [
    { label: 'any', value: 'Any' },
    { label: 'age 1', value: '1' },
    { label: 'age 2', value: '2' },
    { label: 'age 3', value: '3' },
    { label: 'age 4', value: '4' },
    { label: 'age 5', value: '5' },
    { label: 'age 6', value: '6' },
    { label: 'age 7', value: '7' },
    { label: 'age 8', value: '8' },
    { label: 'age 9', value: '9' },
    { label: 'age 10', value: '10' },
    { label: 'age 11', value: '11' },
    { label: 'age 12', value: '12' },
    { label: 'age 13', value: '13' },
    { label: 'age 14', value: '14' },
    { label: 'age 15', value: '15' },
    { label: 'age 16', value: '16' },
    { label: 'age 17', value: '17' },
    { label: 'age 18', value: '18' },
    { label: 'age 19', value: '19' },
    { label: 'age 20', value: '20' },
    { label: 'age 21', value: '21' },
  ];


  public currencySelect = [
    { label: 'FREE', value: '' },
    { label: 'USD', value: 'United States dollar' },
    { label: 'XCD', value: 'Eastern Caribbean dollar' },
    { label: 'EUR', value: 'Euro' },
    { label: 'GBP', value: 'Pound sterling' },
    { label: 'AED', value: 'United Arab Emirates dirham' },
    { label: 'AFN', value: 'Afghan afghani' },
    { label: 'ALL', value: 'Albanian lek' },
    { label: 'AMD', value: 'Armenian dram' },
    { label: 'ANG', value: 'Netherlands Antillean guilder' },
    { label: 'AOA', value: 'Angolan kwanza' },
    { label: 'ARS', value: 'Argentine peso' },
    { label: 'AUD', value: 'Australian dollar' },
    { label: 'AWG', value: 'Aruban florin' },
    { label: 'AZN', value: 'Azerbaijani manat' },
    { label: 'BAM', value: 'Bosnia and Herzegovina convertible mark' },
    { label: 'BBD', value: 'Barbadian dollar' },
    { label: 'BDT', value: 'Bangladeshi taka' },
    { label: 'BGN', value: 'Bulgarian lev' },
    { label: 'BHD', value: 'Bahraini dinar' },
    { label: 'BIF', value: 'Burundian franc' },
    { label: 'BMD', value: 'Bermudian dollar' },
    { label: 'BND', value: 'Brunei dollar' },
    { label: 'BOB', value: 'Bolivian boliviano' },
    { label: 'BRL', value: 'Brazilian real' },
    { label: 'BSD', value: 'Bahamian dollar' },
    { label: 'BTN', value: 'Bhutanese ngultrum' },
    { label: 'BWP', value: 'Botswana pula' },
    { label: 'BYN', value: 'Belarusian ruble' },
    { label: 'BZD', value: 'Belize dollar' },
    { label: 'CAD', value: 'Canadian dollar' },
    { label: 'CDF', value: 'Congolese franc' },
    { label: 'CHF', value: 'Swiss franc' },
    { label: 'CLP', value: 'Chilean peso' },
    { label: 'CNY', value: 'Chinese yuan' },
    { label: 'COP', value: 'Colombian peso' },
    { label: 'CRC', value: 'Costa Rican colón' },
    { label: 'CUC', value: 'Cuban convertible peso' },
    { label: 'CUP', value: 'Cuban peso' },
    { label: 'CVE', value: 'Cape Verdean escudo' },
    { label: 'CZK', value: 'Czech koruna' },
    { label: 'DJF', value: 'Djiboutian franc' },
    { label: 'DKK', value: 'Danish krone' },
    { label: 'DOP', value: 'Dominican peso' },
    { label: 'DZD', value: 'Algerian dinar' },
    { label: 'EGP', value: 'Egyptian pound' },
    { label: 'ERN', value: 'Eritrean nakfa' },
    { label: 'ETB', value: 'Ethiopian birr' },
    { label: 'EUR', value: 'EURO' },
    { label: 'FJD', value: 'Fijian dollar' },
    { label: 'FKP', value: 'Falkland Islands pound' },
    { label: 'GBP', value: 'British pound' },
    { label: 'GEL', value: 'Georgian lari' },
    { label: 'GGP', value: 'Guernsey pound' },
    { label: 'GHS', value: 'Ghanaian cedi' },
    { label: 'GIP', value: 'Gibraltar pound' },
    { label: 'GMD', value: 'Gambian dalasi' },
    { label: 'GNF', value: 'Guinean franc' },
    { label: 'GTQ', value: 'Guatemalan quetzal' },
    { label: 'GYD', value: 'Guyanese dollar' },
    { label: 'HKD', value: 'Hong Kong dollar' },
    { label: 'HNL', value: 'Honduran lempira' },
    { label: 'HKD', value: 'Hong Kong dollar' },
    { label: 'HRK', value: 'Croatian kuna' },
    { label: 'HTG', value: 'Haitian gourde' },
    { label: 'HUF', value: 'Hungarian forint' },
    { label: 'IDR', value: 'Indonesian rupiah' },
    { label: 'ILS', value: 'Israeli new shekel' },
    { label: 'IMP', value: 'Manx pound' },
    { label: 'INR', value: 'Indian rupee' },
    { label: 'IQD', value: 'Iraqi dinar' },
    { label: 'IRR', value: 'Iranian rial' },
    { label: 'ISK', value: 'Icelandic króna' },
    { label: 'JEP', value: 'Jersey pound' },
    { label: 'JMD', value: 'Jamaican dollar' },
    { label: 'JOD', value: 'Jordanian dinar' },
    { label: 'JPY', value: 'Japanese yen' },
    { label: 'KES', value: 'Kenyan shilling' },
    { label: 'KGS', value: 'Kyrgyzstani som' },
    { label: 'KHR', value: 'Cambodian riel' },
    { label: 'KID', value: 'Kiribati dollar' },
    { label: 'KMF', value: 'Comorian franc' },
    { label: 'KPW', value: 'North Korean won' },
    { label: 'KRW', value: 'South Korean won' },
    { label: 'KWD', value: 'Kuwaiti dinar' },
    { label: 'KYD', value: 'Cayman Islands dollar' },
    { label: 'KZT', value: 'Kazakhstani tenge' },
    { label: 'LAK', value: 'Lao kip' },
    { label: 'LBP', value: 'Lebanese pound' },
    { label: 'LKR', value: 'Sri Lankan rupee' },
    { label: 'LRD', value: 'Liberian dollar' },
    { label: 'LSL', value: 'Lesotho loti' },
    { label: 'LYD', value: 'Libyan dinar' },
    { label: 'MAD', value: 'Moroccan dirham' },
    { label: 'MDL', value: 'Moldovan leu' },
    { label: 'MGA', value: 'Malagasy ariary' },
    { label: 'MKD', value: 'Macedonian denar' },
    { label: 'MMK', value: 'Burmese kyat' },
    { label: 'MNT', value: 'Mongolian tögrög' },
    { label: 'MOP', value: 'Macanese pataca' },
    { label: 'MRU', value: 'Mauritanian ouguiya' },
    { label: 'MUR', value: 'Mauritian rupee' },
    { label: 'MVR', value: 'Maldivian rufiyaa' },
    { label: 'MWK', value: 'Malawian kwacha' },
    { label: 'MXN', value: 'Mexican peso' },
    { label: 'MYR', value: 'Malaysian ringgit' },
    { label: 'MZN', value: 'Mozambican metical' },
    { label: 'NAD', value: 'Namibian dollar' },
    { label: 'NGN', value: 'Nigerian naira' },
    { label: 'NIO', value: 'Nicaraguan córdoba' },
    { label: 'NOK', value: 'Norwegian krone' },
    { label: 'NPR', value: 'Nepalese rupee' },
    { label: 'NZD', value: 'New Zealand dollar' },
    { label: 'OMR', value: 'Omani rial' },
    { label: 'PAB', value: 'Panamanian balboa' },
    { label: 'PEN', value: 'Peruvian sol' },
    { label: 'PGK', value: 'Papua New Guinean kina' },
    { label: 'PHP', value: 'Philippine peso' },
    { label: 'PKR', value: 'Pakistani rupee' },
    { label: 'PLN', value: 'Polish złoty' },
    { label: 'PRB', value: 'Transnistrian ruble' },
    { label: 'PYG', value: 'Paraguayan guaraní' },
    { label: 'QAR', value: 'Qatari riyal' },
    { label: 'RON', value: 'Romanian leu' },
    { label: 'RON', value: 'Romanian leu' },
    { label: 'RSD', value: 'Serbian dinar' },
    { label: 'RUB', value: 'Russian ruble' },
    { label: 'RWF', value: 'Rwandan franc' },
    { label: 'SAR', value: 'Saudi riyal' },
    { label: 'SEK', value: 'Swedish krona' },
    { label: 'SGD', value: 'Singapore dollar' },
    { label: 'SHP', value: 'Saint Helena pound' },
    { label: 'SLL', value: 'Sierra Leonean leone' },
    { label: 'SLS', value: 'Somaliland shilling' },
    { label: 'SOS', value: 'Somali shilling' },
    { label: 'SRD', value: 'Surinamese dollar' },
    { label: 'SSP', value: 'South Sudanese pound' },
    { label: 'STN', value: 'São Tomé and Príncipe dobra' },
    { label: 'SYP', value: 'Syrian pound' },
    { label: 'SZL', value: 'Swazi lilangeni' },
    { label: 'THB', value: 'Thai baht' },
    { label: 'TJS', value: 'Tajikistani somoni' },
    { label: 'TMT', value: 'Turkmenistan manat' },
    { label: 'TND', value: 'Tunisian dinar' },
    { label: 'TOP', value: 'Tongan paʻanga' },
    { label: 'TRY', value: 'Turkish lira' },
    { label: 'TTD', value: 'Trinidad and Tobago dollar' },
    { label: 'TVD', value: 'Tuvaluan dollar' },
    { label: 'TWD', value: 'New Taiwan dollar' },
    { label: 'TZS', value: 'Tanzanian shilling' },
    { label: 'UAH', value: 'Ukrainian hryvnia' },
    { label: 'UGX', value: 'Ugandan shilling' },
    { label: 'USD', value: 'United States dollar' },
    { label: 'UYU', value: 'Uruguayan peso' },
    { label: 'UZS', value: 'Uzbekistani soʻm' },
    { label: 'VES', value: 'Venezuelan bolívar soberano' },
    { label: 'VND', value: 'Vietnamese đồng' },
    { label: 'VUV', value: 'Vanuatu vatu' },
    { label: 'WST', value: 'Samoan tālā' },
    { label: 'XAF', value: 'Central African CFA franc' },
    { label: 'XCD', value: 'Eastern Caribbean dollar' },
    { label: 'XOF', value: 'West African CFA franc' },
    { label: 'XPF', value: 'CFP franc' },
    { label: 'ZAR', value: 'South African rand' },
    { label: 'ZMW', value: 'Zambian kwacha' },
    { label: 'ZWB', value: 'Zimbabwean bonds' },
  ];


  public bedSixeSelect = [
    { label: 'Twin Bed', value: 'Twin Bed' },
    { label: 'Full Bed', value: 'Full Bed' },
    { label: 'Queen Bed', value: 'Queen Bed' },
    { label: 'King Bed', value: 'King Bed' },
    { label: 'Bunk Bed', value: 'Bunk Bed' },
    { label: 'Sofa Bed', value: 'Sofa Bed' },
    { label: 'Futon Bed', value: 'Sofa Bed' },
  ];

  public bookingDirectBenefits = [
    'Best available rates guaranteed', 
    'Early check-in (based on availability)', 

    'Breakfast included in room rate', 
    'Express check-in service', 

    'Special packages and discounts only available directly', 
    'Late check-out (based on availability)', 

    'Easier to cancel and make changes to your booking', 
    'Welcome gift', 

    'Guests will not need to pay until arrival', 
    'Welcome drinks', 

    'Complimentary in-room high-speed Internet access',
    'Restaurant discounts', 


    'Complimentary room upgrades (based on availability)', 
    'Free parking', 

    'Guaranteed room availability', 
    'No hidden booking fees or transaction charges',

    'Preference given to special requests', 

  ];
  
  ngOnDestroy() {}

}



  

  