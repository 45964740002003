import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

// Services
import { SignUpService } from '../services/sign-up.service';

@Component({
  selector: 'app-is-this-you',
  templateUrl: './is-this-you.component.html',
  styleUrls: ['./is-this-you.component.scss']
})

export class IsThisYouComponent implements OnInit, OnDestroy {

  destinationId: string;
  destinationString: string;
  selectedHotelItem: any;

  hotels: {name: string, caption: string, destinationId: string}[] = [];
  
  clientId = '';
  city = '';
  name = '';
  country = '';

  nextButtonClickedSub: Subscription;
  skipButtonClickedSub: Subscription;

  constructor(
    private signUpService: SignUpService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.clientId = this.signUpService.clientId;
    this.city = this.signUpService.city;
    this.name = this.signUpService.hotelName;
    this.country = this.signUpService.country;

    const hotels = this.signUpService.searchHotels;
    // console.log(hotels);
    this.getHotelsReadyForChoosing(hotels);
  }

  listClick(newValue) {
    this.selectedHotelItem = newValue;
    this.destinationId = this.selectedHotelItem.destinationId;
    this.destinationString = this.selectedHotelItem.name;
  }

  getHotelsReadyForChoosing(response: any) {
    const tempArr = response;
    if (tempArr && Array.isArray(tempArr)) { 
      tempArr.forEach(item => {
        this.hotels.push({ 
          name: item.regionNames.primaryDisplayName,
          caption: item.regionNames.secondaryDisplayName,
          destinationId: item.hotelId
        });

        this.selectedHotelItem = this.hotels[0];
        this.destinationId = this.hotels[0].destinationId;
        this.destinationString = this.selectedHotelItem.name;
      })
    }
  }

  onSkip() {
    this.router.navigate(['/customize']);
  }

  async onSubmit() {
    if (this.destinationId && this.clientId) {
      await this.signUpService.saveSelectedHotel(this.destinationId, this.clientId, this.destinationString, this.name, this.city, this.country )
        .then((resp: any) => {
          if (resp.success) {
            this.router.navigate(['/customize']);    
          } else {
            // this.showSuccess(false, 'The request was unsuccessful.');        
          }
        }).catch(err => {
          // this.showSuccess(false, 'The system could not complete your request.');        
        });          
    } else {
      // this.showSuccess(false, 'The system could not complete your request. Possible empty fields.');      
    }
  }

  ngOnDestroy(): void {}
}
