<!-- <div style="height: 300px;" *ngIf="loading" [nbSpinner]="loading" nbSpinnerStatus="success" nbSpinnerSize="giant"></div> -->
<!-- <div class="container display-rows"> -->
    <!-- view rooms -->
    <div class="row" *ngIf="!loading">
        <div class="room-flex col-12">
            <div *ngFor="let room of roomCategories; let i = index" class="room-block" (click)="onEditItem(room, i)">
                <!-- <div class="duplicate-icon" (click)="onDuplicate($event, room)">
                    <nb-icon icon="copy-outline"></nb-icon>
                </div> -->
                <div [ngClass]="{'selected-room' : selectedRoom[i]}" class="room-image-frame">
                    <div class="img-div">
                        <img alt="image of the room category" [src]="room.roomPhotoUrl">
                    </div>
                    <div class="room-image-caption">
                        <h5 class="truncate" title="{{room.roomName}}">{{room.roomName}}</h5>
                        <p>{{room.roomView}}</p>
                    </div>
                </div>
                <div class="container" style="padding: 6px 15px 0px 15px;">
                    <div class="row">
                        <div class="col-md-12">
                            <small>Occupancy: 
                                <strong>
                                    {{ room.adultOccupancy }} adults + {{ room.childrenOccupancy  }} children (max {{ room.maximumOccupancy }} guests)
                                </strong>
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <small>Total Bathrooms: <strong>{{ room.numberOfBathrooms }}</strong></small>
                        </div>
                        <div class="col-6">
                            <small>Room Size: <strong>{{ room.roomSizeValue }} {{ room.roomSizeUnit == 'squareMeter' ? 'm' : 'ft' }}<sup>2</sup></strong></small>                            
                        </div>
                    </div>
                </div>
            </div>
            <div (click)="onAddRoomCategory()" class="room-block create-button-parent">
                <div class="create-button-child">
                    <p>Create a new room</p>
                    <nb-icon icon="plus-circle-outline"></nb-icon>
                </div>
            </div>
        </div>

    </div>
    <div class="overlay" *ngIf="loading">
        <div class="overlay-position">
            <div class="gc-animation">
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
                <div style="background: #095570"></div>
            </div>
        </div>
    </div>
<!-- </div> -->