import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Message, BotSettings } from '../services/interfaces';

@Component({
  selector: 'chat-area',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {

  botSettings: BotSettings = {
    title: '',
    openingLine: '',
    botImgUrl: null,
    backgroundColors: ['#FFFFFF', '#FFFFFF'],
    screenPosition: 'right',
    introMessage: ''
  };


  @Input('botSettings')
  public set func(settings: any) {
    this.botSettings = settings;
    
    this.backgroundSuccess = { 'background-color': settings.backgroundColors[0] };

    if (settings.screenPosition === 'left') {
      if (settings.buttonShape === 'round') {
        this.buttonStyle = { 'background-color': settings.backgroundColors[0], bottom: settings.bottomSpacing + 'px', left: settings.fromSideSpacing + 'px' };
      } else if (settings.buttonShape === 'bar') {
        this.buttonStyle = { 'background-color': settings.backgroundColors[0], left: settings.fromSideSpacing + 'px' };
      }
    } else {
      if (settings.buttonShape === 'round') {
        this.buttonStyle = { 'background-color': settings.backgroundColors[0], bottom: settings.bottomSpacing + 'px', right: settings.fromSideSpacing + 'px' };
      } else if (settings.buttonShape === 'bar') {
        this.buttonStyle = { 'background-color': settings.backgroundColors[0], right: settings.fromSideSpacing + 'px' };
      }
    }

    this.preMessages.push({
      reply: true,
      textResponse: this.responseSplitter(this.botSettings.openingLine)
    })
  }

  preMessages: Message[] = [];

  backgroundSuccess = { 'background-color': 'green' };

  buttonStyle: any = {
    'background': 'linear-gradient(to bottom right,  #FFFFFF , #FFFFFF)', bottom: '50px', right: '50px'
  }


  constructor() {}

  ngOnInit() {}

  get columnContainerHeight() {
    return 500 + 'px';
  }


  private pullOutLinks(textIn: string) {
    let cutFrom = 0;
    let linkSection = false;
    const texts = [];
    for (let i = 0; i < textIn.length; i++) {
      let done = false;
      // link character
      if (textIn[i] === '>' && textIn[i - 1] === '>') {
        texts.push({ type: 'text', value: textIn.slice(cutFrom, i - 2).trim() });
        linkSection = true;
        cutFrom = i + 2;
        i++;
        i++;
      }
      if ((i === textIn.length - 1 || textIn[i] === ' ') && linkSection) {
        const value = textIn.slice(cutFrom - 1, i + 1).trim();
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          texts.push({ type: 'link', value, link: 'mailto:' + value });
        } else if (/^(\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)) { //no http
          texts.push({ type: 'link', value, link: 'https://' + value });
        } else if (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)) { //no http
          texts.push({ type: 'link', value, link: value });
        }
        done = true;
        cutFrom = i + 1;
      }

      if (i === textIn.length - 1 && !linkSection && cutFrom !== i) {
        texts.push({ type: 'text', value: textIn.slice(cutFrom).trim() });
        done = true;
        cutFrom = i + 1;
      }

      if (done) {
        linkSection = false;
      }
    }
    // nothing found, just add to array
    if (cutFrom === 0) {
      texts.push({ type: 'text', value: textIn })
    }

    return texts;
  }

  private responseSplitter = (clientTextResponse: string) => {
    if (!clientTextResponse) { return []; }
    const texts = clientTextResponse.split('--');

    return texts.map((text) => {
      return this.pullOutLinks(text);
    });
  }

  get backgroundColors() {
    if (!this.botSettings.backgroundColors) {
      return 'linear-gradient(to right, #6A82FB, #FC5C7D)';
    }
    return 'linear-gradient(to right, ' + this.botSettings.backgroundColors[0] + ',' + this.botSettings.backgroundColors[1] + ')';
  }

  
}
