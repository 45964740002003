import { Component, OnInit } from '@angular/core';
import { Reseller } from 'src/app/services/interfaces';
import { ResellersService } from 'src/app/services/resellers.service';

@Component({
  selector: 'app-app-topbar',
  templateUrl: './app-topbar.component.html',
  styleUrls: ['./app-topbar.component.scss']
})
export class AppTopbarComponent implements OnInit {

  activeReseller: Reseller = { logoBig: null };

  constructor(
    private resellersService: ResellersService,
  ) { }

  ngOnInit(): void {
    this.activeReseller = this.resellersService.activeReseller;

  }

  onLogin() {
    window.location.href = 'https://admin.guestchat.com';
  }
}
