<form [formGroup]="itemForm">
    <!-- Swimming Pool -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 0)" class="check-start checkbox1" #f9 formControlName="hasPool" id="hasPool"></nb-checkbox>                                    
                <div>Swimming Pool</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[0]}" (click)="onCollapseToggle(0, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[0]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f9.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f9.checked" class="container white-top-space">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="label"># Outdoor Pools</label>
                                            <input 
                                                type="number" 
                                                class="form-control"
                                                nbInput 
                                                formControlName="numberOfOutdoorPools">
                                        </div>
                                    </div>
                                    <div class="col-12 white-top-space">
                                        <div class="form-group">
                                            <label class="label"># Indoor Pools</label>
                                            <input 
                                                type="number" 
                                                class="form-control"
                                                nbInput 
                                                formControlName="numberOfIndoorPools">
                                        </div>
                                    </div>    
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="label">Availability</label>
                                            <nb-radio-group 
                                                name="poolAvailability" 
                                                formControlName="poolAvailability">
                                                <nb-radio value="all year">All year</nb-radio>
                                                <nb-radio value="seasonally">Seasonally</nb-radio>
                                            </nb-radio-group>
                                        </div>        
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <nb-checkbox formControlName="childFriendlyPool">
                                                Child friendly / safe
                                            </nb-checkbox>
                                        </div>
                                        <div class="form-group white-top-space">
                                            <nb-checkbox formControlName="hasPoolSideBar">
                                                Poolside bar
                                            </nb-checkbox>
                                        </div>  
                                    </div>
                                </div>                           
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <nb-radio-group 
                                                name="chlorinatedOrSaltedPool" 
                                                formControlName="chlorinatedOrSaltedPool">
                                                <nb-radio value="Chlorinated">Chlorinated</nb-radio>
                                                <nb-radio value="Salt water">Salt water</nb-radio>
                                            </nb-radio-group>
                                        </div>        
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <nb-checkbox formControlName="heatedPool">
                                                Heated pool
                                            </nb-checkbox>
                                        </div>
                                        <div class="form-group white-top-space">
                                            <nb-checkbox formControlName="lifeGuardAtPool">
                                                Life guard at pool
                                            </nb-checkbox>
                                        </div> 
                                        <!-- <div class="form-group white-top-space">
                                            <nb-checkbox 
                                                class="check-start"
                                                formControlName="hasNonGuestsSwimmingPool">Open to Non-guests (outside visitors)
                                            </nb-checkbox>
                                        </div>  -->
                                    </div>
                                </div>                           
                            </div>
                        </div>                            
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <!-- Parking -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 1)" class="check-start checkbox1" #f10 formControlName="hasParking" id="hasParking"></nb-checkbox>                                    
                <div>Parking</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container"  [ngClass]="{collapsed: !collapsedArray[1]}" (click)="onCollapseToggle(1, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[1]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f10.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f10.checked" class="container">
                        <div class="row">
                            <div class="col-12 col-md-8">
                                <!-- self parking -->
                                <div class="row">
                                    <div class="col-12 white-top-space">
                                        <nb-checkbox formControlName="hasSelfParking" #hasSelfParking>
                                            Self parking
                                        </nb-checkbox>
                                        <div *ngIf="hasSelfParking.checked" class="outer-form-array-div">
                                            <div class="form-array-item">
                                                <nb-radio-group 
                                                    #selfParking
                                                    name="selfParking" 
                                                    formControlName="selfParking">
                                                    <nb-radio value="Free">Free</nb-radio>
                                                    <nb-radio value="Surcharge">Surcharge</nb-radio>
                                                </nb-radio-group>
                                                <div *ngIf="selfParking.value == 'Surcharge'" class="container">
                                                    <div class="row white-top-space">
                                                        <div class="col-12">
                                                            <table>
                                                                <tr>
                                                                    <td><label class="label">Amount per vehicle (USD)</label></td>
                                                                    <td><label class="label"></label></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><input 
                                                                            type="number" 
                                                                            nbInput                                                                      
                                                                            formControlName="selfParkingCost"></td>
                                                                    <td><select 
                                                                            placeholder="--select--" 
                                                                            formControlName="selfParkingCostUnit">                                                                                
                                                                            <option value="Per hour">Per hour</option>
                                                                            <option value="Per night">Per night</option>
                                                                            <option value="Per stay">Per stay</option>
                                                                            <option value="Per week">Per week</option>
                                                                        </select></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- valet parking -->
                                <div class="row">
                                    <div class="col-12 white-top-space">
                                        <nb-checkbox formControlName="hasValetParking" #hasValetParking>
                                            Valet parking
                                        </nb-checkbox>
                                        <div *ngIf="hasValetParking.checked" class="outer-form-array-div">
                                            <div class="form-array-item">
                                                <nb-radio-group 
                                                    #valetParking
                                                    name="valetParking" 
                                                    formControlName="valetParking">
                                                    <nb-radio value="Free">Free</nb-radio>
                                                    <nb-radio value="Surcharge">Surcharge</nb-radio>
                                                </nb-radio-group>
                                                <div *ngIf="valetParking.value == 'Surcharge'" class="container">
                                                    <div class="row white-top-space">
                                                        <div class="col-12">
                                                            <table>
                                                                <tr>
                                                                    <td><label class="label">Amount per vehicle (USD)</label></td>
                                                                    <td><label class="label"></label></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><input 
                                                                            type="number" 
                                                                            nbInput                                                                      
                                                                            formControlName="valetParkingCost"></td>
                                                                    <td><select 
                                                                            placeholder="--select--" 
                                                                            formControlName="valetParkingCostUnit">
                                                                            
                                                                            <option value="Per hour">Per hour</option>
                                                                            <option value="Per night">Per night</option>
                                                                            <option value="Per stay">Per stay</option>
                                                                            <option value="Per week">Per week</option>
                                                                        </select></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- offsite parking -->
                                <div class="row">
                                    <div class="col-12 white-top-space">
                                        <nb-checkbox formControlName="hasOffsiteParking" #hasOffsiteParking>
                                            Offsite parking
                                        </nb-checkbox>
                                        <div *ngIf="hasOffsiteParking.checked"  class="outer-form-array-div">
                                            <div class="form-array-item">
                                                <nb-radio-group 
                                                    #offsiteParking
                                                    name="offsiteParking" 
                                                    formControlName="offsiteParking" >
                                                    <nb-radio value="Free">Free</nb-radio>
                                                    <nb-radio value="Surcharge">Surcharge</nb-radio>
                                                </nb-radio-group>
                                                <div *ngIf="offsiteParking.value == 'Surcharge'" class="container">
                                                    <div class="row white-top-space">
                                                        <div class="col-12">
                                                            <table>
                                                                <tr>
                                                                    <td><label class="label">Amount per vehicle (USD)</label></td>
                                                                    <td><label class="label"></label></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><input 
                                                                            type="number" 
                                                                            nbInput                                                                      
                                                                            formControlName="offsiteParkingCost"></td>
                                                                    <td><select 
                                                                            placeholder="--select--" 
                                                                            formControlName="offsiteParkingCostUnit">
                                                                            <option value="Per hour">Per hour</option>
                                                                            <option value="Per night">Per night</option>
                                                                            <option value="Per stay">Per stay</option>
                                                                            <option value="Per week">Per week</option>
                                                                        </select></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 white-top-space">
                                                        <nb-checkbox formControlName="offsiteStreetParking">
                                                            Street parking
                                                        </nb-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <!-- Spa -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 2)" class="check-start checkbox1" #f12 formControlName="hasSpa" id="hasSpa"></nb-checkbox>                                    
                <div>Spa</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[2]}" (click)="onCollapseToggle(2, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[2]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f12.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f12.checked" class="container">
                        <div class="row">
                            <div class="col-12 col-md-8 white-top-space">
                                <label class="label">Enter opening times</label>
                                <ngx-schedule formControlName="spaTime"></ngx-schedule>
                            </div>
                            <div class="col-12 col-md-4 white-top-space">
                                <div class="row">
                                    <div class="col-12 white-top-space">
                                        <h6>Services Offered</h6>
                                        <div class="row">
                                            <div class="col-12">
                                                <ngx-checkbox-group
                                                    [options]="['Spa Treatments', 'Sauna', 'Massages']"
                                                    formControlName="spaServicesOffered"></ngx-checkbox-group>                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 white-top-space">
                                        <h6>Requirements</h6>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <nb-checkbox 
                                                        formControlName="spaRequireReservation">
                                                        Require reservation
                                                    </nb-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <nb-checkbox 
                                                        class="check-start"
                                                        formControlName="hasNonGuestsSpa">Open to Non-guests (outside visitors)
                                                    </nb-checkbox>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>  
                                </div>

                            </div>                              
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Room Service -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 3)" class="check-start checkbox1" #f15 formControlName="hasRoomService" id="hasRoomService"></nb-checkbox>                                    
                <div>Room Service</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[3]}" (click)="onCollapseToggle(3, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[3]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f15.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f15.checked" class="container">
                        <div class="row">
                            <div class="col-12 white-top-space">
                                <label class="label">Enter opening times</label>
                                <ngx-schedule formControlName="roomServiceOpeningScheduleTimes"></ngx-schedule>
                            </div>                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Pets & Service Animals -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 4)" class="check-start checkbox1" #f18 formControlName="hasPetsAndServiceAnimals" id="hasPetsAndServiceAnimals"></nb-checkbox>                                    
                <div>Pets & Service Animals</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[4]}" (click)="onCollapseToggle(4, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[4]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f18.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f18.checked" class="container">
                        <div class="row">
                            <div class="col-12 white-top-space">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-md-8">
                                            <div class="outer-form-array-div">
                                                <div class="form-array-item">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label class="label">Do you have a surcharge for pets?</label>
                                                            <nb-radio-group 
                                                                #allowPetsSurcharge
                                                                name="allowPetsSurcharge" 
                                                                formControlName="allowPetsSurcharge">
                                                                <nb-radio value="Yes">Yes</nb-radio>
                                                                <nb-radio value="No">No</nb-radio>
                                                            </nb-radio-group>
                                                            <div *ngIf="allowPetsSurcharge.value == 'Yes'" class="container">
                                                                <div class="row white-top-space">
                                                                    <div class="col-12">
                                                                        <table>
                                                                            <tr>
                                                                                <td><label class="label">Amount (USD) Per Pet</label></td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><input 
                                                                                        type="number" 
                                                                                        nbInput                                                                                   
                                                                                        formControlName="allowPetsSurchargeValue"></td>
                                                                                <td><select 
                                                                                        placeholder="--select--"                                                                                             
                                                                                        formControlName="allowPetsSurchargeValueUnit2">
                                                                                        
                                                                                        <option value="Per night">Per night</option>
                                                                                        <option value="Per stay">Per stay</option>
                                                                                        <option value="Per week">Per week</option>
                                                                                    </select></td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>   
                                                        </div>
                                                        <div class="col-12 white-top-space">
                                                            <label class="label">Do you have pet type restrictions?</label>
                                                            <select 
                                                                placeholder="--select--" 
                                                                
                                                                formControlName="petRestrictionsType">
                                                                <option value="Only dogs are allowed">Only dogs are allowed</option>
                                                                <option value="Only cats are allowed">Only cats are allowed</option>
                                                                <option value="Only dogs and cats are allowed">Only dogs and cats are allowed</option>
                                                                <option value="All pets are allowed">All pets are allowed</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-12 white-top-space">
                                                            <nb-checkbox 
                                                                #hasPetRestrictions
                                                                formControlName="hasPetRestrictions">
                                                                Restrictions
                                                            </nb-checkbox>                        
                                                            <div *ngIf="hasPetRestrictions.checked" class="container">
                                                                <div class="row">
                                                                    <div class="col-12 white-top-space">
                                                                        <nb-checkbox 
                                                                            #hasPetMaxWeightLimit
                                                                            formControlName="hasPetMaxWeightLimit">
                                                                            Maximum pet weight limited
                                                                        </nb-checkbox> 
                                                                        <div *ngIf="hasPetMaxWeightLimit.checked" class="container">
                                                                            <div class="row white-top-space">
                                                                                <div class="col-12">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <td><label class="label">Weight</label></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><input 
                                                                                                    type="number" 
                                                                                                    nbInput                                                                                               
                                                                                                    formControlName="petMaxWeightLimitValue"> </td>
                                                                                            <td><select 
                                                                                                    placeholder="--select--"
                                                                                                    formControlName="petMaxWeightLimitValueUnit">
                                                                                                    <option value="Pounds">Pounds</option>
                                                                                                    <option value="Kilograms">Kilograms</option>                                                                        
                                                                                                </select></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 white-top-space">
                                                                        <label class="label">Maximum pets per room</label>
                                                                        <select 
                                                                            placeholder="--select--" 
                                                                            formControlName="petMaxPerRoom">
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>                                                                        
                                                                            <option value="3">3</option>                                                                        
                                                                            <option value="4">4</option>                                                                        
                                                                            <option value="5">5</option>                                                                        
                                                                        </select>
                                                                        <div class="container">
                                                                            <div class="row">
                                                                                <div style="margin-bottom: 12px;" class="col-12 white-top-space">
                                                                                    <ngx-checkbox-group
                                                                                        [singleColumn]="true"
                                                                                        #petRestrictions
                                                                                        [options]="['Pets allowed in smoking rooms only', 'Pets allowed in specific rooms only',
                                                                                        'Pets cannot be left unattended', 'Other pet restrictions apply']"
                                                                                        formControlName="petRestrictions"></ngx-checkbox-group>                                                                                        
                                                                                </div>                                                                                    
                                                                            </div>                                                                                    
                                                                        </div>  
                                                                    </div>                                                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 white-top-space">
                                                            <nb-checkbox 
                                                                #hasPetDeposit
                                                                formControlName="hasPetDeposit">
                                                                Pet deposit
                                                            </nb-checkbox>
                                                            <div *ngIf="hasPetDeposit.checked" class="container">
                                                                <div class="row white-top-space">
                                                                    <div class="col-12">
                                                                        <table>
                                                                            <tr>
                                                                                <td><label class="label">Amount (USD)</label></td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><input 
                                                                                        type="number" 
                                                                                        nbInput                                                                                   
                                                                                        formControlName="petDepositValue"> </td>
                                                                                <td><select 
                                                                                        placeholder="--select--" 
                                                                                        formControlName="petDepositValueUnit">
                                                                                        
                                                                                        <option value="Per night">Per night</option>
                                                                                        <option value="Per stay">Per stay</option>                                                                        
                                                                                        <option value="Per week">Per week</option>                                                                        
                                                                                    </select></td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="hasPetRestriction('Pets allowed in specific rooms only') && hasPetRestrictions.checked" class="col-12 white-top-space">
                                                            <label class="label">Pet-friendly rooms</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <ngx-checkbox-group
                                                                        [singleColumn]="true"
                                                                        [options]="roomCategories"
                                                                        formControlName="petFriendlyRoom">
                                                                    </ngx-checkbox-group>
                                                                </div>
                                                            </div>                                                                
                                                        </div>
                                                    </div>                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                        
                                </div>  
                            </div>                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>