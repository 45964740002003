import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable, Subscription, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// Services
// import { LocationService } from '../../services/location.service';
import { MasterDataService } from '../../services/master-data.service';
import { SurveyService } from '../../services/survey.service';
import { airports } from '../../services/airports';
import { SignUpService } from 'src/app/services/sign-up.service';
// import { RoomCategoriesService } from '../../services/room-categories.service';

// Interface
import { RoomCategory, Survey } from '../../services/interfaces';
// import { NbTagComponent } from '@nebular/theme';

// Components
import { AddStringItemComponent } from '../../custom-components/add-string-item/add-string-item.component';

// Nebular
import { NbDialogService } from '@nebular/theme';
@Component({
  selector: 'ngx-survey-page-four',
  templateUrl: './survey-page-four.component.html',
  styleUrls: ['./survey-page-four.component.scss']
})
export class SurveyPageFourComponent implements OnInit, OnDestroy {
   
    loading = true;

    // Form
    editMode = false;
    editItem: Survey;
    itemForm: UntypedFormGroup;
  
    weekDays = [];
    scheduleTime = [];
    barTypes = [];
    barServing = [];
    breakfastTypes = [];
    mealPlansDD = [];
    
    collapsedArray = [true, true, true, false, true, false, false];
  
    formValueSub: Subscription;
    surveySub: Subscription;
  
    //communicate farm validity to parent
    @Output() isValidForm = new EventEmitter<boolean>();
    formIsValid = null;
  
    airportsDD;
    ageFromAllowed;
    
    //notes
    // frontDeskNote = null;
    // checkInNote = null;
    // restaurantNote = null;
    // barNote = null;
    // breakfastNote = null;
    // mealNote = null;
    // poolNote = null;
    // parkingNote = null;
    // fitnessNote = null;
    // spaNote = null;
    // centerNote = null;
    // conferenceNote = null;
    // roomNote = null;
    // housekeepingNote = null;
    // babysittingNote = null;
    // petsNote = null;
    // dayPassesNote = null;
    // beachNote = null;
    // luggageNote = null;
    // jacuzziNote = null;
    // airportNote = null;
    
    roomCategories: string[] = [];
    roomCategorySub: Subscription;
  
    filteredOptions: {}[];
    @ViewChild('autoInput') input;
    // airportsDB: {}[] = [];
    // airportsDB = [];
  
    saving = false;
    hasPets = false;
    hasConferences = false;
  
    showAirportNameOptions = false;
    @ViewChild('selectAirport') selectAirport: ElementRef;
  
    keyword = 'name';
    popupShow: string;

    bookingDirectBenefitsCB: string[] = [];

    regexUrlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    linkValidator = { 
      hasError: false,
      message: ''
    }

    
  filteredControlOptions$: Observable<string[]>;
  airportDD: string[] = [];

  constructor(
    private signupService: SignUpService,
    private surveyService: SurveyService,
    private masterDataService: MasterDataService,
    private dialogService: NbDialogService,
    ) { }

  ngOnInit(): void {

    this.weekDays = this.masterDataService.daysOfWeek;
    this.barTypes = this.masterDataService.barTypeItems;
    this.barServing = this.masterDataService.barServingItems;
    this.breakfastTypes = this.masterDataService.breakfasts;
    this.mealPlansDD = this.masterDataService.mealPlansDD.map(el => el.value);

    this.bookingDirectBenefitsCB = this.masterDataService.bookingDirectBenefits;

    this.scheduleTime = this.masterDataService.scheduleTime;

    if (this.formValueSub) {
        this.formValueSub.unsubscribe();
      }

    this.initForm();

        // Airports
        this.airportDD = airports.map(el => (el.name + ' (' + el.iata  + ')'));
        this.filteredControlOptions$ = of(this.airportDD);
    
        this.filteredControlOptions$ = this.itemForm.get('airportTransportationName').valueChanges
          .pipe(
            startWith(''),
            map(filterString => this.filter(filterString)),
          );
          
    console.log(this.itemForm.value);


    this.surveySub = this.signupService.survey$.subscribe(
      survey => {

        this.editItem = survey;
        const tempObj = {};
        this.initForm();

        if (this.itemForm) {
          this.itemForm.reset();
        }

        if (this.editItem) {
          Object.keys(this.editItem).forEach(key => {
            tempObj[key] = survey[key].value
          });

          this.itemForm.patchValue(tempObj);

          if (this.itemForm.get('hasDayPasses').value) {
            this.collapsedArray[0] = false
          }
          if (this.itemForm.get('hasBeach').value) {
            this.collapsedArray[1] = false
          }
          if (this.itemForm.get('hasAirportTransportation').value) {
            this.collapsedArray[2] = false
          }
          if (this.itemForm.get('hasPaymentSpecials').value) {
            this.collapsedArray[4] = false
          }

        }

  
        let tempArr = this.itemForm.get('bookingDirectBenefits').value;
        console.log(tempArr)
        if (tempArr && tempArr.length) {
          tempArr.forEach(benefit => {
            if (this.bookingDirectBenefitsCB.findIndex(el => el === benefit) < 0) {
              this.bookingDirectBenefitsCB.push(benefit);        
            }
          });
        }

        // specials url
        if (this.editItem && this.editItem.specialsUrls && this.editItem.specialsUrls.value.length) {
          const specialUrlArr = this.editItem.specialsUrls.value;
          this.specialsUrlsFA.clear();
          specialUrlArr.forEach(cSpecialUrl => {
            this.specialsUrlsFA.push(new UntypedFormGroup({
              link: new UntypedFormControl(cSpecialUrl['link']),      
            }));
          });
        }

        if (this.editItem && this.editItem.cancellationPenaltyGrace && this.editItem.cancellationPenaltyGrace.value.length) {
          const cancelPolicyArr = this.editItem.cancellationPenaltyGrace.value;
          console.log(cancelPolicyArr);
          this.cancellationPenaltyGraceFA.clear();
          cancelPolicyArr.forEach(cPolicy => {
            this.cancellationPenaltyGraceFA.push(new UntypedFormGroup({
              cancellationPenaltyGraceDefinition: new UntypedFormControl(cPolicy['cancellationPenaltyGraceDefinition']),      
              cancellationChargeAfterGracePeriod: new UntypedFormControl(cPolicy['cancellationChargeAfterGracePeriod']),
            }));
          });
        } else {
          this.cancellationPenaltyGraceFA.clear();
          this.onAddCancellationPenaltyGrace();
        }

        if (this.editItem && this.editItem.noCancellationPenaltyGrace && this.editItem.noCancellationPenaltyGrace.value.length) {
          const cancelPolicyArr = this.editItem.noCancellationPenaltyGrace.value;
          this.noCancellationPenaltyGraceFA.clear();
          cancelPolicyArr.forEach(cPolicy => {
            this.noCancellationPenaltyGraceFA.push(new UntypedFormGroup({
              noCancellationPenaltyGraceDefinition: new UntypedFormControl(cPolicy['noCancellationPenaltyGraceDefinition']),      
              noCancellationChargeAfterGracePeriod: new UntypedFormControl(cPolicy['noCancellationChargeAfterGracePeriod']),
            }));
          });
        } else {
          this.noCancellationPenaltyGraceFA.clear();
          this.onAddNoCancellationPenaltyGrace();
        }


        // this.surveyService.loadingData$.next(false);
        // this.loading = false;
        console.log(this.itemForm.value);
      }
    )

    this.ageFromAllowed = this.masterDataService.ageFromAllowed;


    this.loading = false;

  }

  initForm() { 
    const specialsUrlsFA =  new UntypedFormArray([]);
    const weddingsUrlsFA =  new UntypedFormArray([]);
    const cancellationPenaltyGraceFA =  new UntypedFormArray([]);
    const noCancellationPenaltyGraceFA =  new UntypedFormArray([]);

    this.itemForm = new UntypedFormGroup({      
      //day passes
      hasDayPasses: new UntypedFormControl(this.editItem ? (this.editItem.hasDayPasses ? this.editItem.hasDayPasses.value : false) : null),
      dayPassesValue: new UntypedFormControl(this.editItem ? (this.editItem.dayPassesValue ? this.editItem.dayPassesValue.value : null) : null),
      dayPassesValueUnit: new UntypedFormControl(this.editItem ? (this.editItem.dayPassesValueUnit ? this.editItem.dayPassesValueUnit.value : 'Per Person') : null),
      dayPassesIncludes: new UntypedFormControl(this.editItem ? (this.editItem.dayPassesIncludes ? this.editItem.dayPassesIncludes.value : []) : null),
      dayPassesText: new UntypedFormControl(this.editItem ? (this.editItem.dayPassesText ? this.editItem.dayPassesText.value : null) : null),
      
      hasLuggageStorage: new UntypedFormControl(this.editItem ? (this.editItem.hasLuggageStorage ? this.editItem.hasLuggageStorage.value : false) : null),
      hasPublicHottubAvailable: new UntypedFormControl(this.editItem ? (this.editItem.hasPublicHottubAvailable ? this.editItem.hasPublicHottubAvailable.value : false) : null),


      //beach
      hasBeach: new UntypedFormControl(this.editItem ? (this.editItem.hasBeach ? this.editItem.hasBeach.value : false) : null),
      beachLocation: new UntypedFormControl(this.editItem ? (this.editItem.beachLocation ? this.editItem.beachLocation.value : null) : null),
      beachNearByType: new UntypedFormControl(this.editItem ? (this.editItem.beachNearByType ? this.editItem.beachNearByType.value : null) : null),
      beachNearByFromProperty: new UntypedFormControl(this.editItem ? (this.editItem.beachNearByFromProperty ? this.editItem.beachNearByFromProperty.value : null) : null),
      beachNearByFromPropertyWalkingOrDriving: new UntypedFormControl(this.editItem ? (this.editItem.beachNearByFromPropertyWalkingOrDriving ? this.editItem.beachNearByFromPropertyWalkingOrDriving.value : 'Walking') : null),
      hasBeachDirectAccess: new UntypedFormControl(this.editItem ? (this.editItem.hasBeachDirectAccess ? this.editItem.hasBeachDirectAccess.value : null) : null),
      beachChairSurchargeValue: new UntypedFormControl(this.editItem ? (this.editItem.beachChairSurchargeValue ? this.editItem.beachChairSurchargeValue.value : null) : null),
      beachChairSurcharge: new UntypedFormControl(this.editItem ? (this.editItem.beachChairSurcharge ? this.editItem.beachChairSurcharge.value : null) : null),
      hasBeachChairAvailable: new UntypedFormControl(this.editItem ? (this.editItem.hasBeachChairAvailable ? this.editItem.hasBeachChairAvailable.value : null) : null),
      
      //airport
      hasAirportTransportation:new UntypedFormControl(this.editItem ? (this.editItem.hasAirportTransportation ? this.editItem.hasAirportTransportation.value : false) :  null),
      airportTransportationName:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationName ? this.editItem.airportTransportationName.value : null) :  null),
      airportTransportationDistance:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationDistance ? this.editItem.airportTransportationDistance.value : null) : null),          
      airportTransportationOptions:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationOptions ? this.editItem.airportTransportationOptions.value : []) :  null),
      airportTransportationTaxi:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationTaxi ? this.editItem.airportTransportationTaxi.value : null) :  null),
      airportTransportationTaxiPrice:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationTaxiPrice ? this.editItem.airportTransportationTaxiPrice.value : null) : null),
      airportTransportationShuttle:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationShuttle ? this.editItem.airportTransportationShuttle.value : null) :  null),
      airportTransportationShuttlePrice:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationShuttlePrice ? this.editItem.airportTransportationShuttlePrice.value : 'Free') :  null),
      airportTransportationShuttlePriceUnit:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationShuttlePriceUnit ? this.editItem.airportTransportationShuttlePriceUnit.value : 'Per person') :  null),
      airportTransportationShuttlePriceValue:new UntypedFormControl(this.editItem ? (this.editItem.airportTransportationShuttlePriceValue ? this.editItem.airportTransportationShuttlePriceValue.value : null) : null),
      
      bookingDirectBenefits:new UntypedFormControl(this.editItem ? (this.editItem.bookingDirectBenefits ? this.editItem.bookingDirectBenefits.value : []) :  null),      
    
      hasPaymentSpecials: new UntypedFormControl(this.editItem ? (this.editItem.hasPaymentSpecials ? this.editItem.hasPaymentSpecials.value : false) : false),
      specialsText: new UntypedFormControl(this.editItem ? (this.editItem.specialsText ? this.editItem.specialsText.value : null) : null),
      specialsUrls: specialsUrlsFA,
    
      weddingsText: new UntypedFormControl(this.editItem ? (this.editItem.weddingsText ? this.editItem.weddingsText.value : null) : null),
      weddingsUrls: weddingsUrlsFA,

      cancelFreeOfCharge:new UntypedFormControl(this.editItem ? (this.editItem.cancelFreeOfCharge ? this.editItem.cancelFreeOfCharge.value : null) :  null),      
      cancellationLink:new UntypedFormControl(this.editItem ? (this.editItem.cancellationLink ? this.editItem.cancellationLink.value : null) :  null),      
      cancellationGraceDefinition:new UntypedFormControl(this.editItem ? (this.editItem.cancellationGraceDefinition ? this.editItem.cancellationGraceDefinition.value : null) :  null),      
      cancellationPenaltyGrace: cancellationPenaltyGraceFA,
      noCancellationPenaltyGrace: noCancellationPenaltyGraceFA,
      paymentNoShowCharge:new UntypedFormControl(this.editItem ? (this.editItem.paymentNoShowCharge ? this.editItem.paymentNoShowCharge.value : null) :  null),      
      noShowGuestCharge:new UntypedFormControl(this.editItem ? (this.editItem.noShowGuestCharge ? this.editItem.noShowGuestCharge.value : null) :  null),      
    
    });
    

    this.formValueSub = this.itemForm.valueChanges.subscribe(values => {
      this.surveyService.processChanges(values);
    });
    
    this.itemForm.updateValueAndValidity();
  }

  onCheckboxChange(event, index) {
    if (event && this.collapsedArray[index]) {
     this.onCollapseToggle(index, event);
   }
 }

 onCollapseToggle(i, event: any) {
  this.collapsedArray.forEach((el, index) => {
    if (index === i) {
      this.collapsedArray[index] = !this.collapsedArray[index];
    }
  });

  this.scrollIntoView(event.target);
}

scrollIntoView(el: HTMLElement) {
  if (el) {
    el.scrollIntoView();
  }
}

hasPetRestriction(restriction: string) {
    const rest = this.itemForm.get('petRestrictions').value;
    if (rest && rest.indexOf(restriction) > -1) {
      return true;
    }
    return false;
  }

  filter(value: string): string[] {
    console.log(value);
    const filterValue = value.toLowerCase();
    return this.airportDD.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  // private filter(value: string): {}[] {
  //   const filterValue = value.toLowerCase();
  //   return this.airportsDB.filter((optionValue: any) => optionValue.id.toLowerCase().indexOf(filterValue) > -1).slice(0, 20);
  // }
  
    onChangeAirportName(event: any) {
    this.filteredOptions = this.filter(event);    
  }

  onSelectAirportOptions(event: any) {
    const value = event.id;
    if (value) {
      this.itemForm.get('airportTransportationName').setValue(value);
    }
  }

  onLoseFocusAirportName() {
    this.showAirportNameOptions = false;
  }

  onFocusAirportName() {
    
  }

  onKeydownAirportName(event: any) {
    if ((event.keyCode === 40 || event.keyCode === 39) && this.showAirportNameOptions) { // down navigation key == 40
      this.selectAirport.nativeElement.focus();
    }
  }

  onAddbookingDirectBenefits() {
    this.dialogService.open(AddStringItemComponent, {
      
    }).onClose.subscribe(result => {
      if (result) {
          this.bookingDirectBenefitsCB.push(result);
          let tempArr = this.itemForm.get('bookingDirectBenefits').value;
          console.log(tempArr);
          tempArr.push(result);
          this.itemForm.get('bookingDirectBenefits').setValue(tempArr);
      }
    });
  }

    onAddSpecialLink() {
    this.specialsUrlsFA.push(new UntypedFormGroup({
      link: new UntypedFormControl(null),      
    }));
  }

  onDeleteSpecialLink(index) {
    this.specialsUrlsFA.removeAt(index);
  }

  get specialsUrlsFA() {
    return this.itemForm.get('specialsUrls') as UntypedFormArray;
  }

  isCateringTo(item: string) {
    let isSelected = false;

    if (this.editItem && this.editItem.propertyCatersTo) {
      const selected: [] = this.editItem.propertyCatersTo['value'];
  
      if (selected && selected.length > 0) {
        selected.forEach(elem => {
          if (item === elem ) {
            isSelected = true;
          }
        });
      }    
    }
    
    return isSelected;
  }

  onAddWeddingsLink() {
    this.weddingsUrlsFA.push(new UntypedFormGroup({
      link: new UntypedFormControl(null),      
    }));
  }

  onDeleteWeddingsLink(index) {
    this.weddingsUrlsFA.removeAt(index);
  }

  get weddingsUrlsFA() {
    return this.itemForm.get('weddingsUrls') as UntypedFormArray;
  }

  onChangeCancellationLink(event: any) {
    const value = event.srcElement.value;
    if (!this.regexUrlPattern.test(value)) {
      this.linkValidator.hasError = true;
      this.linkValidator.message = 'Note: The link should start with http:// or https://.'
    } else {
      if (this.linkValidator.hasError) {
        this.linkValidator.hasError = false;
        this.linkValidator.message = '';
      }
    }
  }

  onAddCancellationPenaltyGrace() {
    this.cancellationPenaltyGraceFA.push(new UntypedFormGroup({
      cancellationPenaltyGraceDefinition: new UntypedFormControl(null),      
      cancellationChargeAfterGracePeriod: new UntypedFormControl(null),      
    }));
  }

  onDeleteCancellationPenaltyGrace(index) {
    this.cancellationPenaltyGraceFA.removeAt(index);
  }

  get cancellationPenaltyGraceFA() {
    return this.itemForm.get('cancellationPenaltyGrace') as UntypedFormArray;
  }

  onAddNoCancellationPenaltyGrace() {
    this.noCancellationPenaltyGraceFA.push(new UntypedFormGroup({
      noCancellationPenaltyGraceDefinition: new UntypedFormControl(null),      
      noCancellationChargeAfterGracePeriod: new UntypedFormControl(null),      
    }));
  }

  onDeleteNoCancellationPenaltyGrace(index) {
    this.noCancellationPenaltyGraceFA.removeAt(index);
  }

  get noCancellationPenaltyGraceFA() {
    return this.itemForm.get('noCancellationPenaltyGrace') as UntypedFormArray;
  }


  ngOnDestroy() {
    if (this.formValueSub) {
      this.formValueSub.unsubscribe();
    }    
    if (this.surveySub) {
      this.surveySub.unsubscribe();
    }
  }
}


