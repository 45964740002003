<nb-card style="height: 100%;">
    <nb-card-body>
        <div class="row" style="height: 100%; max-width: 700px; margin: auto;">
            <div class="col-12">
                <div class="row" style="margin-top: 40px; margin-bottom: 25px;">
                    <div class="col-12 header">
                        <span class="top">Is this you?</span>
                        <!-- <span class="bottom">The widget is now ready for Live chats.</span> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="margin-bottom: 35px;">
                        <ul id="grouplist" style="list-style: none; padding-left: 0px; cursor: pointer;">
                            <li 
                                style="padding: 15px 15px 15px 15px; border-bottom: 1px solid #ededed;"
                                [ngClass]="{'active': selectedHotelItem == hotel}" 
                                (click)="listClick(hotel)" *ngFor="let hotel of hotels; let hotelIndex = index;">
                                <div class="row">
                                    <div class="col-10">																		
                                        <div style="font-weight: bold; padding-bottom: 6px; font-size: 15px;">{{ hotel.name}}</div>
                                        {{ hotel.caption }}
                                    </div>
                                    <div class="col-2" style="display: flex; flex-direction: column; justify-content: center;">
                                        <svg *ngIf="selectedHotelItem == hotel" style="float: right; color: #095570; height: 40px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                                    </div>
                                </div>							
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- <div class="row content">
                    <div class="col-12 hotels-list" style="margin-bottom: 35px; padding: 12px;">
                        <ul id="grouplist" class="list-group limit-height">
                            <li 
                                class="list-group-item" 
                                [ngClass]="{'active': selectedHotelItem == hotel}" 
                                (click)="listClick(hotel)" *ngFor="let hotel of hotels; let hotelIndex = index;">
                                <div class="row">
                                    <div class="col-10">																		
                                        <div style="font-weight: bold;">{{ hotel.name }}</div>
                                        {{ hotel.caption }}
                                    </div>
                                    <div class="col-2" style="display: flex; flex-direction: column; justify-content: center;">
                                        <svg *ngIf="selectedHotelItem == hotel" style="float: right; color: #fff; height: 40px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                                    </div>
                                </div>							
                            </li>
                        </ul>
                    </div>
                </div> -->
                <div class="row" style="display: flex; justify-content: center">
                    <button 
                        style="width: 100px; margin-right: 6px;"
                        nbButton 
                        outline 
                        size="medium"
                        (click)="onSkip()">
                        Not Me
                    </button>
                    <button 
                        style="width: 100px;"
                        nbButton 
                        status="primary" 
                        size="medium"
                        (click)="onSubmit()">
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>

<!-- <div class="title">
    <h4>Is this you?</h4>
</div>
<div class="content">
    <div class="col-12 hotels-list" style="margin-bottom: 60px; padding: 12px;">
        <ul id="grouplist" class="list-group limit-height">
            <li 
                class="list-group-item" 
                [ngClass]="{'active': selectedHotelItem == hotel}" 
                (click)="listClick(hotel)" *ngFor="let hotel of hotels; let hotelIndex = index;">
                <div class="row">
                    <div class="col-10">																		
                        <div style="font-weight: bold;" [innerHtml]="hotel.caption"></div>
                        {{ hotel.name }}
                    </div>
                    <div class="col-2" style="display: flex; flex-direction: column; justify-content: center;">
                        <svg *ngIf="selectedHotelItem == hotel" style="float: right; color: #fff; height: 40px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                    </div>
                </div>							
            </li>
        </ul>
    </div>
</div> -->