import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { 
  NbRadioModule,
  NbCardModule,
  NbButtonModule,
  NbInputModule,
  NbSpinnerModule,
  NbActionsModule,
  NbIconModule,
  NbListModule,
  NbRouteTabsetModule,
  NbTabsetModule,
  NbCheckboxModule,
  NbSelectModule,
  NbTreeGridModule,
  NbWindowModule,
  NbTagModule, 
  NbLayoutModule,
  NbPopoverModule,
  NbDatepickerModule} from '@nebular/theme';

import { RoomCategoriesComponent } from './room-categories.component';
import { RoomCategoryEditComponent } from './room-category-edit/room-category-edit.component';

import { CustomComponentsModule } from '../custom-components/custom-components.module';

@NgModule({
  declarations: [
    RoomCategoriesComponent,
    RoomCategoryEditComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbIconModule,
    NbListModule,
    NbSpinnerModule,
    NbRouteTabsetModule,
    NbTabsetModule,
    NbRadioModule,
    NbLayoutModule,
    NbCheckboxModule,
    NbSelectModule,
    NbTreeGridModule,
    NbWindowModule.forChild(),
    NbActionsModule,
    ReactiveFormsModule,
    FormsModule,
    NbTagModule,
    NbPopoverModule,
    NbDatepickerModule.forRoot(),

    CustomComponentsModule
   ],
   exports: [
    RoomCategoriesComponent
   ],
})
export class RoomCategoriesModule { }
