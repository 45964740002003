<nb-layout>
    <nb-layout-column style="background-color: #ffffff">
        <div class="gc-container">
            <div class="header">
                <app-app-topbar></app-app-topbar>
            </div>
            <div class="content">
                <router-outlet></router-outlet>
            </div>
            <div class="footer">
                <!-- <span>Footer</span> -->
            </div>
        </div>
    </nb-layout-column>
</nb-layout>


