import { Component, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

// Services
// import { LocationService } from '../../services/location.service';
import { MasterDataService } from '../../services/master-data.service';
import { SurveyService } from '../../services/survey.service';
// import { RoomCategoriesService } from '../../services/room-categories.service';

// Interface
import { RoomCategory, Survey } from '../../services/interfaces';
import { SignUpService } from 'src/app/services/sign-up.service';
import { RoomCategoriesService } from 'src/app/services/room-categories.service';
// import { NbTagComponent } from '@nebular/theme';

@Component({
  selector: 'ngx-survey-page-one',
  templateUrl: './survey-page-one.component.html',
  styleUrls: ['./survey-page-one.component.scss']
})
export class SurveyPageOneComponent implements OnInit, OnDestroy {
   
  loading = true;

  // Form
  editMode = false;
  editItem: Survey;
  itemForm: UntypedFormGroup;
  
  // PriceRange table
  customColumn = 'From Age';
  defaultColumns = ['From Age', ' To Age', 'Type', 'Fee'];
  allColumns = [this.customColumn, ...this.defaultColumns];

  public pricesSelect;
 
  // Dropdowns
  languagesSelect;
  countrySelect;
  ageFromAllowed;
  bedSixeSelect;
  
  editKey: string;
  surveyArr: Survey[];
  
  saving = false;
  formValueSub: Subscription;
  
  @Output() isValidForm = new EventEmitter<boolean>();
  formIsValid = false;

  surveySub: Subscription;
  
  propertyNote = false;
  buildingNote = false;
  smokingNote = false;
  languagesNote = false;
  catersNote = false;
  childNote = false;
  
  // Room Categories
  roomCategoriesSub: Subscription;
  roomCategories: RoomCategory[] =[];
  hasRoomCategories = false;

  popupShow: string;

  // Section Two
  visibleArray = [
    false, false, false, false, false, false,
    false, false, false, false, false, false,
    false, false, false, false, false, false,
    false, false, false, false, false, false, false,
    false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false];

  // Section Three
  collapsedArray = [false, false, false, false, true, true];

  scheduleTime = [];


  constructor(
    private signupService: SignUpService,
    private surveyService: SurveyService,
    private masterDataService: MasterDataService,
    private roomCategoriesService: RoomCategoriesService
    ) { }

  ngOnInit(): void {

    if (this.formValueSub) {
        this.formValueSub.unsubscribe();
      }

    this.initForm();
    console.log(this.itemForm.value);

    this.roomCategoriesSub = this.roomCategoriesService.roomCategories$
    .subscribe(rooms => {
      this.roomCategories = rooms;
      if (rooms && rooms.length > 0) {
        this.hasRoomCategories = true;
      }
    });

    this.surveySub = this.signupService.survey$
    .subscribe(survey => {

        this.editItem = survey;
        const tempObj = {};
        this.initForm();
        
        if (this.itemForm) {
          this.itemForm.reset();
        }

        if (this.editItem) {
          Object.keys(this.editItem).forEach(key => {
            tempObj[key] = survey[key].value
          });
          
          this.itemForm.patchValue(tempObj);
     
          if (this.itemForm.get('hasFrontDesk').value) {
            this.collapsedArray[4] = false
          }
          if (this.itemForm.get('hasCheckInCheckOut').value) {
            this.collapsedArray[5] = false
          }


        }

        this.childPriceRangesFA.clear();

        if (this.editItem && this.editItem.childPriceRanges && this.editItem.childPriceRanges.value && this.editItem.childPriceRanges.value.length > 0) {      
          const tempArr: any[] =  this.editItem.childPriceRanges.value;
          tempArr.forEach((range) => {
            if (range['childPriceRangesAgeFrom'] >= 0 || range['childPriceRangesAgeTo'] >= 0 || range['childPriceRangesPrice'] >= 0) {
              this.childPriceRangesFA.push(new UntypedFormGroup({
                childPriceRangesAgeFrom: new UntypedFormControl(range['childPriceRangesAgeFrom'] >= 0 ? range['childPriceRangesAgeFrom'] : null),          
                childPriceRangesAgeTo: new UntypedFormControl(range['childPriceRangesAgeTo'] >= 0 ? range['childPriceRangesAgeTo'] : null),    
                childPriceRangesPrice: new UntypedFormControl(range['childPriceRangesPrice'] >= 0 ? range['childPriceRangesPrice'] : null),    
              }));   
            }
          });
        } 

        // this.surveyService.loadingData$.next(false);
        // this.loading = false;
        console.log(this.itemForm.value);
      }
    )

    this.ageFromAllowed = this.masterDataService.ageFromAllowed;
    this.pricesSelect = this.masterDataService.currencySelect;
    this.scheduleTime = this.masterDataService.scheduleTime;

    this.loading = false;

  }

  initForm() {
    const childPriceRangesFA = new UntypedFormArray([]);
    
    this.itemForm = new UntypedFormGroup({      
      // First Section  
      propertyCatersTo: new UntypedFormControl(this.editItem ? (this.editItem.propertyCatersTo ? this.editItem.propertyCatersTo.value : []) : null),  
      ageOfChildrenAllowed: new UntypedFormControl(this.editItem ? (this.editItem.ageOfChildrenAllowed ? this.editItem.ageOfChildrenAllowed.value : null) : null),
      childPriceRanges: childPriceRangesFA,
      hasCribsAvailable: new UntypedFormControl(this.editItem ? (this.editItem.hasCribsAvailable ? this.editItem.hasCribsAvailable.value : null) : null),
      cribsCost: new UntypedFormControl(this.editItem ? (this.editItem.cribsCost ? this.editItem.cribsCost.value : null) : null),
      accessibilitiesCommonAreas: new UntypedFormControl(this.editItem ? (this.editItem.accessibilitiesCommonAreas ? this.editItem.accessibilitiesCommonAreas.value : null) : null), 
      accessibilitiesRooms: new UntypedFormControl(this.editItem ? (this.editItem.accessibilitiesRooms ? this.editItem.accessibilitiesRooms.value : null) : null), 

      // Second Section
      bathtubAvailability: new UntypedFormControl(this.editItem ? (this.editItem.bathtubAvailability ? this.editItem.bathtubAvailability.value : null) : null),
      bathtubAvailableInRooms: new UntypedFormControl(this.editItem ? (this.editItem.bathtubAvailableInRooms ? this.editItem.bathtubAvailableInRooms.value : []) : null),

      bathtubAvailabilityHandicap: new UntypedFormControl(this.editItem ? (this.editItem.bathtubAvailabilityHandicap ? this.editItem.bathtubAvailabilityHandicap.value : null) : null),
      bathtubAvailableInRoomsHandicap: new UntypedFormControl(this.editItem ? (this.editItem.bathtubAvailableInRoomsHandicap ? this.editItem.bathtubAvailableInRoomsHandicap.value : []) : null),

      showerAvailability: new UntypedFormControl(this.editItem ? (this.editItem.showerAvailability ? this.editItem.showerAvailability.value : null) : null),
      showerAvailableInRooms: new UntypedFormControl(this.editItem ? (this.editItem.showerAvailableInRooms ? this.editItem.showerAvailableInRooms.value : []) : null),

      showerAvailabilityHandicap: new UntypedFormControl(this.editItem ? (this.editItem.showerAvailabilityHandicap ? this.editItem.showerAvailabilityHandicap.value : null) : null),
      showerAvailableInRoomsHandicap: new UntypedFormControl(this.editItem ? (this.editItem.showerAvailableInRoomsHandicap ? this.editItem.showerAvailableInRoomsHandicap.value : []) : null),

      grabRailsAvailabilityHandicap: new UntypedFormControl(this.editItem ? (this.editItem.grabRailsAvailabilityHandicap ? this.editItem.grabRailsAvailabilityHandicap.value : null) : null),
      grabRailsAvailableInRoomsHandicap: new UntypedFormControl(this.editItem ? (this.editItem.grabRailsAvailableInRoomsHandicap ? this.editItem.grabRailsAvailableInRoomsHandicap.value : []) : null),

      hairDryerAvailability: new UntypedFormControl(this.editItem ? (this.editItem.hairDryerAvailability ? this.editItem.hairDryerAvailability.value : null) : null),
      // hairDryerOnRequest:new FormControl(this.editItem ? (this.editItem.hairDryerOnRequest ? this.editItem.hairDryerOnRequest.value : null) : null),
      hairDryerAvailableInRooms: new UntypedFormControl(this.editItem ? (this.editItem.hairDryerAvailableInRooms ? this.editItem.hairDryerAvailableInRooms.value : []) : null),


      // Section Three
      frontDeskHours: new UntypedFormControl(this.editItem ? (this.editItem.frontDeskHours ? this.editItem.frontDeskHours.value : []) : null),
      hasFrontDesk: new UntypedFormControl(this.editItem ? (this.editItem.hasFrontDesk ? this.editItem.hasFrontDesk.value : false) : null),
      
      // facilitiesActionFlags: new FormControl(this.editItem ? (this.editItem.facilitiesActionFlags.value ? this.editItem.facilitiesActionFlags.value : []) : []),
      
      hasCheckInCheckOut: new UntypedFormControl(this.editItem ? (this.editItem.hasCheckInCheckOut ? this.editItem.hasCheckInCheckOut.value : false) : null),
      checkInNoEndTime: new UntypedFormControl(this.editItem ? (this.editItem.checkInNoEndTime ? this.editItem.checkInNoEndTime.value : null) : null),
      checkInFromTime: new UntypedFormControl(this.editItem ? (this.editItem.checkInFromTime ? this.editItem.checkInFromTime.value : null) : null),
      checkInToTime: new UntypedFormControl(this.editItem ? (this.editItem.checkInToTime ? this.editItem.checkInToTime.value : null) : null),
      checkInEarlyAvailable: new UntypedFormControl(this.editItem ? (this.editItem.checkInEarlyAvailable ? this.editItem.checkInEarlyAvailable.value : null) : null),
      
      checkInEarlyCost: new UntypedFormControl(this.editItem ? (this.editItem.checkInEarlyCost ? this.editItem.checkInEarlyCost.value : null) : null),
      checkInEarlySurcharge: new UntypedFormControl(this.editItem ? (this.editItem.checkInEarlySurcharge ? this.editItem.checkInEarlySurcharge.value : null) : null),
      checkInEarlySurchargeValue: new UntypedFormControl(this.editItem ? (this.editItem.checkInEarlySurchargeValue ? this.editItem.checkInEarlySurchargeValue.value : null) : null),
      checkOutTime: new UntypedFormControl(this.editItem ? (this.editItem.checkOutTime ? this.editItem.checkOutTime.value : 'Noon') : null),
      isLateCheckOutAvailable: new UntypedFormControl(this.editItem ? (this.editItem.isLateCheckOutAvailable ? this.editItem.isLateCheckOutAvailable.value : null) : null),
      
      lateCheckOutCost: new UntypedFormControl(this.editItem ? (this.editItem.lateCheckOutCost ? this.editItem.lateCheckOutCost.value : null) : null),
      lateCheckOutCostUnit: new UntypedFormControl(this.editItem ? (this.editItem.lateCheckOutCostUnit ? this.editItem.lateCheckOutCostUnit.value : null) : null),
      lateCheckOutCostValue: new UntypedFormControl(this.editItem ? (this.editItem.lateCheckOutCostValue ? this.editItem.lateCheckOutCostValue.value : null) : null),
      
    });
    
    this.formValueSub = this.itemForm.valueChanges.subscribe(values => {
      this.surveyService.processChanges(values);
    });
    
    this.itemForm.updateValueAndValidity();
  }

  isAccessibility(item: string) {
    const arr = this.itemForm.get('accessibilities').value;
    if (arr.includes(item)) {
      return true;
    } else {
      return false;
    }
  }

  addPriceRange() {
    const group = new UntypedFormGroup({
      childPriceRangesAgeFrom: new UntypedFormControl(null),
      childPriceRangesAgeTo: new UntypedFormControl(null),
      childPriceRangesPrice: new UntypedFormControl(null),
    });
    this.childPriceRangesFA.push(group);
  }

  onDeletePriceRange(index) {
    this.childPriceRangesFA.removeAt(index);
  }

  get childPriceRangesFA() {
    return this.itemForm.get('childPriceRanges') as UntypedFormArray
  }

  isCateringTo(item: string) {
    let isSelected = false;

    const selected: [] = this.itemForm?.get('propertyCatersTo')?.value;

    if (selected && selected.length > 0) {
      selected.forEach(elem => {
        if (item === elem ) {
          isSelected = true;
        }
      });
    }
    
    return isSelected;
  }

  onCommonAreasCheckedChanged(event: boolean) {
    if (!event) {
      if (this.itemForm.get('accessibilitiesRooms').value) {
        this.itemForm.get('accessibilitiesRooms').setValue(false);
      }
    }
  }

  onToggleVisibleArray(i, forcedValue: boolean | null = null) {
    console.log(this.visibleArray)
    this.visibleArray.forEach((el, index) => {
      if (index === i) {
        if (forcedValue != null) {
          this.visibleArray[index] = forcedValue;
        } else {
          this.visibleArray[index] = !this.visibleArray[index];
        }
      } else {
        this.visibleArray[index] = false;
      }
    });
  }

  hasRoomAccessibility() {
    if (this.itemForm && this.itemForm.get('accessibilitiesRooms') && this.itemForm.get('accessibilitiesRooms').value) {
      // console.log('True')
      return true;
    }
    // console.log('False')
    return false;
  }

  onCheckboxChange(event, index) {
    if (event && this.collapsedArray[index]) {
     this.onCollapseToggle(index, event);
    }
  }

 onCollapseToggle(i, event: any) {
    this.collapsedArray.forEach((el, index) => {
      if (index === i) {
        this.collapsedArray[index] = !this.collapsedArray[index];
      }
    });

    this.scrollIntoView(event.target);
  }

  scrollIntoView(el: HTMLElement) {
    if (el) {
      el.scrollIntoView();
    }
  }

  ngOnDestroy() {
    if (this.formValueSub) {
      this.formValueSub.unsubscribe();
    }    
    if (this.surveySub) {
      this.surveySub.unsubscribe();
    }
    if (this.roomCategoriesSub) {
      this.roomCategoriesSub.unsubscribe();
    }
  }
}


