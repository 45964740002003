import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MasterDataService } from '../../services/master-data.service';

@Component({
  selector: 'ngx-bar-types',
  templateUrl: './bar-types.component.html',
  styleUrls: ['./bar-types.component.scss'],
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => BarTypesComponent),
       multi: true
    }
  ]
})
export class BarTypesComponent implements OnInit {
  onChange: any = () => { };
  onTouched: any = () => { };
  value: {barType: string, openTimes: string[]}[] = [];

  barTypes: string[]= [];
  @Input() singleColumn: boolean = false;

  constructor(
    private masterDataService: MasterDataService
  ) { }

  ngOnInit(): void {
    this.barTypes = this.masterDataService.barTypeItems;
  }

  writeValue(value: {barType: string, openTimes: string[]}[]): void {
    if (!value) {
      this.value = [];
      return;
    }
    if (this.checkIfElementsAreStrings(value)) {
      this.value = value.map((el: any) => {
        return { 
          barType: el, 
          openTimes: []
        }
      })
    } else {
      this.value = value;
    }

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;    
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  checkIfElementsAreStrings(x: any) {
    return x?.every((i: any) => (typeof i === "string"));
  }

  getActiveState(item: string) {
    if (this.value) {
      const index = this.value.findIndex(el => el.barType === item) > -1;
      return index;
    } else {
      return false;
    }
  }

  onChangeCheckbox(value: boolean, item: string){
    const index = this.value.findIndex(el => el.barType === item);

    if (index < 0) {
      this.value.push({barType: item, openTimes: []});
    } else {
      this.value.splice(index, 1);
    }
    this.onChange(this.value);
  }

  getValueIndexOfItem(item) {
    return this.value.findIndex(el => el.barType === item);
  }

  onChangeSchedule(event: boolean, item: string) {
    if (event) {
      this.onChange(this.value);
      // const index = this.value.findIndex(el => el.barType === item);
      // if (index > -1) { 
      //   this.value[index].openTimes
      // }
    }
  }
}
