<div class="row">
    <div class="col-12">
        <div class="space">
            <select style="width: 94px;" [(ngModel)]="periodModel.pre" (change)="setValue()">
                <option value="Within">Within</option>
                <option value="Before">Before</option>
                <option value="After">After</option>
            </select>
        </div>
        <div class="space">
            <input style="width: 60px;" type="number" nbInput fullWidth [(ngModel)]="periodModel.number" (input)="setValue()">
        </div>
        <div class="space">
            <select style="width: 114px;" [(ngModel)]="periodModel.unit1" (change)="setValue()">
                <option value="hours">hour(s)</option>
                <option value="day(s)">day(s)</option>
                <option value="weeks">week(s)</option>
                <option value="months">month(s)</option>
            </select>
        </div>
        <div class="space">
            <select style="width: 122px;" [(ngModel)]="periodModel.unit2" (change)="setValue()">
                <option value="of booking.">of booking.</option>
                <option value="of arrival.">of arrival.</option>
            </select>
        </div>
    </div>
</div>
