<nb-card style="height: 100%;">
    <nb-card-body>
        <div class="row" style="height: 100%;">
            <div class="col-12">
                <div class="row" style="margin-top: 40px; margin-bottom: 65px;">
                    <div class="col-12 header">
                        <span class="top">Time to Go Live</span>
                        <!-- <span class="bottom">The widget is now ready for Live chats.</span> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <nb-accordion>
                            <!-- Manual Setup -->
                            <nb-accordion-item [expanded]="true">
                                <nb-accordion-item-header>
                                    Manual Setup
                                </nb-accordion-item-header>
                                <nb-accordion-item-body>
                                    <div style="margin-top: 10px; margin-bottom: 10px">
                                        <span>
                                            <img style="height: 30px; width: auto;" src="/assets/images/checked-clipboard.png" alt="check clipboard icon"> <strong>Install LiveChat code manually</strong>
                                            <!-- <i *ngIf="acc2?.isOpen" style="float: right; font-size: 20px; margin-top: 5px; color: #e8e9ed;" class="fas fa-caret-right"></i>
                                            <i *ngIf="!acc2?.isOpen" style="float: right; font-size: 20px; margin-top: 5px; color: #e8e9ed;" class="fas fa-caret-down"></i>								 -->
                                        </span>							
                                        <p>Follow these steps to activate the Guestchat Bot on any website</p>
                                        <ol>
                                            <li>
                                                Insert the code below on each page you want the bot to be active on. Paste this within the body tags (typically on the root index.html file)
                                            </li>
                                            <li [copy-clipboard]="botHtmlCode" style="cursor: pointer;">
                                                <div>
                                                    Copy this html code (click to copy) and paste it into your website:
                                                </div>
                                                <div style="font-style: italic; font-weight: bold;">{{botHtmlCode}}</div>
                                            </li>
                                        </ol>
                                    </div>
                                </nb-accordion-item-body>
                            </nb-accordion-item>
                            <!-- Wordpress Setup -->
                            <nb-accordion-item>
                                <nb-accordion-item-header>
                                    Wordpress Setup
                                </nb-accordion-item-header>
                                <nb-accordion-item-body>
                                    <div style="margin-top: 10px; margin-bottom: 10px">
                                        <span>
                                            <img style="height: 30px; width: auto;" src="/assets/images/wordpress-icon.png" alt="wordpress icon"> <strong>Install on Wordpress</strong>
                                            <!-- <i *ngIf="acc1?.isOpen" style="float: right; font-size: 20px; margin-top: 5px; color: #e8e9ed;" class="fas fa-caret-right"></i>
                                            <i *ngIf="!acc1?.isOpen" style="float: right; font-size: 20px; margin-top: 5px; color: #e8e9ed;" class="fas fa-caret-down"></i>								 -->
                                        </span>							
                                        <div>
                                            <p>Follow these steps to activate the Guestchat Bot on any Wordpress website</p>
                                            <ol class="wordpress-images">
                                                <li>
                                                    Download the official Guestchat plugin from the Wordpress admin backend of the site you want the Bot
                                                    to appear on
                                
                                                </li>			
                                                <img class="img-1" src="assets/images/wordpress.png" style="width: 100%; margin-top: 10px; margin-bottom: 15px;">
                                                <li>After Installing and Activating the plugin navigate to 'Guestchat Settings'
                                                </li>			
                                                <img class="img-2" src="assets/images/wordpress-settings.png" style="width: 100%; margin-top: 10px; margin-bottom: 15px;">
                                                <li [copy-clipboard]="clientId" style="cursor: pointer;">Copy this key (click to copy) and paste it into the 'Client Key' input: <span style="font-weight: bold;">{{clientId}}</span></li>			
                                                <li>Select 'Show Everywhere' and 'Save Changes'</li>			
                                            </ol>
                                        </div>
                                    </div>
                                </nb-accordion-item-body>
                            </nb-accordion-item>
                        </nb-accordion>
                    </div>
             
                    <span *ngIf="complete && clientId">
                        <!-- <chat-widget id="gc" [key]="clientId"></chat-widget> -->
                        <div [innerHTML]="botTag"></div>
                    </span>
                    <div class="counter widget-mobile" *ngIf="!complete">
                        {{ secondsLeft}}
                    </div>

                    <div class="widget-mobile" style="position: fixed; bottom: 0; right: 18px; width: 400px;">
                        <img class="img-2" src="assets/images/arrow.png" style="width: 400px;">
                    </div>

                
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>