<nb-card status="success">
  <nb-card-header>
      <i
        style="font-size: 18px; margin-right: 20px"
        class="fas fa-clipboard-check"></i>{{ title }}
    </nb-card-header
  >
  <nb-card-body class="confirm-body">
    {{ contentText }}
  </nb-card-body>
  <nb-card-footer>
      <div class="flex-center">
          <button nbButton style="margin-right: 20px;" hero status="success" (click)="confirm()">Yes</button>
          <button nbButton hero (click)="cancel()">No</button>
      </div>
  </nb-card-footer>
</nb-card>
