import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

// Services
import { RoomCategoriesService } from './../../services/room-categories.service';
// import { SurveyService } from '../../services/survey.service';
import { SignUpService } from 'src/app/services/sign-up.service';
import { MasterDataService } from '../../services/master-data.service';
// import { ClientsService } from '../../../services/clients.service';
// import { FunctionsService } from '../../../services/functions.service';

// Interfaces
import { Client, RoomCategory, Survey } from '../../services/interfaces';

// import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
// import { MediaModalComponent } from '../../media-modal/media-modal.component';

// Nebular
// import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { NbDialogRef, NbDialogService} from '@nebular/theme';

@Component({
  selector: 'ngx-room-category-edit',
  templateUrl: './room-category-edit.component.html',
  styleUrls: ['./room-category-edit.component.scss']
})
export class RoomCategoryEditComponent implements OnInit, OnDestroy {

  saving = false;

  // Form
  itemForm: UntypedFormGroup;
  
  // Edit
  editMode: boolean;
  editItem: RoomCategory;
  // roomCategoryId: string;
  roomCategory: RoomCategory;
  
  // Data
  maxOcc: number = 0;

  totalRooms = 0;

  roomViews: {value: string, label: string}[] = [];
  
  amenitiesDD: string[] = [
    'WiFi',
    'Air conditioning',
    'Flat-screen TV',
  ];
  selectedAmenities: string[] = [
    'Wifi',
    'Air conditioning',
    'Flat-screen TV'
  ];
  selectedFacilities: string[] = [];

  survey: Survey; // I need this to toggle smoking allowed
  surveySub: Subscription;

  //date picker
  @ViewChildren('rate') rates:QueryList<Input>;

  otherLanguages: {
    language: string,
    iso: string
  }[] = [];
  activeClientSub: Subscription;
  activeClient: Client;

  selectedLanguageIso = 'en';

  constructor(
    private dialogService: NbDialogService,
    // private toastrService: NbToastrService,
    private roomCategoriesService: RoomCategoriesService,
    private signupService: SignUpService,
    private masterDataService: MasterDataService,
    protected dialogRef: NbDialogRef<RoomCategoryEditComponent>,
    // private clientsService: ClientsService,
    // private functionsService: FunctionsService
    ) { }

  ngOnInit(): void {
    
    this.initForm();

    if (this.roomCategory) {
      this.editMode = true;
      this.editItem = this.roomCategory;
      console.log(this.editItem);

      this.initForm();
      // this.roomCategoriesService.getCategory(this.roomCategoryId)
      //   .then(roomCategory => {
      //     this.initForm();
      //   }).catch();
    } 

    // else if (this.editItem) {
    //   this.editMode = true;
    //   this.initForm();
    //   this.editMode = false;
    // }

    
    this.surveySub = this.signupService.survey$
    .subscribe(survey => {
        if (survey) {
          this.survey = survey;          
        } 
      }
    )

    this.roomViews = this.masterDataService.roomViews.sort((a, b) => a.label > b.label ? 1 : -1);

    // this.activeClientSub = this.clientsService.activeClient$
    //   .subscribe(async client => {
    //     if (client.id) {
    //       // other languages
    //       this.otherLanguages = [];
          
    //       if (client && client.otherLanguages && client.otherLanguages.length) {
    //         this.otherLanguages.push({language: 'English', iso: 'en'});
    //         client.otherLanguages.forEach((iso: any) => {
    //           this.otherLanguages.push({language: this.masterDataService.getLanguageFromCode(iso).name, iso: iso});                
    //         });
    //       }
    //     }            
    //   });
  }

  private initForm() {
    const beds = new UntypedFormArray([]);
    const ratesByDate = new UntypedFormArray([]);

    if (!this.editMode) {
      const roomCategories = this.roomCategoriesService.roomCategories$.getValue();
      this.totalRooms = roomCategories ? roomCategories.length + 1 : 1;
      this.itemForm = new UntypedFormGroup({
        roomName: new UntypedFormControl(null, Validators.required),
        roomNameTranslations: new UntypedFormGroup({
          af: new UntypedFormControl(null),
          nl: new UntypedFormControl(null),
          fr: new UntypedFormControl(null),
          de: new UntypedFormControl(null),
          es: new UntypedFormControl(null)
        }),
        roomPhotoUrl: new UntypedFormControl(null),
        otherRoomPhotosUrls: new UntypedFormControl(null),
        roomSizeUnit: new UntypedFormControl('squareFeet'),
        roomSizeValue: new UntypedFormControl(null),
        roomView: new UntypedFormControl(null),
        numberOfBathrooms: new UntypedFormControl(1),

        // accessibleBathroomFeatures: new FormControl([]),
        wheelchairAccessible: new UntypedFormControl(false),

        rollawayBedsCost: new UntypedFormControl(null),
        rollawayBedsCostValue: new UntypedFormControl(null),
        rollawayBedsCostValueUnit: new UntypedFormControl(null),
        
        adultOccupancy: new UntypedFormControl('2'),
        childrenOccupancy: new UntypedFormControl('0'),
        maximumOccupancy: new UntypedFormControl('2'),
        smoking: new UntypedFormControl(null),
        shortSellingText: new UntypedFormControl(null, Validators.required),
        shortSellingTextTranslations: new UntypedFormGroup({
          af: new UntypedFormControl(null),
          nl: new UntypedFormControl(null),
          fr: new UntypedFormControl(null),
          de: new UntypedFormControl(null),
          es: new UntypedFormControl(null)
        }),
        fallbackBookingUrl: new UntypedFormControl(null),
        connectingDoors: new UntypedFormControl('No'),
        showSecretRatesAvailable: new UntypedFormControl(false),
        showSecretRatesText: new UntypedFormControl('Secret Rates Available'),
        order: new UntypedFormControl(this.totalRooms),
        beds: beds,
        ratesByDate: ratesByDate
      });
      
      beds.push(
        new UntypedFormGroup({
          bedType: new UntypedFormControl(null),
          numberOfBeds: new UntypedFormControl(1),
          or: new UntypedFormControl(false),
        }));

      this.selectedAmenities = [
        'Wifi',
        'Air conditioning',
        'Flat-screen TV'
      ];
      this.selectedFacilities = [];

    } else {

      this.itemForm = new UntypedFormGroup({
        roomName: new UntypedFormControl(this.editItem.roomName, Validators.required),
        roomNameTranslations: new UntypedFormGroup({
          af: new UntypedFormControl(this.editItem?.roomNameTranslations?.af ? this.editItem?.roomNameTranslations?.af : null),
          nl: new UntypedFormControl(this.editItem?.roomNameTranslations?.nl ? this.editItem?.roomNameTranslations?.nl : null),
          fr: new UntypedFormControl(this.editItem?.roomNameTranslations?.fr ? this.editItem?.roomNameTranslations?.fr : null),
          de: new UntypedFormControl(this.editItem?.roomNameTranslations?.de ? this.editItem?.roomNameTranslations?.de : null),
          es: new UntypedFormControl(this.editItem?.roomNameTranslations?.es ? this.editItem?.roomNameTranslations?.es : null)
        }),
        // roomPhotoUrl: new FormControl(this.editItem.roomPhotoUrl, Validators.required),
        roomPhotoUrl: new UntypedFormControl(this.editItem.roomPhotoUrl),
        otherRoomPhotosUrls: new UntypedFormControl(this.editItem.otherRoomPhotosUrls),
        roomSizeUnit: new UntypedFormControl(this.editItem.roomSizeUnit),
        roomSizeValue: new UntypedFormControl(this.editItem.roomSizeValue),
        roomView: new UntypedFormControl(this.editItem.roomView, Validators.required),
        numberOfBathrooms: new UntypedFormControl(this.editItem.numberOfBathrooms),

        adultOccupancy: new UntypedFormControl(this.editItem.adultOccupancy, Validators.required),
        childrenOccupancy: new UntypedFormControl(this.editItem.childrenOccupancy, Validators.required),
        maximumOccupancy: new UntypedFormControl((+this.editItem.childrenOccupancy + +this.editItem.adultOccupancy).toString()),
        shortSellingText: new UntypedFormControl(this.editItem.shortSellingText),
        shortSellingTextTranslations: new UntypedFormGroup({
          af: new UntypedFormControl(this.editItem?.shortSellingTextTranslations?.af ? this.editItem?.shortSellingTextTranslations?.af : null),
          nl: new UntypedFormControl(this.editItem?.shortSellingTextTranslations?.nl ? this.editItem?.shortSellingTextTranslations?.nl : null),
          fr: new UntypedFormControl(this.editItem?.shortSellingTextTranslations?.fr ? this.editItem?.shortSellingTextTranslations?.fr : null),
          de: new UntypedFormControl(this.editItem?.shortSellingTextTranslations?.de ? this.editItem?.shortSellingTextTranslations?.de : null),
          es: new UntypedFormControl(this.editItem?.shortSellingTextTranslations?.es ? this.editItem?.shortSellingTextTranslations?.es : null)
        }),
        fallbackBookingUrl: new UntypedFormControl(this.editItem.fallbackBookingUrl),
        showSecretRatesAvailable: new UntypedFormControl(this.editItem.showSecretRatesAvailable),
        showSecretRatesText: new UntypedFormControl(this.editItem.showSecretRatesText),
        order: new UntypedFormControl((this.editItem.order === 0 || this.editItem.order === null) ? 1 : this.editItem.order),
        beds: beds,
        ratesByDate: ratesByDate
      });
      
      this.editItem.beds.forEach(bed => {
        if (bed.bedType || bed.numberOfBeds) {
          beds.push(
            new UntypedFormGroup({
              bedType: new UntypedFormControl(bed.bedType, Validators.required),
              numberOfBeds: new UntypedFormControl(bed.numberOfBeds, Validators.required),
              or: new UntypedFormControl(bed.or)
            }));
        }
      })
      if (this.editItem.ratesByDate && this.editItem.ratesByDate.length > 0) {

        this.editItem.ratesByDate.forEach((range: any) => {
          // const fireBaseTime = new Date(range.range.start._seconds * 1000 + range.range.start._nanoseconds / 1000000);
          // console.log(fireBaseTime.toDateString())
          ratesByDate.push(new UntypedFormGroup({
            range: new UntypedFormControl({start: new Date(range.range.start._seconds * 1000 + range.range.start._nanoseconds / 1000000).toDateString(), end: new Date(range.range.end._seconds * 1000 + range.range.end._nanoseconds / 1000000).toDateString()}, Validators.required),
            // range: new FormControl({start: new Date(), end: new Date()}, Validators.required),
            rate: new UntypedFormControl(range.rate, Validators.required),
            currency: new UntypedFormControl(range.currency, Validators.required),
            rateBy: new UntypedFormControl(range.rateBy)
          }))
        })
      }

      this.selectedAmenities = this.editItem.featuredAmmenities ? this.editItem.featuredAmmenities : [];
      // this.selectedFacilities = this.editItem.featuredFacilities ? this.editItem.featuredFacilities : [];
    }
  }

  onAddBed(or: boolean) {
    this.beds.push(
      new UntypedFormGroup({
        bedType: new UntypedFormControl(null),
        numberOfBeds: new UntypedFormControl(1),
        or: new UntypedFormControl(or ? true : false)
      })
    );
  }

  get beds() {
    return (<UntypedFormArray>this.itemForm.get('beds'));
  }

  onAddRate() {
    this.ratesByDate.push(new UntypedFormGroup({
      range: new UntypedFormControl(null, Validators.required),
      rate: new UntypedFormControl(null, Validators.required),
      currency: new UntypedFormControl('USD'),
      rateBy: new UntypedFormControl(null)
    }))
  }

  get ratesByDate() {
    return (<UntypedFormArray>this.itemForm.get('ratesByDate'));
  }

  onDeleteRate(index: number) {
    this.ratesByDate.removeAt(index);
    this.itemForm.markAsDirty();
  }

  onCancel() {
    this.editItem = {};
    this.editMode = false;
    this.itemForm.reset();
    this.saving = true;
    this.close();
  }

  onAddPhoto() {
    // this.dialogService.open(MediaModalComponent, {
    //   context: {
    //     singleItemSelect: true
    //     // chosenMedia: this.intents[index].client.mediaResponse,
    //   },
    // }).onClose
    //   .subscribe((media: ExternalMedia[]) => {
    //     if (media[0] && media[0].type.substring(0, 5) === 'image') {
    //       if (media.length > 0 && media[0]['path']) {
    //         this.itemForm.get('roomPhotoUrl').setValue(media[0]['path']);
    //       }
    //     } else {
    //       this.toastrService.show('You have to choose an image file.', 'Warning', { status: 'warning' });
    //     }        
    //   });
  }

  onDeletePhoto() {
    this.itemForm.get('roomPhotoUrl').setValue(null);
  }

  onAddOtherPhoto() {
    // this.dialogService.open(MediaModalComponent, {
    //   context: {
    //     singleItemSelect: true
    //     // chosenMedia: this.intents[index].client.mediaResponse,
    //   },
    // }).onClose
    //   .subscribe((media: ExternalMedia[]) => {
    //     if (media[0] && media[0].type.substring(0, 5) === 'image') {
    //       if (media.length > 0 && media[0]['path']) {
    //         let tempArr = [];
    //         if (this.itemForm.get('otherRoomPhotosUrls').value) {
    //           tempArr = this.itemForm.get('otherRoomPhotosUrls').value.slice();
    //         }
    //         tempArr.push(media[0]['path']);
    //         this.itemForm.get('otherRoomPhotosUrls').setValue(tempArr);
    //       }
    //     } else {
    //       this.toastrService.show('You have to choose an image file.', 'Warning', { status: 'warning' });
    //     }        
    //   });
  }

  onDeleteOtherPhoto(index: number) {
    this.itemForm.get('otherRoomPhotosUrls').value.splice(index, 1);
  }

  onDeleteBed(index: number) {
    (<UntypedFormArray>this.itemForm.get('beds')).removeAt(index);
    this.itemForm.markAsDirty();
  }

  close() {
    this.dialogRef.close();
  }

  onDelete() {
    // let roomName = this.editItem.roomName;

    // //delete the room category 
    // const title = 'Confirm Dialog';
    // const contentText = 'Are you sure you want to delete the room category ' + roomName + '?';

    // this.dialogService.open(ConfirmDialogComponent, {
    //   context: {
    //     title,
    //     contentText
    //   },
    // }).onClose.subscribe(isYes => {
    //   if (isYes) {
    //     this.roomCategoriesService.deleteRoomCategory(this.roomCategoryId)
    //     .then(() => {
    //       this.onCancel();
    //     });
    //     this.toastrService.show('Room category removed successfully', 'Success', { status: 'success' });
    //   }
    // });    
  }

  getFilledStatusAmenity(text: string) {
    return this.selectedAmenities.findIndex(el => el === text) > -1;
  }

  onToggleAmenity(amenity: string) {
    const i = this.selectedAmenities.findIndex(el => el === amenity);

    if (i > -1) {
      this.selectedAmenities.splice(i, 1);
    } else {
      this.selectedAmenities.push(amenity);
    }
    this.itemForm.markAsDirty();
  }

  onToggleFacility(facility: string) {
    const i = this.selectedFacilities.findIndex(el => el === facility);

    if (i > -1) {
      this.selectedFacilities.splice(i, 1);
    } else {
      this.selectedFacilities.push(facility);
    }
    this.itemForm.markAsDirty();
  }

  calcMaxOcc() {
    if (this.itemForm.get('adultOccupancy').value && this.itemForm.get('childrenOccupancy').value) {
      const adult = +this.itemForm.get('adultOccupancy').value;
      const children = +this.itemForm.get('childrenOccupancy').value;
      this.itemForm.get('maximumOccupancy').setValue((adult + children).toString())
    }
  }

  getGetFormArray(arrayName) {
    return this.itemForm.get(arrayName) as UntypedFormArray;
  }

  get checkBedsInputValid() {
    let bool = false;
    
    if (typeof this.getGetFormArray('beds') !== 'undefined') {
      return true;
    }

    let lastIndex = this.getGetFormArray('beds').length - 1;

    const controlBedType = this.getGetFormArray('beds').at(lastIndex).get('bedType').value;
    const controlNumberOfBeds = this.getGetFormArray('beds').at(lastIndex).get('numberOfBeds').value;
    
    if (controlBedType && controlNumberOfBeds) {
      bool = true;
    }
    
    return bool;
  }

  hasAccessibility(item: string) {
    let isSelected = false;

    if (this.survey && this.survey.propertyCatersTo) {
      const selected: [] = this.survey.propertyCatersTo.value;
  
      if (selected && selected.length > 0) {
        selected.forEach(elem => {
          if (item === elem ) {
            isSelected = true;
          }
        });
      }
    }
    
    return isSelected;
  }

  onDateChange(event: any, index: number) {
    if (event.start && event.end) {
      this.rates[index].nativeElement.focus();
    }
  }

  // onChangeLanguage(event: any) {
  //   // fetch translation for roomName
  //   const canTranslateRoomName = this.itemForm.get('roomName') && this.itemForm.get('roomName').value && !this.itemForm.get('roomNameTranslations').get(this.selectedLanguageIso).value;
  //   if (canTranslateRoomName) {
  //     this.functionsService.fetchTranslation(this.itemForm.get('roomName').value, this.selectedLanguageIso)
  //       .then(translation => {
  //         this.itemForm.get('roomNameTranslations').get(this.selectedLanguageIso).setValue(translation);
  //       });
  //   }

  //   // fetch translation for selling text
  //   const canTranslateSellingText = this.itemForm.get('shortSellingText').value && !this.itemForm.get('shortSellingTextTranslations').get(this.selectedLanguageIso).value;
  //   if (canTranslateSellingText) {
  //     this.functionsService.fetchTranslation(this.itemForm.get('shortSellingText').value, this.selectedLanguageIso)
  //       .then(translation => {
  //         this.itemForm.get('shortSellingTextTranslations').get(this.selectedLanguageIso).setValue(translation);
  //       });
  //   }
  // }

  onSubmit() {
    this.saving = true;

    const roomCategory: RoomCategory = {
      ...this.itemForm.value,
      featuredAmmenities: this.selectedAmenities,
    }

    if (this.editMode) {
      this.roomCategoriesService.updateRoomCategory(this.roomCategory.id, roomCategory)
        .then(() => {
          this.onCancel()
        }).catch();
    } else {
      this.roomCategoriesService.addRoomCategory(roomCategory)
      .then(() => {
        this.onCancel()
      }).catch();
    }
  }

  ngOnDestroy() {
    if (this.surveySub) {
      this.surveySub.unsubscribe();
    }    
    // if (this.activeClientSub) {
    //   this.activeClientSub.unsubscribe();
    // }    
  }
}
