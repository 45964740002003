import { Component, OnInit } from '@angular/core';
import { ResellersService } from '../services/resellers.service';
import { Reseller } from '../services/interfaces';

@Component({
  selector: 'ngx-after-signup',
  templateUrl: './after-signup.component.html',
  styleUrls: ['./after-signup.component.scss']
})
export class AfterSignupComponent implements OnInit {

  activeReseller: Reseller;
  constructor(
    private resellerService: ResellersService,
  ) { }

  ngOnInit(): void {
    this.activeReseller = this.resellerService.activeReseller;
  }
}
