import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { 
  NbButtonGroupModule,
  NbRadioModule,
  NbCardModule,
  NbButtonModule,
  NbInputModule,
  NbSpinnerModule,
  NbActionsModule,
  NbIconModule,
  NbListModule,
  NbRouteTabsetModule,
  NbTabsetModule,
  NbCheckboxModule,
  NbSelectModule,
  NbTreeGridModule,
  NbWindowModule,
  NbTagModule, 
  NbLayoutModule,
  NbPopoverModule} from '@nebular/theme';

import { BarTypesComponent } from './bar-types/bar-types.component';
import { AllSomeNoneSelectorComponent } from './all-some-none-selector/all-some-none-selector.component';
import { RoomCategorySelectorComponent } from './room-category-selector/room-category-selector.component';
// import { AtNearbyNoneSelectorComponent } from './at-nearby-none-selector/at-nearby-none-selector.component';
// import { ContactMethodSelectorComponent } from './contact-method-selector/contact-method-selector.component';
import { CheckboxesComponent } from './checkboxes/checkboxes.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { GracePeriodDefinerComponent } from './grace-period-definer/grace-period-definer.component';
// import { MonthlyYearlySelectorComponent } from './monthly-yearly-selector/monthly-yearly-selector.component';
// import { AdaptersSelectorComponent } from './adapters-selector/adapters-selector.component';
// import { FileUploaderSubColComponent } from './file-uploader-sub-col/file-uploader-sub-col.component';
// import { SelectButtonComponent } from './select-button/select-button.component';
import { AddStringItemComponent } from './add-string-item/add-string-item.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    BarTypesComponent,
    AllSomeNoneSelectorComponent,
    RoomCategorySelectorComponent,
    // AtNearbyNoneSelectorComponent,
    // ContactMethodSelectorComponent,
    CheckboxesComponent,
    ScheduleComponent,
    GracePeriodDefinerComponent,
    // MonthlyYearlySelectorComponent,
    // AdaptersSelectorComponent,
    // FileUploaderSubColComponent,
    // SelectButtonComponent
    AddStringItemComponent,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    NbCheckboxModule,
    FormsModule, 
    // SharedModule,
    NbButtonGroupModule,
    NbRadioModule,
    NbIconModule,
    NbButtonModule,
    NbCardModule
  ],
  exports: [
    BarTypesComponent,
    AllSomeNoneSelectorComponent,
    RoomCategorySelectorComponent,
    // AtNearbyNoneSelectorComponent,
    // ContactMethodSelectorComponent,
    CheckboxesComponent,
    ScheduleComponent,
    GracePeriodDefinerComponent,
    // MonthlyYearlySelectorComponent,
    // AdaptersSelectorComponent,
    // FileUploaderSubColComponent,
    // SelectButtonComponent
  ]
})
export class CustomComponentsModule { }
