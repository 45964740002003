import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

// Services
import { SignUpService } from './sign-up.service';

// Interfaces
import { Survey } from './interfaces';

// Other
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class SurveyService implements OnDestroy {
  
  formChanged = false;

  // baseUrl = 'http://localhost:3000';
  baseUrl = 'https://api2.guestchat.com';
  TOKEN_KEY = 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse';

  public saveObjectContainer = {};
  public loadingData$ = new BehaviorSubject<boolean>(false);
  public widget$ = new BehaviorSubject<boolean>(false);

  constructor(
    private signUpService: SignUpService,
    private httpClient: HttpClient
  ) {

    this.saveObjectContainer = {};

  }

  public processChanges(fieldValues: any) {
    Object.keys(fieldValues).forEach(key => {
      if (fieldValues[key] !== null) {
        this.saveObjectContainer[key] = fieldValues[key]
      }
    });
    // console.log(this.saveObjectContainer);
  }

  // Update Survey
  public async updateSurvey() {

    const survey = this.createSurveyObject(this.saveObjectContainer);

    // try {
    //   if ('propertyPhone' in survey && survey['propertyPhone'].value !== this.activeClient?.botSettings?.fallbackCommunication?.callUsNumber) {
    //     const bot: BotSettings = this.activeClient.botSettings;
    //     bot.fallbackCommunication.callUsNumber = survey['propertyPhone'].value;

    //     await this.clientService.updateBotSettings(bot);
    //   }
    // } catch (err) {
    //   console.log(err);
    // }

    const updatedSurvey: Survey = {
      ...survey,
      updatedOn: new Date(),
    };

    // console.log(updatedSurvey);

    try {

       const clientId = this.signUpService.clientId;
       const path = this.baseUrl +'/signup/updateSurvey';
       
       return new Promise((resolve, reject) => {
        const headers = new HttpHeaders().set('content-type', 'application/json')
          .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');
  
        this.httpClient.post(path, { clientId, survey: updatedSurvey }, { headers })
          .subscribe((resp: any) => {
            if (resp.success) {
              resolve(resp);
            }
          }, (err) => {            
            reject(err.statusText);
          });
      });

    } catch (error) {
      console.log(error);
    }
  }

  // Run Madlips
  public async runMadlips() {
    try {

       const clientId = this.signUpService.clientId;
       const path = 'https://us-central1-guestchat-live.cloudfunctions.net/runMadlibs';
       
       return new Promise((resolve, reject) => {
        const headers = new HttpHeaders().set('content-type', 'application/json')
  
        this.httpClient.post(path, { clientId, apiKey: 'rerkmvkmsvdsvds12423vczqr23', miniMadlibs: false, dialogflowId: null }, { headers })
          .subscribe((resp: any) => {
            if (resp) {
              this.widget$.next(true);
              // resolve(resp);
            }
          }, (err) => {            
            reject(err.statusText);
          });
      });

    } catch (error) {
      console.log(error);
    }
  }

  // Create Survey Obj
  public createSurveyObject(values = null) {

    const clientId = this.signUpService.clientId;

    const newSurvey: Survey = {
      id: clientId,
      updatedOn: null,
    }

    Object.keys(values).forEach(key => {
      if (key === 'roomCategories') {
        newSurvey['roomCategories'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Room Categories',
          questionText: 'Rooms list',
          value: values[key],
          intents: ['bathtub_shower', 'refrigerator'],
        };
      }

      if (key === 'hasNonGuestsRestaurant') {
        newSurvey['hasNonGuestsRestaurant'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Restaurant',
          questionText: 'Open to Non-guests (outside visitors)',
          value: values[key],
          intents: ['restaurant', 'dinner', 'lunch', 'breakfast'],
        };
      }

      if (key === 'hasNonGuestsBar') {
        newSurvey['hasNonGuestsBar'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Bar',
          questionText: 'Open to Non-guests (outside visitors)',
          value: values[key],
          intents: ['bar'],
        };
      }

      // if (key === 'hasNonGuestsDinner') {
      //   newSurvey['hasNonGuestsDinner'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Dinner',
      //     questionText: 'Open to Non-guests (outside visitors)',
      //     value: values[key],
      //     intents: ['dinner'],
      //   };
      // }

      // if (key === 'hasNonGuestsSwimmingPool') {
      //   newSurvey['hasNonGuestsSwimmingPool'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Swimming Pool',
      //     questionText: 'Open to Non-guests (outside visitors)',
      //     value: values[key],
      //     intents: ['pool'],
      //   };
      // }

      if (key === 'hasNonGuestsSpa') {
        newSurvey['hasNonGuestsSpa'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Spa',
          questionText: 'Open to Non-guests (outside visitors)',
          value: values[key],
          intents: ['spa'],
        };
      }

      if (key === 'minimumStay') {
        newSurvey['minimumStay'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Minimum Stay',
          questionText: 'Text',
          value: values[key],
          intents: ['minimum_stay'],
        };
      }

      if (key === 'minimumStayNote') {
        newSurvey['minimumStayNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Minimum Length of Stay',
          questionText: 'Note',
          value: values[key],
          intents: ['minimum_stay'],
        };
      }

      if (key === 'addressLine1') {
        newSurvey['addressLine1'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Address Line 1',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'addressState') {
        newSurvey['addressState'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'State / Province / Region',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'propertyNote') {
        newSurvey['propertyNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Property Details',
          questionText: 'Note',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'roomAmenitiesNote') {
        newSurvey['roomAmenitiesNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Room Amenities',
          questionText: 'Note',
          value: values[key],
          intents: ['room_amenities'],
        };
      }

      if (key === 'additionalServicesNote') {
        newSurvey['additionalServicesNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Additional Services',
          questionText: 'Note',
          value: values[key],
          intents: [],
        };
      }

      if (key === 'paymentsNote') {
        newSurvey['paymentsNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Payments & Payment Methods',
          questionText: 'Note',
          value: values[key],
          intents: ['payment_policy'],
        };
      }

      if (key === 'taxesNote') {
        newSurvey['taxesNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Taxes & Charges',
          questionText: 'Note',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'specialsNote') {
        newSurvey['specialsNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Specials',
          questionText: 'Note',
          value: values[key],
          intents: ['specials_packages'],
        };
      }

      if (key === 'weddingsNote') {
        newSurvey['weddingsNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Weddings',
          questionText: 'Note',
          value: values[key],
          intents: ['weddings'],
        };
      }

      if (key === 'directBenefitsNote') {
        newSurvey['directBenefitsNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Benefits Of Direct Booking',
          questionText: 'Note',
          value: values[key],
          intents: ['book_direct'],
        };
      }

      if (key === 'cancellationNote') {
        newSurvey['cancellationNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Cancellation and NO SHOW policy',
          questionText: 'Note',
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }

      if (key === 'guestContactNote') {
        newSurvey['guestContactNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Guest Contact',
          questionText: 'Note',
          value: values[key],
          intents: [],
        };
      }

      if (key === 'uniqueSellingNote') {
        newSurvey['uniqueSellingNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Unique Selling Proposition',
          questionText: 'Note',
          value: values[key],
          intents: [],
        };
      }

      if (key === 'bathroomAmenitiesNote') {
        newSurvey['bathroomAmenitiesNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Bathroom Amenities',
          questionText: 'Note',
          value: values[key],
          intents: ['room_amenities'],
        };
      }

      if (key === 'toiletriesNote') {
        newSurvey['toiletriesNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Toiletries',
          questionText: 'Note',
          value: values[key],
          intents: ['room_amenities', 'toiletries'],
        };
      }


      if (key === 'kitchenAmenitiesNote') {
        newSurvey['kitchenAmenitiesNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Kitchen Amenities',
          questionText: 'Note',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }

      if (key === 'googleMapsLink') {
        newSurvey['googleMapsLink'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Google Maps Link',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'addressLine2') {
        newSurvey['addressLine2'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Address Line 2',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'addressLine3') {
        newSurvey['addressLine3'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Address Line 3',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'city') {
        newSurvey['city'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'City',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'zipPostCode') {
        newSurvey['zipPostCode'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Zip / Postal Code',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'country') {
        newSurvey['country'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Country',
          value: values[key],
          intents: ['location_&_directions', 'contact_us'],
        };
      }

      if (key === 'propertyEmail') {
        newSurvey['propertyEmail'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Property Email',
          value: values[key],
          intents: ['email_us', 'change_reservation', 'confirm_booking', 'cancel_booking', 'complaints', 'requests', 'live_chat', 'contact_us', 'group_booking'],
        };
      }

      if (key === 'propertyPhone') {
        newSurvey['propertyPhone'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Property Phone Number',
          value: values[key],
          intents: ['call_us', 'change_reservation', 'confirm_booking', 'cancel_booking', 'complaints', 'requests', 'live_chat', 'contact_us', 'group_booking'],
        };
      }

      if (key === 'propertyLatitude') {
        newSurvey['propertyLatitude'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Latitude of the property',
          value: values[key],
          intents: ['location_&_directions'],
        };
      }

      if (key === 'propertyLongitude') {
        newSurvey['propertyLongitude'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Property Details',
          questionText: 'Longitude of the property',
          value: values[key],
          intents: ['location_&_directions'],
        };
      }

      // 3 Building Features
      if (key === 'buildingNote') {
        newSurvey['buildingNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Building Features',
          questionText: 'Note',
          value: values[key],
          intents: ['hotel_capacity'],
        };
      }

      if (key === 'numberOfBuildings') {
        newSurvey['numberOfBuildings'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Building Features',
          questionText: 'Number of buildings',
          value: values[key],
          intents: ['hotel_capacity'],
        };
      }

      if (key === 'numberOfFloors') {
        newSurvey['numberOfFloors'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Building Features',
          questionText: 'Number of floors',
          value: values[key],
          intents: ['hotel_capacity'],
        };
      }

      if (key === 'totalRoomSuitesUnits') {
        newSurvey['totalRoomSuitesUnits'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Building Features',
          questionText: 'Total room suites/units',
          value: values[key],
          intents: ['hotel_capacity'],
        };
      }

      if (key === 'haveElevatorsStairsBoth') {
        newSurvey['haveElevatorsStairsBoth'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Building Features',
          questionText: 'Which do you have?',
          value: values[key],
          intents: ['elevators_or_stairs', 'accessibility'],
        };
      }

      if (key === 'accessibilityNote') {
        newSurvey['accessibilityNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Accessibility',
          questionText: 'Note?',
          value: values[key],
          intents: ['accessibility'],
        };
      }

      if (key === 'accessibilitiesRooms') {
        newSurvey['accessibilitiesRooms'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Accessibility',
          questionText: 'Wheelchair Accessible Rooms?',
          value: values[key],
          intents: ['accessibility'],
        };
      }

      if (key === 'accessibilitiesCommonAreas') {
        newSurvey['accessibilitiesCommonAreas'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Accessibility',
          questionText: 'Wheelchair Accessible Common Areas?',
          value: values[key],
          intents: ['accessibility'],
        };
      }

      // smoking policy
      if (key === 'smokingNote') {
        newSurvey['smokingNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Smoking Policy',
          questionText: 'Note',
          value: values[key],
          intents: ['smoking_policies'],
        };
      }

      if (key === 'smokingPolicy') {
        newSurvey['smokingPolicy'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Smoking Policy',
          questionText: 'What is your policy?',
          value: values[key],
          intents: ['smoking_policies'],
        };
      }

      // if (key === 'haveSmokingRooms') {
      //   newSurvey['haveSmokingRooms'] = {
      //     variableType: 'string',
      //     heading: 'Smoking Policy',
      //     questionText: 'Do you offer smoking rooms?',
      //     value: values[key],
      //     intents: ['smoking_policies'],
      //   }
      // }

      // 4 Languages Spoken
      if (key === 'languagesNote') {
        newSurvey['languagesNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Spoken Languages',
          questionText: 'Note',
          value: values[key],
          intents: ['languages'],
        };
      }

      if (key === 'languagesSpoken') {
        newSurvey['languagesSpoken'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Spoken Languages',
          questionText: 'What languages are spoken at the property?',
          value: values[key],
          intents: ['languages'],
        };
      }

      // 5 Property Caters To
      if (key === 'catersNote') {
        newSurvey['catersNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Property Caters To',
          questionText: 'Note',
          value: values[key],
          intents: ['longterm_booking'],
        };
      }

      if (key === 'propertyCatersTo') {
        newSurvey['propertyCatersTo'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Property Caters To',
          questionText: 'Property Caters To:',
          value: values[key],
          intents: ['longterm_booking', 'accessibility', 'group_booking', 'crib_cot', 'child_policy', 'pet_policy', 'events'],
        };
      }

      // 6 Child Policy
      if (key === 'childNote') {
        newSurvey['childNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Child Policy',
          questionText: 'Note',
          value: values[key],
          intents: ['child_policy'],
        };
      }

      if (key === 'ageOfChildrenAllowed') {
        newSurvey['ageOfChildrenAllowed'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Child Policy',
          questionText: 'At what age (and older) are children allowed?',
          value: values[key],
          intents: ['child_policy'],
        };
      }

      if (key === 'childPriceRanges') {
        newSurvey['childPriceRanges'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Child Rates',
          questionText: {
            childPriceRanges: 'Child rates (per child, per night)',
            childPriceRangesAgeFrom: 'Price Per Child Per Night - Age From',
            childPriceRangesAgeTo: 'Price Per Child Per Night - Age To',
            childPriceRangesPrice: 'Price Per Child Per Night - Price (USD)',
          },
          value: values[key],
          intents: ['child_policy'],
        };
      }

      if (key === 'hasCribsAvailable') {
        newSurvey['hasCribsAvailable'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Crib/Cots',
          questionText: 'Crib/Infants beds available',
          value: values[key],
          intents: ['crib_cot', 'child_policy'],
        };
      }

      if (key === 'cribsCost') {
        newSurvey['cribsCost'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Crib/Cots',
          questionText: 'Free or Surcharge',
          value: values[key],
          intents: ['crib_cot', 'child_policy'],
        };
      }

      if (key === 'frontDeskHours') {
        newSurvey['frontDeskHours'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Front Desk Opening Hours',
          questionText: 'When is the frontdesk open',
          value: values[key],
          intents: ['opening_hours', 'call_us', 'live_chat'],
        };
      }

      if (key === 'frontDeskNote') {
        newSurvey['frontDeskNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Front Desk Opening Hours',
          questionText: 'Note',
          value: values[key],
          intents: ['opening_hours', 'call_us'],
        };
      }

      if (key === 'hasFrontDesk') {
        newSurvey['hasFrontDesk'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Front Desk Opening Hours',
          questionText: 'Has front desk',
          value: values[key],
          intents: ['opening_hours', 'call_us'],
        };
      }

      if (key === 'hotelBasicsFlags') {
        newSurvey['hotelBasicsFlags'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Action flags for Hotel basics',
          questionText: 'These sections are flagged for action.',
          value: values[key],
          intents: ['opening_hours', 'call_us'],
        };
      }

      if (key === 'facilitiesActionFlags') {
        newSurvey['facilitiesActionFlags'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Action flags for facilities',
          questionText: 'These facilities are flagged for action.',
          value: values[key],
          intents: ['opening_hours', 'call_us'],
        };
      }

      if (key === 'hasCheckInCheckOut') {
        newSurvey['hasCheckInCheckOut'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Has check-in / check-out',
          value: values[key],
          intents: ['check_in', 'check_out'],
        };
      }

      if (key === 'checkInNoEndTime') {
        newSurvey['checkInNoEndTime'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Check-in',
          questionText: 'No check-in end time',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'checkInNote') {
        newSurvey['checkInNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Check-in',
          questionText: 'Note',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'checkInFromTime') {
        newSurvey['checkInFromTime'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Check-in from time',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'checkInToTime') {
        newSurvey['checkInToTime'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Check-in to time',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'checkInEarlyAvailable') {
        newSurvey['checkInEarlyAvailable'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Is early check-in available?',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'checkInIDRequired') {
        newSurvey['checkInIDRequired'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Is guest passport or ID required at check-in?',
          value: values[key],
          intents: ['check_in', 'payment_policy'],
        };
      }

      if (key === 'checkInEarlyCost') {
        newSurvey['checkInEarlyCost'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Check-in early free or surcharge',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'checkInEarlySurcharge') {
        newSurvey['checkInEarlySurcharge'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Check-in early surcharge amount unit',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'checkInEarlySurchargeValue') {
        newSurvey['checkInEarlySurchargeValue'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Check-in early surcharge amount value',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'checkOutTime') {
        newSurvey['checkOutTime'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-out',
          questionText: 'Check-out time',
          value: values[key],
          intents: ['check_out'],
        };
      }

      if (key === 'isLateCheckOutAvailable') {
        newSurvey['isLateCheckOutAvailable'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-out',
          questionText: 'Is late check-out available?',
          value: values[key],
          intents: ['check_out'],
        };
      }

      if (key === 'ageRestrictionCheckIn') {
        newSurvey['ageRestrictionCheckIn'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-in',
          questionText: 'Minimum check-in age',
          value: values[key],
          intents: ['check_in'],
        };
      }

      if (key === 'lateCheckOutCost') {
        newSurvey['lateCheckOutCost'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-out',
          questionText: 'Late check-out cost',
          value: values[key],
          intents: ['check_out'],
        };
      }

      if (key === 'lateCheckOutCostUnit') {
        newSurvey['lateCheckOutCostUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-out',
          questionText: 'Amount, Percent or Fee Varies',
          value: values[key],
          intents: ['check_out'],
        };
      }

      if (key === 'lateCheckOutCostValue') {
        newSurvey['lateCheckOutCostValue'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Check-out',
          questionText: 'The value of late check-out cost',
          value: values[key],
          intents: ['check_out'],
        };
      }

      if (key === 'hasRestaurant') {
        newSurvey['hasRestaurant'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Restaurant',
          questionText: 'Has restaurant',
          value: values[key],
          intents: ['restaurant', 'facilities', 'menu_&_diet'],
        };
      }

      if (key === 'numberOfRestaurants') {
        newSurvey['numberOfRestaurants'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Restaurant',
          questionText: 'Number of restaurants?',
          value: values[key],
          intents: ['restaurant'],
        };
      }

      if (key === 'restaurantCuisine') {
        newSurvey['restaurantCuisine'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Restaurant',
          questionText: 'Cuisine',
          value: values[key],
          intents: ['restaurant'],
        };
      }

      if (key === 'restaurantDietaryOptions') {
        newSurvey['restaurantDietaryOptions'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Restaurant',
          questionText: 'Dietary Options',
          value: values[key],
          intents: ['restaurant', 'menu_&_diet'],
        };
      }

      if (key === 'restaurantNote') {
        newSurvey['restaurantNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Restaurant',
          questionText: 'Note',
          value: values[key],
          intents: ['restaurant'],
        };
      }

      if (key === 'restaurantDressCode') {
        newSurvey['restaurantDressCode'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Restaurant',
          questionText: 'Dress Code',
          value: values[key],
          intents: ['restaurant', 'lunch', 'breakfast', 'dinner'],
        };
      }


      if (key === 'hasBar') {
        newSurvey['hasBar'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Bar',
          questionText: 'Has bar',
          value: values[key],
          intents: ['bar', 'facilities'],
        };
      }

      if (key === 'numberOfBars') {
        newSurvey['numberOfBars'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Bar',
          questionText: 'Number of bars',
          value: values[key],
          intents: ['bar'],
        };
      }

      if (key === 'barNote') {
        newSurvey['barNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Bar',
          questionText: 'Note',
          value: values[key],
          intents: ['bar'],
        };
      }

      if (key === 'barTypes') {
        newSurvey['barTypes'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Bar',
          questionText: 'Types',
          value: values[key],
          intents: ['bar'],
        };
      }

      if (key === 'barServing') {
        newSurvey['barServing'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Bar',
          questionText: 'Serving',
          value: values[key],
          intents: ['bar'],
        };
      }
      // if (key === 'barNoLicense') {
      //   newSurvey['barNoLicense'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Bar',
      //     questionText: 'No License | Non Alcoholic Drinks Only',
      //     value: values[key],
      //     intents: ['bar'],
      //   };
      // }

      // if (key === 'barServeWine') {
      //   newSurvey['barServeWine'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Bar',
      //     questionText: 'Serve wine',
      //     value: values[key],
      //     intents: ['bar'],
      //   };
      // }

      // if (key === 'barServeBeer') {
      //   newSurvey['barServeBeer'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Bar',
      //     questionText: 'Serve beer',
      //     value: values[key],
      //     intents: ['bar'],
      //   };
      // }

      // if (key === 'barServeCocktails') {
      //   newSurvey['barServeCocktails'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Bar',
      //     questionText: 'Serve Cocktails',
      //     value: values[key],
      //     intents: ['bar'],
      //   };
      // }

      // if (key === 'barServeHardLiquor') {
      //   newSurvey['barServeHardLiquor'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Bar',
      //     questionText: 'Serve Hard Liquor',
      //     value: values[key],
      //     intents: ['bar'],
      //   };
      // }

      if (key === 'barDescription') {
        newSurvey['barDescription'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Bar',
          questionText: 'Description',
          value: values[key],
          intents: ['bar'],
        };
      }


      if (key === 'hasBreakfast') {
        newSurvey['hasBreakfast'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Breakfast',
          questionText: 'Has breakfast',
          value: values[key],
          intents: ['breakfast', 'meal_plans', 'restaurant', 'menu_&_diet'],
        };
      }

      if (key === 'breakfastOpeningSchedule') {
        newSurvey['breakfastOpeningSchedule'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Breakfast',
          questionText: 'Opening Times',
          value: values[key],
          intents: ['breakfast', 'meal_plans', 'restaurant'],
        };
      }

      if (key === 'breakfastNote') {
        newSurvey['breakfastNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Breakfast',
          questionText: 'Note',
          value: values[key],
          intents: ['breakfast', 'meal_plans'],
        };
      }

      if (key === 'breakfastCharge') {
        newSurvey['breakfastCharge'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Breakfast',
          questionText: 'Free or Surcharge',
          value: values[key],
          intents: ['breakfast', 'meal_plans'],
        };
      }

      if (key === 'breakfastSurcharge') {
        newSurvey['breakfastSurcharge'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Breakfast',
          questionText: 'Specific amount (USD) or Price range',
          value: values[key],
          intents: ['breakfast', 'meal_plans'],
        };
      }

      if (key === 'breakfastSurchargeAmount') {
        newSurvey['breakfastSurchargeAmount'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Breakfast',
          questionText: 'Specific amount in USD',
          value: values[key],
          intents: ['breakfast', 'meal_plans'],
        };
      }

      if (key === 'breakfastSurchargeAmountMin') {
        newSurvey['breakfastSurchargeAmountMin'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Breakfast',
          questionText: 'Price range minimum price',
          value: values[key],
          intents: ['breakfast', 'meal_plans'],
        };
      }

      if (key === 'breakfastSurchargeAmountMax') {
        newSurvey['breakfastSurchargeAmountMax'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Breakfast',
          questionText: 'Price range Maximum price',
          value: values[key],
          intents: ['breakfast', 'meal_plans'],
        };
      }

      if (key === 'breakfastType') {
        newSurvey['breakfastType'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Breakfast',
          questionText: 'Types of breakfast',
          value: values[key],
          intents: ['breakfast', 'meal_plans'],
        };
      }


      if (key === 'closeDiningGoogleMapsLink') {
        newSurvey['closeDiningGoogleMapsLink'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Close Dining',
          questionText: 'Google maps link',
          value: values[key],
          intents: ['bar', 'breakfast', 'lunch', 'dinner', 'meal_plans', 'restaurant', 'menu_&_diet'],
        };
      }

      // lunch
      if (key === 'hasLunch') {
        newSurvey['hasLunch'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Lunch',
          questionText: 'Has Lunch',
          value: values[key],
          intents: ['lunch', 'meal_plans', 'restaurant', 'menu_&_diet'],
        };
      }

      if (key === 'lunchNote') {
        newSurvey['lunchNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Lunch',
          questionText: 'Note',
          value: values[key],
          intents: ['lunch', 'meal_plans'],
        };
      }

      if (key === 'lunchOpeningSchedule') {
        newSurvey['lunchOpeningSchedule'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Lunch',
          questionText: 'Opening Times',
          value: values[key],
          intents: ['lunch', 'meal_plans', 'restaurant'],
        };
      }

      if (key === 'lunchMenuTypes') {
        newSurvey['lunchMenuTypes'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Lunch',
          questionText: 'Menu Types',
          value: values[key],
          intents: ['lunch', 'meal_plans'],
        };
      }

      if (key === 'lunchCuisine') {
        newSurvey['lunchCuisine'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Lunch',
          questionText: 'Cuisine / Description',
          value: values[key],
          intents: ['lunch', 'meal_plans'],
        };
      }

      if (key === 'lunchReservationRequired') {
        newSurvey['lunchReservationRequired'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Lunch',
          questionText: 'Reservation required',
          value: values[key],
          intents: ['lunch', 'restaurant_reservations'],
        };
      }

      // dinner
      if (key === 'hasDinner') {
        newSurvey['hasDinner'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Dinner',
          questionText: 'Has Dinner',
          value: values[key],
          intents: ['dinner', 'meal_plans', 'restaurant', 'menu_&_diet'],
        };
      }

      if (key === 'dinnerNote') {
        newSurvey['dinnerNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Dinner',
          questionText: 'Note',
          value: values[key],
          intents: ['dinner', 'meal_plans'],
        };
      }

      if (key === 'dinnerOpeningSchedule') {
        newSurvey['dinnerOpeningSchedule'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Dinner',
          questionText: 'Opening Times',
          value: values[key],
          intents: ['dinner', 'meal_plans', 'restaurant'],
        };
      }

      if (key === 'dinnerMenuTypes') {
        newSurvey['dinnerMenuTypes'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Dinner',
          questionText: 'Menu Types',
          value: values[key],
          intents: ['dinner', 'meal_plans'],
        };
      }

      if (key === 'dinnerCuisine') {
        newSurvey['dinnerCuisine'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Dinner',
          questionText: 'Cuisine / Description',
          value: values[key],
          intents: ['dinner', 'meal_plans'],
        };
      }

      if (key === 'dinnerReservationRequired') {
        newSurvey['dinnerReservationRequired'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Dinner',
          questionText: 'Reservation required',
          value: values[key],
          intents: ['dinner', 'restaurant_reservations'],
        };
      }

      // meals
      if (key === 'hasMealPlans') {
        newSurvey['hasMealPlans'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Meal Plans',
          questionText: 'Has meal plans',
          value: values[key],
          intents: ['meal_plans'],
        };
      }

      if (key === 'mealNote') {
        newSurvey['mealNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Meal Plans',
          questionText: 'Note',
          value: values[key],
          intents: ['meal_plans'],
        };
      }


      if (key === 'mealPlan') {
        newSurvey['mealPlan'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Meal Plans',
          questionText: 'Which meal plans do you offer? (Choose all that apply.)',
          value: values[key],
          intents: ['meal_plans'],
        };
      }

      if (key === 'mealPlanDescription') {
        newSurvey['mealPlanDescription'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Meal Plans',
          questionText: 'Description',
          value: values[key],
          intents: ['meal_plans'],
        };
      }

      if (key === 'hasPool') {
        newSurvey['hasPool'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Has swimming pool',
          value: values[key],
          intents: ['pool', 'facilities'],
        };
      }

      if (key === 'poolNote') {
        newSurvey['poolNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Swimming Pool',
          questionText: 'Note',
          value: values[key],
          intents: ['pool'],
        };
      }


      if (key === 'numberOfOutdoorPools') {
        newSurvey['numberOfOutdoorPools'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Number of outdoor pools',
          value: values[key],
          intents: ['pool'],
        };
      }

      if (key === 'numberOfIndoorPools') {
        newSurvey['numberOfIndoorPools'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Number of indoor pools',
          value: values[key],
          intents: ['pool'],
        };
      }

      if (key === 'poolAvailability') {
        newSurvey['poolAvailability'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Availability',
          value: values[key],
          intents: ['pool'],
        };
      }

      if (key === 'hasPoolSideBar') {
        newSurvey['hasPoolSideBar'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Has poolside bar',
          value: values[key],
          intents: ['pool'],
        };
      }

      if (key === 'childFriendlyPool') {
        newSurvey['childFriendlyPool'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Child friendly safe',
          value: values[key],
          intents: ['pool', 'activities_for_children'],
        };
      }

      if (key === 'heatedPool') {
        newSurvey['heatedPool'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Heated pool',
          value: values[key],
          intents: ['pool'],
        };
      }

      if (key === 'lifeGuardAtPool') {
        newSurvey['lifeGuardAtPool'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Life guard at pool',
          value: values[key],
          intents: ['pool'],
        };
      }

      if (key === 'chlorinatedOrSaltedPool') {
        newSurvey['chlorinatedOrSaltedPool'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Swimming Pool',
          questionText: 'Chlorinated or salted pool',
          value: values[key],
          intents: ['pool'],
        };
      }

      // parking
      if (key === 'hasParking') {
        newSurvey['hasParking'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Parking',
          questionText: 'Has parking',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'parkingNote') {
        newSurvey['parkingNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Parking',
          questionText: 'Note',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'hasSelfParking') {
        newSurvey['hasSelfParking'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Parking',
          questionText: 'Has self parking',
          value: values[key],
          intents: ['parking', 'facilities'],
        };
      }

      if (key === 'hasValetParking') {
        newSurvey['hasValetParking'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Parking',
          questionText: 'Has valet parking',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'hasOffsiteParking') {
        newSurvey['hasOffsiteParking'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Parking',
          questionText: 'Has offsite parking',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'selfParking') {
        newSurvey['selfParking'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Parking',
          questionText: 'Self parking free or surcharge',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'selfParkingCost') {
        newSurvey['selfParkingCost'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Parking',
          questionText: 'Self parking cost',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'selfParkingCostUnit') {
        newSurvey['selfParkingCostUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Parking',
          questionText: 'Self parking cost unit',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'valetParking') {
        newSurvey['valetParking'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Parking',
          questionText: 'Valet parking free or surcharge',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'valetParkingCost') {
        newSurvey['valetParkingCost'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Parking',
          questionText: 'Valet parking cost',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'valetParkingCostUnit') {
        newSurvey['valetParkingCostUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Parking',
          questionText: 'Valet parking cost unit',
          value: values[key],
          intents: ['parking'],
        };
      }


      if (key === 'offsiteParking') {
        newSurvey['offsiteParking'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Parking',
          questionText: 'Offsite parking free or surcharge',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'offsiteParkingCost') {
        newSurvey['offsiteParkingCost'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Parking',
          questionText: 'Offsite parking cost',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'offsiteParkingCostUnit') {
        newSurvey['offsiteParkingCostUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Parking',
          questionText: 'Offsite parking cost unit',
          value: values[key],
          intents: ['parking'],
        };
      }

      if (key === 'offsiteStreetParking') {
        newSurvey['offsiteStreetParking'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Parking',
          questionText: 'has street parking',
          value: values[key],
          intents: ['parking'],
        };
      }

      // fitness
      if (key === 'hasFitness') {
        newSurvey['hasFitness'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Fitness Center / Gym',
          questionText: 'Has fitness center / gym',
          value: values[key],
          intents: ['gym', 'facilities'],
        };
      }

      if (key === 'fitnessNote') {
        newSurvey['fitnessNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Fitness Center / Gym',
          questionText: 'Note',
          value: values[key],
          intents: ['gym'],
        };
      }

      if (key === 'fitnessFacilitiesTime') {
        newSurvey['fitnessFacilitiesTime'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Fitness Center / Gym',
          questionText: 'Fitness facilities availability',
          value: values[key],
          intents: ['gym'],
        };
      }

      // spa
      if (key === 'hasSpa') {
        newSurvey['hasSpa'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Spa',
          questionText: 'Has spa',
          value: values[key],
          intents: ['spa', 'facilities'],
        };
      }

      if (key === 'spaNote') {
        newSurvey['spaNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Spa',
          questionText: 'Note',
          value: values[key],
          intents: ['spa'],
        };
      }

      if (key === 'spaTime') {
        newSurvey['spaTime'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Spa',
          questionText: 'Spa open daily or selected days',
          value: values[key],
          intents: ['spa'],
        };
      }

      if (key === 'spaServicesOffered') {
        newSurvey['spaServicesOffered'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Spa',
          questionText: 'Services Offered',
          value: values[key],
          intents: ['spa'],
        };
      }

      if (key === 'spaRequireReservation') {
        newSurvey['spaRequireReservation'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Spa',
          questionText: 'Require Reservation',
          value: values[key],
          intents: ['spa'],
        };
      }

      // business center
      if (key === 'hasBusinessCenter') {
        newSurvey['hasBusinessCenter'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Business Center',
          questionText: 'Has business center',
          value: values[key],
          intents: ['facilities', 'business_center'],
        };
      }

      if (key === 'centerNote') {
        newSurvey['centerNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Business Center',
          questionText: 'Note',
          value: values[key],
          intents: ['business_center'],
        };
      }

      if (key === 'businessCenterTime') {
        newSurvey['businessCenterTime'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Business Center',
          questionText: 'Business center time',
          value: values[key],
          intents: ['business_center'],
        };
      }

      // beach
      if (key === 'beachNote') {
        newSurvey['beachNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Beach',
          questionText: 'Note',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'hasBeach') {
        newSurvey['hasBeach'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Beach',
          questionText: 'Has beach',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'beachLocation') {
        newSurvey['beachLocation'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Beach',
          questionText: 'Onsite or Nearby',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'beachNearByType') {
        newSurvey['beachNearByType'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Beach',
          questionText: 'Public beach or Private beach',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'beachNearByFromProperty') {
        newSurvey['beachNearByFromProperty'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Beach',
          questionText: 'Time from property to the beach (minutes)',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'beachNearByFromPropertyWalkingOrDriving') {
        newSurvey['beachNearByFromPropertyWalkingOrDriving'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Beach',
          questionText: 'Time from property to the beach (minutes) - Walking or Driving',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'hasBeachDirectAccess') {
        newSurvey['hasBeachDirectAccess'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Beach',
          questionText: 'Has direct access to beach',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'hasBeachChairAvailable') {
        newSurvey['hasBeachChairAvailable'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Beach',
          questionText: 'Has beach chair available',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'beachChairSurcharge') {
        newSurvey['beachChairSurcharge'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Beach',
          questionText: 'Free or surcharge (Per Person, USD)',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      if (key === 'beachChairSurchargeValue') {
        newSurvey['beachChairSurchargeValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Beach',
          questionText: 'surcharge (Per Person, USD) value',
          value: values[key],
          intents: ['beach', 'activities'],
        };
      }

      // room service
      if (key === 'hasRoomService') {
        newSurvey['hasRoomService'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Room Service',
          questionText: 'Has room service',
          value: values[key],
          intents: ['room_service', 'facilities'],
        };
      }

      if (key === 'roomNote') {
        newSurvey['roomNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Room Service',
          questionText: 'Note',
          value: values[key],
          intents: ['room_service'],
        };
      }

      if (key === 'roomServiceOpeningScheduleTimes') {
        newSurvey['roomServiceOpeningScheduleTimes'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Room Service',
          questionText: 'Opening times',
          value: values[key],
          intents: ['room_service'],
        };
      }

      // housekeeping
      if (key === 'hasHousekeeping') {
        newSurvey['hasHousekeeping'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Housekeeping',
          questionText: 'Has housekeeping',
          value: values[key],
          intents: ['housekeeping'],
        };
      }

      if (key === 'housekeepingNote') {
        newSurvey['housekeepingNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Housekeeping',
          questionText: 'Note',
          value: values[key],
          intents: ['housekeeping'],
        };
      }

      if (key === 'housekeepingWhen') {
        newSurvey['housekeepingWhen'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Housekeeping',
          questionText: 'Daily, limited or on request',
          value: values[key],
          intents: ['housekeeping'],
        };
      }

      if (key === 'housekeepingLimitedWhen') {
        newSurvey['housekeepingLimitedWhen'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Housekeeping',
          questionText: 'Once per stay, weekly, weekdays only or weekends only',
          value: values[key],
          intents: ['housekeeping'],
        };
      }

      if (key === 'hasHousekeepingChangeOfTowels') {
        newSurvey['hasHousekeepingChangeOfTowels'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Housekeeping',
          questionText: 'Has change of towels',
          value: values[key],
          intents: ['housekeeping', 'towels'],
        };
      }

      if (key === 'housekeepingChangeOfTowelsWhen') {
        newSurvey['housekeepingChangeOfTowelsWhen'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Housekeeping',
          questionText: 'How often change of towels?',
          value: values[key],
          intents: ['housekeeping', 'towels'],
        };
      }

      if (key === 'hasHousekeepingChangeOfBedSheets') {
        newSurvey['hasHousekeepingChangeOfBedSheets'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Housekeeping',
          questionText: 'Has change of bed sheets',
          value: values[key],
          intents: ['housekeeping'],
        };
      }

      if (key === 'housekeepingChangeOfBedSheetsWhen') {
        newSurvey['housekeepingChangeOfBedSheetsWhen'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Housekeeping',
          questionText: 'How often change of bed sheets?',
          value: values[key],
          intents: ['housekeeping'],
        };
      }

      // conference facilities
      if (key === 'hasConferenceFacilities') {
        newSurvey['hasConferenceFacilities'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Conference Facilities',
          questionText: 'Has conference facilities',
          value: values[key],
          intents: ['conference_room', 'facilities'],
        };
      }

      if (key === 'conferenceNote') {
        newSurvey['conferenceNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Conference Facilities',
          questionText: 'Note',
          value: values[key],
          intents: ['conference_room'],
        };
      }



      if (key === 'conferenceEquipmentAvailable') {
        newSurvey['conferenceEquipmentAvailable'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Conference Facilities',
          questionText: 'Audio / Visual Equipment Available',
          value: values[key],
          intents: ['conference_room'],
        };
      }

      if (key === 'conferenceCateringAvailable') {
        newSurvey['conferenceCateringAvailable'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Conference Facilities',
          questionText: 'Catering Available',
          value: values[key],
          intents: ['conference_room'],
        };
      }

      if (key === 'conferenceRooms') {
        newSurvey['conferenceRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Conference Facilities',
          questionText: {
            conferenceRooms: 'Conference Rooms',
            conferenceRoomsName: 'Conference room name',
            conferenceRoomsDimensions: 'Conference room dimensions',
            conferenceRoomsCapacity: 'Conference room capacity',
          },
          value: values[key],
          intents: ['conference_room'],
        };
      }

      // Taxes
      if (key === 'hasValueAddedTax') {
        newSurvey['hasValueAddedTax'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Has value added tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'valueAddedTax') {
        newSurvey['valueAddedTax'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Amount or percent for value added tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'valueAddedTaxValue') {
        newSurvey['valueAddedTaxValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The cost value of value added tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'valueAddedTaxValueUnit1') {
        newSurvey['valueAddedTaxValueUnit1'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The unit of the value of value added tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'valueAddedTaxValueUnit2') {
        newSurvey['valueAddedTaxValueUnit2'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The second unit of the value of value added tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'includedInRoomRateValueAdded') {
        newSurvey['includedInRoomRateValueAdded'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees - Value Added',
          questionText: 'Included in Room Rate',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'hasSalesTax') {
        newSurvey['hasSalesTax'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Has sales tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'salesTax') {
        newSurvey['salesTax'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Amount or percent for sales tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'salesTaxValue') {
        newSurvey['salesTaxValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The cost value of sales tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'salesTaxValueUnit1') {
        newSurvey['salesTaxValueUnit1'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The unit of the value of sales tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'salesTaxValueUnit2') {
        newSurvey['salesTaxValueUnit2'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The second unit of the value of sales tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'includedInRoomRateSalesTax') {
        newSurvey['includedInRoomRateSalesTax'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees - Sales Tax',
          questionText: 'Included in Room Rate',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'hasServiceCharge') {
        newSurvey['hasServiceCharge'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Has service charge',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'serviceCharge') {
        newSurvey['serviceCharge'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Amount or percent for service charge',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'serviceChargeValue') {
        newSurvey['serviceChargeValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The cost value of service charge',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'serviceChargeValueUnit1') {
        newSurvey['serviceChargeValueUnit1'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The unit of the value of service charge',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'serviceChargeValueUnit2') {
        newSurvey['serviceChargeValueUnit2'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The second unit of the value of service charge',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'includedInRoomRateServiceCharge') {
        newSurvey['includedInRoomRateServiceCharge'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees - Service Charge',
          questionText: 'Included in Room Rate',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'hasOccupancyTax') {
        newSurvey['hasOccupancyTax'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Has occupancy tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'occupancyTax') {
        newSurvey['occupancyTax'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Amount or percent for occupancy tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'occupancyTaxValue') {
        newSurvey['occupancyTaxValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The cost value of occupancy tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'occupancyTaxValueUnit1') {
        newSurvey['occupancyTaxValueUnit1'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The unit of the value of occupancy tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'occupancyTaxValueUnit2') {
        newSurvey['occupancyTaxValueUnit2'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The second unit of the value of occupancy tax',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'includedInRoomRateOccupancyTax') {
        newSurvey['includedInRoomRateOccupancyTax'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees - Occupancy Tax',
          questionText: 'Included in Room Rate',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'hasResortFees') {
        newSurvey['hasResortFees'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Has resort fees',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'resortFees') {
        newSurvey['resortFees'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'Amount or percent',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'resortFeesValue') {
        newSurvey['resortFeesValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The value of resort fees',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'resortFeesValueUnit1') {
        newSurvey['resortFeesValueUnit1'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The unit of the value of resort fees',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      if (key === 'resortFeesValueUnit2') {
        newSurvey['resortFeesValueUnit2'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Taxes & Fees',
          questionText: 'The second unit of the value of resort fees',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }


      if (key === 'includedInRoomRateResortFees') {
        newSurvey['includedInRoomRateResortFees'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Taxes & Fees - Resort Fees',
          questionText: 'Included in Room Rate',
          value: values[key],
          intents: ['taxes_&_fees'],
        };
      }

      // pets and service animals
      if (key === 'petsNote') {
        newSurvey['petsNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Pets & Service Animals',
          questionText: 'Note',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'hasPetsAndServiceAnimals') {
        newSurvey['hasPetsAndServiceAnimals'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Has Pets and Service Animals',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'allowPetsSurcharge') {
        newSurvey['allowPetsSurcharge'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Do you have a surcharge for pets?',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'allowPetsSurchargeValue') {
        newSurvey['allowPetsSurchargeValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Amount (USD)',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'allowPetsSurchargeValueUnit2') {
        newSurvey['allowPetsSurchargeValueUnit2'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Surcharge amount value unit 2',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'petRestrictionsType') {
        newSurvey['petRestrictionsType'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Do you have pet restrictions?',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'hasPetRestrictions') {
        newSurvey['hasPetRestrictions'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Restrictions',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'hasPetMaxWeightLimit') {
        newSurvey['hasPetMaxWeightLimit'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Has maximum weight limit for pet',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'petMaxWeightLimitValue') {
        newSurvey['petMaxWeightLimitValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Maximum weight limit value',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'petMaxWeightLimitValueUnit') {
        newSurvey['petMaxWeightLimitValueUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Maximum weight limit value unit',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'petMaxPerRoom') {
        newSurvey['petMaxPerRoom'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Maximum per room',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'petRestrictions') {
        newSurvey['petRestrictions'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Pet restrictions',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'hasPetDeposit') {
        newSurvey['hasPetDeposit'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Has pet deposit',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'petDepositValue') {
        newSurvey['petDepositValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Amount (USD)',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'petDepositValueUnit') {
        newSurvey['petDepositValueUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Pet deposit amount unit',
          value: values[key],
          intents: ['pet_policy'],
        };
      }

      if (key === 'petFriendlyRoom') {
        newSurvey['petFriendlyRoom'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Pets & Service Animals',
          questionText: 'Pet-friendly rooms',
          value: values[key],
          intents: ['pet_policy'],
        };
      }


      // day passes
      if (key === 'hasDayPasses') {
        newSurvey['hasDayPasses'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Day Passes',
          questionText: 'Has day passes',
          value: values[key],
          intents: ['day_pass'],
        };
      }

      if (key === 'dayPassesNote') {
        newSurvey['dayPassesNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Day Passes',
          questionText: 'Note',
          value: values[key],
          intents: ['day_pass'],
        };
      }

      if (key === 'dayPassesValue') {
        newSurvey['dayPassesValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Day Passes',
          questionText: 'Amount (USD)',
          value: values[key],
          intents: ['day_pass'],
        };
      }

      if (key === 'dayPassesValueUnit') {
        newSurvey['dayPassesValueUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Day Passes',
          questionText: 'Amount unit',
          value: values[key],
          intents: ['day_pass'],
        };
      }

      if (key === 'dayPassesText') {
        newSurvey['dayPassesText'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Day Passes',
          questionText: 'Other Text (Optional)',
          value: values[key],
          intents: ['day_pass'],
        };
      }

      if (key === 'dayPassesIncludes') {
        newSurvey['dayPassesIncludes'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Day Passes',
          questionText: 'Day Pass includes the following:',
          value: values[key],
          intents: ['day_pass'],
        };
      }

      if (key === 'hasLuggageStorage') {
        newSurvey['hasLuggageStorage'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Luggage Storage',
          questionText: 'Has Luggage Storage',
          value: values[key],
          intents: ['luggage_storage', 'facilities'],
        };
      }

      if (key === 'luggageNote') {
        newSurvey['luggageNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Luggage Storage',
          questionText: 'Note',
          value: values[key],
          intents: ['luggage_storage'],
        };
      }

      if (key === 'hasPublicHottubAvailable') {
        newSurvey['hasPublicHottubAvailable'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Hot Tub/Jacuzzi',
          questionText: 'Has Public Hot Tub/Jacuzzi',
          value: values[key],
          intents: ['hot_tub_jacuzzi'],
        };
      }

      if (key === 'jacuzziNote') {
        newSurvey['jacuzziNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Hot Tub/Jacuzzi',
          questionText: 'Note',
          value: values[key],
          intents: ['hot_tub_jacuzzi'],
        };
      }

      // amenities
      if (key === 'internetAvailability') {
        newSurvey['internetAvailability'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Internet Availability',
          questionText: 'Internet availability',
          value: values[key],
          intents: ['wifi', 'room_amenities'],
        };
      }

      if (key === 'basicWifiAvailableInRooms') {
        newSurvey['basicWifiAvailableInRooms'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Internet Availability',
          questionText: 'Basic wifi in Rooms',
          value: values[key],
          intents: ['wifi', 'room_amenities'],
        };
      }

      if (key === 'wifiGuestRoomCost') {
        newSurvey['wifiGuestRoomCost'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Internet Availability',
          questionText: 'Wi-fi cost in rooms',
          value: values[key],
          intents: ['wifi', 'room_amenities', 'facilities'],
        };
      }

      if (key === 'wifiAvailableInRoomComments') {
        newSurvey['wifiAvailableInRoomComments'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Internet Availability',
          questionText: 'Enter any additional information here.',
          value: values[key],
          intents: ['wifi', 'room_amenities'],
        };
      }
      if (key === 'wifiAvailableInPublicAreas') {
        newSurvey['wifiAvailableInPublicAreas'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Internet Availability',
          questionText: 'Is there Wi-Fi in public areas?',
          value: values[key],
          intents: ['wifi', 'room_amenities'],
        };
      }

      if (key === 'premiumInternetConnection') {
        newSurvey['premiumInternetConnection'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Internet Availability',
          questionText: 'High speed internet in rooms?',
          value: values[key],
          intents: ['wifi', 'room_amenities'],
        };
      }

      if (key === 'premiumInternetConnectionCost') {
        newSurvey['premiumInternetConnectionCost'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Internet Availability',
          questionText: 'Free or Surcharge?',
          value: values[key],
          intents: ['wifi', 'room_amenities'],
        };
      }

      if (key === 'premiumInternetConnectionCostValue') {
        newSurvey['premiumInternetConnectionCostValue'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Internet Availability',
          questionText: 'Specify the surcharge here',
          value: values[key],
          intents: ['wifi', 'room_amenities'],
        };
      }

      if (key === 'airconAvailability') {
        newSurvey['airconAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Air-conditioning Availability',
          value: values[key],
          intents: ['air_conditioning', 'room_amenities'],
        };
      }
      if (key === 'airconAvailableInRooms') {
        newSurvey['airconAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Air-conditioning Availability in some rooms',
          value: values[key],
          intents: ['air_conditioning', 'room_amenities'],
        };
      }

      if (key === 'ironAvailability') {
        newSurvey['ironAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Iron Availability',
          value: values[key],
          intents: ['iron_ironing_board', 'room_amenities'],
        };
      }

      if (key === 'ironAvailableInRooms') {
        newSurvey['ironAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Iron availability in some rooms',
          value: values[key],
          intents: ['iron_ironing_board', 'room_amenities'],
        };
      }

      if (key === 'ironingBoardAvailability') {
        newSurvey['ironingBoardAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Iron Board Availability',
          value: values[key],
          intents: ['iron_ironing_board', 'room_amenities'],
        };
      }

      if (key === 'ironingBoardAvailableInRooms') {
        newSurvey['ironingBoardAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Ironing board availability in some rooms',
          value: values[key],
          intents: ['iron_ironing_board', 'room_amenities'],
        };
      }

      if (key === 'minibarAvailability') {
        newSurvey['minibarAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Mini-Fridge Availability',
          value: values[key],
          intents: ['refrigerator', 'room_amenities', 'kitchen'],
        };
      }

      if (key === 'minibarAvailableInRooms') {
        newSurvey['minibarAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Mini-Fridge always availability in some rooms',
          value: values[key],
          intents: ['refrigerator', 'room_amenities', 'kitchen'],
        };
      }

      if (key === 'minibarOnRequestAvailableInRooms') {
        newSurvey['minibarOnRequestAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Mini-Fridge available on request in some rooms',
          value: values[key],
          intents: ['refrigerator', 'room_amenities', 'kitchen'],
        };
      }

      if (key === 'minibarbarAvailability') {
        newSurvey['minibarbarAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Minibar Fridge Availability',
          value: values[key],
          intents: ['refrigerator', 'room_amenities', 'kitchen'],
        };
      }

      if (key === 'minibarbarAvailableInRooms') {
        newSurvey['minibarbarAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Minibar Fridge always availability in some rooms',
          value: values[key],
          intents: ['refrigerator', 'room_amenities', 'kitchen'],
        };
      }

      if (key === 'minibarbarOnRequestAvailableInRooms') {
        newSurvey['minibarbarOnRequestAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Minibar Fridge available on request in some rooms',
          value: values[key],
          intents: ['refrigerator', 'room_amenities', 'kitchen'],
        };
      }

      if (key === 'inRoomSafeBoxAvailability') {
        newSurvey['inRoomSafeBoxAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'In-room Safe Box Availability',
          value: values[key],
          intents: ['safe_box', 'room_amenities'],
        };
      }
      if (key === 'inRoomSafeBoxAvailableInRooms') {
        newSurvey['inRoomSafeBoxAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'In-room Safe Box Availability in some rooms',
          value: values[key],
          intents: ['safe_box', 'room_amenities'],
        };
      }

      if (key === 'flatScreenTVAvailability') {
        newSurvey['flatScreenTVAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Flat-Screen TV Availability',
          value: values[key],
          intents: ['television', 'room_amenities'],
        };
      }

      if (key === 'sizeTV') {
        newSurvey['sizeTV'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Flat-Screen TV Availability',
          questionText: 'TV size',
          value: values[key],
          intents: ['television', 'room_amenities'],
        };
      }
      if (key === 'serviceTypeTV') {
        newSurvey['serviceTypeTV'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Flat-Screen TV Availability',
          questionText: 'TV service type',
          value: values[key],
          intents: ['television', 'room_amenities'],
        };
      }
      if (key === 'isSmartTV') {
        newSurvey['isSmartTV'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Flat-Screen TV Availability',
          questionText: 'Has smart tv',
          value: values[key],
          intents: ['television', 'room_amenities'],
        };
      }

      if (key === 'jacuzziHotTubAvailability') {
        newSurvey['jacuzziHotTubAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'General Amenities',
          questionText: 'Jacuzzi/Hot Tub Availability',
          value: values[key],
          intents: ['hot_tub_jacuzzi', 'room_amenities'],
        };
      }
      if (key === 'jacuzziHotTubAvailableInRooms') {
        newSurvey['jacuzziHotTubAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Jacuzzi/Hot Tub Availability',
          questionText: 'Jacuzzi/Hot Tub Availability in some rooms',
          value: values[key],
          intents: ['hot_tub_jacuzzi', 'room_amenities'],

        };
      }

      if (key === 'rollawayBedsAvailability') {
        newSurvey['rollawayBedsAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Roll Away Beds Availability',
          questionText: 'Roll Away Beds Availability',
          value: values[key],
          intents: ['beds'],

        };
      }

      if (key === 'rollawayBedsCost') {
        newSurvey['rollawayBedsCost'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Roll Away Beds Availability',
          questionText: 'Roll Away Beds Cost',
          value: values[key],
          intents: ['beds'],

        };
      }

      if (key === 'rollawayBedsCostValue') {
        newSurvey['rollawayBedsCostValue'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Roll Away Beds Availability',
          questionText: 'Roll Away Beds Cost Value',
          value: values[key],
          intents: ['beds'],

        };
      }

      if (key === 'rollawayBedsCostValueUnit') {
        newSurvey['rollawayBedsCostValueUnit'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Roll Away Beds Availability',
          questionText: 'Roll Away Beds Cost Value Unit',
          value: values[key],
          intents: ['beds'],

        };
      }
      if (key === 'rollawayBedsOnRequest') {
        newSurvey['rollawayBedsOnRequest'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Roll Away Beds Availability',
          questionText: 'Roll Away Beds Available on request',
          value: values[key],
          intents: ['beds'],

        };
      }

      if (key === 'connectingDoorsAvailability') {
        newSurvey['connectingDoorsAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Connecting Doors Availability',
          questionText: 'Connecting Doors Availability',
          value: values[key],
          intents: ['connecting_rooms', 'room_amenities'],

        };
      }

      if (key === 'connectingDoorsAvailableInRooms') {
        newSurvey['connectingDoorsAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Connecting Doors Availability',
          questionText: 'Connecting Doors Availability in some rooms',
          value: values[key],
          intents: ['connecting_rooms', 'room_amenities'],

        };
      }

      if (key === 'smokingAllowedAvailability') {
        newSurvey['smokingAllowedAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Smoking Allowed Availability',
          questionText: 'Smoking Allowed Availability',
          value: values[key],
          intents: ['smoking_policies', 'room_amenities'],

        };
      }

      if (key === 'smokingAllowedAvailableInRooms') {
        newSurvey['smokingAllowedAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Smoking Allowed Availability',
          questionText: 'Smoking Allowed Availability in some rooms',
          value: values[key],
          intents: ['smoking_policies', 'room_amenities'],

        };
      }

      if (key === 'balconyAvailability') {
        newSurvey['balconyAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Balcony Availability',
          questionText: 'Balcony Availability',
          value: values[key],
          intents: ['balcony', 'room_amenities'],

        };
      }

      if (key === 'balconyAvailableInRooms') {
        newSurvey['balconyAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Balcony Availability',
          questionText: 'Balcony Availability in some rooms',
          value: values[key],
          intents: ['balcony', 'room_amenities'],

        };
      }

      if (key === 'bathtubAvailability') {
        newSurvey['bathtubAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Bathtub Availability',
          value: values[key],
          intents: ['bathtub_shower'],
        };
      }
      if (key === 'bathtubAvailableInRooms') {
        newSurvey['bathtubAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Bathtub Availability in some rooms',
          value: values[key],
          intents: ['bathtub_shower'],
        };
      }

      if (key === 'bathtubAvailabilityHandicap') {
        newSurvey['bathtubAvailabilityHandicap'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Handicap Accessibility Bathtub Availability',
          value: values[key],
          intents: ['bathtub_shower', 'accessibility'],
        };
      }
      if (key === 'bathtubAvailableInRoomsHandicap') {
        newSurvey['bathtubAvailableInRoomsHandicap'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Handicap Accessibility Bathtub Availability in some rooms',
          value: values[key],
          intents: ['bathtub_shower', 'accessibility'],
        };
      }

      if (key === 'showerAvailability') {
        newSurvey['showerAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Shower Availability',
          value: values[key],
          intents: ['bathtub_shower'],
        };
      }
      if (key === 'showerAvailableInRooms') {
        newSurvey['showerAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Shower Availability in some rooms',
          value: values[key],
          intents: ['bathtub_shower'],
        };
      }

      if (key === 'showerAvailabilityHandicap') {
        newSurvey['showerAvailabilityHandicap'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Handicap Accessible Shower Availability',
          value: values[key],
          intents: ['bathtub_shower', 'accessibility'],
        };
      }
      if (key === 'showerAvailableInRoomsHandicap') {
        newSurvey['showerAvailableInRoomsHandicap'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Handicap Accessible Shower Availability in some rooms',
          value: values[key],
          intents: ['bathtub_shower', 'accessibility'],
        };
      }

      if (key === 'grabRailsAvailabilityHandicap') {
        newSurvey['grabRailsAvailabilityHandicap'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Handicap Accessible Grab Rails Availability',
          value: values[key],
          intents: ['bathtub_shower', 'accessibility'],
        };
      }
      if (key === 'grabRailsAvailableInRoomsHandicap') {
        newSurvey['grabRailsAvailableInRoomsHandicap'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Handicap Accessible Grab Rails Availability in some rooms',
          value: values[key],
          intents: ['bathtub_shower', 'accessibility'],
        };
      }

      if (key === 'hairDryerAvailability') {
        newSurvey['hairDryerAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Hair Dryer Availability',
          value: values[key],
          intents: ['hair_dryer'],
        };
      }

      // if (key === 'hairDryerOnRequest') {
      //   newSurvey['hairDryerOnRequest'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Bathroom Amenities',
      //     questionText: 'Hair dryer availability on request in some rooms',
      //     value: values[key],
      //     intents: ['hair_dryer'],
      //   };
      // }

      if (key === 'hairDryerAvailableInRooms') {
        newSurvey['hairDryerAvailableInRooms'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Bathroom Amenities',
          questionText: 'Hair Dryer Availability in some rooms',
          value: values[key],
          intents: ['hair_dryer'],
        };
      }

      // toiletries  
      if (key === 'toiletriesAvailableInRooms') {
        newSurvey['toiletriesAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Toiletries Amenities',
          questionText: 'Availability',
          value: values[key],
          intents: ['room_amenities', 'toiletries', 'towels', 'housekeeping'],
        };
      }

      // kitchen amenities  
      if (key === 'refrigeratorAvailability') {
        newSurvey['refrigeratorAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Refrigerator Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'refrigerator'],
        };
      }
      if (key === 'refrigeratorAvailableInRooms') {
        newSurvey['refrigeratorAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Refrigerator Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'refrigerator'],
        };
      }

      if (key === 'coffeemakerAvailability') {
        newSurvey['coffeemakerAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Coffeemaker Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'coffee_maker'],
        };
      }
      if (key === 'coffeemakerAvailableInRooms') {
        newSurvey['coffeemakerAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Coffeemaker Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'coffee_maker'],
        };
      }

      if (key === 'electricKettleAvailability') {
        newSurvey['electricKettleAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Electric Kettle Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'kettle'],
        };
      }
      if (key === 'electricKettleAvailableInRooms') {
        newSurvey['electricKettleAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Electric Kettle Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'kettle'],
        };
      }

      if (key === 'microwaveAvailability') {
        newSurvey['microwaveAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Microwave Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'microwave'],
        };
      }
      if (key === 'microwaveAvailableInRooms') {
        newSurvey['microwaveAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Microwave Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'microwave'],
        };
      }
      // if (key === 'microwaveOnRequest') {
      //   newSurvey['microwaveOnRequest'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Kitchen Amenities',
      //     questionText: 'Microwave Available on request',
      //     value: values[key],
      //     intents: ['room_amenities', 'kitchen', 'microwave'],
      //   };
      // }

      if (key === 'kitchenAvailability') {
        newSurvey['kitchenAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Kitchen Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }
      if (key === 'kitchenAvailableInRooms') {
        newSurvey['kitchenAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Kitchen Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }

      if (key === 'stoveAvailability') {
        newSurvey['stoveAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Stove Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }
      if (key === 'stoveAvailableInRooms') {
        newSurvey['stoveAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Stove Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }

      if (key === 'ovenAvailability') {
        newSurvey['ovenAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Oven Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }
      if (key === 'ovenAvailableInRooms') {
        newSurvey['ovenAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Oven Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }

      if (key === 'dishwasherAvailability') {
        newSurvey['dishwasherAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Dishwasher Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }
      if (key === 'dishwasherAvailableInRooms') {
        newSurvey['dishwasherAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Dishwasher Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }

      if (key === 'cutleryAvailability') {
        newSurvey['cutleryAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Cutlery Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }
      if (key === 'cutleryAvailableInRooms') {
        newSurvey['cutleryAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Cutlery Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }
      // if (key === 'cutleryOnRequest') {
      //   newSurvey['cutleryOnRequest'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Kitchen Amenities',
      //     questionText: 'Cutlery Available on request',
      //     value: values[key],
      //     intents: ['room_amenities', 'kitchen'],
      //   };
      // }

      if (key === 'cookwareAvailability') {
        newSurvey['cookwareAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Cookware Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }

      if (key === 'cookwareAvailableInRooms') {
        newSurvey['cookwareAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Cookware Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }
      // if (key === 'cookwareOnRequest') {
      //   newSurvey['cookwareOnRequest'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Kitchen Amenities',
      //     questionText: 'Cookware Available on request',
      //     value: values[key],
      //     intents: ['room_amenities', 'kitchen'],
      //   };
      // }

      if (key === 'toasterAvailability') {
        newSurvey['toasterAvailability'] = {
          variableType: 'all-some-none',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Toaster Availability',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }

      if (key === 'toasterAvailableInRooms') {
        newSurvey['toasterAvailableInRooms'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Toaster Availability in some rooms',
          value: values[key],
          intents: ['room_amenities', 'kitchen'],
        };
      }
      // if (key === 'toasterOnrequest') {
      //   newSurvey['toasterOnrequest'] = {
      //     variableType: 'boolean',
      //     isNote: false,
      //     heading: 'Kitchen Amenities',
      //     questionText: 'Toaster Available on request',
      //     value: values[key],
      //     intents: ['room_amenities', 'kitchen'],
      //   };
      // }

      if (key === 'hasPublicMicrowave') {
        newSurvey['hasPublicMicrowave'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Kitchen Amenities',
          questionText: 'Is there a microwave in the restaurant or public area for guests to warm up their food?',
          value: values[key],
          intents: ['room_amenities', 'kitchen', 'microwave'],
        };
      }

      // addtional services
      if (key === 'aServiceAvailableATM') {
        newSurvey['aServiceAvailableATM'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Additional Services',
          questionText: 'ATM',
          value: values[key],
          intents: ['atm_currency_exchange'],
        };
      }

      if (key === 'aServiceAvailableCurrencyExhange') {
        newSurvey['aServiceAvailableCurrencyExhange'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Additional Services',
          questionText: 'Currency Exhange',
          value: values[key],
          intents: ['atm_currency_exchange'],
        };
      }

      if (key === 'aServiceAvailableLaundry') {
        newSurvey['aServiceAvailableLaundry'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Additional Services',
          questionText: 'Laundry',
          value: values[key],
          intents: ['laundry', 'facilities'],
        };
      }

      if (key === 'aServiceAvailableLaundryAt') {
        newSurvey['aServiceAvailableLaundryAt'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Additional Services',
          questionText: 'Laundry - At Hotel',
          value: values[key],
          intents: ['laundry'],
        };
      }

      if (key === 'aServiceAvailableLaundryNearby') {
        newSurvey['aServiceAvailableLaundryNearby'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Additional Services',
          questionText: 'Laundry - Nearby Hotel',
          value: values[key],
          intents: ['laundry'],
        };
      }

      // airport and transportation
      if (key === 'airportTransportationName') {
        newSurvey['airportTransportationName'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Preferred Airport Name',
          value: values[key],
          intents: ['airports', 'location_&_directions'],
        };
      }
      if (key === 'airportGoogleMapsLink') {
        newSurvey['airportGoogleMapsLink'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Google maps link Airport',
          value: values[key],
          intents: ['airports', 'location_&_directions'],
        };
      }

      if (key === 'hasAirportTransportation') {
        newSurvey['hasAirportTransportation'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Has Airport and Transportation',
          value: values[key],
          intents: ['airports', 'airport_transfer'],
        };
      }

      if (key === 'airportNote') {
        newSurvey['airportNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Airport and Transportation',
          questionText: 'Note',
          value: values[key],
          intents: ['airports', 'airport_transfer'],
        };
      }

      if (key === 'airportTransportationDistance') {
        newSurvey['airportTransportationDistance'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Distance from airport to property',
          value: values[key],
          intents: ['airports', 'airport_transfer', 'location_&_directions'],
        };
      }

      if (key === 'airportTransportationOptions') {
        newSurvey['airportTransportationOptions'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Transport options available from the airport to the property',
          value: values[key],
          intents: ['airport_transfer', 'transport_options', 'uber_lyft'],
        };
      }

      if (key === 'airportTransportationTaxi') {
        newSurvey['airportTransportationTaxi'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Transport options available from the airport to the property - Taxi',
          value: values[key],
          intents: ['airport_transfer', 'transport_options', 'taxi'],
        };
      }

      if (key === 'airportTransportationTaxiPrice') {
        newSurvey['airportTransportationTaxiPrice'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Estimated price for taxi from the airport to the property',
          value: values[key],
          intents: ['airport_transfer', 'transport_options', 'taxi'],
        };
      }

      if (key === 'airportTransportationShuttle') {
        newSurvey['airportTransportationShuttle'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Transport options available from the airport to the property - Shuttle',
          value: values[key],
          intents: ['airport_transfer', 'transport_options'],
        };
      }

      if (key === 'airportTransportationShuttlePrice') {
        newSurvey['airportTransportationShuttlePrice'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Shuttle free or surcharge',
          value: values[key],
          intents: ['airport_transfer', 'transport_options'],
        };
      }

      if (key === 'airportTransportationShuttlePriceUnit') {
        newSurvey['airportTransportationShuttlePriceUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Shuttle surcharge unit',
          value: values[key],
          intents: ['airport_transfer', 'transport_options'],
        };
      }

      if (key === 'airportTransportationShuttlePriceValue') {
        newSurvey['airportTransportationShuttlePriceValue'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Airport and Transportation',
          questionText: 'Shuttle surcharge amount (USD)',
          value: values[key],
          intents: ['airport_transfer', 'transport_options'],
        };
      }


      if (key === 'hasElectricalAdapters') {
        newSurvey['hasElectricalAdapters'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Electrical adapters',
          questionText: 'Electrical Adapters/Chargers Availability in some rooms adapter types',
          value: values[key],
          intents: ['adapters_voltage'],
        };
      }

      if (key === 'electricalNote') {
        newSurvey['electricalNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Electrical Adapters',
          questionText: 'Note',
          value: values[key],
          intents: ['adapters_voltage'],
        };
      }

      if (key === 'electricalAdaptersChargers') {
        newSurvey['electricalAdaptersChargers'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Electrical adapters',
          questionText: 'Electrical Adapters/Chargers Availability in some rooms adapter types',
          value: values[key],
          intents: ['adapters_voltage'],
        };
      }
      if (key === 'electricalAdaptersChargersOnRequest') {
        newSurvey['electricalAdaptersChargersOnRequest'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Electrical adapters',
          questionText: 'Electrical Adapters/Chargers on request',
          value: values[key],
          intents: ['adapters_voltage'],
        };
      }

      // daytime activities available at the hotel or nearby
      if (key === 'daytimeActivityNote') {
        newSurvey['daytimeActivityNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Daytime Activities',
          questionText: 'Note',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'pointsOfInterestNote') {
        newSurvey['pointsOfInterestNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Points of Interest',
          questionText: 'Note',
          value: values[key],
          intents: ['activities', 'tours'],
        };
      }

      if (key === 'dayActivityTennis') {
        newSurvey['dayActivityTennis'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Tennis available at the hotel or nearby',
          value: values[key],
          intents: ['activities', 'tennis'],
        };
      }

      if (key === 'dayActivityTennisIndoor') {
        newSurvey['dayActivityTennisIndoor'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Tennis - Indoor Courts',
          questionText: 'Tennis indoor courts available',
          value: values[key],
          intents: ['activities', 'tennis'],
        };
      }

      if (key === 'dayActivityTennisIndoorNumberOfCourts') {
        newSurvey['dayActivityTennisIndoorNumberOfCourts'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Tennis - Indoor Courts',
          questionText: 'Number of indoor tennis courts available',
          value: values[key],
          intents: ['activities', 'tennis'],
        };
      }

      if (key === 'dayActivityTennisOutdoor') {
        newSurvey['dayActivityTennisOutdoor'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Tennis outdoor courts available',
          value: values[key],
          intents: ['activities', 'tennis'],
        };
      }

      if (key === 'dayActivityTennisExtras') {
        newSurvey['dayActivityTennisExtras'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'What extras is available for playing tennis?',
          value: values[key],
          intents: ['activities', 'tennis'],
        };
      }

      if (key === 'dayActivityTennisOutdoorNumberOfCourts') {
        newSurvey['dayActivityTennisOutdoorNumberOfCourts'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Tennis - Outdoor Courts',
          questionText: 'Number of Outdoor tennis courts available',
          value: values[key],
          intents: ['activities', 'tennis'],
        };
      }

      if (key === 'dayActivityGolf') {
        newSurvey['dayActivityGolf'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities - Golf',
          questionText: 'Golf available0 at the hotel or nearby',
          value: values[key],
          intents: ['activities', 'golf'],
        };
      }

      if (key === 'dayActivityGolfNumberOfHoles') {
        newSurvey['dayActivityGolfNumberOfHoles'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Daytime Activities - Golf',
          questionText: 'Number of holes',
          value: values[key],
          intents: ['activities', 'golf'],
        };
      }

      if (key === 'dayActivityGolfExtras') {
        newSurvey['dayActivityGolfExtras'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Daytime Activities - Golf',
          questionText: 'Extras',
          value: values[key],
          intents: ['activities', 'golf'],
        };
      }

      if (key === 'dayActivitySnorkeling') {
        newSurvey['dayActivitySnorkeling'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Snorkeling available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityKayaking') {
        newSurvey['dayActivityKayaking'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Kayaking available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityDiving') {
        newSurvey['dayActivityDiving'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Diving available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityWindsurfing') {
        newSurvey['dayActivityWindsurfing'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Windsurfing available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityCanoeing') {
        newSurvey['dayActivityCanoeing'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Canoeing available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivitySailing') {
        newSurvey['dayActivitySailing'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Sailing available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityFishing') {
        newSurvey['dayActivityFishing'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Fishing available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityVolleyball') {
        newSurvey['dayActivityVolleyball'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Volleyball available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityBasketball') {
        newSurvey['dayActivityBasketball'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Basketball available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityRacquetball') {
        newSurvey['dayActivityRacquetball'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Racquetball available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityBadminton') {
        newSurvey['dayActivityBadminton'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Badminton available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityAerobics') {
        newSurvey['dayActivityAerobics'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Aerobics available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityBicycling') {
        newSurvey['dayActivityBicycling'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Bicycling available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityHiking') {
        newSurvey['dayActivityHiking'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Hiking available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityHorsebackRiding') {
        newSurvey['dayActivityHorsebackRiding'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Horseback Riding available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityArchery') {
        newSurvey['dayActivityArchery'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Archery available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityMiniGolf') {
        newSurvey['dayActivityMiniGolf'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Mini Golf available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityDarts') {
        newSurvey['dayActivityDarts'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Darts available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityPingPong') {
        newSurvey['dayActivityPingPong'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Ping Pong available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityPoolTable') {
        newSurvey['dayActivityPoolTable'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Pool Table available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityBoating') {
        newSurvey['dayActivityBoating'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Boating available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivitySkiing') {
        newSurvey['dayActivitySkiing'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Skiing available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityCookingClass') {
        newSurvey['dayActivityCookingClass'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Cooking Class available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityWaterPark') {
        newSurvey['dayActivityWaterPark'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Water Park available at property or nearby',
          value: values[key],
          intents: ['activities'],
        };
      }

      if (key === 'dayActivityGroceryShopping') {
        newSurvey['dayActivityGroceryShopping'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Grocery Shopping available at property or nearby',
          value: values[key],
          intents: ['activities', 'shopping'],
        };
      }

      if (key === 'dayActivityShoppingMall') {
        newSurvey['dayActivityShoppingMall'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Shopping Mall/Stores available at property or nearby',
          value: values[key],
          intents: ['activities', 'shopping'],
        };
      }

      if (key === 'dayActivityWaterSkiing') {
        newSurvey['dayActivityWaterSkiing'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Water Skiing available at property or nearby',
          value: values[key],
          intents: ['activities', 'shopping'],
        };
      }

      if (key === 'dayActivityJetSkiing') {
        newSurvey['dayActivityJetSkiing'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Jet Skiing available at property or nearby',
          value: values[key],
          intents: ['activities', 'shopping'],
        };
      }

      if (key === 'dayActivityPaddleBoarding') {
        newSurvey['dayActivityPaddleBoarding'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Daytime Activities',
          questionText: 'Paddle Boarding available at property or nearby',
          value: values[key],
          intents: ['activities', 'shopping'],
        };
      }

      // nighttime activities
      if (key === 'nighttimeActivityNote') {
        newSurvey['nighttimeActivityNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Nighttime Activities',
          questionText: 'Note',
          value: values[key],
          intents: ['activities', 'nightlife'],
        };
      }

      if (key === 'nightActivities') {
        newSurvey['nightActivities'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Nighttime Activities',
          questionText: 'Choose the nighttime activities that are available at the hotel?',
          value: values[key],
          intents: ['activities', 'nightlife'],
        };
      }

      // children's activities
      if (key === 'childrenActivityNote') {
        newSurvey['childrenActivityNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Children\'s  Activities',
          questionText: 'Note',
          value: values[key],
          intents: ['activities', 'activities_for_children'],
        };
      }

      if (key === 'hasBabysitting') {
        newSurvey['hasBabysitting'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Babysitting',
          questionText: 'Babysitting available at the hotel',
          value: values[key],
          intents: ['activities_for_children', 'babysitting'],
        };
      }

      if (key === 'babysittingNote') {
        newSurvey['babysittingNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Babysitting',
          questionText: 'Note',
          value: values[key],
          intents: ['activities_for_children', 'babysitting'],
        };
      }

      if (key === 'childrensActivityBabysittingPrice') {
        newSurvey['childrensActivityBabysittingPrice'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Babysitting',
          questionText: 'Babysitting price',
          value: values[key],
          intents: ['activities_for_children', 'babysitting'],
        };
      }

      if (key === 'childrensActivityBabysittingPriceValue') {
        newSurvey['childrensActivityBabysittingPriceValue'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Babysitting',
          questionText: 'Babysitting price value (USD)',
          value: values[key],
          intents: ['activities_for_children', 'babysitting'],
        };
      }

      if (key === 'childrensActivityBabysittingPriceValueUnit') {
        newSurvey['childrensActivityBabysittingPriceValueUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Babysitting',
          questionText: 'Babysitting price value (USD) unit',
          value: values[key],
          intents: ['activities_for_children', 'babysitting'],
        };
      }

      if (key === 'childrensActivity') {
        newSurvey['childrensActivity'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Children\'s Activities',
          questionText: 'Children\'s activities available at the hotel',
          value: values[key],
          intents: ['activities_for_children'],
        };
      }


      // payment methods
      if (key === 'noShowPolicyNote') {
        newSurvey['noShowPolicyNote'] = {
          variableType: 'string',
          isNote: true,
          heading: 'Action flags for Payment Methods',
          questionText: 'Note',
          value: values[key],
          intents: ['payment_types'],
        };
      }

      if (key === 'paymentMethods') {
        newSurvey['paymentMethods'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Which payment methods do you accept?',
          value: values[key],
          intents: ['payment_types'],
        };
      }

      if (key === 'paymentMethodsCreditDebitCard') {
        newSurvey['paymentMethodsCreditDebitCard'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Which credit and debit cards do you accept?',
          value: values[key],
          intents: ['payment_types'],
        };
      }

      if (key === 'paymentMethodsRequireWhen') {
        newSurvey['paymentMethodsRequireWhen'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'When do you require payment for accommodations?',
          value: values[key],
          intents: ['payment_policy', 'check_in', 'check_out', 'deposits_installments'],
        };
      }

      if (key === 'paymentMethodsIsCreditDebitCardRequired') {
        newSurvey['paymentMethodsIsCreditDebitCardRequired'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'During the check-in process is a credit/debit card required?',
          value: values[key],
          intents: ['payment_policy', 'check_in'],
        };
      }

      if (key === 'paymentMethodsIsDepositRequired') {
        newSurvey['paymentMethodsIsDepositRequired'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'During the check-in process is a deposit for incidental charges required?',
          value: values[key],
          intents: ['payment_policy', 'deposits_installments', 'check_in'],
        };
      }

      if (key === 'paymentMethodsIsDepositRequiredAmount') {
        newSurvey['paymentMethodsIsDepositRequiredAmount'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'During the check-in process what is the amount for deposit of incidental charges?',
          value: values[key],
          intents: ['payment_policy', 'deposits_installments', 'check_in'],
        };
      }

      if (key === 'paymentMethodsIsDepositRequiredPriorArrival') {
        newSurvey['paymentMethodsIsDepositRequiredPriorArrival'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Is a deposit required prior to arrival?',
          value: values[key],
          intents: ['payment_policy', 'deposits_installments', 'check_in'],
        };
      }

      if (key === 'paymentMethodsIsDepositRequiredValuePriorArrival') {
        newSurvey['paymentMethodsIsDepositRequiredValuePriorArrival'] = {
          variableType: 'number',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Deposit amount (USD) required prior to arrival?',
          value: values[key],
          intents: ['payment_policy', 'deposits_installments', 'check_in'],
        };
      }

      if (key === 'priorArrivalWhen') {
        newSurvey['priorArrivalWhen'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'When is the balance of the deposit amount (USD) required prior to arrival due? Specify the time.',
          value: values[key],
          intents: ['payment_policy', 'deposits_installments', 'check_in'],
        };
      }

      if (key === 'specialsText') {
        newSurvey['specialsText'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Specials',
          questionText: 'Specials Text',
          value: values[key],
          intents: ['specials_packages'],
        };
      }

      if (key === 'specialsUrls') {
        newSurvey['specialsUrls'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Specials',
          questionText: {
            link: 'Specials Link'
          },
          value: values[key],
          intents: ['specials_packages'],
        };
      }

      if (key === 'hasPaymentSpecials') {
        newSurvey['hasPaymentSpecials'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Has specials?',
          value: values[key],
          intents: ['specials_packages'],
        };
      }

      if (key === 'weddingsText') {
        newSurvey['weddingsText'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Weddings',
          questionText: 'Text',
          value: values[key],
          intents: ['weddings'],
        };
      }

      if (key === 'weddingsUrls') {
        newSurvey['weddingsUrls'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Weddings',
          questionText: {
            link: 'Wedding link'
          },
          value: values[key],
          intents: ['weddings'],
        };
      }

      if (key === 'paymentMethodsHasLoyaltyProgram') {
        newSurvey['paymentMethodsHasLoyaltyProgram'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Do you have a loyalty or rewards program?',
          value: values[key],
          intents: ['rewards_program'],
        };
      }

      if (key === 'paymentMethodsHasLoyaltyProgramLink') {
        newSurvey['paymentMethodsHasLoyaltyProgramLink'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Please share a link for details of your loyalty program?',
          value: values[key],
          intents: ['rewards_program'],
        };
      }

      if (key === 'paymentNightRatesUnit') {
        newSurvey['paymentNightRatesUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Are you nightly rates per person or per room?',
          value: values[key],
          intents: ['price_per_room_or_per_person'],
        };
      }
      if (key === 'paymentNightRatesPerRoomOcc') {
        newSurvey['paymentNightRatesPerRoomOcc'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Based on double occupancy with additional charges for persons or based on the maximum occupancy of the room?',
          value: values[key],
          intents: ['price_per_room_or_per_person'],
        };
      }

      if (key === 'paymentNightRatesPerRoomExtraOcc') {
        newSurvey['paymentNightRatesPerRoomExtraOcc'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Based on double occupancy with additional charges for persons or based on the maximum occupancy of the room?',
          value: values[key],
          intents: ['price_per_room_or_per_person'],
        };
      }
      if (key === 'paymentNightRatesPerRoomExtraUnit') {
        newSurvey['paymentNightRatesPerRoomExtraUnit'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Based on double occupancy with additional charges for persons or based on the maximum occupancy of the room?',
          value: values[key],
          intents: ['price_per_room_or_per_person'],
        };
      }

      if (key === 'canSomeoneElsePayForBooking') {
        newSurvey['canSomeoneElsePayForBooking'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'Can someone else pay for a guest\'s booking by completing a credit card authorization form?',
          value: values[key],
          intents: ['booking_for_someone_else', 'payment_policy', 'payment_types'],
        };
      }

      if (key === 'bookingDirectBenefits') {
        newSurvey['bookingDirectBenefits'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Payment Methods',
          questionText: 'List of benefits of booking direct:',
          value: values[key],
          intents: ['book_direct'],
        };
      }

      if (key === 'contactConfirmReservation') {
        newSurvey['contactConfirmReservation'] = {
          variableType: 'string',
          isNote: false,
          heading: 'How would you prefer to be contacted by guests who would like to:',
          questionText: 'Confirm a Reservation',
          value: values[key],
          intents: ['confirm_booking'],

        };
      }

      if (key === 'contactExtendShortenReservation') {
        newSurvey['contactExtendShortenReservation'] = {
          variableType: 'string',
          isNote: false,
          heading: 'How would you prefer to be contacted by guests who would like to:',
          questionText: 'Extend/Shorten a Reservation',
          value: values[key],
          intents: ['change_reservation'],

        };
      }

      if (key === 'contactMakeComplaint') {
        newSurvey['contactMakeComplaint'] = {
          variableType: 'string',
          isNote: false,
          heading: 'How would you prefer to be contacted by guests who would like to:',
          questionText: 'Make a Complaint',
          value: values[key],
          intents: ['complaints'],

        };
      }

      if (key === 'contactMakeSpecialRequest') {
        newSurvey['contactMakeSpecialRequest'] = {
          variableType: 'string',
          isNote: false,
          heading: 'How would you prefer to be contacted by guests who would like to:',
          questionText: 'Make a Special Request',
          value: values[key],
          intents: ['requests'],

        };
      }

      if (key === 'contactMakeGroupBooking') {
        newSurvey['contactMakeGroupBooking'] = {
          variableType: 'string',
          isNote: false,
          heading: 'How would you prefer to be contacted by guests who would like to:',
          questionText: 'Make Group Booking',
          value: values[key],
          intents: ['requests', 'group_booking'],

        };
      }

      if (key === 'contactMakeLongTermStay') {
        newSurvey['contactMakeLongTermStay'] = {
          variableType: 'string',
          isNote: false,
          heading: 'How would you prefer to be contacted by guests who would like to:',
          questionText: 'Make Long Term Stay',
          value: values[key],
          intents: ['requests'],

        };
      }
      if (key === 'contactPayForSomeoneElse') {
        newSurvey['contactPayForSomeoneElse'] = {
          variableType: 'string',
          isNote: false,
          heading: 'How would you prefer to be contacted by guests who would like to:',
          questionText: 'Pay for someone else s booking',
          value: values[key],
          intents: ['booking_for_someone_else'],
        };
      }


      // cancellation or no-show      
      if (key === 'cancelFreeOfCharge') {
        newSurvey['cancelFreeOfCharge'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Cancellation and NO SHOW policy? (You may have a link with all those details.)',
          questionText: 'Is there a period when the guest can cancel free of charge?',
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }

      if (key === 'cancellationChargeAfterGracePeriod') {
        newSurvey['cancellationChargeAfterGracePeriod'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Cancellation and NO SHOW policy? (You may have a link with all those details.)',
          questionText: 'How much will the guest be charged for cancelling after the grace period?',
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }

      if (key === 'cancellationLink') {
        newSurvey['cancellationLink'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Cancellation and NO SHOW policy? (You may have a link with all those details.)',
          questionText: 'External link',
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }

      if (key === 'paymentNoShowCharge') {
        newSurvey['paymentNoShowCharge'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Cancellation and NO SHOW policy? (You may have a link with all those details.)',
          questionText: 'Is there a charge for a No_show?',
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }


      if (key === 'noShowGuestCharge') {
        newSurvey['noShowGuestCharge'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Cancellation and NO SHOW policy? (You may have a link with all those details.)',
          questionText: 'How much is the guest charged for a no-show?',
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }

      if (key === 'cancellationGraceDefinition') {
        newSurvey['cancellationGraceDefinition'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Is there a grace period when guests can cancel without penalty?',
          questionText: 'What is that grace period? - There is no charge if cancelled: ',
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }

      if (key === 'cancellationPenaltyGrace') {
        newSurvey['cancellationPenaltyGrace'] = {
          variableType: 'array',
          isNote: false,
          heading: 'What are the penalties after the grace period?',
          questionText: {
            cancellationPenaltyGraceDefinition: 'If cancelled:',
            cancellationChargeAfterGracePeriod: 'Then will be charged:',
          },
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }

      if (key === 'noCancellationPenaltyGrace') {
        newSurvey['noCancellationPenaltyGrace'] = {
          variableType: 'array',
          isNote: false,
          heading: 'What are the penalties for cancellation?',
          questionText: {
            noCancellationPenaltyGraceDefinition: 'If cancelled:',
            noCancellationChargeAfterGracePeriod: 'Then will be charged:',
          },
          value: values[key],
          intents: ['cancellation_no_show_policies'],
        };
      }

      // places of iterests
      if (key === 'placesOfInterest') {
        newSurvey['placesOfInterest'] = {
          variableType: 'array',
          isNote: false,
          heading: 'Points of Interest',
          questionText: {
            placesOfInterest: 'Name and Photo-URL',
            placesOfInterestName: 'Place of interest name',
            placesOfInterestPhotoUrl: 'Place of interest photo URL',
          },
          value: values[key],
          intents: ['activities', 'tours'],
        };
      }

      // covid

      if (key === 'covidStandardCleaning') {
        newSurvey['covidStandardCleaning'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Covid',
          questionText: 'Do you follow standard cleaning practices recommended by an industry association?',
          value: values[key],
          intents: ['covid19'],
        };
      }

      if (key === 'covidStandardCleaningProtocals') {
        newSurvey['covidStandardCleaningProtocals'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Covid - Do you follow standard cleaning practices recommended by an industry association?',
          questionText: 'Which protocals do you follow?',
          value: values[key],
          intents: ['covid19'],
        };
      }


      if (key === 'covidGuestsWearMasks') {
        newSurvey['covidGuestsWearMasks'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Covid',
          questionText: 'Do you require guests to wear masks in public areas?',
          value: values[key],
          intents: ['covid19'],
        };
      }
      if (key === 'covidVaccinationRequired') {
        newSurvey['covidVaccinationRequired'] = {
          variableType: 'boolean',
          isNote: false,
          heading: 'Covid',
          questionText: 'Are guests required to be vaccinated?',
          value: values[key],
          intents: ['covid19'],
        };
      }
      if (key === 'covidOtherRestrictions') {
        newSurvey['covidOtherRestrictions'] = {
          variableType: 'string',
          isNote: false,
          heading: 'Covid',
          questionText: 'Are guests required to be vaccinated?',
          value: values[key],
          intents: ['covid19'],
        };
      }

      

      // added by functions from the Room Categories
      if (key === 'numberBathroomsByRoom') {
        newSurvey['numberBathroomsByRoom'] = {
          variableType: 'arrayByRoom',
          isNote: false,
          heading: 'Bathroom',
          questionText: 'Number of Bathrooms',
          value: values[key],
          intents: ['bathtub_shower'],
        };
      }
      if (key === 'roomViewByRoom') {
        newSurvey['roomViewByRoom'] = {
          variableType: 'arrayByRoom',
          isNote: false,
          heading: 'Room View',
          questionText: 'Room View',
          value: values[key],
          intents: ['room_view', 'balcony'],
        };
      }
      if (key === 'bedsByRoom') {
        newSurvey['bedsByRoom'] = {
          variableType: 'arrayByRoom',
          isNote: false,
          heading: 'Beds',
          questionText: 'Beds by Room',
          value: values[key],
          intents: ['beds'],
        };
      }
      if (key === 'maxOccupancyByRoom') {
        newSurvey['maxOccupancyByRoom'] = {
          variableType: 'arrayByRoom',
          isNote: false,
          heading: 'Max Occupancy',
          questionText: 'Max Occupancy by Room',
          value: values[key],
          intents: ['maximum_occupancy'],
        };
      }


    });

    return newSurvey;
  }

  ngOnDestroy() {}

}




