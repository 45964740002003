<div *ngFor="let room of roomCategories; let i = index;" class="room-row" >
    <nb-checkbox [checked]="getCheckedStatus(room.roomName)" (checkedChange)="onSelectedValue($event, i)">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="room-name">
                    <img [src]="room.roomPhotoUrl" class="room-avatar">
                    <strong style="margin-left: 15px;">{{ room.roomName }}</strong>
                </div>
            </div>
        </div>
    </nb-checkbox>
</div>