<nb-card>
    <nb-card-body>
        <div class="row">
            <div class="col-md-12" style="text-align: center; margin-bottom: 30px;">
                <span style="font-size: 22px; font-weight: bold;">What should happen if the bot can't answer?</span>
                <span style="font-size: 16px; font-weight: 400; display: block; margin-top: 8px;">Please choose as many as possible</span>
            </div>
        </div>
        <form [formGroup]="itemForm"> 
            <div class="row" formGroupName="botSettings" style="margin: auto; max-width: 580px;">
                <div class="col-12">
                    <div class="my-form-group">
                        <nb-checkbox #liveChatAvailable formControlName="liveChatAvailable" style="font-size: 14px;">
                            <span class="live-chat-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" style="width: 16px;" viewBox="0 0 512 512" ><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z"/></svg>
                            </span>
                            Front Desk take over
                        </nb-checkbox>
                        <div *ngIf="liveChatAvailable.checked" style="margin-left: 22px;">
                            <div>
                                <nb-radio-group formControlName="basedOn">
                                    <nb-radio value="Based on online status">Based on online status</nb-radio>
                                    <nb-radio value="Based on business hours">Based on business hours</nb-radio>
                                  </nb-radio-group>
                            </div>
                            <div *ngIf="itemForm.get('botSettings').get('basedOn').value === 'Based on business hours'">
                                <div style="overflow-x: auto; width: 100%;">
                                    <ngx-schedule formControlName="businessHours"></ngx-schedule>
                                </div>
                            </div>
                            <div style="margin-top: 15px" *ngIf="liveChatAvailable.checked && itemForm.get('botSettings').get('basedOn').value === 'Based on business hours'">
                                <label class="label" style="margin-bottom: 10px;">Please select your timezone</label>
                                <ng-moment-timezone-picker [getUserZone]="true" formControlName="timezone"></ng-moment-timezone-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="fallbackCommunication" style="margin: auto; max-width: 580px;">
                <div class="col-12">
                    <div class="my-form-group">
                        <nb-checkbox #hasCallUs formControlName="hasCallUs">
                            <span class="live-chat-icon">
                                <svg aria-hidden="true" style="width: 16px;" viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z">
                                    </path>
                                </svg>
                            </span>
                            Call Us
                        </nb-checkbox>
                        <div *ngIf="hasCallUs.checked" class="container">
                            <input type="text" nbInput style="width: 380px;" placeholder="Enter contact number"
                                formControlName="callUsNumber">
                        </div>
                    </div>
                    <div class="my-form-group">
                        <nb-checkbox #hasWhatsapp formControlName="hasWhatsapp">
                            <span class="live-chat-icon">
                                <svg viewBox="0 0 1219.547 1225.016" style="width: 20px; ">
                                    <path fill="#E0E0E0"
                                        d="M1041.858 178.02C927.206 63.289 774.753.07 612.325 0 277.617 0 5.232 272.298 5.098 606.991c-.039 106.986 27.915 211.42 81.048 303.476L0 1225.016l321.898-84.406c88.689 48.368 188.547 73.855 290.166 73.896h.258.003c334.654 0 607.08-272.346 607.222-607.023.056-162.208-63.052-314.724-177.689-429.463zm-429.533 933.963h-.197c-90.578-.048-179.402-24.366-256.878-70.339l-18.438-10.93-191.021 50.083 51-186.176-12.013-19.087c-50.525-80.336-77.198-173.175-77.16-268.504.111-278.186 226.507-504.503 504.898-504.503 134.812.056 261.519 52.604 356.814 147.965 95.289 95.36 147.728 222.128 147.688 356.948-.118 278.195-226.522 504.543-504.693 504.543z" />
                                    <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="609.77" y1="1190.114" x2="609.77" y2="21.084">
                                        <stop offset="0" stop-color="#20b038" />
                                        <stop offset="1" stop-color="#60d66a" />
                                    </linearGradient>
                                    <path fill="url(#a)"
                                        d="M27.875 1190.114l82.211-300.18c-50.719-87.852-77.391-187.523-77.359-289.602.133-319.398 260.078-579.25 579.469-579.25 155.016.07 300.508 60.398 409.898 169.891 109.414 109.492 169.633 255.031 169.57 409.812-.133 319.406-260.094 579.281-579.445 579.281-.023 0 .016 0 0 0h-.258c-96.977-.031-192.266-24.375-276.898-70.5l-307.188 80.548z" />
                                    <image overflow="visible" opacity=".08" width="682" height="639" xlink:href="FCC0802E2AF8A915.png"
                                        transform="translate(270.984 291.372)" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFF"
                                        d="M462.273 349.294c-11.234-24.977-23.062-25.477-33.75-25.914-8.742-.375-18.75-.352-28.742-.352-10 0-26.25 3.758-39.992 18.766-13.75 15.008-52.5 51.289-52.5 125.078 0 73.797 53.75 145.102 61.242 155.117 7.5 10 103.758 166.266 256.203 226.383 126.695 49.961 152.477 40.023 179.977 37.523s88.734-36.273 101.234-71.297c12.5-35.016 12.5-65.031 8.75-71.305-3.75-6.25-13.75-10-28.75-17.5s-88.734-43.789-102.484-48.789-23.75-7.5-33.75 7.516c-10 15-38.727 48.773-47.477 58.773-8.75 10.023-17.5 11.273-32.5 3.773-15-7.523-63.305-23.344-120.609-74.438-44.586-39.75-74.688-88.844-83.438-103.859-8.75-15-.938-23.125 6.586-30.602 6.734-6.719 15-17.508 22.5-26.266 7.484-8.758 9.984-15.008 14.984-25.008 5-10.016 2.5-18.773-1.25-26.273s-32.898-81.67-46.234-111.326z" />
                                    <path fill="#FFF"
                                        d="M1036.898 176.091C923.562 62.677 772.859.185 612.297.114 281.43.114 12.172 269.286 12.039 600.137 12 705.896 39.633 809.13 92.156 900.13L7 1211.067l318.203-83.438c87.672 47.812 186.383 73.008 286.836 73.047h.255.003c330.812 0 600.109-269.219 600.25-600.055.055-160.343-62.328-311.108-175.649-424.53zm-424.601 923.242h-.195c-89.539-.047-177.344-24.086-253.93-69.531l-18.227-10.805-188.828 49.508 50.414-184.039-11.875-18.867c-49.945-79.414-76.312-171.188-76.273-265.422.109-274.992 223.906-498.711 499.102-498.711 133.266.055 258.516 52 352.719 146.266 94.195 94.266 146.031 219.578 145.992 352.852-.118 274.999-223.923 498.749-498.899 498.749z" />
                                </svg>
                            </span>
                            Enable Whatsapp Chat
                        </nb-checkbox>
                        <div *ngIf="hasWhatsapp.checked" class="container">
                            <input type="text" nbInput style="width: 380px;" placeholder="Enter URL here"
                                formControlName="whatsappUrl">
                        </div>
                    </div>
                    <div class="my-form-group">
                        <nb-checkbox #hasfbMessenger formControlName="hasfbMessenger">
                            <span class="live-chat-icon">
                                <svg style="width: 21px; " viewBox="-149.1 -248.49675 1292.2 1490.9805" class="live-chat-icon"
                                    id="fb">
                                    <g transform="translate(-15 -11.5)">
                                        <linearGradient y2="1005.5" x2="512" y1="11.5" x1="512" gradientUnits="userSpaceOnUse" id="b">
                                            <stop offset="0" stop-color="#00b2ff" />
                                            <stop offset="1" stop-color="#006aff" />
                                        </linearGradient>
                                        <path
                                            d="M512 11.5c-280 0-497 205.1-497 482.1 0 144.9 59.4 270.1 156.1 356.6 8.1 7.3 13 17.4 13.4 28.3l2.7 88.4c.9 28.2 30 46.5 55.8 35.2l98.6-43.5c8.4-3.7 17.7-4.4 26.5-2 45.3 12.5 93.6 19.1 143.9 19.1 280 0 497-205.1 497-482.1S792 11.5 512 11.5z"
                                            fill="url(#b)" fill-rule="evenodd" clip-rule="evenodd" />
                                        <path
                                            d="M213.6 634.6l146-231.6c23.2-36.8 73-46 107.8-19.9l116.1 87.1c10.7 8 25.3 7.9 35.9-.1l156.8-119c20.9-15.9 48.3 9.2 34.2 31.4L664.5 614c-23.2 36.8-73 46-107.8 19.9l-116.1-87.1c-10.7-8-25.3-7.9-35.9.1L247.8 666c-20.9 15.9-48.3-9.2-34.2-31.4z"
                                            fill-rule="evenodd" clip-rule="evenodd" fill="#fff" />
                                    </g>
                                </svg>
                            </span>
                            Enable Facebook Messenger Chat
                        </nb-checkbox>
                        <div *ngIf="hasfbMessenger.checked" class="container">
                            <input type="text" nbInput style="width: 380px;" placeholder="Enter URL here"
                                formControlName="fbUrl">
                        </div>
                    </div>
                    <div class="my-form-group">
                        <nb-checkbox formControlName="hasReceiveCallBack">
                            <span class="live-chat-icon">
                                <svg viewBox="0 0 512 512" style="width: 16px">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                            </span>
                            Receive Call Back
                        </nb-checkbox>
                    </div>
                    <!-- <div class="my-form-group">
                        <nb-checkbox formControlName="hasMessageUs">
                            <span class="live-chat-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 16px">
                                    <path d="M512 464c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V200.724a48 48 0 0 1 18.387-37.776c24.913-19.529 45.501-35.365 164.2-121.511C199.412 29.17 232.797-.347 256 .003c23.198-.354 56.596 29.172 73.413 41.433 118.687 86.137 139.303 101.995 164.2 121.512A48 48 0 0 1 512 200.724V464zm-65.666-196.605c-2.563-3.728-7.7-4.595-11.339-1.907-22.845 16.873-55.462 40.705-105.582 77.079-16.825 12.266-50.21 41.781-73.413 41.43-23.211.344-56.559-29.143-73.413-41.43-50.114-36.37-82.734-60.204-105.582-77.079-3.639-2.688-8.776-1.821-11.339 1.907l-9.072 13.196a7.998 7.998 0 0 0 1.839 10.967c22.887 16.899 55.454 40.69 105.303 76.868 20.274 14.781 56.524 47.813 92.264 47.573 35.724.242 71.961-32.771 92.263-47.573 49.85-36.179 82.418-59.97 105.303-76.868a7.998 7.998 0 0 0 1.839-10.967l-9.071-13.196z"/>
                                </svg>
                            </span>
                            Message Us
                        </nb-checkbox>
                    </div> -->
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>

<!-- 
<div class="content">
    <div class="row">
        <div class="col-12 white-top-space">
            <form [formGroup]="itemForm">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group bottom-space">
                            <div class="form-check">
                                <input 
                                    class="form-check-input" 
                                    type="checkbox"
                                    formControlName="liveChatAvailable"
                                    #liveChatAvailable>
                                    <i style="margin-left: 10px; font-size: 14px;" class="far fa-comments"></i>
                                    <span style="margin-left: 6px;" class="live-chat-text">Answer myself (live chat)</span>
                            </div>
                            <div *ngIf="liveChatAvailable.checked" class="container white-top-space">
                                <div class="form-check">
                                    <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        name="basedOn"
                                        value="Based on online status" 
                                        formControlName="basedOn"
                                        id="basedOn1">
                                    <label class="form-check-label" for="basedOn1">Available only when we are online</label>
                                </div>
                                <div class="form-check">
                                    <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        name="basedOn" 
                                        formControlName="basedOn"
                                        value="Based on business hours" 
                                        id="basedOn2">
                                    <label class="form-check-label" for="basedOn2">Available during specific hours</label>
                                </div>
                                <div *ngIf="itemForm.get('basedOn').value === 'Based on business hours'" class="container white-top-space">
                                    <div style="overflow-x: auto; width: 100%;">
                                        <ngx-schedule formControlName="businessHours"></ngx-schedule>
                                    </div>
                                </div>
                                <div *ngIf="liveChatAvailable.checked && itemForm.get('basedOn').value === 'Based on business hours'" class="col-12 white-top-space">
                                    <label class="label">Please select your timezone</label>
                                    <ng-moment-timezone-picker [getUserZone]="true" formControlName="timezone"></ng-moment-timezone-picker>
                                </div>
                            </div>
                        </div>
                    </div>			                    
                </div>
            </form>						
            <form [formGroup]="fallbackCommunicationForm">
                <div class="form-group bottom-space">
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="checkbox"
                            formControlName="hasCallUs"
                            #hasCallUs>
                        <span class="live-chat-icon">
                            <svg aria-hidden="true" style="width: 16px;" viewBox="0 0 512 512">
                                <path fill="currentColor"
                                    d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z">
                                </path>
                            </svg>
                        </span>
                        <span class="live-chat-text">Give our contact number</span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="hasCallUs.checked" class="container">
                        <input 
                            type="text" 
                            class="form-control"
                            placeholder="Enter contact number"
                            formControlName="callUsNumber">
                    </div>
                </div>
                <div class="form-group bottom-space">
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="checkbox"
                            #hasWhatsapp formControlName="hasWhatsapp">
                        <span class="live-chat-icon">
                            <svg viewBox="0 0 1219.547 1225.016" style="width: 20px;">
                                <path fill="#E0E0E0"
                                    d="M1041.858 178.02C927.206 63.289 774.753.07 612.325 0 277.617 0 5.232 272.298 5.098 606.991c-.039 106.986 27.915 211.42 81.048 303.476L0 1225.016l321.898-84.406c88.689 48.368 188.547 73.855 290.166 73.896h.258.003c334.654 0 607.08-272.346 607.222-607.023.056-162.208-63.052-314.724-177.689-429.463zm-429.533 933.963h-.197c-90.578-.048-179.402-24.366-256.878-70.339l-18.438-10.93-191.021 50.083 51-186.176-12.013-19.087c-50.525-80.336-77.198-173.175-77.16-268.504.111-278.186 226.507-504.503 504.898-504.503 134.812.056 261.519 52.604 356.814 147.965 95.289 95.36 147.728 222.128 147.688 356.948-.118 278.195-226.522 504.543-504.693 504.543z" />
                                <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="609.77" y1="1190.114" x2="609.77" y2="21.084">
                                    <stop offset="0" stop-color="#20b038" />
                                    <stop offset="1" stop-color="#60d66a" />
                                </linearGradient>
                                <path fill="url(#a)"
                                    d="M27.875 1190.114l82.211-300.18c-50.719-87.852-77.391-187.523-77.359-289.602.133-319.398 260.078-579.25 579.469-579.25 155.016.07 300.508 60.398 409.898 169.891 109.414 109.492 169.633 255.031 169.57 409.812-.133 319.406-260.094 579.281-579.445 579.281-.023 0 .016 0 0 0h-.258c-96.977-.031-192.266-24.375-276.898-70.5l-307.188 80.548z" />
                                <image overflow="visible" opacity=".08" width="682" height="639" xlink:href="FCC0802E2AF8A915.png"
                                    transform="translate(270.984 291.372)" />
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFF"
                                    d="M462.273 349.294c-11.234-24.977-23.062-25.477-33.75-25.914-8.742-.375-18.75-.352-28.742-.352-10 0-26.25 3.758-39.992 18.766-13.75 15.008-52.5 51.289-52.5 125.078 0 73.797 53.75 145.102 61.242 155.117 7.5 10 103.758 166.266 256.203 226.383 126.695 49.961 152.477 40.023 179.977 37.523s88.734-36.273 101.234-71.297c12.5-35.016 12.5-65.031 8.75-71.305-3.75-6.25-13.75-10-28.75-17.5s-88.734-43.789-102.484-48.789-23.75-7.5-33.75 7.516c-10 15-38.727 48.773-47.477 58.773-8.75 10.023-17.5 11.273-32.5 3.773-15-7.523-63.305-23.344-120.609-74.438-44.586-39.75-74.688-88.844-83.438-103.859-8.75-15-.938-23.125 6.586-30.602 6.734-6.719 15-17.508 22.5-26.266 7.484-8.758 9.984-15.008 14.984-25.008 5-10.016 2.5-18.773-1.25-26.273s-32.898-81.67-46.234-111.326z" />
                                <path fill="#FFF"
                                    d="M1036.898 176.091C923.562 62.677 772.859.185 612.297.114 281.43.114 12.172 269.286 12.039 600.137 12 705.896 39.633 809.13 92.156 900.13L7 1211.067l318.203-83.438c87.672 47.812 186.383 73.008 286.836 73.047h.255.003c330.812 0 600.109-269.219 600.25-600.055.055-160.343-62.328-311.108-175.649-424.53zm-424.601 923.242h-.195c-89.539-.047-177.344-24.086-253.93-69.531l-18.227-10.805-188.828 49.508 50.414-184.039-11.875-18.867c-49.945-79.414-76.312-171.188-76.273-265.422.109-274.992 223.906-498.711 499.102-498.711 133.266.055 258.516 52 352.719 146.266 94.195 94.266 146.031 219.578 145.992 352.852-.118 274.999-223.923 498.749-498.899 498.749z" />
                            </svg>
                        </span>
                        <span class="live-chat-text">Redirect to Whatsapp</span>
                    </div>
                    <div *ngIf="hasWhatsapp.checked" style="margin-top: 10px; padding-right: 0;" class="container">
                        <div class="form-group">
                            <label>Select your country and enter your phone number:</label>
                            <select 
                                style="width: 372px;"
                                [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="selectedCountryCode">
                                <option *ngFor="let code of country_codes;" value="{{code.code}}">{{code.name}}</option>											
                            </select>
                            <div style="display: flex; margin-top: 12px;">
                                <input 
                                    type="tel"
                                    class="whatsapp-input"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="selectedCountryCode"
                                    [disabled]="true" 
                                    style="width: 100px; text-align: center;">
                                <input 
                                    type="text"
                                    class="whatsapp-input"
                                    (input)="onChangeWhatsappNumber()"
                                    placeholder="phone number"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="whatsappNumber"
                                    style="width: 272px;">			
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group bottom-space">
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="checkbox" 
                            id="flexCheckDefault" 
                            #hasfbMessenger formControlName="hasfbMessenger">
                        <span class="live-chat-icon">
                            <svg style="width: 21px;" viewBox="-149.1 -248.49675 1292.2 1490.9805" class="live-chat-icon"
                                id="fb">
                                <g transform="translate(-15 -11.5)">
                                    <linearGradient y2="1005.5" x2="512" y1="11.5" x1="512" gradientUnits="userSpaceOnUse" id="b">
                                        <stop offset="0" stop-color="#00b2ff" />
                                        <stop offset="1" stop-color="#006aff" />
                                    </linearGradient>
                                    <path
                                        d="M512 11.5c-280 0-497 205.1-497 482.1 0 144.9 59.4 270.1 156.1 356.6 8.1 7.3 13 17.4 13.4 28.3l2.7 88.4c.9 28.2 30 46.5 55.8 35.2l98.6-43.5c8.4-3.7 17.7-4.4 26.5-2 45.3 12.5 93.6 19.1 143.9 19.1 280 0 497-205.1 497-482.1S792 11.5 512 11.5z"
                                        fill="url(#b)" fill-rule="evenodd" clip-rule="evenodd" />
                                    <path
                                        d="M213.6 634.6l146-231.6c23.2-36.8 73-46 107.8-19.9l116.1 87.1c10.7 8 25.3 7.9 35.9-.1l156.8-119c20.9-15.9 48.3 9.2 34.2 31.4L664.5 614c-23.2 36.8-73 46-107.8 19.9l-116.1-87.1c-10.7-8-25.3-7.9-35.9.1L247.8 666c-20.9 15.9-48.3-9.2-34.2-31.4z"
                                        fill-rule="evenodd" clip-rule="evenodd" fill="#fff" />
                                </g>
                            </svg>
                        </span>
                        <span class="live-chat-text">Redirect to Facebook Messenger</span>
                    </div>
                    <div *ngIf="hasfbMessenger.checked" style="margin-top: 10px; padding-right: 0;" class="container">
                        <div class="form-group">
                            <label>Enter the handle of your Facebook page the app is linked to (For example, https://facebook.com/<span style="color: #0d6efd; font-weight: bold; font-size: 14spx">EnterThisPartOnly</span>):</label>
                            <input 
                                type="text" 
                                class="form-control"
                                (input)="onFBUrlInput($event)"
                                placeholder="Enter page name here">
                        </div>
                    </div>
                </div>
                <div class="form-group bottom-space">
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="checkbox" 
                            id="hasReceiveCallBack" 
                            formControlName="hasReceiveCallBack">
                            <span class="live-chat-icon">
                                <svg viewBox="0 0 512 512" style="width: 16px;">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                            </span>
                        <span class="live-chat-text">Ask for details to call back</span>
                    </div>								
                </div>
            </form>                        
        </div>					
    </div>
</div> -->