<nb-card style="height: 100%;">
    <nb-card-body style="padding: 0px;">
        <div *ngIf="!loading" class="row" style="display: flex; flex-direction: column; justify-content: center; max-width: 1000px; margin: auto; height: 100%;">
            <div class="col-12">
                <div class="header-content">
                    <div class="logo-container">
                        <img style="height: 100%; width: auto;" [src]="activeReseller.logoBig" [alt]="activeReseller.normalName + 'Logo'" class="logo">
                    </div>
                    <!-- <div class="col-12" class="header" >
                        <span class="sign-up-text">{{promoCodeText}}</span>
                    </div> -->
                </div>
                <form [formGroup]="itemForm">
                    <div class="row" style="border-radius: 25px; box-shadow: 0px 0px 50px rgba(0,0,255,0.1); margin: 20px">  
                        <div class="col-md-6 form-fields-container" style="background-color: white; padding-top: 15px;">
                    
                            <div class="col-12 form-content">
                                <div class="form-group">
                                    <label class="label">Full name*</label>
                                    <input
                                        fullWidth
                                        placeholder="Full Name"
                                        formControlName="contactPerson"
                                        type="text"
                                        nbInput/> 
                                </div>
                            </div>
                            <div class="col-12 form-content">
                                <div class="form-group">
                                    <label class="label">Email*</label>
                                    <input  
                                        placeholder="Email Address"
                                        fullWidth
                                        type="email"
                                        formControlName="contactEmail" 
                                        nbInput>  
                                </div>
                            </div>
    
                            <div class="col-12 form-content">
                                <div class="form-group">
                                    <label class="label">Hotel name*</label>
                                    <input
                                        placeholder="Hotel Name"
                                        fullWidth
                                        formControlName="name"
                                        type="text"
                                        nbInput/>
                                </div>
                            </div>
                            <div class="col-12 form-content">
                                <div class="form-group">
                                    <label class="label">Web Address*</label>
                                    <input
                                        fullWidth
                                        placeholder="Web Address"
                                        formControlName="website"
                                        type="text"
                                        nbInput/>
                                </div>
                            </div>
    
                            <div class="col-12 form-content">
                                <div class="form-group">
                                    <label class="label">Country*</label>
                                    <nb-select          
                                        id="country"                  
                                        fullWidth                                   
                                        formControlName="country">
                                        <nb-option (selectionChange)="onCountryChanged($event)" *ngFor="let country of countryDD" [value]="country.name">
                                            {{ country.name }}
                                        </nb-option>
                                    </nb-select>
                                    <!-- <input
                                        nbInput
                                        type="text"
                                        fullWidth
                                        formControlName="country"
                                        placeholder="Search Country"
                                        [nbAutocomplete]="autoControl" />
                                    <nb-autocomplete #autoControl>
                                        <nb-option *ngFor="let option of filteredControlOptions$ | async" [value]="option">
                                            {{ option }}
                                        </nb-option>
                                    </nb-autocomplete> -->
                                </div>
                            </div>
                            <div class="col-12 form-content">
                                <div class="form-group">
                                    <label class="label">City*</label>
                                    <input
                                        fullWidth
                                        placeholder="City"
                                        formControlName="city"
                                        type="text"
                                        nbInput/>
                                </div>
                            </div>
                            <div class="col-12 form-content">
                                <div class="form-group">
                                    <label class="label">Deal Code</label>
                                    <input
                                        fullWidth
                                        placeholder="Deal Code"
                                        formControlName="dealCode"
                                        type="text"
                                        nbInput/>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 15px">
                                <div class="col-12">
                                    <div class="from-check">
                                        <nb-checkbox style="margin-bottom: 0px"               
                                        [(ngModel)]="termsChecked"
                                        [ngModelOptions]="{standalone: true}"></nb-checkbox>                                    
                                        <div>
                                            I agree to GuestChat's <a class="logo-color" style="text-decoration: none;" href="https://guestchat.com/terms-and-conditions/" target="_blank">Terms & Conditions</a>
                                        </div>
                                    </div>
                                    <div class="from-check">
                                        <nb-checkbox 
                                            style="margin-bottom: 0px"           
                                            [(ngModel)]="privacyChecked"
                                            [ngModelOptions]="{standalone: true}">
                                        </nb-checkbox>                                    
                                        <div>
                                            <!-- I accept GuestChat's use of my data for the service and everthing else described in the <a style="text-decoration: none;" href="https://guestchat.com/privacy-policy/" class="logo-color" target="_blank">Privacy Policy and Data Processing Agreement</a> -->
                                            I accept GuestChat's use of my data for the service and everything else described in the <br> <a style="text-decoration: none;" href="https://guestchat.com/privacy-policy/" class="logo-color" target="_blank">Privacy Policy</a> and <a style="text-decoration: none;" href="https://guestchat.com/data-processing-term/" class="logo-color" target="_blank">Data Processing Agreement</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12" style="margin-top: 10px; margin-bottom: 10px; display: flex; justify-content: center; align-items: center;">
                                    <button 
                                        style="width: 100px;"
                                        nbButton 
                                        outline 
                                        status="primary" 
                                        size="medium"
                                        (click)="onSignUp()" 
                                        [disabled]="!itemForm.valid || !privacyChecked || !termsChecked"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 image-container form-image mobile-hide" style="width: 100%; height: 100%; min-height: 720px; margin: 0px; padding: 0px; background-image: url('{{activeReseller.signupLogo}}');">
                            <div class="form-image mobile-hide" style="width: 100%; height: 100%;">
                                <!-- <img style="height: 100%;" [src]="activeReseller.signupLogo" class="background-image"> -->
                                
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="overlay" *ngIf="loading">
            <div class="overlay-position">
                <div class="gc-animation">
                    <div style="background: #095570"></div>
                    <div style="background: #095570"></div>
                    <div style="background: #095570"></div>
                    <div style="background: #095570"></div>
                    <div style="background: #095570"></div>
                    <div style="background: #095570"></div>
                    <div style="background: #095570"></div>
                    <div style="background: #095570"></div>
                    <div style="background: #095570"></div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>