import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { SignUpService } from '../services/sign-up.service';
import { MasterDataService } from '../services/master-data.service';
import { ResellersService } from '../services/resellers.service';
import { Reseller } from '../services/interfaces';
// import { CountriesService } from '../services/countries.service';

@Component({
  selector: 'app-sign-up-pre',
  templateUrl: './sign-up-pre.component.html',
  styleUrls: ['./sign-up-pre.component.scss']
})
export class SignUpPreComponent implements OnInit {

  // State
  loading = false;
  submitting = false;
  showProgressScreen = false;

  // Subscriptions
  routeSub: Subscription;

  // From
  itemForm: FormGroup;  

  // Checked
  termsChecked = false;
  privacyChecked = false;

  // isValid = false;
  // contactEmail = '';
  
  // Promo Codes
  promoCodes = {
    bookingFactory22: 'Get your first 3 months on us, and 10% off therafter', 
    noPromo: 'Sign up for your first free month.'
  };
  promoCodeText: string = `Sign up for your first Free month`;
  promoCode = null;

  webReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  resetEmailSent = false;
  accountCreated = false;
  showWave = false;

  activeReseller: Reseller = null;

  filteredControlOptions$: Observable<string[]>;
  countryDD: any[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private signUpService: SignUpService,
    // private countriesService: CountriesService,
    private masterDataService: MasterDataService,
    // private toastr: ToastrService
    private resellerService: ResellersService,

  ) { }

  ngOnInit(): void {
   
    // this.router.navigate(['/customize']);  // This is for testing without creating a new Customer
    this.initForm();

    // this.countryDD = this.countriesService.getCountries();
    this.countryDD = this.masterDataService.countrySelect;
    this.filteredControlOptions$ = of(this.countryDD);

    this.filteredControlOptions$ = this.itemForm.get('country').valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );

    this.route.params.subscribe((params: Params) => {
      if (params['promoCode']) {
        const key = params['promoCode'];

        if (this.promoCodes[key]) {
          this.promoCode = key;
          this.promoCodeText = this.promoCodes[params['promoCode']];
        }
      }
    });

    this.activeReseller = this.resellerService.activeReseller;


    this.itemForm.get('resellerName').setValue(this.activeReseller?.name);
    console.log(this.itemForm.get('resellerName').value);
  }

  filter(value: string): string[] {
    // console.log(value);
    const filterValue = value.toLowerCase();
    return this.countryDD.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  initForm() {
    this.itemForm = new FormGroup({        
      name: new FormControl<String>(null, Validators.required),
      website: new FormControl<String>(null, [Validators.required, Validators.pattern(this.webReg)]),
      contactPerson: new FormControl<String>(null, Validators.required),
      contactEmail: new FormControl<String>(null, [Validators.required, Validators.email, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      contactNumber: new FormControl<String>(null),
      country: new FormControl<String>(null, Validators.required),
      countryIso: new FormControl<String>(null, Validators.required),
      city: new FormControl<String>(null, Validators.required),
      resellerName: new FormControl<String>(null),
      dealCode: new FormControl<String>(null),
    });  
  }

  onCountryChanged(event: any) {
    // console.log(event.value);
    const selectedCountryObj = this.countryDD.find(el => el.name === event.value);
    // console.log(selectedCountryObj);
    this.itemForm.get('countryIso').setValue(selectedCountryObj.alpha3);

  }

  onSelectionChange(event) {
    console.log(event);
  }
  // onCheckValidEmail(event: any) {
  //   const value = event.target.value;
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
  //     this.isValid = true;
  //   } else {
  //     this.isValid = false;
  //   }
  // }

  // showSuccess(success: boolean, message: string) {
  //   if (success) {
  //     this.toastr.success(message, 'Success', {
  //       timeOut: 3000,
  //     });
  //   } else {
  //     this.toastr.error(message, 'Error', {
  //       timeOut: 3000,
  //     });
  //   }
  // }

  onSignUp() {
    this.submitting = true;
    this.loading = true;
    // Activate Loader

    const saveObj = {
      ...this.itemForm.value,
      promoCode: this.promoCode,
      resellerName: this.activeReseller?.name
    }
    // Create Account
    this.signUpService.newSignUp(saveObj)
      .then((result: any) => {
        if (result.success) {
          this.router.navigate(['/after-signup']);
          // this.submitting = false;
          // // Search Hotel
          // this.signUpService.searchHotel(this.itemForm.get('name').value, this.itemForm.get('city').value, this.itemForm.get('country').value)
          // .then((resp: any) => {
          //   console.log(resp)
          //   this.loading = false;
          //   if (resp.success && resp.hotel.length > 0) {
          //     this.router.navigate(['/is-this-you']);
          //     // Disable Loader

          //   } else {
          //     this.router.navigate(['/customize']);  
          //     // Disable Loader

          //   }
          // })
          // .catch(err => {
          //   console.log(err);  
          // });
        }
      })
      .catch(err => {
        console.log(err); 
        this.submitting = false;        
      });
  }

}
