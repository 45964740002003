import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { SignUpService } from '../services/sign-up.service';
import { SurveyService } from '../services/survey.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})

export class StepperComponent implements OnInit, OnDestroy {

  // State
  loading = true;
  activeIndex = 0

  // Data
  clientId: string = '';
  name: string = '';

  constructor(
    private signUpService: SignUpService,
    private surveyService: SurveyService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.clientId = this.signUpService.clientId;
    this.name = this.signUpService.hotelName;

    await this.onGetDestinationData()
  }

  async onGetDestinationData() {
    this.signUpService.getDestinationDataStatus()
    .then((resp: any) => {
        if (resp.success) {
            console.log(resp.payload)
            if (resp.payload) {
                if (resp.payload.status === 'complete') {
                    console.log('Completed')
                    this.signUpService.getSurvey()
                    .then((resp: any) => {
                        if (resp.success) {
                            console.log(resp.payload)
                            this.loading = false;
                            return;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
                } else {
                    console.log('Waiting')
                    setTimeout(() => {
                        this.onGetDestinationData()
                        // return;
                    }, 9000);
                }
            } else {
                console.log('Create New Survey')
                this.loading = false;
                return;
            }
          }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  async onChangeActiveIndex(update: boolean = true) {
    
    this.activeIndex++
    
    if (this.activeIndex === 7) {
      if (update) {
        await this.onUpdateSurvey()
      }
      await this.onRunMadlips()
      this.router.navigate(['/go-live']);   
    } else if (this.activeIndex > 3 && this.activeIndex < 7) {
      if (update) {
        console.log('Updating Survey')
        await this.onUpdateSurvey()
      }
    } else if (this.activeIndex === 3) {
      console.log('Updating Rooms')
    } else if (this.activeIndex === 1) {
      if (update) {
        this.signUpService.addNewBotSettings()
        .then((resp: any) => {
          if (resp.success) {
            // Active Loader
            // this.router.navigate(['/survey']);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.showSuccess(false, 'Something went wrong. Kindly, try again.');
        });
      }
    } else if (this.activeIndex === 2) {
      if (update) {
        
      }
    }
  }

  async onUpdateSurvey() {
    this.surveyService.updateSurvey()
    .then((result: any) => {
      if (result.success) {
        console.log(result.success)
      }
    })
    .catch(err => {
      console.log(err);        
    });
  }

  async onRunMadlips() {
    this.surveyService.runMadlips()
    .then(result => {
        console.log(result)
    })
    .catch(err => {
      console.log(err);        
    });
  }

  ngOnDestroy(): void {

  }
}
