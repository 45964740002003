<form [formGroup]="itemForm">
    <!-- Day Passes -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 0)" class="check-start checkbox1" #f19 formControlName="hasDayPasses" id="hasDayPasses"></nb-checkbox>                                    
                <div>Day Passes</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[0]}" (click)="onCollapseToggle(0, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[0]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f19.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f19.checked" class="container">
                        <div class="row">
                            <div class="col-12">
                                <h6>What does your day pass include?</h6>
                                <div class="row white-top-space">
                                    <div class="col-12 col-md-5">
                                        <table>
                                            <tr>
                                                <td><label class="label">Amount (USD)</label></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td><input 
                                                        type="number" 
                                                        nbInput                                                   
                                                        formControlName="dayPassesValue"></td>
                                                <td><select 
                                                        placeholder="--select--"                                                 
                                                        formControlName="dayPassesValueUnit">
                                                        <option value="Per person">Per person</option>
                                                        <option value="Per family">Per family</option>                                                                        
                                                        <option value="Per room">Per room</option>                                                                        
                                                    </select></td>
                                            </tr>
                                        </table>
                                        <div class="row">
                                            <div class="col-12 white-top-space">
                                                <label class="label">Day Pass includes the following:</label>
                                            </div>
                                            <div class="col-12 narrow-top-space">
                                                <ngx-checkbox-group
                                                    [singleColumn]="true"
                                                    [options]="['Room Access', 'Pool Access', 'Beach Access', 'Bar Access', 'Restaurant Access', 'Cabanas', 'Food', 'Drinks', 'Towels']"
                                                    formControlName="dayPassesIncludes"></ngx-checkbox-group>                                    
                                            </div>
                                        </div>                                                                  
                                    </div>
                                    <div class="col-12 col-md-7">
                                        <label class="label">Enter any Additional Information (Optional)</label>
                                        <textarea 
                                            nbInput 
                                            formControlName="dayPassesText"
                                            rows="5"
                                            fullWidth>
                                        </textarea>
                                    </div>
                                </div>                                    
                            </div>                                                                
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Beach -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 1)" class="check-start checkbox1" #f20 formControlName="hasBeach" id="hasBeach"></nb-checkbox>                                    
                <div>Beach</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[1]}" (click)="onCollapseToggle(1, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[1]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f20.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f20.checked" class="container">
                        <div class="row">
                            <div class="col-12 col-md-8 white-top-space">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="label">Location</label>
                                        <nb-radio-group 
                                            #beachLocation
                                            name="beachLocation" 
                                            formControlName="beachLocation">
                                            <nb-radio value="Onsite">Onsite</nb-radio>
                                            <nb-radio value="Nearby">Nearby</nb-radio>
                                        </nb-radio-group>   
                                        <div *ngIf="beachLocation.value === 'Nearby'" style="padding-left: 87px; margin-top: 10px;" class="container">
                                            <label class="label">Time from property to the beach (minutes)</label>
                                            <div class="row">
                                                <div class="col-12" style="display: flex; align-items: center; flex-direction: row;">
                                                    <input 
                                                        type="number" 
                                                        nbInput
                                                        min="0"
                                                        style="width: 120px; margin-right: 10px;"
                                                        formControlName="beachNearByFromProperty">                                                        

                                                    <nb-radio-group 
                                                        name="beachNearByFromPropertyWalkingOrDriving" 
                                                        formControlName="beachNearByFromPropertyWalkingOrDriving">
                                                        <nb-radio value="Walking">Walking</nb-radio>
                                                        <nb-radio value="Driving">Driving</nb-radio>
                                                    </nb-radio-group>   
                                                </div>                                                    
                                            </div>                                                
                                        </div>
                                    </div>
                                    <div class="col-12 white-top-space">
                                        <label class="label">Beach type</label>
                                        <nb-radio-group 
                                            #beachNearByType
                                            name="beachNearByType" 
                                            formControlName="beachNearByType">
                                            <nb-radio value="Public beach">Public beach</nb-radio>
                                            <nb-radio value="Private beach">Private beach</nb-radio>
                                        </nb-radio-group>
                                    </div>
                                    <div *ngIf="beachLocation.value === 'Nearby' && beachNearByType.value === 'Private beach'" class="col-12 top-10-margin">
                                        <div class="container">
                                            <nb-checkbox 
                                                formControlName="hasBeachDirectAccess">
                                                Direct access to beach
                                            </nb-checkbox>
                                        </div>
                                    </div>
                                    <div class="col-12 top-white-top-space">
                                        <label class="label white-top-space">Beach Chairs</label>
                                        <nb-checkbox 
                                            #hasBeachChairAvailable    
                                            formControlName="hasBeachChairAvailable">
                                            Available
                                        </nb-checkbox>
                                        <div *ngIf="hasBeachChairAvailable.checked" class="container">
                                            <nb-radio-group 
                                                #beachChairSurcharge
                                                name="beachChairSurcharge" 
                                                formControlName="beachChairSurcharge">
                                                <nb-radio value="Free">Free</nb-radio>
                                                <nb-radio value="Surcharge (Per Person, USD)">Surcharge (Per Person, USD)</nb-radio>
                                            </nb-radio-group>
                                            <div style="padding-left: 96px;" *ngIf="beachChairSurcharge.value === 'Surcharge (Per Person, USD)'" class="container">
                                                <input 
                                                    type="number" 
                                                    nbInput
                                                    min="0"
                                                    style="width: 120px; margin-right: 120px;"
                                                    formControlName="beachChairSurchargeValue">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                                
                        </div>                            
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Airport and Transportation -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 2)" class="check-start checkbox1" #f23 formControlName="hasAirportTransportation" id="hasAirportTransportation"></nb-checkbox>                                    
                <div>Airport and Transportation</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[2]}" (click)="onCollapseToggle(2, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[2]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!f23.checked" class="container">
                        Please activate this facility.
                    </div>
                    <div *ngIf="f23.checked" class="container">
                        <div class="row">
                            <div class="col-12 top-bottom-space">
                                 <table>
                                    <tr>
                                        <td><label class="label">Preferred Airport Name</label></td>                                                        
                                        <td><label class="label">Distance to property (miles)</label></td>                                                        
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                            nbInput
                                            type="text"
                                            fullWidth
                                            formControlName="airportTransportationName"
                                            placeholder="Start typing name or iata"
                                            [nbAutocomplete]="autoControl" />
                                            <nb-autocomplete #autoControl>
                                                <nb-option *ngFor="let option of filteredControlOptions$ | async" [value]="option">
                                                    {{ option }}
                                                </nb-option>
                                            </nb-autocomplete>
                                            <!-- <div class="ng-autocomplete">
                                                <ng-autocomplete 
                                                    #autoInput
                                                    [data]="filteredOptions"
                                                    [searchKeyword]="keyword"
                                                    placeholder="Start typing name or iata"
                                                    (selected)='onSelectAirportOptions($event)'
                                                    (inputChanged)='onChangeAirportName($event)'
                                                    formControlName="airportTransportationName"
                                                    [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate">                                 
                                                </ng-autocomplete>
                                                
                                                <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                                </ng-template>
                                                
                                                <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>                                                 -->
                                        </td>
                                        <td><input 
                                                type="number"
                                                min="0" 
                                                nbInput
                                                formControlName="airportTransportationDistance">
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-12 white-top-space">            
                                <label class="label">Transport options available from the airport to the property:</label>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <ngx-checkbox-group
                                                    [singleColumn]="false"
                                                    [options]="['Bus', 'Metro', 'Train', 'Ferry', 'Ride Sharing', 'Car Rental']"                    
                                                    formControlName="airportTransportationOptions">
                                                </ngx-checkbox-group>
                                            </div>                    
                                        </div>
                                    </div>                    
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-6 col-md-6">
                                                <div style="margin-top: 20px;" class="form-group">
                                                    <nb-checkbox #airportTransportationTaxi  formControlName="airportTransportationTaxi">
                                                        Taxi
                                                    </nb-checkbox>
                                                    <div class="container" *ngIf="airportTransportationTaxi.checked">
                                                        <div class="row">
                                                            <div class="col-8 col-md-6">
                                                                <div class="form-group">
                                                                    <label class="label">Estimated price (USD)</label>
                                                                    <input                                                          
                                                                        type="number"
                                                                        min="0" 
                                                                        nbInput
                                                                        formControlName="airportTransportationTaxiPrice">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div style="margin-top: 20px;" class="form-group">
                                                    <nb-checkbox #airportTransportationShuttle  formControlName="airportTransportationShuttle">
                                                        Shuttle
                                                    </nb-checkbox>
                                                    <div class="container" *ngIf="airportTransportationShuttle.checked">
                                                        <nb-radio-group 
                                                            #airportTransportationShuttlePrice 
                                                            name="airportTransportationShuttlePrice"
                                                            formControlName="airportTransportationShuttlePrice">
                                                            <nb-radio value="Free">Free</nb-radio>
                                                            <nb-radio value="Surcharge">Surcharge</nb-radio>
                                                        </nb-radio-group>
                                                        <div *ngIf="airportTransportationShuttlePrice.value == 'Surcharge'" class="container">
                                                            <div class="container medium-top-white-space">
                                                                <div class="row">
                                                                    <div class="col-8 col-md-6">
                                                                        <div class="form-group">
                                                                            <label class="label">Amount (USD)</label>
                                                                            <input                                                          
                                                                                type="number"
                                                                                min="0" 
                                                                                nbInput
                                                                                formControlName="airportTransportationShuttlePriceValue">
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <select 
                                                                                formControlName="airportTransportationShuttlePriceUnit">
                                                                                <option value="Per person">Per person</option>
                                                                                <option value="Per vehicle">Per vehicle</option>
                                                                            </select>
                                                                        </div>                
                                                                    </div>
                                                                </div>
                                                            </div>                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                
                            </div>
                        </div>              
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Benefits Of Direct Booking -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>                                   
                <div>Benefits Of Direct Booking</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[3]}" (click)="onCollapseToggle(3, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[3]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <label style="display: inline; margin-right: 20px;" class="label">List of benefits of booking direct:</label>
                    <a (click)="onAddbookingDirectBenefits()" style="cursor: pointer;">
                        <nb-icon tooltip="Click here to an item to the list" icon="plus-outline" status="info"></nb-icon></a>
                    <!-- <a 
                        (click)="onAddbookingDirectBenefits()" 
                        title="Click here to an item to the list." 
                        style="font-size: 20px; cursor: pointer; text-decoration: none; color: #095570">
                        <i class="fa fa-plus"></i>
                    </a> -->
                    <div style="margin-top: 10px;">
                        <ngx-checkbox-group
                            [singleColumn]="false"
                            [options]="bookingDirectBenefitsCB"
                            formControlName="bookingDirectBenefits"></ngx-checkbox-group>                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Specials -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" (checkedChange)="onCheckboxChange($event, 4)" class="check-start checkbox1" #hasPaymentSpecials formControlName="hasPaymentSpecials" id="hasPaymentSpecials"></nb-checkbox>                                    
                <div>Specials</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[4]}" (click)="onCollapseToggle(4, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[4]">
            <div class="row gc-spacing">
                <div class="col-12">
                    <div *ngIf="!hasPaymentSpecials.checked" class="container">
                        Please activate this facility.
                    </div>            
                    <div *ngIf="hasPaymentSpecials.checked" class="container room-flex">
                        <div style="width: 320px;">
                            <label class="label">Text</label>
                            <textarea 
                                nbInput
                                formControlName="specialsText"
                                rows="5"
                                fullWidth>
                            </textarea>
                        </div>
                        <div style="width: 320px; padding-left: 20px;">
                            <label class="label">Specials Link(s) <a (click)="onAddSpecialLink()" style="cursor: pointer;">
                                <nb-icon tooltip="Add special link" icon="plus-outline" status="info"></nb-icon></a>
                            </label>
                            <div formArrayName="specialsUrls" class="white-top-space">
                                <div 
                                    class="row" 
                                    style="margin-bottom: 6px; padding-bottom: 16px;"
                                    *ngFor="let specialLink of specialsUrlsFA.controls; let specialLinkIndex=index;"
                                    [formGroupName]="specialLinkIndex">
                                    <div class="col-10">                                            
                                        <input 
                                            nbInput
                                            fullWidth
                                            type="text"                            
                                            formControlName="link">
                                    </div>
                                    <div class="col-1">
                                        <a 
                                            style="cursor: pointer;" 
                                            (click)="onDeleteSpecialLink(specialLinkIndex)">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Weddings -->
    <div class="row gc-survey" *ngIf="isCateringTo('Weddings')">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>                                    
                <div>Weddings</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[5]}" (click)="onCollapseToggle(5, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[5]">
            <div class="row gc-spacing">
                <div class="col-12">            
                    <div class="container room-flex">
                        <div style="width: 320px;">
                            <label class="label">Text</label>
                            <textarea 
                                nbInput
                                formControlName="weddingsText"
                                rows="5"
                                fullWidth>
                            </textarea>
                        </div>
                        <div style="width: 320px; padding-left: 20px;">
                            <label class="label">Weddings Link(s) <a (click)="onAddWeddingsLink()" style="cursor: pointer;">
                                <nb-icon tooltip="Add wedding link" icon="plus-outline" status="info"></nb-icon></a>
                            </label>
                            <div formArrayName="weddingsUrls" class="white-top-space">
                                <div 
                                    class="row" 
                                    style="margin-bottom: 6px; padding-bottom: 16px;"
                                    *ngFor="let weddingLink of weddingsUrlsFA.controls; let weddingLinkIndex=index;"
                                    [formGroupName]="weddingLinkIndex">
                                    <div class="col-10">                                            
                                        <input 
                                            nbInput
                                            min="0"
                                            fullWidth
                                            type="text"                            
                                            formControlName="link">
                                    </div>
                                    <div class="col-1">
                                        <a 
                                            style="cursor: pointer;" 
                                            (click)="onDeleteWeddingsLink(weddingLinkIndex)">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Cancellation and NO SHOW policy -->
    <div class="row gc-survey">
        <div class="gc-survey-header col-12">
            <div class="gc-survey-header-left">                                   
                <nb-checkbox style="margin-bottom: 0px" [disabled]="true" [checked]="true"></nb-checkbox>                                    
                <div>Cancellation and NO SHOW policy</div>
            </div>
            <div class="gc-survey-header-right">
                <div class="icon-container" [ngClass]="{collapsed: !collapsedArray[6]}" (click)="onCollapseToggle(6, $event)">
                    <nb-icon icon="arrow-ios-downward-outline" style="color: #ffffff !important" status="info"></nb-icon>
                </div> 
            </div>
        </div>
        <div class="gc-survey-content col-12" *ngIf="!collapsedArray[6]">
            <div class="row gc-spacing">
                <div class="col-12">           
                    <div class="container room-flex">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="label">Is there a grace period when guests can cancel without penalty?</label>
                                        <nb-radio-group 
                                            #cancelFreeOfCharge
                                            name="cancelFreeOfCharge" 
                                            formControlName="cancelFreeOfCharge">
                                            <nb-radio value="Yes">Yes</nb-radio>
                                            <nb-radio value="No">No</nb-radio>
                                        </nb-radio-group>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="label">Enter optional external link to your cancellation policy</label>
                                        <input 
                                            nbInput
                                            fullWidth
                                            (input)="onChangeCancellationLink($event)" 
                                            type="text"                            
                                            formControlName="cancellationLink">
                                        <small style="color: red;" *ngIf="linkValidator.hasError">{{linkValidator.message}}</small>
                                    </div>
                                </div>
                            </div>                                
                            <div *ngIf="cancelFreeOfCharge.value == 'Yes'" class="container">
                                <div class="row">
                                    <div class="col-8">
                                        <label class="label">What is that grace period?</label>
                                        <div class="container">
                                            <label>There is no charge if cancelled: </label> 
                                            <ngx-grace-period-definer formControlName="cancellationGraceDefinition"></ngx-grace-period-definer>                                                
                                        </div>
                                    </div>
                                </div>
                                
                                <div formArrayName="cancellationPenaltyGrace" class="white-top-space">
                                    <label class="label">What are the penalties after the grace period?</label>
                                    <div 
                                        class="row" 
                                        style="margin-bottom: 14px;"
                                        *ngFor="let cPolicy of cancellationPenaltyGraceFA.controls; let cancelPolicyIndex=index;"
                                        [formGroupName]="cancelPolicyIndex">
                                        <div class="col-8">                                            
                                            <div class="container">
                                                <label>If cancelled: </label>                                                    
                                                <ngx-grace-period-definer formControlName="cancellationPenaltyGraceDefinition"></ngx-grace-period-definer>                                                    
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label>Then will be charged: </label>                                                        
                                            <textarea 
                                                rows="2" 
                                                style="min-width: 160px;" 
                                                formControlName="cancellationChargeAfterGracePeriod" 
                                                nbInput fullWidth 
                                                placeholder="Enter your answer here">
                                            </textarea>                                                                                                    
                                        </div>
                                        <div class="col-1">
                                            <a 
                                                style="cursor: pointer;" 
                                                (click)="onDeleteCancellationPenaltyGrace(cancelPolicyIndex)">
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </div>                                            
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 white-top-space">
                                        <a 
                                            class="link-btn" 
                                            (click)="onAddCancellationPenaltyGrace()">
                                            <i class="far fa-plus-circle i-style"></i> 
                                            Add Another Restriction
                                        </a>
                                    </div>
                                </div>                                    
                            </div>
                            <div *ngIf="cancelFreeOfCharge.value == 'No'" class="container">
                                <div formArrayName="noCancellationPenaltyGrace">
                                    <label class="label">What are the penalties for cancellation?</label>
                                    <div 
                                        class="row" 
                                        *ngFor="let cPolicy of noCancellationPenaltyGraceFA.controls; let noCancelPolicyIndex=index;"
                                        [formGroupName]="noCancelPolicyIndex">
                                        <div class="col-8">                                            
                                            <div class="container">
                                                <label>If cancelled: </label>                                                    
                                                <ngx-grace-period-definer formControlName="noCancellationPenaltyGraceDefinition"></ngx-grace-period-definer>                                                    
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label>Then will be charged: </label>                                                        
                                            <textarea 
                                                rows="2"
                                                style="min-width: 160px;" 
                                                formControlName="noCancellationChargeAfterGracePeriod" 
                                                nbInput fullWidth 
                                                placeholder="Enter your answer here">
                                            </textarea>                                                
                                        </div>
                                        <div class="col-1">
                                            <a 
                                                style="cursor: pointer; float: right;" 
                                                (click)="onDeleteNoCancellationPenaltyGrace(noCancelPolicyIndex)">
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 white-top-space">
                                        <a 
                                            class="link-btn" 
                                            (click)="onAddNoCancellationPenaltyGrace()">
                                            <i class="far fa-plus-circle i-style"></i> 
                                            Add Another Restriction
                                        </a>
                                    </div>
                                </div>                                    
                            </div>
                            <div class="form-group white-top-space">
                                <label class="label">Is there a charge for a No-Show?</label>
                                <nb-radio-group 
                                    #paymentNoShowCharge
                                    name="paymentNoShowCharge" 
                                    formControlName="paymentNoShowCharge">
                                    <nb-radio value="Yes">Yes</nb-radio>
                                    <nb-radio value="No">No</nb-radio>
                                </nb-radio-group>
                            </div>
                            <div *ngIf="paymentNoShowCharge.value == 'Yes'" class="container">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="form-group white-top-space">
                                            <label class="label">How much is the guest charged for a no-show?</label>
                                            <textarea 
                                                rows="4" 
                                                formControlName="noShowGuestCharge" 
                                                nbInput fullWidth placeholder="Enter your answer here"></textarea>                                                
                                        </div>
                                    </div>
                                </div>
                            </div>                                
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>