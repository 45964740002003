<div class="row">
    <div 
        [ngClass]="{'col-md-6' : !singleColumn}" 
        class="col-12" 
        *ngFor="let item of barTypes; let i = index" 
        style="min-width: 100px; margin-bottom: 12px;">
        <nb-checkbox 
            [ngModel]="getActiveState(item)" 
            (checkedChange)="onChangeCheckbox($event, item)">
            {{item}}
        </nb-checkbox>
        <ngx-schedule
            *ngIf="getActiveState(item)" 
            (newChangedEvent)="onChangeSchedule($event, item)"
            [(ngModel)]="value[getValueIndexOfItem(item)].openTimes">
        </ngx-schedule>
    </div>
</div>