import { Component } from '@angular/core';
import { ResellersService } from './services/resellers.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private resellersService: ResellersService,

  ) {
    this.resellersService.setActiveReseller();
  }


}
 