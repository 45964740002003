import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Layout
import { LayoutComponent } from './layout/layout.component';

// Components
import { SignUpPreComponent } from './sign-up-pre/sign-up-pre.component';
import { IsThisYouComponent } from './is-this-you/is-this-you.component';
import { GoLiveComponent } from './go-live/go-live.component';
import { StepperComponent } from './stepper/stepper.component';
import { PreviewComponent } from './preview/preview.component';
import { AfterSignupComponent } from './after-signup/after-signup.component';

const routes: Routes = [
    { path: 's', component: LayoutComponent,
      children: [
        { path: ':id', component: PreviewComponent },
      ]
    },
    { path: '', component: LayoutComponent,
      children: [
        { path: 'sign-up',  component: SignUpPreComponent },
        { path: 'sign-up/:promoCode',  component: SignUpPreComponent },
        { path: 'is-this-you',  component: IsThisYouComponent },
        { path: 'after-signup', component: AfterSignupComponent },
        { path: 'customize', component: StepperComponent },
        { path: 'go-live',  component: GoLiveComponent },

        { path: '**',  redirectTo: '/sign-up' },
        // { path: '**',  redirectTo: '/customize' },
      ]  
    },    

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {}
