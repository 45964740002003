import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

// Services
import { SignUpService } from './sign-up.service';

// Interfaces
import { RoomCategory } from './interfaces';

// Other
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class RoomCategoriesService {


  // baseUrl = 'http://localhost:3000';
  baseUrl = 'https://api2.guestchat.com';
  TOKEN_KEY = 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse';

  roomCategories$ = new BehaviorSubject<RoomCategory[]>([]);

  constructor(
    private signUpService: SignUpService,
    private httpClient: HttpClient
  ) {}

  // Get Room Categories
  public async getRoomCategories() {
    this.roomCategories$.next([]);
    const clientId = this.signUpService.clientId;
    const path = this.baseUrl +'/signup/getRoomCategories';
    
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('content-type', 'application/json')
        .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');

      this.httpClient.post(path, {clientId: clientId}, { headers })
        .subscribe((resp: any) => {
          if (resp.success) {
            this.roomCategories$.next(resp.payload);
            resolve(resp.payload);
          } else {
            this.roomCategories$.next(resp.payload);
            resolve(resp.payload);
          }
        }, (err) => {            
          reject(err.statusText);
        });
    });
  }

  // Add Room Category
  async addRoomCategory(roomCategoryObj: RoomCategory) {
    try {
      const clientId = this.signUpService.clientId;
      const path = this.baseUrl +'/signup/addRoomCategory';

      const update: RoomCategory = {
        ...roomCategoryObj,
        updatedOn: new Date(),
        createdOn: new Date(),
      };

      return new Promise((resolve, reject) => {
        const headers = new HttpHeaders().set('content-type', 'application/json')
          .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');
  
        this.httpClient.post(path, {clientId: clientId, roomCategory: update}, { headers })
          .subscribe((resp: any) => {
            if (resp.success) {
              resolve(resp);
            }
          }, (err) => {            
            reject(err.statusText);
          });
      });

    } catch (error) {
      console.log(error);
      this._handleError();
      throw new Error('error');
    }
  }

  // Update Room Category
  async updateRoomCategory(categoryId: string, categoryObj: RoomCategory) {   
    try {
      const clientId = this.signUpService.clientId;
      const path = this.baseUrl +'/signup/updateRoomCategory';

      const update: RoomCategory = {
        ...categoryObj,
        updatedOn: new Date(),
      };

      return new Promise((resolve, reject) => {
        const headers = new HttpHeaders().set('content-type', 'application/json')
          .set('authorization', 'Bearer cvisdj dsiofhosf340sdfm;234m;mcsdsgsgse');
  
        this.httpClient.post(path, {clientId: clientId, roomCategoryId: categoryId, roomCategory: update}, { headers })
          .subscribe((resp: any) => {
            if (resp.success) {
              resolve(resp);
            }
          }, (err) => {            
            reject(err.statusText);
          });
      });

    } catch (error) {
      console.log(error);
      this._handleError();
      throw new Error('error');
    }
  }

  // Delete Room Category
  async deleteRoomCategory(categoryId: string) {
    const clientId = this.signUpService.clientId;
    
    try {

      // await this.afs.collection('Clients').doc(clientId).collection('RoomCategories').doc(categoryId).delete();
      return 'success';
    } catch (error) {
      console.log(error);
      this._handleError();
      throw new Error('error');
    }
  }

  private _handleError() {
    // this.toastrService.show('There was an error', 'Error', { status: 'danger' });
  }

  ngOnDestroy() {}
  
}
