import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ngx-checkbox-group',
  templateUrl: './checkboxes.component.html',
  styleUrls: ['./checkboxes.component.scss'],
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => CheckboxesComponent),
       multi: true
    }
  ]
})
export class CheckboxesComponent implements OnInit {
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled = false;

  value: string[] = [];

  @Input() options: string[];
  @Input() images: string[] = [];
  @Input() singleColumn: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
    
  }

  writeValue(value: string[]): void {
      if (Array.isArray(value)) {
        this.value = value || [];        
        //remove duplicates
        this.value = this.value.filter(function (value, index, array) { 
          return array.indexOf(value) === index;
        });
      }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getActiveState(item: string) {
    if (this.value) {
      const index = this.value?.findIndex(el => el === item) > -1;
      return index;
    } else {
      return false;
    }
  }

  onChangeCheckbox(value: boolean, item: string){
    if (value) {
      if (this.value?.findIndex(el => el === item) < 0) {
        this.value.push(item);
      }
    } else {
      const index = this.value.findIndex(el => el === item);
      this.value.splice(index, 1);
    }
    this.onChange(this.value)
    // console.log(this.value);
  }
}
