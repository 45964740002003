<div class="flex-space-between">
    <img style="height: 100%; width: auto;" class="logo" src="/assets/logos/Logo new color.png" alt="company logo guest chat">
    <div class="flex-vertical-center">
        <p class="mobile-hide" style="font-size: 14px;">Already have an account?</p>
        <button 
            nbButton 
            outline 
            status="primary" 
            size="medium" 
            outline
            (click)="onLogin()">
            Log In
        </button>
    </div>
</div>
<hr>
