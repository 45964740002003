<button   
    class="gc-button"
    [ngClass]="{left: botSettings.screenPosition=='left', bar: botSettings.buttonShape=='bar', 'side-bar': botSettings.buttonShape=='side-bar'}"
    [ngStyle]="buttonStyle">
  
    <img 
        *ngIf="botSettings.botImgUrl" 
        [src]="botSettings.botImgUrl" 
        alt="avatar of bot" class="avatar"
        [ngStyle]="backgroundSuccess">

    <span class="bar-text" *ngIf="botSettings.buttonShape=='bar'">
        Let's chat
    </span>

    <span *ngIf="botSettings.buttonShape=='side-bar'">
        <span class="side-bar-text">
            Let's chat
        </span>
    </span>
    <div id="gc_exclamation" tabindex="-1">1</div>
</button>

