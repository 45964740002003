<div 
    [ngClass]="{left: botSettings.screenPosition=='left'}" 
    class="main-container" >
    <div class="chat-window" >
        <!-- [ngStyle]="{'background': background}" -->
        <div class="chat-heading" [ngStyle]="backgroundSuccess">

            <div class="chatting-with">
                <div class="available-operators" *ngIf="botSettings.title">
                    <img [src]="botSettings.botImgUrl" alt="avatar of bot">
                </div>
                <div class="chat-name">
                    {{botSettings.title}}
                </div>

            </div>
            <div class="operations">
                <div class="ellipses-icon" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"/></svg>
                </div>
                <div class="close-icon" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/></svg>
                </div>
            </div>
        </div>




        <div class="column-container" [style.height]="columnContainerHeight">
            <div class="chat-container" #scrollContainer>
                <div class="messages">
                    <div class="each-msg">
                      {{botSettings.openingLine}}  
                    </div>
                </div>
            </div>
        </div>


        <div class="input-container">          

            <div class="entry-container" >
                <div class="left-items" >
                    <input type="text"  placeholder="Type a message..." #textInput  />
                </div>

                <div class="icon-container" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M440 6.5L24 246.4c-34.4 19.9-31.1 70.8 5.7 85.9L144 379.6V464c0 46.4 59.2 65.5 86.6 28.6l43.8-59.1 111.9 46.2c5.9 2.4 12.1 3.6 18.3 3.6 8.2 0 16.3-2.1 23.6-6.2 12.8-7.2 21.6-20 23.9-34.5l59.4-387.2c6.1-40.1-36.9-68.8-71.5-48.9zM192 464v-64.6l36.6 15.1L192 464zm212.6-28.7l-153.8-63.5L391 169.5c10.7-15.5-9.5-33.5-23.7-21.2L155.8 332.6 48 288 464 48l-59.4 387.3z"/></svg>
                </div>
            </div>

            
        </div>


    </div>
</div>