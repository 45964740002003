import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

// Services
// import { LocationService } from '../../services/location.service';
import { MasterDataService } from '../../services/master-data.service';
import { SurveyService } from '../../services/survey.service';
import { airports } from '../../services/airports';
// import { RoomCategoriesService } from '../../services/room-categories.service';

// Interface
import { RoomCategory, Survey } from '../../services/interfaces';
import { SignUpService } from 'src/app/services/sign-up.service';
// import { NbTagComponent } from '@nebular/theme';

@Component({
  selector: 'ngx-survey-page-two',
  templateUrl: './survey-page-two.component.html',
  styleUrls: ['./survey-page-two.component.scss']
})
export class SurveyPageTwoComponent implements OnInit, OnDestroy {
   
    loading = true;

    // Form
    editMode = false;
    editItem: Survey;
    itemForm: UntypedFormGroup;
  
    weekDays = [];
    scheduleTime = [];
    barTypes = [];
    barServing = [];
    breakfastTypes = [];
    mealPlansDD = [];
    
    collapsedArray = [true, true, true, true, true];
  
    formValueSub: Subscription;
    surveySub: Subscription;
  
    //communicate farm validity to parent
    @Output() isValidForm = new EventEmitter<boolean>();
    formIsValid = null;
  
    airportsDD;
    ageFromAllowed;
    
    //notes
    // frontDeskNote = null;
    // checkInNote = null;
    // restaurantNote = null;
    // barNote = null;
    // breakfastNote = null;
    // mealNote = null;
    // poolNote = null;
    // parkingNote = null;
    // fitnessNote = null;
    // spaNote = null;
    // centerNote = null;
    // conferenceNote = null;
    // roomNote = null;
    // housekeepingNote = null;
    // babysittingNote = null;
    // petsNote = null;
    // dayPassesNote = null;
    // beachNote = null;
    // luggageNote = null;
    // jacuzziNote = null;
    // airportNote = null;
    
    roomCategories: string[] = [];
    roomCategorySub: Subscription;
  
    filteredOptions: {}[];
    @ViewChild('autoInput') input;
    airportsDB: {}[] = [];
  
    saving = false;
    hasPets = false;
    hasConferences = false;
  
    showAirportNameOptions = false;
    @ViewChild('selectAirport') selectAirport: ElementRef;
  
    keyword = 'name';
    popupShow: string;

  constructor(
    private signupService: SignUpService,
    private surveyService: SurveyService,
    private masterDataService: MasterDataService
    ) { }

  ngOnInit(): void {

    this.weekDays = this.masterDataService.daysOfWeek;
    this.barTypes = this.masterDataService.barTypeItems;
    this.barServing = this.masterDataService.barServingItems;
    this.breakfastTypes = this.masterDataService.breakfasts;
    this.mealPlansDD = this.masterDataService.mealPlansDD.map(el => el.value);

    this.scheduleTime = this.masterDataService.scheduleTime;
    this.airportsDB = airports.map((el) => {
      return {
        id: el.name + ' (' + el.iata  + ')',
        name: el.name + ' (' + el.iata  + ')'
      };
    });
    this.airportsDD = this.airportsDB.slice(0, 20);
    
    this.filteredOptions = this.airportsDD;

    if (this.formValueSub) {
        this.formValueSub.unsubscribe();
      }

    this.initForm();
    console.log(this.itemForm.value);


    this.surveySub = this.signupService.survey$.subscribe(
      survey => {
        this.editItem = survey;
        const tempObj = {};
        this.initForm();
        if (this.itemForm) {
          this.itemForm.reset();
        }

        if (this.editItem) {
          Object.keys(this.editItem).forEach(key => {
            tempObj[key] = survey[key].value
          });
          
          this.itemForm.patchValue(tempObj);

          if (this.itemForm.get('hasRestaurant').value) {
            this.collapsedArray[0] = false
          }
          if (this.itemForm.get('hasBar').value) {
            this.collapsedArray[1] = false
          }
          if (this.itemForm.get('hasBreakfast').value) {
            this.collapsedArray[2] = false
          }
          if (this.itemForm.get('hasDinner').value) {
            this.collapsedArray[3] = false
          }
          if (this.itemForm.get('hasMealPlans').value) {
            this.collapsedArray[4] = false
          }
        }


          //check petsNote
          if (this.editItem && this.editItem.propertyCatersTo) {
            const tempArr = this.editItem.propertyCatersTo.value;
            if (tempArr.length) {              
              this.hasPets = tempArr.indexOf('Pets') > -1;
              // this.hasConferences = tempArr.indexOf('Conferences') > -1;
              // if (!this.hasConferences && this.itemForm.get('hasConferenceFacilities').value) {
              //   this.itemForm.get('hasConferenceFacilities').setValue(false);
              // }
            }
          }

        // this.surveyService.loadingData$.next(false);
        // this.loading = false;
        console.log(this.itemForm.value);
      }
    )

    this.ageFromAllowed = this.masterDataService.ageFromAllowed;


    this.loading = false;

  }

  initForm() { 
    this.itemForm = new UntypedFormGroup({      
      hasRestaurant: new UntypedFormControl(this.editItem ? (this.editItem.hasRestaurant ? this.editItem.hasRestaurant.value : false) : null),
      numberOfRestaurants: new UntypedFormControl(this.editItem ? (this.editItem.numberOfRestaurants ? this.editItem.numberOfRestaurants.value : null) : null),
      restaurantCuisine: new UntypedFormControl(this.editItem ? (this.editItem.restaurantCuisine ? this.editItem.restaurantCuisine.value : null) : null),
      restaurantDietaryOptions: new UntypedFormControl(this.editItem ? (this.editItem.restaurantDietaryOptions ? this.editItem.restaurantDietaryOptions.value : []) : null),
      restaurantDressCode: new UntypedFormControl(this.editItem ? (this.editItem.restaurantDressCode ? this.editItem.restaurantDressCode.value : null) : null),
      
      hasBar: new UntypedFormControl(this.editItem ? (this.editItem.hasBar ? this.editItem.hasBar.value : false) : null),
      numberOfBars: new UntypedFormControl(this.editItem ? (this.editItem.numberOfBars ? this.editItem.numberOfBars.value : null) : null),
      barTypes: new UntypedFormControl(this.editItem ? (this.editItem.barTypes ? this.editItem.barTypes.value : []) : null),
      barServing: new UntypedFormControl(this.editItem ? (this.editItem.barServing ? this.editItem.barServing.value : []) : null),
      // barNoLicense: new FormControl(this.editItem ? (this.editItem.barNoLicense ? this.editItem.barNoLicense.value : null) : null),
      // barServeWine: new FormControl(this.editItem ? (this.editItem.barServeWine ? this.editItem.barServeWine.value : null) : null),
      // barServeBeer: new FormControl(this.editItem ? (this.editItem.barServeBeer ? this.editItem.barServeBeer.value : null) : null),
      // barServeCocktails: new FormControl(this.editItem ? (this.editItem.barServeCocktails ? this.editItem.barServeCocktails.value : null) : null),
      // barServeHardLiquor: new FormControl(this.editItem ? (this.editItem.barServeHardLiquor ? this.editItem.barServeHardLiquor.value : null) : null),
      barDescription: new UntypedFormControl(this.editItem ? (this.editItem.barDescription ? this.editItem.barDescription.value : null) : null),
      hasNonGuestsBar: new UntypedFormControl(this.editItem ? (this.editItem.hasNonGuestsBar ? this.editItem.hasNonGuestsBar.value : false) : null),
      
      hasBreakfast: new UntypedFormControl(this.editItem ? (this.editItem.hasBreakfast ? this.editItem.hasBreakfast.value : false) : null),
      breakfastOpeningSchedule: new UntypedFormControl(this.editItem ? (this.editItem.breakfastOpeningSchedule ? this.editItem.breakfastOpeningSchedule.value : []) : null),
      breakfastCharge: new UntypedFormControl(this.editItem ? (this.editItem.breakfastCharge ? this.editItem.breakfastCharge.value : null) : null),
      breakfastSurcharge: new UntypedFormControl(this.editItem ? (this.editItem.breakfastSurcharge ? this.editItem.breakfastSurcharge.value : null) : null),
      breakfastSurchargeAmount: new UntypedFormControl(this.editItem ? (this.editItem.breakfastSurchargeAmount ? this.editItem.breakfastSurchargeAmount.value : null) : null),
      breakfastSurchargeAmountMin: new UntypedFormControl(this.editItem ? (this.editItem.breakfastSurchargeAmountMin ? this.editItem.breakfastSurchargeAmountMin.value : null) : null),
      breakfastSurchargeAmountMax: new UntypedFormControl(this.editItem ? (this.editItem.breakfastSurchargeAmountMax ? this.editItem.breakfastSurchargeAmountMax.value : null) : null),
      breakfastType: new UntypedFormControl(this.editItem ? (this.editItem.breakfastType ? this.editItem.breakfastType.value : []) : null),
      
      hasDinner: new UntypedFormControl(this.editItem ? (this.editItem.hasDinner ? this.editItem.hasDinner.value : false) : null),
      dinnerOpeningSchedule: new UntypedFormControl(this.editItem ? (this.editItem.dinnerOpeningSchedule ? this.editItem.dinnerOpeningSchedule.value : []) : null),
      dinnerMenuTypes: new UntypedFormControl(this.editItem ? (this.editItem.dinnerMenuTypes ? this.editItem.dinnerMenuTypes.value : []) : null),
      dinnerNote: new UntypedFormControl(this.editItem ? (this.editItem.dinnerNote ? this.editItem.dinnerNote.value : null) : null),
      dinnerCuisine: new UntypedFormControl(this.editItem ? (this.editItem.dinnerCuisine ? this.editItem.dinnerCuisine.value : null) : null),
      dinnerReservationRequired: new UntypedFormControl(this.editItem ? (this.editItem.dinnerReservationRequired ? this.editItem.dinnerReservationRequired.value : null) : null),
      
      hasMealPlans: new UntypedFormControl(this.editItem ? (this.editItem.hasMealPlans ? this.editItem.hasMealPlans.value : false) : null),
      mealPlan: new UntypedFormControl(this.editItem ? (this.editItem.mealPlan ? this.editItem.mealPlan.value : []) : null),
      mealPlanDescription: new UntypedFormControl(this.editItem ? (this.editItem.mealPlanDescription ? this.editItem.mealPlanDescription.value : '') : null),

    });
    

    this.formValueSub = this.itemForm.valueChanges.subscribe(values => {
      this.surveyService.processChanges(values);
    });
    
    this.itemForm.updateValueAndValidity();
  }

  onCheckboxChange(event, index) {
    if (event && this.collapsedArray[index]) {
     this.onCollapseToggle(index, event);
   }
 }

 onCollapseToggle(i, event: any) {
  this.collapsedArray.forEach((el, index) => {
    if (index === i) {
      this.collapsedArray[index] = !this.collapsedArray[index];
    }
  });

  this.scrollIntoView(event.target);
  }

  scrollIntoView(el: HTMLElement) {
    if (el) {
      el.scrollIntoView();
    }
  }

  ngOnDestroy() {
    if (this.formValueSub) {
      this.formValueSub.unsubscribe();
    }    
    if (this.surveySub) {
      this.surveySub.unsubscribe();
    }
  }
}


