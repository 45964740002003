<div class="main-container">
    <div class="row" style="margin-bottom: 15px;">
        <div class="day">
            <label class="label">Day</label>
        </div>
        <div class="from-to">
            <label class="label">From</label>
        </div>
        <div class="from-to">
            <label class="label">To</label>
        </div>
        <div class="all-day">
            <label class="label">Open 24 hours</label>
        </div>
    </div>
    <div *ngFor="let day of weekDays; let dayIndex = index;">
        <div *ngIf="!scheduleModel[0].enabled || day === 'every day'" class="row">
            <div class="day">
                <div class="form-check">
                    <input 
                        class="form-check-input" 
                        type="checkbox" 
                        id="day-check"
                        [(ngModel)]="scheduleModel[dayIndex].enabled" 
                        (change)="onDayCheckedChanged($event, day, dayIndex)">
                    <span for="day-check">{{ day | titlecase }}</span>
                </div>                
            </div>
            <div class="from-to">
                <select [disabled]="!scheduleModel[dayIndex].enabled" *ngIf="!scheduleModel[dayIndex].allDay" [(ngModel)]="scheduleModel[dayIndex].from" (change)="setValue()" style="width: 110px;" placeholder="--select--">
                    <option *ngFor="let time of scheduleTime" [value]="time.value">
                        {{ time.label }}
                    </option>
                </select>
            </div>
            <div class="from-to">
                <select [disabled]="!scheduleModel[dayIndex].enabled" *ngIf="!scheduleModel[dayIndex].allDay" [(ngModel)]="scheduleModel[dayIndex].to" (change)="setValue()" style="width: 110px;" placeholder="--select--">
                    <option *ngFor="let time of scheduleTime" [value]="time.value">
                        {{ time.label }}
                    </option>
                </select>
            </div>
            <div class="all-day">
                <div class="form-check">
                    <input
                        type="checkbox"
                        [style]="{'border-color': '#3366ff'}" 
                        [disabled]="!scheduleModel[dayIndex].enabled" 
                        (change)="onChangeAllDay($event, dayIndex)"
                        [(ngModel)]="scheduleModel[dayIndex].allDay">
                </div>                
            </div>
        </div>        
    </div>
</div>
