import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { CopyClipboardDirective } from './copy-clipboard.pipe';
// import { MomentTimezonePickerModule } from 'moment-timezone-picker';

// Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { AppTopbarComponent } from './layout/app-topbar/app-topbar.component';
import { SignUpPreComponent } from './sign-up-pre/sign-up-pre.component';
import { IsThisYouComponent } from './is-this-you/is-this-you.component';
import { StepperComponent } from './stepper/stepper.component';
import { CustomWidgetComponent } from './custom-widget/custom-widget.component';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { GoLiveComponent } from './go-live/go-live.component';
import { PreviewComponent } from './preview/preview.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PopupComponent } from './popup/popup.component';
import { StartButtonComponent } from './start-button/start-button.component';

// Modules
import { CustomComponentsModule } from './custom-components/custom-components.module';
import { SurveyModule } from './survey/survey.module';
import { RoomCategoriesModule } from './room-categories/room-categories.module';

import { 
  NbThemeModule, 
  NbStepperModule, 
  NbRadioModule,
  NbCardModule,
  NbButtonModule,
  NbInputModule,
  NbSpinnerModule,
  NbActionsModule,
  NbIconModule,
  NbListModule,
  NbRouteTabsetModule,
  NbTabsetModule,
  NbCheckboxModule,
  NbSelectModule,
  NbTreeGridModule,
  NbWindowModule,
  NbTagModule, 
  NbLayoutModule,
  NbPopoverModule,
  NbIconLibraries,
  NbAccordionModule,
  NbAutocompleteModule,
  NbDialogModule  } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AfterSignupComponent } from './after-signup/after-signup.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AppTopbarComponent,
    SignUpPreComponent,
    IsThisYouComponent,
    StepperComponent,
    CustomWidgetComponent,
    LiveChatComponent,
    GoLiveComponent,
    PreviewComponent,
    PageNotFoundComponent,
    PopupComponent,
    StartButtonComponent,
    CopyClipboardDirective,
    AfterSignupComponent,
    
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,

    NbThemeModule.forRoot(),
    NbDialogModule.forRoot(),
    NbStepperModule,
    NbRadioModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbSpinnerModule,
    NbActionsModule,
    NbIconModule,
    NbListModule,
    NbRouteTabsetModule,
    NbTabsetModule,
    NbCheckboxModule,
    NbSelectModule,
    NbTreeGridModule,
    NbWindowModule,
    NbTagModule, 
    NbLayoutModule,
    NbPopoverModule,
    NbEvaIconsModule,
    NbAccordionModule,
    NbAutocompleteModule,
    CustomComponentsModule,
    SurveyModule,
    RoomCategoriesModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
  constructor(
    private iconLibraries: NbIconLibraries,
  ) {
    this.iconLibraries.registerSvgPack('guest-chat', { 'botgc': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style="width:22px;padding-right: 2px; margin-left: 5px;"><defs><style>.gcbot{fill:#231f20;}</style></defs><title>Bot_icon_64x64</title><g id="Layer_2" data-name="Layer 2"><path class="gcbot" d="M55.26,23.46a1.5,1.5,0,0,1-.85-.75A24.8,24.8,0,0,0,34.14,8.55a1,1,0,0,1-.91-1V6A1.28,1.28,0,0,1,33.82,5,2.67,2.67,0,0,0,35.06,2.7a2.73,2.73,0,1,0-4.27,2.23A1.29,1.29,0,0,1,31.37,6V7.44a1.06,1.06,0,0,1-1,1.06A24.83,24.83,0,0,0,9.46,22.75a1.46,1.46,0,0,1-.84.76c-4.71,1.69-8,5.41-8,9.73s3.25,8,7.94,9.72a1.46,1.46,0,0,1,.84.76A24.83,24.83,0,0,0,32,58.1a25.2,25.2,0,0,0,5.93-.71,1.48,1.48,0,0,1,1.35.33l2.18,1.93L46.54,64l.21-6.83.1-3.35a1.48,1.48,0,0,1,.56-1.12,25,25,0,0,0,7-8.93A1.5,1.5,0,0,1,55.3,43c4.75-1.68,8.06-5.42,8.06-9.76S60,25.14,55.26,23.46Zm-1.92,9.78a8.63,8.63,0,0,1-8.62,8.63H19.21a8.63,8.63,0,0,1-8.62-8.63h0a8.62,8.62,0,0,1,8.62-8.63H44.72a8.62,8.62,0,0,1,8.62,8.63Z"/><circle class="gcbot" cx="22.19" cy="33.42" r="4.91"/><circle class="gcbot" cx="41.91" cy="33.42" r="4.91"/></g></svg>' });
    this.iconLibraries.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }
}
